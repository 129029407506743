import { faPlus, faWeightHanging } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Col, ProgressBar, Row, Spinner } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { logout, sendRequestAuth } from '../../services/apiFunctions';
import ChangeCoins from '../charSheetModals/ChangeCoins';
import NewEquipment from '../charSheetModals/NewEquipment';

export default function Inventory({ char, coins, reload }) {
    const [equipment, setEquipment] = useState([])
    const [chosenEquipment, setChosenEquipment] = useState({});
    const [carryingWeight, setCarryingWeight] = useState(0)

    const [showCoins, setShowCoins] = useState(false);
    const handleCloseCoins = () => setShowCoins(false);

    const [showEquipment, setShowEquipment] = useState(false);
    const handleCloseEquipment = (reload = true) => {
        if (reload) {
            loadContent()
        }
        setShowEquipment(false);
    }

    const navigate = useNavigate()

    function calcCarryPerc(weight) {
        return weight / (char.strength * 15) * 100;
    }

    const { mutate: mutateEquipment, isLoading: isLoadingEquipment } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setEquipment(data.data.result);

                var carryingWeightTemp = 0;
                data.data.result.map(equip => {
                    carryingWeightTemp = carryingWeightTemp + (equip.weightVal * equip.amount);
                    return true;
                })
                setCarryingWeight(carryingWeightTemp);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    function loadContent() {
        var request = {
            name: "getEquipmentOfChar",
            param: {
                charID: parseInt(char.id)
            }
        };
        mutateEquipment(request);
    }

    useEffect(() => {
        loadContent()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className='text-primary mt-3 alert alert-light bg-white-tp cursor-pointer' onClick={() => { setShowCoins(true); }}>
                <Row className=''>
                    <Col>Copper Pieces</Col>
                    <Col>Silver Pieces</Col>
                    <Col>Gold Pieces</Col>
                    <Col>Platinum Pieces</Col>
                </Row>
                <Row className='h5'>
                    <Col>{coins.copper}</Col>
                    <Col>{coins.silver}</Col>
                    <Col>{coins.gold}</Col>
                    <Col>{coins.platinum}</Col>
                </Row>
            </div>
            <div className='text-primary mt-3 alert alert-light bg-white-tp' >
                <h3 className="text-center mb-3">Equipment</h3>
                <hr />
                <div className="text-start p-0">
                    {!isLoadingEquipment ? equipment.length > 0 ? equipment.map((item) => (
                        <div key={item.equipID}>
                            <Row className="cursor-pointer" onClick={() => { setChosenEquipment(item); setShowEquipment(true); }}>
                                <Col>
                                    <p className="ms-1 m-0" >
                                        {item.equipmentName !== "" ? item.equipmentName : item.name} {item.amount > 1 ? "(x" + item.amount + ")" : null}

                                    </p>
                                </Col>
                                {item.weightVal ?
                                    <Col xs="auto" className='text-end'>
                                        {item.weightVal * item.amount + " lb."}
                                    </Col>
                                    : null}
                            </Row>

                            <hr />
                        </div>
                    ))
                        :
                        null
                        :
                        <Spinner animation="border" className="my-2" />
                    }
                </div>
                <div className="d-grid gap-2 mt-3">
                    <Button variant="outline-primary" onClick={() => { setChosenEquipment(null); setShowEquipment(true); }}>
                        <FontAwesomeIcon icon={faPlus} /> Equipment
                    </Button>
                </div>
                {equipment ?
                    <>
                        <ProgressBar style={{ height: "32px", backgroundColor: "#9b9b9b" }} className="p-0 mt-3" >
                            <ProgressBar striped variant={calcCarryPerc(carryingWeight) <= 66.67 ? "success" : calcCarryPerc(carryingWeight) <= 90 ? "warning" : calcCarryPerc(carryingWeight) <= 100 ? "orange" : "danger"}
                                now={calcCarryPerc(carryingWeight)}
                                key={1} />
                            <div className="position-absolute bar-lable h6 p-0 pt-1 mt-0 text-light fill-available">
                                <FontAwesomeIcon icon={faWeightHanging} /> {carryingWeight + "/" + char.strength * 15} lb
                            </div>
                        </ProgressBar>
                        <div style={{ backgroundColor: "", marginLeft: "66%", marginTop: "-32px", height: "32px" }} className='text-start border-start border-warning border-3'></div>
                        <div style={{ backgroundColor: "", marginLeft: "90%", marginTop: "-32px", height: "32px" }} className='text-start border-start border-orange border-3'></div>
                        {calcCarryPerc(carryingWeight) > 66.67 ?
                            <div className='mt-3'>
                                {calcCarryPerc(carryingWeight) <= 90 ?
                                    <>
                                        <b>Encumbered</b><br /> Speed -10 ft.
                                    </>
                                    : calcCarryPerc(carryingWeight) <= 100 ?
                                        <>
                                            <b>Heavily Encumbered</b> <br /> Speed -20 ft.<br /><br /> You have disadvantage on ability checks, attack rolls, and saving throws that use Strength, Dexterity, or Constitution
                                        </>
                                        :
                                        <>
                                            <b>You are immobile</b><br /><br /> You have disadvantage on ability checks, attack rolls, and saving throws that use Strength, Dexterity, or Constitution
                                        </>
                                }
                            </div>
                            : null}
                    </>
                    : null}
            </div>
            <ChangeCoins show={showCoins} handleClose={handleCloseCoins} char={char} reload={reload} />
            <NewEquipment show={showEquipment} handleClose={handleCloseEquipment} char={char} equipment={chosenEquipment} reload={reload} />
        </div >
    )
}
