import { faArrowUp19, faHashtag, faHeart, faShieldHalved, faShuffle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Col, Dropdown, DropdownButton, Form, InputGroup, ListGroup, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { API_URL, logout, sendRequestAuth } from '../services/apiFunctions';

export default function AddMonster({ show, handleClose, type, playerChars, reload }) {

    const [formData, setFormData] = useState({ monster: "---", pic: 0 })
    const [alert, setAlert] = useState("");
    const [monsters, setMonsters] = useState([]);
    const [selectedMonster, setSelectedMonster] = useState([]);
    const [healthCalc, setHealthCalc] = useState("Value");
    const [search, setSearch] = useState("")

    const navigate = useNavigate()

    const gameID = localStorage.getItem("gameID");

    const { mutate: mutateGetMonsters, isLoading: isLoadingGetMonsters } = useMutation(sendRequestAuth, {
        onError: () => {
            setAlert("An error occured while uploading data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                setMonsters(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setAlert(data.data.message);
            }
        },
    });

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            setAlert("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                handleClose();
                reload();
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setAlert(data.data.message);
            }
        },
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    function handleMonsterSelect(monster) {
        setSelectedMonster(monster);

        const hp = Math.floor(((Number(monster.HPdice) + 1) / 2) * Number(monster.HPmultiplier) + Number(monster.HPadd));

        setFormData(values => ({ ...values, "monster": monster }))
        setFormData(values => ({ ...values, "name": monster.name }))
        setFormData(values => ({ ...values, "ac": monster.AC }))
        setFormData(values => ({ ...values, "hp": hp }))

        //console.log(monster)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var request;

        if (type === "Companions") {
            request = {
                name: "addCompanion",
                param: {
                    monsterID: parseInt(selectedMonster.id),
                    gameID: parseInt(gameID),
                    name: formData.name,
                    ac: parseInt(formData.ac),
                    hp: parseInt(formData.hp),
                    maxHP: parseInt(formData.hp),
                    charID: parseInt(formData.playerAssign)
                }
            };
        } else if (type === "Fight") {
            if (formData.playerAssign === 0) {
                request = {
                    name: "addToFight",
                    param: {
                        charID: parseInt(selectedMonster.id),
                        gameID: parseInt(gameID),
                        type: "monster",
                        name: formData.name,
                        ac: parseInt(formData.ac),
                        hp: parseInt(formData.hp),
                        maxHP: parseInt(formData.hp),
                        count: parseInt(formData.count),
                        healthCalc: healthCalc
                    }
                };
            } else {
                request = {
                    name: "addCompanionAndFight",
                    param: {
                        monsterID: parseInt(selectedMonster.id),
                        gameID: parseInt(gameID),
                        name: formData.name,
                        ac: parseInt(formData.ac),
                        hp: parseInt(formData.hp),
                        maxHP: parseInt(formData.hp),
                        charID: parseInt(formData.playerAssign),
                        count: parseInt(formData.count),
                        healthCalc: healthCalc
                    }
                };
            }
        }

        //console.log(request.param);
        mutate(request);
    }

    useEffect(() => {
        setFormData({ monster: "---", pic: 0, playerAssign: 0 });
        setAlert("");
        setSelectedMonster({});
        setHealthCalc("Value");
        setSearch("");

        const request = {
            name: "getMonsters",
            param: {
            }
        };

        mutateGetMonsters(request)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    function handleHPCalcChange(way) {
        if (document.getElementById("HP")) {
            if (way === "Value") {
                setHealthCalc("Value")
                document.getElementById("HP").type = "number"
                document.getElementById("HP").value = Math.floor(((Number(selectedMonster.HPdice) + 1) / 2)
                    * Number(selectedMonster.HPmultiplier)
                    + Number(selectedMonster.HPadd));
            } else if (way === "Random") {
                setHealthCalc("Random")
                document.getElementById("HP").type = "text"
                document.getElementById("HP").value = selectedMonster.HPmultiplier + "d" + selectedMonster.HPdice + "+" + selectedMonster.HPadd;
            }
        }
    }

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    return (
        <Modal size="lg" backdrop="static" show={show} onHide={handleClose}>
            <Form onSubmit={handleSubmit} className="mb-3">
                <Modal.Header closeButton>
                    <Modal.Title>Add Monster to {type}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isLoading || isLoadingGetMonsters ? <div className="text-center"><Spinner animation="border" /></div> : null
                    }
                    {
                        alert !== "" ?
                            <div className="alert alert-danger">
                                {alert}
                            </div>
                            :
                            null
                    }
                    {monsters ? monsters.length > 0 ?
                        <div className="d-grid gap-2">
                            <Form.Group className='mb-2'>
                                <Form.Control type="text" name="search" id="search" placeholder="Search..." onChange={handleSearch} />
                            </Form.Group>
                        </div>
                        : null : null}
                    <div className="chooseList border rounded">
                        <ListGroup defaultActiveKey={"#" + (selectedMonster ? selectedMonster.id : 0)}>
                            {monsters ? monsters.length > 0 ? monsters.filter(monster => monster.name.toLowerCase().includes(search.toLowerCase())).map(monster => (
                                <ListGroup.Item key={monster.id} href={"#" + monster.id} action onClick={() => handleMonsterSelect(monster)}>
                                    <Row>
                                        <Col className="text-start pe-0">
                                            {monster.name}
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            )) : <i>Create a monster first</i> : null}
                        </ListGroup>
                    </div>
                    {formData.monster !== "---" ?
                        <div className='mt-3' key={selectedMonster.id}>
                            <Row className="mb-3">
                                {selectedMonster.pic ?
                                    <Col className="col-auto">
                                        <img src={API_URL + "/monsterCharPics/" + selectedMonster.id + ".png"} alt="" height="100px" className='rounded'></img>
                                    </Col>
                                    : null}
                                <Col className="d-flex justify-content-center align-items-center">
                                    <Form.Control type="text" name="name" defaultValue={selectedMonster.name} onChange={handleChange} />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <div className="col-auto h2">
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip>Armor Class</Tooltip>}
                                    >
                                        <FontAwesomeIcon icon={faShieldHalved} />
                                    </OverlayTrigger>
                                </div>
                                <div className="col-3">
                                    <Form.Control type="number" min="0" name="ac" defaultValue={selectedMonster.AC} onChange={handleChange} />
                                </div>
                                <div className="col-auto h2">
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip>Health Points</Tooltip>}
                                    >
                                        <FontAwesomeIcon icon={faHeart} />
                                    </OverlayTrigger>
                                </div>
                                <Col>
                                    {type === "Fight" ?
                                        <InputGroup className="mb-3">
                                            <DropdownButton
                                                variant="outline-secondary"
                                                title={healthCalc === "Value" ? <FontAwesomeIcon icon={faHashtag} /> : <FontAwesomeIcon icon={faShuffle} />}
                                                id="input-group-dropdown-1"
                                            >
                                                <Dropdown.Item onClick={() => handleHPCalcChange("Value")}>Value</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleHPCalcChange("Random")}>Random</Dropdown.Item>
                                            </DropdownButton>
                                            <Form.Control id="HP" disabled={healthCalc === "Value" ? false : true} type="number" min="1" name="hp" defaultValue={Math.floor(((Number(selectedMonster.HPdice) + 1) / 2)
                                                * Number(selectedMonster.HPmultiplier)
                                                + Number(selectedMonster.HPadd))}
                                                onChange={handleChange} />
                                        </InputGroup>
                                        :
                                        <Form.Control id="HP" type="number" min="1" name="hp" defaultValue={Math.floor(((Number(selectedMonster.HPdice) + 1) / 2)
                                            * Number(selectedMonster.HPmultiplier)
                                            + Number(selectedMonster.HPadd))}
                                            onChange={handleChange} />
                                    }

                                </Col>
                            </Row>
                            {type === "Fight" ?
                                <Row className="mb-3">
                                    <div className="col-auto h2">
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={<Tooltip>How many to add</Tooltip>}
                                        >
                                            <FontAwesomeIcon icon={faArrowUp19} />
                                        </OverlayTrigger>
                                    </div>
                                    <Col>
                                        <Form.Control type="number" min="1" max="50" name="count" defaultValue={1} onChange={handleChange} />
                                    </Col>
                                </Row>
                                : null}
                            <Row className="mb-3">
                                <Col>
                                    <Form.Label>Assign to Character</Form.Label>
                                    <Form.Select name="playerAssign" onChange={handleChange} required>
                                        <option value="0">None</option>
                                        <option disabled="disabled">----</option>
                                        {playerChars ? playerChars.map(char =>
                                            <option key={char.id} value={char.id}>{char.name}</option>
                                        ) : null}
                                    </Form.Select>
                                </Col>
                            </Row>

                        </div>
                        : null}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Abort
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Add to {type}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
