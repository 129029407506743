import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Col, Form, InputGroup, Modal, Row, Spinner, ToggleButton } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { logout, sendRequestAuth, } from '../../services/apiFunctions';

export default function NewWeapon({ show, handleClose, charID, weapon, reload }) {

    const [deleteQuestion, setDeleteQuestion] = useState(false);

    const [formData, setFormData] = useState({})
    const [alert, setAlert] = useState("");
    const [proficient, setProficient] = useState(false);
    const [hidden, setHidden] = useState(false);

    const navigate = useNavigate()

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            setAlert("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                reload();
                handleClose();
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setAlert(data.data.message);
            }
        },
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var request;
        if (weapon) {
            request = {
                name: "editWeapon",
                param: {
                    id: parseInt(weapon.id),
                    name: formData.name,
                    attackScore: formData.attackScore,
                    attackMod: parseInt(formData.attackMod),
                    attackRange: formData.attackRange,
                    proficient: proficient,
                    damageScore: formData.damageScore,
                    damageDice: parseInt(formData.damageDice),
                    damageDiceMultiplier: parseInt(formData.damageDiceMultiplier),
                    damageMod: parseInt(formData.damageMod),
                    damageType: formData.damageType,
                    hidden: hidden
                }
            };
        } else {
            request = {
                name: "newWeapon",
                param: {
                    charID: parseInt(charID),
                    name: formData.name,
                    attackScore: formData.attackScore,
                    attackMod: parseInt(formData.attackMod),
                    attackRange: formData.attackRange,
                    proficient: proficient,
                    damageScore: formData.damageScore,
                    damageDice: parseInt(formData.damageDice),
                    damageDiceMultiplier: parseInt(formData.damageDiceMultiplier),
                    damageMod: parseInt(formData.damageMod),
                    damageType: formData.damageType,
                    hidden: hidden
                }
            };
        }

        //console.log(request.param);
        mutate(request);
    }

    function handleDelete() {
        const request = {
            name: "deleteWeapon",
            param: {
                id: weapon.id
            }
        };
        mutate(request);
    }

    useEffect(() => {
        setAlert("");
        setDeleteQuestion(false);

        if (weapon) {
            setProficient(weapon.proficient === 1 ? true : false)
            setHidden(weapon.hidden)

            setFormData(values => ({ ...values, "name": weapon.name }))
            setFormData(values => ({ ...values, "attackScore": weapon.attackScore }))
            setFormData(values => ({ ...values, "attackMod": weapon.attackMod }))
            setFormData(values => ({ ...values, "attackRange": weapon.attackRange }))
            setFormData(values => ({ ...values, "damageScore": weapon.damageScore }))
            setFormData(values => ({ ...values, "damageDice": weapon.damageDice }))
            setFormData(values => ({ ...values, "damageDiceMultiplier": weapon.damageDiceMultiplier }))
            setFormData(values => ({ ...values, "damageMod": weapon.damageMod }))
            setFormData(values => ({ ...values, "damageType": weapon.damageType }))
        } else {
            setProficient(false)
            setHidden(false)

            setFormData(values => ({ ...values, "name": "" }))
            setFormData(values => ({ ...values, "attackScore": "strength" }))
            setFormData(values => ({ ...values, "attackMod": 0 }))
            setFormData(values => ({ ...values, "attackRange": "5" }))
            setFormData(values => ({ ...values, "damageScore": "strength" }))
            setFormData(values => ({ ...values, "damageDice": 4 }))
            setFormData(values => ({ ...values, "damageDiceMultiplier": 1 }))
            setFormData(values => ({ ...values, "damageMod": 0 }))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <Modal size="lg" backdrop="static" show={show} onHide={() => handleClose(false)}>
            <Form onSubmit={handleSubmit} className="mb-3">
                <Modal.Header closeButton>
                    <Modal.Title>{weapon ? "Edit " + weapon.name : "Add new Weapon"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isLoading ? <div className="text-center"><Spinner animation="border" /></div> : null
                    }
                    {
                        alert !== "" ?
                            <div className="alert alert-danger">
                                {alert}
                            </div>
                            :
                            null
                    }
                    {weapon && weapon.equipID !== 0 ?
                        <div className="alert alert-info">You can't delete this weapon or change its name, because its related to an item in your inventory. Please delete or update the associated Item in the inventory tab to make these changes.</div>
                        : null}
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name="name" defaultValue={weapon ? weapon.name : null} onChange={handleChange} disabled={weapon && weapon.equipID !== 0} />
                    </Form.Group>
                    <h5>Attack</h5>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Ability Score</Form.Label>
                                <Form.Select aria-label="Default select example" name="attackScore" defaultValue={weapon ? weapon.attackScore : null} onChange={handleChange}>
                                    <option value="strength">Strength</option>
                                    <option value="dexterity">Dexterity</option>
                                    <option value="constitution">Constitution</option>
                                    <option value="intelligence">Intelligence</option>
                                    <option value="wisdom">Wisdom</option>
                                    <option value="charisma">Charisma</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Mod</Form.Label>
                                <Form.Control type="number" name="attackMod" defaultValue={weapon ? weapon.attackMod : 0} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Range</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control type="text" name="attackRange" defaultValue={weapon ? weapon.attackRange : "5"} onChange={handleChange} />
                                    <InputGroup.Text>ft.</InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col className="col-auto">
                            <Form.Group className="mb-3">
                                <Form.Label>Proficient</Form.Label><br />
                                <ButtonGroup>
                                    <ToggleButton
                                        type="radio"
                                        variant={'outline-primary'}
                                        checked={!proficient}
                                        onClick={(e) => { setProficient(false) }}
                                    >
                                        <FontAwesomeIcon icon={faXmark} />
                                    </ToggleButton>
                                    <ToggleButton
                                        type="radio"
                                        variant={'outline-primary'}
                                        checked={proficient}
                                        onClick={(e) => { setProficient(true) }}
                                    >
                                        <FontAwesomeIcon icon={faCheck} />
                                    </ToggleButton>
                                </ButtonGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <h5>Damage</h5>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Ability Score</Form.Label>
                                <Form.Select aria-label="Default select example" name="damageScore" defaultValue={weapon ? weapon.damageScore : null} onChange={handleChange}>
                                    <option value="strength">Strength</option>
                                    <option value="dexterity">Dexterity</option>
                                    <option value="constitution">Constitution</option>
                                    <option value="intelligence">Intelligence</option>
                                    <option value="wisdom">Wisdom</option>
                                    <option value="charisma">Charisma</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Damage Type</Form.Label>
                                <Form.Select name="damageType" defaultValue={weapon ? weapon.damageType : null} onChange={handleChange}>
                                    <option value="Slashing">Slashing</option>
                                    <option value="Piercing">Piercing</option>
                                    <option value="Bludgeoning">Bludgeoning</option>
                                    <option value="Poison">Poison</option>
                                    <option value="Acid">Acid</option>
                                    <option value="Radiant">Radiant</option>
                                    <option value="Necrotic">Necrotic</option>
                                    <option value="Lightning">Lightning</option>
                                    <option value="Thunder">Thunder</option>
                                    <option value="Force">Force</option>
                                    <option value="Psychic">Psychic</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Dice Multi.</Form.Label>
                                <Form.Control type="number" name="damageDiceMultiplier" min="1" defaultValue={weapon ? weapon.damageDiceMultiplier : 1} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Dice</Form.Label>
                                <Form.Select aria-label="Default select example" name="damageDice" defaultValue={weapon ? weapon.damageDice : 4} onChange={handleChange}>
                                    <option value="4">d4</option>
                                    <option value="6">d6</option>
                                    <option value="8">d8</option>
                                    <option value="10">d10</option>
                                    <option value="12">d12</option>
                                    <option value="20">d20</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Mod</Form.Label>
                                <Form.Control type="number" name="damageMod" defaultValue={weapon ? weapon.damageMod : 0} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>

                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Check // prettier-ignore
                                    type="checkbox"
                                    id={"hide"}
                                    name={"hidden"}
                                    label={"Hide this weapon"}
                                    checked={hidden}
                                    onChange={e => setHidden(!hidden)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {weapon ? deleteQuestion ?
                        <div className='text-center container-fluid'>
                            <hr />
                            <div>
                                <p>Do you really want to delete this weapon?</p>
                                <Button variant="outline-primary me-2" onClick={() => setDeleteQuestion(false)}>
                                    Abort
                                </Button>
                                <Button variant="primary" onClick={handleDelete}>
                                    Delete
                                </Button>
                            </div>
                            <hr />
                        </div>
                        : weapon.equipID === 0 ?
                            <Button variant="outline-primary" onClick={() => setDeleteQuestion(true)}>
                                Delete
                            </Button>
                            : null
                        : null}
                    <Button variant="secondary" onClick={() => handleClose(false)}>
                        Abort
                    </Button>
                    <Button variant="primary" type="submit">
                        Save Weapon
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
