import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import useDocumentTitle, { logout, sendRequestAuth } from '../services/apiFunctions'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons'
import ShowItem from '../parts/ShowItem'
import NewItem from '../parts/NewItem'

export default function Items() {

    useDocumentTitle("Items");

    const [dataItems, setDataItems] = useState("")
    const [search, setSearch] = useState("")
    const [item, setItem] = useState()
    const [showAll, setShowAll] = useState(false)

    const [showItem, setShowItem] = useState(false);
    const [showItemEdit, setShowItemEdit] = useState(false);

    function reload() {
        const request = { name: "getUserItems", param: {} };
        mutate(request);
    }

    const handleCloseItem = () => {
        setShowItem(false);
    };

    const handleCloseItemEdit = () => {
        setShowItemEdit(false)
    };

    const navigate = useNavigate()

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            setDataItems("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setDataItems(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setDataItems(data.data.message);
            }
        },
    });

    const handleSearch = (e) => {
        if (e.target.value.length > 0) {
            setShowAll(true)
        } else {
            setShowAll(false)
        }
        setSearch(e.target.value);
    }

    useEffect(() => {
        const request = { name: "getUserItems", param: {} };
        mutate(request);

        setShowAll(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleItemEditClick(item) {
        setItem(item);
        if (parseInt(localStorage.getItem('userID')) === item.userID) {
            setShowItemEdit(true)
        }
    }

    function handleItemShowClick(item) {
        setItem(item);
        //console.log(item);
        setShowItem(true);
    }

    return (
        <Container className="py-4 text-center">
            <Row>
                <Col></Col>
                <Col xl={8} className="position-relative">
                    <div className="position-absolute top-0 start-0 ps-3">
                        <Link to="/home" className='btn btn-outline-primary text-left'><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    </div>
                    <div className="position-absolute top-0 end-0 pe-3">
                        <Button variant="outline-primary" onClick={() => { setItem(null); setShowItemEdit(true); }}><FontAwesomeIcon icon={faPlus} /></Button>
                    </div>
                    <h2 className='mb-4'>Your Items</h2>
                    {dataItems ? dataItems.length > 0 ?
                        <div className="d-grid gap-2">
                            <Form.Group className='mb-4'>
                                <Form.Control type="text" name="search" id="search" placeholder="Search..." onChange={handleSearch} />
                            </Form.Group>
                        </div>
                        : null : null}
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" /> : null
                    }
                    {dataItems ? dataItems.length > 0 ? dataItems.slice(0, showAll ? 100000 : 1000).filter(item =>
                        item.name.toLowerCase().includes(search.toLowerCase()) || item.source.toLowerCase().includes(search.toLowerCase())).map(d => (
                            <div className={d.public === 0 ? 'alert alert-light text-primary bg-white-tp p-1 cursor-pointer' : 'alert alert-light text-primary p-1 cursor-pointer'}
                                key={d.id}>
                                <Row>
                                    <Col className="text-start py-2 ms-2 pe-0" onClick={() => handleItemShowClick(d)}>
                                        <h4>{d.name}</h4>
                                    </Col>
                                    {parseInt(localStorage.getItem('userID')) === d.userID ?
                                        <Col className="d-flex col-auto align-items-center">
                                            <Button className="me-4" variant="outline-primary" onClick={() => { handleItemEditClick(d); }}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                        </Col>
                                        : null}
                                </Row>

                            </div>
                        )) : <i>Create a spell by clicking on the <b>+</b></i> : null}
                    {!showAll && !isLoading ?
                        <Button variant='outline-primary' onClick={() => setShowAll(true)}>Load all items</Button>
                        : null}
                </Col>
                <Col></Col>
            </Row>
            <ShowItem show={showItem} handleClose={handleCloseItem} item={item} removeButton={false} />
            <NewItem show={showItemEdit} handleClose={handleCloseItemEdit} item={item} reload={reload} />
        </Container>
    )
}
