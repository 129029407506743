import React, { useState } from 'react'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import useDocumentTitle, { sendRequest } from '../services/apiFunctions';

export default function Register() {

    useDocumentTitle("Register");

    const [formData, setFormData] = useState({})
    const [alert, setAlert] = useState("");

    const navigate = useNavigate()

    const { mutate, isLoading } = useMutation(sendRequest, {
        onError: () => {
            setAlert("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            if (data.data.status === 200) {
                localStorage.setItem('registerMail', formData.email);
                navigate("/");
            } else {
                setAlert(data.data.message);
            }
        },
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (formData.pass === formData.pass2) {
            const request = {
                name: "registerUser",
                param: {
                    email: formData.email,
                    name: formData.name,
                    pass: formData.pass
                }
            };
            mutate(request);
        } else {
            setAlert("Passwords do not match");
        }
    }

    return (
        <Container className="py-5 text-center">
            <Row>
                <Col></Col>
                <Col xl={6}>
                    <h2 className="mb-4">Register</h2>
                    {
                        isLoading ? <Spinner animation="border" /> : null
                    }
                    {
                        alert !== "" ?
                            <div className="alert alert-danger">
                                {alert}
                            </div>
                            :
                            null
                    }
                    <Form onSubmit={handleSubmit} className="mb-3">
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>E-Mail</Form.Label>
                            <Form.Control type="text" name="email" onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Nickname</Form.Label>
                            <Form.Control type="text" name="name" onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="pass">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" name="pass" onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="pass2">
                            <Form.Label>Repeat Password</Form.Label>
                            <Form.Control type="password" name="pass2" onChange={handleChange} />
                        </Form.Group>
                        <div className="d-grid gap-2">
                            <Button variant="primary" type="submit">
                                Register
                            </Button>
                        </div>
                    </Form>
                    <Link to="/" className='link-primary'>Back to login</Link>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}
