import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Collapse, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { getModifier, sendRequestAuth } from '../../services/apiFunctions';

export default function ChangeCustom({ show, char, counter, handleClose, reload }) {

    const [formData, setFormData] = useState({});
    const [val, setVal] = useState(0);
    const [maxVal, setMaxVal] = useState(0);
    const [open, setOpen] = useState(false);

    const [deleteQuestion, setDeleteQuestion] = useState(false);

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                reload();
                handleClose(true);
            } else {
                console.log(data.data.message);
            }
        },
    });

    function handleCount(amount) {
        setVal(val + amount)
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var amount = counter.val + val;

        if (amount > maxVal) {
            amount = maxVal;
        } else if (amount < 0) {
            amount = 0;
        }

        //console.log(amount)

        const request = {
            name: "editCustomCounter",
            param: {
                counterID: counter.id,
                name: formData.customName,
                val: parseInt(amount),
                maxVal: maxVal
            }
        };

        //console.log(request);
        mutate(request);
    }

    function handleNewCounter() {
        const request = {
            name: "newCustomCounter",
            param: {
                charID: char.id
            }
        };

        mutate(request);
    }

    function handleDelete() {
        const request = {
            name: "deleteCustomCounter",
            param: {
                id: counter.id
            }
        };
        mutate(request);
    }

    useEffect(() => {
        setVal(0);
        setDeleteQuestion(false);
        setOpen(false);

        var tempMaxVal;
        if (counter.maxValAbility === "charisma1") {
            tempMaxVal = (getModifier(char["charisma"]) + 1 > 1 ? getModifier(char["charisma"]) + 1 : 1)
        } else if (counter.maxValAbility === "level5") {
            tempMaxVal = char.level * 5
        } else {
            tempMaxVal = counter.maxValAbility !== "" ? (getModifier(char[counter.maxValAbility]) > 1 ? getModifier(char[counter.maxValAbility]) : 1) : counter.maxVal
        }
        setMaxVal(tempMaxVal)

        setFormData(values => ({ ...values, "customName": counter.name }));
        setFormData(values => ({ ...values, "maxCustom": tempMaxVal }));

        //console.log(tempMaxVal)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);


    return (
        <Modal size="lg" show={show} onHide={(e) => handleClose(false)} centered>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Change {counter.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-start'>
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" />
                            :
                            null
                    }
                    {counter.maxVal > 0 || counter.maxValAbility !== "" ?
                        <Row>
                            <Col xs={2} className="p-1">
                                <div className="d-grid gap-2"><Button variant="outline-danger" className="p-3 fw-bold" onClick={() => handleCount(-5)}>-5</Button></div>
                            </Col>
                            <Col xs={2} className="p-1">
                                <div className="d-grid gap-2"><Button variant="outline-danger" className="p-3 fw-bold" onClick={() => handleCount(-1)}>-1</Button></div>
                            </Col>
                            <Col xs={4} className="p-1 text-center">
                                <Alert variant={val === 0 ? "dark" : val > 0 ? "success" : "danger"} className="h5">{val}</Alert>
                            </Col>
                            <Col xs={2} className="p-1">
                                <div className="d-grid gap-2"><Button variant="outline-success" className="p-3 fw-bold" onClick={() => handleCount(1)}>+1</Button></div>
                            </Col>
                            <Col xs={2} className="p-1">
                                <div className="d-grid gap-2"><Button variant="outline-success" className="p-3 fw-bold" onClick={() => handleCount(5)}>+5</Button></div>
                            </Col>
                        </Row>
                        : null}
                    <Row>
                        <Button variant="outline-secondary" size="sm" onClick={() => setOpen(!open)}><FontAwesomeIcon icon={open ? faChevronDown : faChevronUp} /></Button>
                        <Collapse in={open}>
                            <div id="example-collapse-text">
                                {counter.blocked ?
                                    <div className="alert alert-info mt-3">You can't change the name or the max value of this counter and you can't delete it, because its inherited from your class.</div>
                                    : null}
                                {!counter.blocked ?
                                    <>
                                        <Row>
                                            <Col>
                                                <Form.Group className="my-3" controlId="name">
                                                    <Form.Label>Custom Name</Form.Label>
                                                    <Form.Control type="text" name="customName" defaultValue={counter.name} onChange={handleChange} required />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="my-3" controlId="name">
                                                    <Form.Label>Max Custom</Form.Label>
                                                    <Form.Control type="number" name="maxCustom" value={maxVal} onChange={e => setMaxVal(e.target.value)} required />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                                    : null}
                                <hr />
                                <div className='text-center'>
                                    <Button variant="outline-primary" onClick={(e) => handleNewCounter(false)}>
                                        Add new Custom Counter
                                    </Button>
                                </div>
                            </div>
                        </Collapse>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {!counter.blocked ? deleteQuestion ?
                        <div className='text-center container-fluid'>
                            <hr />
                            <div>
                                <p>Do you really want to delete this counter?</p>
                                <Button variant="outline-primary me-2" onClick={() => setDeleteQuestion(false)}>
                                    Abort
                                </Button>
                                <Button variant="primary" onClick={handleDelete}>
                                    Delete
                                </Button>
                            </div>
                            <hr />
                        </div>
                        :
                        <Button variant="outline-primary" onClick={() => setDeleteQuestion(true)}>
                            Delete
                        </Button>
                        : null}
                    <Button variant="secondary" onClick={(e) => handleClose(false)}>
                        Close
                    </Button>
                    <Button variant="outline-primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
