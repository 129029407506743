import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Form, Button, Spinner } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation } from "react-query";
import useDocumentTitle, { sendRequest } from '../services/apiFunctions';

export default function Login() {

    useDocumentTitle("");

    const [formData, setFormData] = useState({})
    const [alert, setAlert] = useState("");

    const navigate = useNavigate()

    const { mutate, isLoading } = useMutation(sendRequest, {
        onError: () => {
            setAlert("An error occured while fetching data.");
            localStorage.removeItem('token');
            localStorage.removeItem('userID');
        },
        onSuccess: (data) => {
            //console.log(data);
            if (data.data.status === 200) {
                setAlert("");
                localStorage.setItem('token', data.data.result.token);
                localStorage.setItem('userID', data.data.result.userID);
                navigate("/home");
            } else {
                setAlert(data.data.message);
                localStorage.removeItem('token');
                localStorage.removeItem('userID');
            }
        },
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        localStorage.removeItem('registerMail')

        const request = {
            name: "generateToken",
            param: {
                email: formData.email,
                pass: formData.pass
            }
        };
        mutate(request);
    }

    useEffect(() => {
        if (localStorage.getItem('token') !== "" && localStorage.getItem('token')) {
            navigate("/home");
        }
        if (localStorage.getItem('registerMail')) {
            const mail = localStorage.getItem('registerMail');
            setFormData(values => ({ ...values, "email": mail }))
            document.getElementById("emailInput").value = mail;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container className="py-5 text-center">
            <Row>
                <Col></Col>
                <Col xl={6}>
                    <h1 className="mb-5 text-primary">
                        <img width="50px" className="me-2" src="/logo-transparent.png" alt="" />
                        <b><span className='align-middle'>DigitalP&P</span></b>
                    </h1>
                    {
                        isLoading ? <Spinner animation="border" /> : null
                    }
                    {
                        alert !== "" ?
                            <div className="alert alert-danger">
                                {alert}
                            </div>
                            :
                            null
                    }
                    {
                        localStorage.getItem('registerMail') !== "" && localStorage.getItem('registerMail') ?
                            <div className="alert alert-success">
                                Registration complete. Please log in.
                            </div>
                            :
                            null
                    }
                    <Form onSubmit={handleSubmit} className="mb-3">
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" name="email" id="emailInput" onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="pass">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" name="pass" onChange={handleChange} />
                        </Form.Group>
                        <div className="d-grid gap-2">
                            <Button variant="primary" type="submit">
                                Login
                            </Button>
                        </div>
                    </Form>
                    <Link to="/register" className='link-primary'>Register</Link>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}
