import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { conditions, logout, sendRequestAuth } from '../services/apiFunctions'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'

export default function EditConditions({ show, handleClose, charConditions, charID, reload, charType }) {

    const [newCharConditions, setNewCharConditions] = useState(charConditions.map(Number))

    const navigate = useNavigate()

    const { mutate } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                if (reload) {
                    reload()
                }
                handleClose();
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });


    function handleSave() {
        var request = {
            name: "editConditions",
            param: {
                charID: charID,
                charType: charType,
                conditions: newCharConditions.filter(d => d !== 0).sort().join()
            }
        };
        console.log(request.param)
        mutate(request);
    }

    function handleConditionChange(condID) {
        if (newCharConditions.some(cond => cond === condID)) {
            setNewCharConditions(newCharConditions.filter(cond => cond !== condID))
        } else {
            setNewCharConditions(newCharConditions.concat(condID))
        }
    }

    useEffect(() => {
        setNewCharConditions(charConditions.map(Number));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <Modal size="lg" show={show} onHide={() => handleClose(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body className="line-breaks p-3">
                {conditions.map(condition => (
                    <div key={condition.id} className="mb-3">
                        <Form.Check // prettier-ignore
                            type="checkbox"
                            id={condition.name}
                            name={condition.name}
                            label={<span><img src={condition.pic} style={{ maxHeight: "20px", maxWidth: "100%" }} alt="" className='rounded'></img> {condition.name}</span>}
                            onChange={e => handleConditionChange(condition.id)}
                            checked={newCharConditions.includes(condition.id)}
                        />
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleClose(false)}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => handleSave()}>
                    Save
                </Button>
            </Modal.Footer>

        </Modal>
    )
}
