import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner, Table } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { API_URL, sendRequestAuth } from '../services/apiFunctions';

export default function ShowClass({ show, handleClose, singleClass }) {

    const [page, setPage] = useState("info");
    const [classData, setClassData] = useState();

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setClassData(data.data.result);
                if ((!isNaN(parseInt(page)) && (data.data.result.subclasses.filter(item => item.id === parseInt(page)) <= 0))) {
                    //console.log("TEST")
                    setPage("info");
                }
            } else {
                console.log(data.data.message);
            }
        },
    });

    useEffect(() => {
        if (show === true) {
            const request = {
                name: "getClassData",
                param: {
                    id: singleClass.id
                }
            };
            mutate(request);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const alphabet = ["a", "b", "c", "d"]

    return (
        <Modal size="lg" show={show} onHide={() => handleClose(false)}>
            {singleClass ?
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title>{singleClass.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="line-breaks p-3">
                        {isLoading ?
                            <Spinner animation="border" className="mb-4" />
                            :
                            <div>
                                <Form.Select className="mt-1" name="page" value={page} onChange={(e) => setPage(e.target.value)}>
                                    <option value="info">Info</option>
                                    <option value="features">Features</option>
                                    <option value="table">Level Table</option>
                                    <option value="" disabled>-----</option>
                                    {
                                        classData ? classData.subclasses.sort((a, b) => a.id - b.id).map(subclass => (
                                            <option key={subclass.id} value={subclass.id}>{subclass.name}</option>
                                        ))
                                            : null
                                    }
                                    {
                                        singleClass.id === 11 ?
                                            <>
                                                <option value="" disabled>-----</option>
                                                <option value="eldritchInvocations">Eldritch Invocations</option>
                                            </>
                                            : null
                                    }
                                    {
                                        singleClass.id === 13 ?
                                            <>
                                                <option value="" disabled>-----</option>
                                                <option value="artificerInfusion">Artificer Infusion</option>
                                            </>
                                            : null
                                    }
                                </Form.Select>
                                {
                                    page === "info" ?
                                        <>
                                            {singleClass.pic ?
                                                <div className='text-center my-3'>
                                                    <img src={API_URL + "/classPics/" + singleClass.id + ".jpg"} style={{ maxHeight: "250px", maxWidth: "100%" }} alt="" className='rounded'></img>
                                                </div>
                                                : null}
                                            <div className="my-2 feature" dangerouslySetInnerHTML={{ __html: singleClass.description }}></div>
                                            <p className='mt-3'><b>Source:</b> {singleClass.source}</p>
                                        </>
                                        : page === "features" ?
                                            <>
                                                <h5 className='mt-3'>Hit Points</h5>
                                                <b>Hit Dice: </b>1d{singleClass.hitDice}<br />
                                                <b>Hit Points at 1st Level: </b>{singleClass.hitDice} + your Constitution modifier<br />
                                                <b>Hit Points at Higher Levels: </b>1d{singleClass.hitDice} + your Constitution modifier per {singleClass.name} level after 1st<br />

                                                <h5 className='mt-3'>Proficiencies</h5>
                                                {singleClass.armorProf !== "" ?
                                                    <><b>Armor: </b>{singleClass.armorProf.split(";").map((d, i) => ((i !== 0 ? ", " : "") + d))}<br /></>
                                                    : <><b>Armor: </b>none<br /></>}
                                                {singleClass.weaponProf !== "" ?
                                                    <><b>Weapons: </b>{singleClass.weaponProf.split(";").map((d, i) => ((i !== 0 ? ", " : "") + d))}<br /></>
                                                    : <><b>Weapons: </b>none<br /></>}
                                                {singleClass.toolProf !== "" ?
                                                    <><b>Tools: </b>{classData.toolProf.map((d, i) => ((i !== 0 ? ", " : "") + d.name))}<br /></>
                                                    : singleClass.toolProfChoose !== "" ?
                                                        <><b>Tools: </b>Choose {singleClass.toolProfChooseNumber} out of: {classData.toolProfChoose.map((d, i) => ((i !== 0 ? " or " : "") + d.name))}<br /></>
                                                        : <><b>Tools: </b>none<br /></>}
                                                {singleClass.savingThrows !== "" ?
                                                    <><b>Saving Throws: </b>{singleClass.savingThrows.split(";").map((d, i) => ((i !== 0 ? ", " : "") + d))}<br /></>
                                                    : <><b>Saving Throws: </b>none<br /></>}
                                                <b>Skills: </b>Choose {singleClass.skillsChooseNumber} out of{singleClass.skillsChoose === "" ? " any" : ": " + classData.skillsChoose.map((d, i) => ((i !== 0 ? ", " : "") + d.name))}<br />


                                                <h5 className='mt-3'>Starting Equipment</h5>
                                                <p>You start with the following items, plus anything provided by your background.</p>
                                                <ul>
                                                    {classData ? classData.equipChoose1.length > 0 ?
                                                        <li>
                                                            {classData.equipChoose1.map((g, k) => ((k !== 0 ? " or " : " ") + "(" + alphabet[k] + ") " + g.map((d, i) => (i !== 0 ? " " : "") + d.name + (d.amount > 1 ? " (x" + d.amount + ")" : ""))))}
                                                        </li>
                                                        : null : null}
                                                    {classData ? classData.equipChoose2.length > 0 ?
                                                        <li>
                                                            {classData.equipChoose2.map((g, k) => ((k !== 0 ? " or " : " ") + "(" + alphabet[k] + ") " + g.map((d, i) => (i !== 0 ? " " : "") + d.name + (d.amount > 1 ? " (x" + d.amount + ")" : ""))))}
                                                        </li>
                                                        : null : null}
                                                    {classData ? classData.equipChoose3.length > 0 ?
                                                        <li>
                                                            {classData.equipChoose3.map((g, k) => ((k !== 0 ? " or " : " ") + "(" + alphabet[k] + ") " + g.map((d, i) => (i !== 0 ? " " : "") + d.name + (d.amount > 1 ? " (x" + d.amount + ")" : ""))))}
                                                        </li>
                                                        : null : null}
                                                    {classData ? classData.equipChoose4.length > 0 ?
                                                        <li>
                                                            {classData.equipChoose4.map((g, k) => ((k !== 0 ? " or " : " ") + "(" + alphabet[k] + ") " + g.map((d, i) => (i !== 0 ? " " : "") + d.name + (d.amount > 1 ? " (x" + d.amount + ")" : ""))))}
                                                        </li>
                                                        : null : null}
                                                    {classData ? classData.equipFixed.length > 0 ?
                                                        <li>
                                                            {classData.equipFixed.map((d, i) => ((i !== 0 ? ", " : "") + d.name + (d.amount > 1 ? " (x" + d.amount + ")" : "")))}
                                                        </li>
                                                        : null : null}
                                                </ul>

                                                <p>Alternatively, you may start with {singleClass.alternativeGoldDice}d4 × 10 gp to buy your own equipment.</p>

                                                <hr></hr>
                                                {classData ? classData.features.filter(feature => feature.type === "class").map(feature => (
                                                    <>
                                                        <h5 className='mt-4' id={feature.id}>{feature.name}</h5>
                                                        <p className='mb-2'><i>{feature.level + (feature.level === 1 ? "st" : feature.level === 2 ? "nd" : feature.level === 3 ? "rd" : "th") + "-level " + singleClass.name + " feature"}</i></p>
                                                        <div className="feature" dangerouslySetInnerHTML={{ __html: feature.description }}></div>
                                                        {
                                                            feature.id === 887 ? classData.pactBoons.filter(pactBoon => pactBoon.type === "pactBoon").map(pactBoon => (
                                                                <>
                                                                    <h6 className='mt-3' id={pactBoon.id}>{pactBoon.name}</h6>
                                                                    <div className="feature" dangerouslySetInnerHTML={{ __html: pactBoon.description }}></div>
                                                                </>
                                                            )) : null
                                                        }
                                                    </>
                                                ))
                                                    : null}
                                                <p className='mt-3'><b>Source:</b> {singleClass.source}</p>
                                            </>
                                            : page === "table" ?
                                                <>
                                                    <div className='overflow-auto'>
                                                        <Table className="mt-3" striped bordered hover>
                                                            <thead>
                                                                {singleClass.id === 5 ?
                                                                    <tr>
                                                                        <th colSpan={3}></th>
                                                                        <th colSpan={6} className='text-center'>Eldritch Knight only</th>
                                                                    </tr>
                                                                    : null}
                                                                {singleClass.id === 2 || singleClass.id === 3 || singleClass.id === 4 || singleClass.id === 5 || singleClass.id === 7 || singleClass.id === 8 || singleClass.id === 10 || singleClass.id === 12 || singleClass.id === 13 ?
                                                                    <tr>
                                                                        <th colSpan={singleClass.id === 2 || singleClass.id === 5 ? 5 : singleClass.id === 7 ? 3 : singleClass.id === 10 || singleClass.id === 13 ? 6 : 4}></th>
                                                                        <th colSpan={9} className='text-center'>Spell Slots per Spell Level</th>
                                                                    </tr>
                                                                    : null}
                                                                <tr>
                                                                    <th>Level</th>
                                                                    <th>Proficiency Bonus</th>
                                                                    <th>Features</th>
                                                                    {singleClass.id === 1 ?
                                                                        <>
                                                                            <th>Rages</th>
                                                                            <th>Rage Damage</th>
                                                                        </>
                                                                        : null}
                                                                    {singleClass.id === 6 ?
                                                                        <>
                                                                            <th>Martial Arts</th>
                                                                            <th>Ki Points</th>
                                                                            <th>Unarmored Movement</th>
                                                                        </>
                                                                        : null}
                                                                    {singleClass.id === 9 ?
                                                                        <>
                                                                            <th>Sneak Attack</th>
                                                                        </>
                                                                        : null}
                                                                    {singleClass.id === 10 ?
                                                                        <>
                                                                            <th>Sorcery Points</th>
                                                                        </>
                                                                        : null}
                                                                    {singleClass.id === 2 || singleClass.id === 3 || singleClass.id === 4 || singleClass.id === 5 || singleClass.id === 10 || singleClass.id === 11 || singleClass.id === 12 || singleClass.id === 13 ?
                                                                        <>
                                                                            <th>Cantrips Known</th>
                                                                        </>
                                                                        : null}
                                                                    {singleClass.id === 2 || singleClass.id === 5 || singleClass.id === 8 || singleClass.id === 10 || singleClass.id === 11 ?
                                                                        <>
                                                                            <th>Spells Known</th>
                                                                        </>
                                                                        : null}
                                                                    {singleClass.id === 11 ?
                                                                        <>
                                                                            <th>Spell Slots</th>
                                                                        </>
                                                                        : null}
                                                                    {singleClass.id === 11 ?
                                                                        <>
                                                                            <th>Slot Level</th>
                                                                        </>
                                                                        : null}
                                                                    {singleClass.id === 11 ?
                                                                        <>
                                                                            <th>Invocations Known</th>
                                                                        </>
                                                                        : null}
                                                                    {singleClass.id === 13 ?
                                                                        <>
                                                                            <th>Infusions Known</th>
                                                                        </>
                                                                        : null}
                                                                    {singleClass.id === 13 ?
                                                                        <>
                                                                            <th>Infused Items</th>
                                                                        </>
                                                                        : null}
                                                                    {singleClass.id === 2 || singleClass.id === 3 || singleClass.id === 4 || singleClass.id === 5 || singleClass.id === 7 || singleClass.id === 8 || singleClass.id === 10 || singleClass.id === 12 ?
                                                                        <>
                                                                            <th>1st</th>
                                                                            <th>2nd</th>
                                                                            <th>3rd</th>
                                                                            <th>4th</th>
                                                                            {singleClass.id !== 5 ?
                                                                                <th>5th</th>
                                                                                : null}
                                                                            {singleClass.id !== 5 && singleClass.id !== 7 && singleClass.id !== 8 ?
                                                                                <>
                                                                                    <th>6th</th>
                                                                                    <th>7th</th>
                                                                                    <th>8th</th>
                                                                                    <th>9th</th>
                                                                                </>
                                                                                : null}
                                                                        </>
                                                                        : null}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    classData ? classData.levelTable.map(level => (
                                                                        <tr key={level.level}>
                                                                            <td>{level.level}</td>
                                                                            <td>{"+" + level.profBonus}</td>
                                                                            <td>
                                                                                {classData.features.filter(feature => feature.level === level.level && feature.type === "class").length > 0 ? classData.features.filter(feature => feature.level === level.level && feature.type === "class").map((feature, index) =>
                                                                                    <>
                                                                                        {index !== 0 ? ", " : ""}
                                                                                        <a className="link-primary" href={"#" + feature.id} onClick={() => setPage("features")}>{feature.name}</a>
                                                                                    </>
                                                                                ) : "—"}
                                                                            </td>
                                                                            {singleClass.id === 1 ?
                                                                                <>
                                                                                    <td>{level.rages === -1 ? "∞" : level.rages}</td>
                                                                                    <td>{"+" + level.rageDmg}</td>
                                                                                </>
                                                                                : null}
                                                                            {singleClass.id === 6 ?
                                                                                <>
                                                                                    <td>{level.martialArtsDie === 0 ? "—" : "1d" + level.martialArtsDie}</td>
                                                                                    <td>{level.kiPoints}</td>
                                                                                    <td>{"+" + level.unarmoredMovement + " ft."}</td>
                                                                                </>
                                                                                : null}
                                                                            {singleClass.id === 9 ?
                                                                                <>
                                                                                    <td>{level.sneakAttack + "d6"}</td>
                                                                                </>
                                                                                : null}
                                                                            {singleClass.id === 10 ?
                                                                                <>
                                                                                    <td>{level.sorceryPoints === 0 ? "—" : level.sorceryPoints}</td>
                                                                                </>
                                                                                : null}
                                                                            {singleClass.id === 2 || singleClass.id === 3 || singleClass.id === 4 || singleClass.id === 5 || singleClass.id === 10 || singleClass.id === 11 || singleClass.id === 12 || singleClass.id === 13 ?
                                                                                <>
                                                                                    <td>{level.cantripsKnown}</td>
                                                                                </>
                                                                                : null}
                                                                            {singleClass.id === 2 || singleClass.id === 5 || singleClass.id === 8 || singleClass.id === 10 || singleClass.id === 11 ?
                                                                                <>
                                                                                    <td>{level.spellsKnown === 0 ? "—" : level.spellsKnown}</td>
                                                                                </>
                                                                                : null}
                                                                            {singleClass.id === 11 ?
                                                                                <>
                                                                                    <td>{level.spellSlots === 0 ? "—" : level.spellSlots}</td>
                                                                                </>
                                                                                : null}
                                                                            {singleClass.id === 11 ?
                                                                                <>
                                                                                    <td>{level.slotLevel === 1 ? "1st" : level.slotLevel === 1 ? "2nd" : level.slotLevel === 1 ? "3rd" : level.slotLevel + "th"}</td>
                                                                                </>
                                                                                : null}
                                                                            {singleClass.id === 11 ?
                                                                                <>
                                                                                    <td>{level.invocations === 0 ? "—" : level.invocations}</td>
                                                                                </>
                                                                                : null}
                                                                            {singleClass.id === 13 ?
                                                                                <>
                                                                                    <td>{level.infusionsKnown === 0 ? "—" : level.infusionsKnown}</td>
                                                                                </>
                                                                                : null}
                                                                            {singleClass.id === 13 ?
                                                                                <>
                                                                                    <td>{level.infusedItems === 0 ? "—" : level.infusedItems}</td>
                                                                                </>
                                                                                : null}
                                                                            {singleClass.id === 2 || singleClass.id === 3 || singleClass.id === 4 || singleClass.id === 5 || singleClass.id === 7 || singleClass.id === 8 || singleClass.id === 10 || singleClass.id === 12 || singleClass.id === 13 ?
                                                                                <>
                                                                                    <td>{level.spellSlotLvl1 === 0 ? "—" : level.spellSlotLvl1}</td>
                                                                                    <td>{level.spellSlotLvl2 === 0 ? "—" : level.spellSlotLvl2}</td>
                                                                                    <td>{level.spellSlotLvl3 === 0 ? "—" : level.spellSlotLvl3}</td>
                                                                                    <td>{level.spellSlotLvl4 === 0 ? "—" : level.spellSlotLvl4}</td>
                                                                                    {singleClass.id !== 5 ?
                                                                                        <td>{level.spellSlotLvl5 === 0 ? "—" : level.spellSlotLvl5}</td>
                                                                                        : null}
                                                                                    {singleClass.id !== 5 && singleClass.id !== 7 && singleClass.id !== 8 && singleClass.id !== 13 ?
                                                                                        <>
                                                                                            <td>{level.spellSlotLvl6 === 0 ? "—" : level.spellSlotLvl6}</td>
                                                                                            <td>{level.spellSlotLvl7 === 0 ? "—" : level.spellSlotLvl7}</td>
                                                                                            <td>{level.spellSlotLvl8 === 0 ? "—" : level.spellSlotLvl8}</td>
                                                                                            <td>{level.spellSlotLvl9 === 0 ? "—" : level.spellSlotLvl9}</td>
                                                                                        </>
                                                                                        : null}
                                                                                </>
                                                                                : null}
                                                                        </tr>
                                                                    )) : null
                                                                }

                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    <p className='mt-3'><b>Source:</b> {singleClass.source}</p>
                                                </>
                                                : page === "eldritchInvocations" ?
                                                    <>
                                                        {classData ? classData.eldritchInvocation.map(invocation => (
                                                            <>
                                                                <h5 className='mt-4' id={invocation.id}>{invocation.name}</h5>
                                                                <div className="feature" dangerouslySetInnerHTML={{ __html: invocation.description }}></div>
                                                                <small>Source: {invocation.source}</small>
                                                            </>
                                                        )) : null}
                                                    </>
                                                    : page === "artificerInfusion" ?
                                                        <>
                                                            {classData ? classData.artificerInfusion.map(infusion => (
                                                                <>
                                                                    <h5 className='mt-4' id={infusion.id}>{infusion.name}</h5>
                                                                    <div className="feature" dangerouslySetInnerHTML={{ __html: infusion.description }}></div>
                                                                    <small>Source: {infusion.source}</small>
                                                                </>
                                                            )) : null}
                                                        </>
                                                        :
                                                        <>
                                                            {classData.subclasses.find(subclass => subclass.id === parseInt(page)).pic ?
                                                                <div className='text-center my-3'>
                                                                    <img src={API_URL + "/subclassPics/" + parseInt(page) + ".jpg"} style={{ maxHeight: "250px", maxWidth: "100%" }} alt="" className='rounded'></img>
                                                                </div>
                                                                : null}
                                                            <div className="mt-3 fst-italic feature" dangerouslySetInnerHTML={{ __html: classData.subclasses.find(subclass => subclass.id === parseInt(page)).description }}></div>
                                                            {classData ? classData.features.filter(feature => feature.type === "subclass" && feature.originID === parseInt(page)).map(feature => (
                                                                <>
                                                                    <h5 className='mt-5' id={feature.id}>{feature.name}</h5>
                                                                    <p className='mb-2'><i>{feature.level + (feature.level === 1 ? "st" : feature.level === 2 ? "nd" : feature.level === 3 ? "rd" : "th") + "-level " + classData.subclasses.find(subclass => subclass.id === parseInt(page)).name + " feature"}</i></p>
                                                                    <div className="feature" dangerouslySetInnerHTML={{ __html: feature.description }}></div>
                                                                </>
                                                            )) : null}
                                                            <p className='mt-3'><b>Source:</b> {classData.subclasses.find(subclass => subclass.id === parseInt(page)).source}</p>
                                                        </>

                                }


                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </div>
                : null}
        </Modal>
    )
}
