import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Col, Form, Modal, Row, Spinner, ToggleButton } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { sendRequestAuth } from '../../services/apiFunctions';

export default function ChangeAbilityScores({ show, char, handleClose, abilityName, reload }) {

    const [abilityScore, setAbilityScore] = useState();
    const [saveProficient, setSaveProficient] = useState();

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                reload();
                handleClose();
            } else {
                console.log(data.data.message);
            }
        },
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        const request = {
            name: "editAbilityScore",
            param: {
                charID: parseInt(char.id),
                name: abilityName,
                value: parseInt(abilityScore),
                saveProficient: saveProficient
            }
        };
        //console.log(request)
        mutate(request);
    }

    useEffect(() => {
        if (abilityName === "strength") {
            setAbilityScore(char.strength);
            setSaveProficient(char.strengthProf === 1 ? true : false);
        } else if (abilityName === "dexterity") {
            setAbilityScore(char.dexterity);
            setSaveProficient(char.dexterityProf === 1 ? true : false);
        } else if (abilityName === "constitution") {
            setAbilityScore(char.constitution);
            setSaveProficient(char.constitutionProf === 1 ? true : false);
        } else if (abilityName === "intelligence") {
            setAbilityScore(char.intelligence);
            setSaveProficient(char.intelligenceProf === 1 ? true : false);
        } else if (abilityName === "wisdom") {
            setAbilityScore(char.wisdom);
            setSaveProficient(char.wisdomProf === 1 ? true : false);
        } else if (abilityName === "charisma") {
            setAbilityScore(char.charisma);
            setSaveProficient(char.charismaProf === 1 ? true : false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [show]);


    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Change {abilityName}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-start'>
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" />
                            :
                            null
                    }
                    <Row>
                        <Col>
                            <Form.Group className="my-3" controlId="name">
                                <Form.Label>Value</Form.Label>
                                <Form.Control type="number" name="value" value={abilityScore} onChange={(e) => setAbilityScore(e.target.value)} required />
                            </Form.Group>
                        </Col>
                        <Col className="col-auto">
                            <Form.Group className="my-3" controlId="name">
                                <Form.Label>Save Proficiency</Form.Label><br />
                                <ButtonGroup>
                                    <ToggleButton
                                        type="radio"
                                        variant={'outline-primary'}
                                        checked={!saveProficient}
                                        onClick={(e) => { setSaveProficient(false) }}
                                    >
                                        <FontAwesomeIcon icon={faXmark} />
                                    </ToggleButton>
                                    <ToggleButton
                                        type="radio"
                                        variant={'outline-primary'}
                                        checked={saveProficient}
                                        onClick={(e) => { setSaveProficient(true) }}
                                    >
                                        <FontAwesomeIcon icon={faCheck} />
                                    </ToggleButton>
                                </ButtonGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="outline-primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
