import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { API_URL, logout, sendRequestAuth, uploadMonsterPic } from '../services/apiFunctions';

export default function NewMonster({ show, handleClose, monster }) {

    const [formData, setFormData] = useState({})
    const [alert, setAlert] = useState("");
    const [deleteQuestion, setDeleteQuestion] = useState(false);

    const navigate = useNavigate();

    const [passiveActions, setPassiveActions] = useState([]);
    const [actions, setActions] = useState([]);
    const [reactions, setReactions] = useState([]);
    const [legendaryActions, setLegendaryActions] = useState([]);

    const handleActionRemove = (index, type) => {
        if (type === "passive") {
            const list = [...passiveActions];
            list.splice(index, 1);
            setPassiveActions(list);
        } else if (type === "action") {
            const list = [...actions];
            list.splice(index, 1);
            setActions(list);
        } else if (type === "reaction") {
            const list = [...reactions];
            list.splice(index, 1);
            setReactions(list);
        } else if (type === "legendary") {
            const list = [...legendaryActions];
            list.splice(index, 1);
            setLegendaryActions(list);
        }
    }

    const handleActionAdd = (type) => {
        if (type === "passive") {
            setPassiveActions([...passiveActions, { actionName: "", actionDesc: "" }])
        } else if (type === "action") {
            setActions([...actions, { actionName: "", actionDesc: "" }])
        } else if (type === "reaction") {
            setReactions([...reactions, { actionName: "", actionDesc: "" }])
        } else if (type === "legendary") {
            setLegendaryActions([...legendaryActions, { actionName: "", actionDesc: "" }])
        }
    }

    const handleActionChange = (e, index, type) => {
        const { name, value } = e.target;
        if (type === "passive") {
            const list = [...passiveActions];
            list[index][name] = value;
            setPassiveActions(list);
        } else if (type === "action") {
            const list = [...actions];
            list[index][name] = value;
            setActions(list);
        } else if (type === "reaction") {
            const list = [...reactions];
            list[index][name] = value;
            setReactions(list);
        } else if (type === "legendary") {
            const list = [...legendaryActions];
            list[index][name] = value;
            setLegendaryActions(list);
        }

    }

    const { mutate: mutateUpload, isLoading: isLoadingUpload } = useMutation(uploadMonsterPic, {
        onError: () => {
            setAlert("An error occured while uploading data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                handleClose(true);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setAlert(data.data.message);
            }
        },
    });

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            setAlert("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                var formDataObj = new FormData();
                var imagefile = document.querySelector('#pic');
                formDataObj.append("pic", imagefile.files[0]);
                formDataObj.append("charID", data.data.result.charID);
                if (imagefile.files[0]) {
                    caches.delete(API_URL + "/monsterCharPics/" + data.data.result.charID + ".png");
                    mutateUpload(formDataObj);
                } else {
                    handleClose(true);
                }
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setAlert(data.data.message);
            }
        },
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const request = {
            name: monster ? "editMonster" : "newMonster",
            param: {
                monsterID: monster ? monster.id : 0,
                name: formData.name,
                size: formData.size,
                monsterType: formData.monsterType,
                alignment: formData.alignment,
                ac: formData.ac,
                armorType: formData.armorType,
                HPmultiplier: formData.HPmultiplier,
                HPdice: formData.HPdice,
                HPadd: formData.HPadd,
                speed: formData.speed,
                flySpeed: formData.flySpeed,
                hover: formData.hover,
                burrowSpeed: formData.burrowSpeed,
                climbSpeed: formData.climbSpeed,
                swimSpeed: formData.swimSpeed,
                strength: formData.strength,
                dexterity: formData.dexterity,
                constitution: formData.constitution,
                intelligence: formData.intelligence,
                wisdom: formData.wisdom,
                charisma: formData.charisma,
                savingThrows: formData.savingThrows,
                skills: formData.skills,
                dmgVulnerabilities: formData.dmgVulnerabilities,
                dmgResistances: formData.dmgResistances,
                dmgImmunities: formData.dmgImmunities,
                conditionImmunities: formData.conditionImmunities,
                senses: formData.senses,
                languages: formData.languages,
                CR: formData.CR,
                proficiencyBonus: formData.proficiencyBonus,
                passiveActions: passiveActions,
                actions: actions,
                reactions: reactions,
                legendaryDesc: formData.legendaryDesc,
                legendaryActions: legendaryActions,
                lairActions: formData.lairActions,
                equipment: formData.equipment
            }
        };

        //console.log(request.param);
        mutate(request);
    }

    const { mutate: mutateActions } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                data.data.result.forEach(action => {
                    if (action.type === "passive") {
                        setPassiveActions(current => [...current, { actionName: action.name, actionDesc: action.description }])
                    } else if (action.type === "action") {
                        setActions(current => [...current, { actionName: action.name, actionDesc: action.description }])
                    } else if (action.type === "reaction") {
                        setReactions(current => [...current, { actionName: action.name, actionDesc: action.description }])
                    } else if (action.type === "legendary") {
                        setLegendaryActions(current => [...current, { actionName: action.name, actionDesc: action.description }])
                    }
                });

                //console.log(passiveActions)
            } else {
                console.log(data.data.message);
            }
        },
    });

    useEffect(() => {
        if (monster) {
            setFormData([]);
            setFormData(values => ({ ...values, "name": monster.name }))
            setFormData(values => ({ ...values, "size": monster.size }))
            setFormData(values => ({ ...values, "monsterType": monster.monsterType }))
            setFormData(values => ({ ...values, "alignment": monster.alignment }))
            setFormData(values => ({ ...values, "ac": monster.AC }))
            setFormData(values => ({ ...values, "HPmultiplier": monster.HPmultiplier }))
            setFormData(values => ({ ...values, "HPdice": monster.HPdice }))
            setFormData(values => ({ ...values, "HPadd": monster.HPadd }))
            setFormData(values => ({ ...values, "speed": monster.speed }))
            setFormData(values => ({ ...values, "flySpeed": monster.flySpeed }))
            setFormData(values => ({ ...values, "hover": monster.hover }))
            setFormData(values => ({ ...values, "burrowSpeed": monster.burrowSpeed }))
            setFormData(values => ({ ...values, "climbSpeed": monster.climbSpeed }))
            setFormData(values => ({ ...values, "swimSpeed": monster.swimSpeed }))
            setFormData(values => ({ ...values, "strength": monster.strength }))
            setFormData(values => ({ ...values, "dexterity": monster.dexterity }))
            setFormData(values => ({ ...values, "constitution": monster.constitution }))
            setFormData(values => ({ ...values, "intelligence": monster.intelligence }))
            setFormData(values => ({ ...values, "wisdom": monster.wisdom }))
            setFormData(values => ({ ...values, "charisma": monster.charisma }))
            setFormData(values => ({ ...values, "CR": monster.CR }))
            setFormData(values => ({ ...values, "proficiencyBonus": monster.proficiencyBonus }))
        } else {
            setFormData([]);
            setFormData(values => ({ ...values, "size": "medium" }))
            setFormData(values => ({ ...values, "alignment": "Neutral" }))
            setFormData(values => ({ ...values, "ac": 10 }))
            setFormData(values => ({ ...values, "HPmultiplier": 1 }))
            setFormData(values => ({ ...values, "HPdice": "4" }))
            setFormData(values => ({ ...values, "HPadd": 0 }))
            setFormData(values => ({ ...values, "speed": 30 }))
            setFormData(values => ({ ...values, "flySpeed": 0 }))
            setFormData(values => ({ ...values, "hover": 0 }))
            setFormData(values => ({ ...values, "burrowSpeed": 0 }))
            setFormData(values => ({ ...values, "climbSpeed": 0 }))
            setFormData(values => ({ ...values, "swimSpeed": 0 }))
            setFormData(values => ({ ...values, "strength": 10 }))
            setFormData(values => ({ ...values, "dexterity": 10 }))
            setFormData(values => ({ ...values, "constitution": 10 }))
            setFormData(values => ({ ...values, "intelligence": 10 }))
            setFormData(values => ({ ...values, "wisdom": 10 }))
            setFormData(values => ({ ...values, "charisma": 10 }))
            setFormData(values => ({ ...values, "CR": "0" }))
            setFormData(values => ({ ...values, "proficiencyBonus": 0 }))
        }


        setAlert("");
        setPassiveActions([]);
        setActions([]);
        setReactions([]);
        setLegendaryActions([]);

        if (show === true && monster) {
            const request = {
                name: "getMonsterActions",
                param: {
                    id: monster.id
                }
            };
            mutateActions(request);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                handleClose(true);
            } else {
                console.log(data.data.message);
            }
        },
    });

    function handleDelete() {
        setDeleteQuestion(false);
        var request = {
            name: "deleteMonster",
            param: {
                id: monster.id
            }
        };

        mutateDelete(request);
    }

    return (
        <Modal size="lg" backdrop="static" show={show} onHide={handleClose}>
            <Form onSubmit={handleSubmit} className="mb-3">
                <Modal.Header closeButton>
                    <Modal.Title>{monster ? "Edit" : "Create new"} Monster</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isLoading || isLoadingUpload ? <div className="text-center"><Spinner animation="border" /></div> : null
                    }
                    {
                        alert !== "" ?
                            <div className="alert alert-danger">
                                {alert}
                            </div>
                            :
                            null
                    }
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name="name" onChange={handleChange} defaultValue={monster ? monster.name : null} required />
                    </Form.Group>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="size">
                            <Form.Label>Size</Form.Label>
                            <Form.Select name="size" onChange={handleChange} defaultValue={monster ? monster.size : "Medium"} required>
                                <option value="Tiny">Tiny</option>
                                <option value="Small">Small</option>
                                <option value="Medium">Medium</option>
                                <option value="Large">Large</option>
                                <option value="Huge">Huge</option>
                                <option value="Gargantuan">Gargantuan</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} controlId="monsterType">
                            <Form.Label>Type</Form.Label>
                            <Form.Control type="text" name="monsterType" onChange={handleChange} defaultValue={monster ? monster.monsterType : null} required />
                        </Form.Group>
                        <Form.Group as={Col} controlId="alignment">
                            <Form.Label>Alignment</Form.Label>
                            <Form.Select name="alignment" onChange={handleChange} defaultValue={monster ? monster.alignment : "Neutral"} required>
                                <option value="Lawful Good">Lawful Good</option>
                                <option value="Neutral Good">Neutral Good</option>
                                <option value="Chaotic Good">Chaotic Good</option>
                                <option value="Lawful Neutral">Lawful Neutral</option>
                                <option value="Neutral">Neutral</option>
                                <option value="Chaotic Neutral">Chaotic Neutral</option>
                                <option value="Lawful Evil">Lawful Evil</option>
                                <option value="Neutral Evil">Neutral Evil</option>
                                <option value="Chaotic Evil">Chaotic Evil</option>
                                <option value="Any Alignment">Any Alignment</option>
                                <option value="Unaligned">Unaligned</option>
                                <option value="Any Good">Any Good</option>
                                <option value="Any Evil">Any Evil</option>
                                <option value="Any Lawfull">Any Lawfull</option>
                                <option value="Any Chaotic">Any Chaotic</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3">
                        <Form.Label>Image</Form.Label>
                        <Form.Control type="file" name="pic" id="pic" accept="image/png, image/jpeg" onChange={handleChange} />
                        {monster ? <small>You can leave the image field empty, if you don't want to change the image.</small> : null}
                    </Form.Group>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="ac">
                            <Form.Label>Default AC</Form.Label>
                            <Form.Control type="number" name="ac" defaultValue={monster ? monster.AC : "10"} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group as={Col} controlId="armorType">
                            <Form.Label>Armor Type</Form.Label>
                            <Form.Control type="text" name="armorType" placeholder="natural armor" defaultValue={monster ? monster.armorType : null} onChange={handleChange} />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Label>Hit Point Dice</Form.Label>
                        <Form.Group as={Col} controlId="HPmultiplier">
                            <Form.Control type="number" name="HPmultiplier" min="1" defaultValue={monster ? monster.HPmultiplier : 1} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group as={Col} controlId="HPdice">
                            <Form.Select name="HPdice" defaultValue={monster ? monster.HPdice : 4} onChange={handleChange} required>
                                <option value="4">d4</option>
                                <option value="6">d6</option>
                                <option value="8">d8</option>
                                <option value="10">d10</option>
                                <option value="12">d12</option>
                                <option value="20">d20</option>
                                <option value="100">d100</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} controlId="HPadd">
                            <Form.Control type="number" name="HPadd" defaultValue={monster ? monster.HPadd : 0} onChange={handleChange} required />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <div className='col-md-6'>
                            <Row>
                                <Form.Group className='col-4' controlId="speed">
                                    <Form.Label>Speed</Form.Label>
                                    <Form.Control type="number" name="speed" defaultValue={monster ? monster.speed : 30} step="5" min="0" onChange={handleChange} required />
                                </Form.Group>
                                <Form.Group className='col-4' controlId="flySpeed">
                                    <Form.Label>Fly</Form.Label>
                                    <Form.Control type="number" name="flySpeed" defaultValue={monster ? monster.flySpeed : 0} step="5" min="0" onChange={handleChange} />
                                </Form.Group>
                                <Form.Group className='col-4' controlId="hover">
                                    <Form.Label>Hover</Form.Label>
                                    <Form.Select name="hover" onChange={handleChange} defaultValue={monster ? monster.hover : 0}>gh
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </div>
                        <div className='col-md-6'>
                            <Row>
                                <Form.Group className='col-4' controlId="burrowSpeed">
                                    <Form.Label>Burrow</Form.Label>
                                    <Form.Control type="number" name="burrowSpeed" defaultValue={monster ? monster.burrowSpeed : 0} step="5" min="0" onChange={handleChange} />
                                </Form.Group>
                                <Form.Group className='col-4' controlId="climbSpeed">
                                    <Form.Label>Climb</Form.Label>
                                    <Form.Control type="number" name="climbSpeed" defaultValue={monster ? monster.climbSpeed : 0} step="5" min="0" onChange={handleChange} />
                                </Form.Group>
                                <Form.Group className='col-4' controlId="swimSpeed">
                                    <Form.Label>Swim</Form.Label>
                                    <Form.Control type="number" name="swimSpeed" defaultValue={monster ? monster.swimSpeed : 0} step="5" min="0" onChange={handleChange} />
                                </Form.Group>
                            </Row>
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <div className='col-md-6'>
                            <Row>
                                <Form.Group className='col-4' controlId="strength">
                                    <Form.Label>STR</Form.Label>
                                    <Form.Control type="number" name="strength" defaultValue={monster ? monster.strength : 10} min="0" max="30" onChange={handleChange} required />
                                </Form.Group>
                                <Form.Group className='col-4' controlId="dexterity">
                                    <Form.Label>DEX</Form.Label>
                                    <Form.Control type="number" name="dexterity" defaultValue={monster ? monster.dexterity : 10} min="0" max="30" onChange={handleChange} required />
                                </Form.Group>
                                <Form.Group className='col-4' controlId="constitution">
                                    <Form.Label>CON</Form.Label>
                                    <Form.Control type="number" name="constitution" defaultValue={monster ? monster.constitution : 10} min="0" max="30" onChange={handleChange} required />
                                </Form.Group>
                            </Row>
                        </div>
                        <div className='col-md-6'>
                            <Row>
                                <Form.Group className='col-4' controlId="intelligence">
                                    <Form.Label>INT</Form.Label>
                                    <Form.Control type="number" name="intelligence" defaultValue={monster ? monster.intelligence : 10} min="0" max="30" onChange={handleChange} required />
                                </Form.Group>
                                <Form.Group className='col-4' controlId="wisdom">
                                    <Form.Label>WIS</Form.Label>
                                    <Form.Control type="number" name="wisdom" defaultValue={monster ? monster.wisdom : 10} min="0" max="30" onChange={handleChange} required />
                                </Form.Group>
                                <Form.Group className='col-4' controlId="charisma">
                                    <Form.Label>CHA</Form.Label>
                                    <Form.Control type="number" name="charisma" defaultValue={monster ? monster.charisma : 10} min="0" max="30" onChange={handleChange} required />
                                </Form.Group>
                            </Row>
                        </div>
                    </Row>
                    <Form.Group className="mb-3" controlId="savingThrows">
                        <Form.Label>Saving Throws</Form.Label>
                        <Form.Control type="text" name="savingThrows" defaultValue={monster ? monster.savingThrows : null} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="skills">
                        <Form.Label>Skills</Form.Label>
                        <Form.Control type="text" name="skills" defaultValue={monster ? monster.skills : null} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="dmgVulnerabilities">
                        <Form.Label>Damage Vulnerabilities</Form.Label>
                        <Form.Control type="text" name="dmgVulnerabilities" defaultValue={monster ? monster.dmgVulnerabilities : null} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="dmgResistances">
                        <Form.Label>Damage Resistances</Form.Label>
                        <Form.Control type="text" name="dmgResistances" defaultValue={monster ? monster.dmgResistances : null} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="dmgImmunities">
                        <Form.Label>Damage Immunities</Form.Label>
                        <Form.Control type="text" name="dmgImmunities" defaultValue={monster ? monster.dmgImmunities : null} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="conditionImmunities">
                        <Form.Label>Condition Immunities</Form.Label>
                        <Form.Control type="text" name="conditionImmunities" defaultValue={monster ? monster.conditionImmunities : null} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="senses">
                        <Form.Label>Senses</Form.Label>
                        <Form.Control type="text" name="senses" defaultValue={monster ? monster.senses : null} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="languages">
                        <Form.Label>Languages</Form.Label>
                        <Form.Control type="text" name="languages" defaultValue={monster ? monster.languages : null} onChange={handleChange} />
                    </Form.Group>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="size">
                            <Form.Label>Challange Rating</Form.Label>
                            <Form.Select name="CR" onChange={handleChange} defaultValue={monster ? monster.CR : 0} required>
                                <option value="0">0</option>
                                <option value="1/8">1/8</option>
                                <option value="1/4">1/4</option>
                                <option value="1/2">1/2</option>
                                {[...Array(24)].map((x, i) =>
                                    <option key={i + 1} value={i + 1}>{i + 1}</option>
                                )}
                                <option value="30">30</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} controlId="charisma">
                            <Form.Label>Proficiency Bonus</Form.Label>
                            <Form.Control type="number" name="proficiencyBonus" defaultValue={monster ? monster.proficiencyBonus : 0} onChange={handleChange} />
                        </Form.Group>
                    </Row>
                    <h4 className='mb-3'>Passive Actions</h4>
                    {passiveActions.map((singleAction, index) => (
                        <div key={index}>
                            <Row className="mb-4">
                                <Form.Group className="col pe-1">
                                    <Form.Control type="text" name="actionName" placeholder="Name" value={singleAction.actionName} onChange={(e) => handleActionChange(e, index, "passive")} />
                                    <Form.Control className="mt-2" as="textarea" name="actionDesc" placeholder="Description" value={singleAction.actionDesc} onChange={(e) => handleActionChange(e, index, "passive")} rows={3} />
                                </Form.Group>
                                <Form.Group className="col-auto ps-1">
                                    <Button variant="outline-secondary" onClick={() => handleActionRemove(index, "passive")}>
                                        <FontAwesomeIcon icon={faMinus} />
                                    </Button>
                                </Form.Group>
                            </Row>
                        </div>
                    ))}
                    {passiveActions.length < 20 &&
                        <Form.Group className="col-auto ps-1 mb-3">
                            <div className="d-grid gap-2">
                                <Button variant="outline-primary" onClick={() => handleActionAdd("passive")}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </div>
                        </Form.Group>}

                    <h4 className='mb-3'>Actions</h4>
                    {actions.map((singleAction, index) => (
                        <div key={index}>
                            <Row className="mb-4">
                                <Form.Group className="col pe-1">
                                    <Form.Control type="text" name="actionName" placeholder="Name" value={singleAction.actionName} onChange={(e) => handleActionChange(e, index, "action")} />
                                    <Form.Control className="mt-2" as="textarea" name="actionDesc" placeholder="Description" value={singleAction.actionDesc} onChange={(e) => handleActionChange(e, index, "action")} rows={3} />
                                </Form.Group>
                                <Form.Group className="col-auto ps-1">
                                    <Button variant="outline-secondary" onClick={() => handleActionRemove(index, "action")}>
                                        <FontAwesomeIcon icon={faMinus} />
                                    </Button>
                                </Form.Group>
                            </Row>
                        </div>
                    ))}
                    {actions.length < 20 &&
                        <Form.Group className="col-auto ps-1 mb-3">
                            <div className="d-grid gap-2">
                                <Button variant="outline-primary" onClick={() => handleActionAdd("action")}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </div>
                        </Form.Group>}

                    <h4 className='mb-3'>Reactions</h4>
                    {reactions.map((singleAction, index) => (
                        <div key={index}>
                            <Row className="mb-4">
                                <Form.Group className="col pe-1">
                                    <Form.Control type="text" name="actionName" placeholder="Name" value={singleAction.actionName} onChange={(e) => handleActionChange(e, index, "reaction")} />
                                    <Form.Control className="mt-2" as="textarea" name="actionDesc" placeholder="Description" value={singleAction.actionDesc} onChange={(e) => handleActionChange(e, index, "reaction")} rows={3} />
                                </Form.Group>
                                <Form.Group className="col-auto ps-1">
                                    <Button variant="outline-secondary" onClick={() => handleActionRemove(index, "reaction")}>
                                        <FontAwesomeIcon icon={faMinus} />
                                    </Button>
                                </Form.Group>
                            </Row>
                        </div>
                    ))}
                    {reactions.length < 20 &&
                        <Form.Group className="col-auto ps-1 mb-3">
                            <div className="d-grid gap-2">
                                <Button variant="outline-primary" onClick={() => handleActionAdd("reaction")}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </div>
                        </Form.Group>}

                    <h4 className='mb-3'>Legendary Actions</h4>
                    <Form.Group className="mb-3" controlId="legendaryDesc">
                        <Form.Control className="mt-2" as="textarea" name="legendaryDesc" defaultValue={monster ? monster.legendaryDesc : null} placeholder="Legendary Actions Description" rows={3} onChange={handleChange} />
                    </Form.Group>
                    {legendaryActions.map((singleAction, index) => (
                        <div key={index}>
                            <Row className="mb-4">
                                <Form.Group className="col pe-1">
                                    <Form.Control type="text" name="actionName" placeholder="Name" value={singleAction.actionName} onChange={(e) => handleActionChange(e, index, "legendary")} />
                                    <Form.Control className="mt-2" as="textarea" name="actionDesc" placeholder="Description" value={singleAction.actionDesc} onChange={(e) => handleActionChange(e, index, "legendary")} rows={3} />
                                </Form.Group>
                                <Form.Group className="col-auto ps-1">
                                    <Button variant="outline-secondary" onClick={() => handleActionRemove(index, "legendary")}>
                                        <FontAwesomeIcon icon={faMinus} />
                                    </Button>
                                </Form.Group>
                            </Row>
                        </div>
                    ))}
                    {legendaryActions.length < 20 &&
                        <Form.Group className="col-auto ps-1 mb-3">
                            <div className="d-grid gap-2">
                                <Button variant="outline-primary" onClick={() => handleActionAdd("legendary")}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </div>
                        </Form.Group>}
                    <h4 className='mb-3'>Lair Actions</h4>
                    <Form.Group className="mb-3" controlId="equipment">
                        <Form.Control className="mt-2" as="textarea" name="lairActions" defaultValue={monster ? monster.lairActions : null} placeholder="Description of Lair Actions" rows={3} onChange={handleChange} />
                    </Form.Group>
                    <h4 className='mb-3'>Equipment</h4>
                    <Form.Group className="mb-3" controlId="equipment">
                        <Form.Control className="mt-2" as="textarea" name="equipment" defaultValue={monster ? monster.equipment : null} placeholder="Description of Equipment" rows={3} onChange={handleChange} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {isLoadingDelete ?
                        <Modal.Body className="text-center text-primary">
                            <Spinner animation="border" />
                        </Modal.Body>
                        : null}
                    {monster ? deleteQuestion ?
                        <div className='text-center container-fluid'>
                            <hr />
                            <div>
                                <p>Do you really want to delete this monster?</p>
                                <Button variant="outline-primary me-2" onClick={() => setDeleteQuestion(false)}>
                                    Abort
                                </Button>
                                <Button variant="primary" onClick={handleDelete}>
                                    Delete
                                </Button>
                            </div>
                            <hr />
                        </div>
                        :
                        <Button variant="outline-primary" onClick={() => setDeleteQuestion(true)}>
                            Delete
                        </Button>
                        : null}
                    <Button variant="secondary" onClick={handleClose}>
                        Abort
                    </Button>
                    <Button variant="primary" type="submit" disabled={isLoading || isLoadingUpload}>
                        {isLoading || isLoadingUpload ? "Saving..." : "Save Monster"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal >
    )
}