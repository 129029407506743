import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Col, Collapse, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { sendRequestAuth } from '../../services/apiFunctions';

export default function ChangeSpellSlots({ show, charID, spellSlots, handleClose, reload }) {

    const [formData, setFormData] = useState({});
    const [open, setOpen] = useState(false);

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                reload();
                handleClose();
            } else {
                console.log(data.data.message);
            }
        },
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    const handleClick = (change) => {

        var newSpellSlots = spellSlots.spellSlots + change;
        var maxSpellSlots = formData.maxSpellSlots;

        if (newSpellSlots > maxSpellSlots) {
            newSpellSlots = maxSpellSlots;
        } else if (newSpellSlots < 0) {
            newSpellSlots = 0;
        }

        handleSubmit(newSpellSlots)
    }

    const handleReset = () => {
        handleSubmit(formData.maxSpellSlots)
    }

    const handleSubmit = (value) => {
        const request = {
            name: "editSpellSlots",
            param: {
                charID: parseInt(charID),
                level: parseInt(spellSlots.level),
                spellSlots: Number.isInteger(value) ? parseInt(value) : 0,
                maxSpellSlots: parseInt(formData.maxSpellSlots)
            }
        };

        //console.log(request);
        mutate(request);
    }

    useEffect(() => {
        setOpen(false);
        setFormData(values => ({ ...values, "maxSpellSlots": spellSlots.maxSpellSlots }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);


    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Change {spellSlots.level}st Level Spell Slots</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-start'>
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" />
                            :
                            null
                    }
                    <Row>
                        <Col className="p-1">
                            <div className="d-grid gap-2"><Button variant="outline-success" className="p-3 fw-bold" onClick={() => handleClick(1)}>Regain</Button></div>
                        </Col>
                        <Col className="p-1">
                            <div className="d-grid gap-2"><Button variant="outline-danger" className="p-3 fw-bold" onClick={() => handleClick(-1)}>Use</Button></div>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Button variant="outline-secondary" size="sm" onClick={() => setOpen(!open)}><FontAwesomeIcon icon={open ? faChevronDown : faChevronUp} /></Button>
                        <Collapse in={open}>
                            <div id="example-collapse-text">
                                <Col>
                                    <Form.Group className="my-3">
                                        <Form.Label>Max Spell Slots</Form.Label>
                                        <Form.Control type="number" name="maxSpellSlots" defaultValue={spellSlots.maxSpellSlots} onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                            </div>
                        </Collapse>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={handleReset}>
                        Restore All
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="outline-primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
