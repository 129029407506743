import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { sendRequestAuth } from '../services/apiFunctions';

export default function ShowAncestry({ show, handleClose, ancestry }) {

    const [page, setPage] = useState("lore");

    const [ancestryData, setAncestryData] = useState();

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setAncestryData(data.data.result);
            } else {
                console.log(data.data.message);
            }
        },
    });

    useEffect(() => {
        if (ancestry && ancestry.description === "" && ancestry.pic === "") {
            setPage("stats");
        }

        if (show === true) {
            const request = {
                name: "getAncestryData",
                param: {
                    id: ancestry.id
                }
            };
            mutate(request);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);


    return (
        <Modal size="lg" show={show} onHide={() => handleClose(false)}>
            {ancestry ?
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title>{ancestry.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="line-breaks p-3">
                        {isLoading ?
                            <Spinner animation="border" className="mb-4" />
                            :
                            <>
                                <Form.Select className="mt-1" name="page" value={page} onChange={(e) => setPage(e.target.value)}>
                                    {ancestry.description !== "" || ancestry.pic !== "" ?
                                        <option value="lore">Lore</option>
                                        : null}
                                    <option value="stats">Stats</option>
                                </Form.Select>
                                {
                                    page === "lore" ?
                                        <>
                                            {ancestry.pic !== "" ?
                                                <div className='text-center my-3'>
                                                    <img src={ancestry.pic} alt="" style={{ maxHeight: "250px", maxWidth: "100%" }} className='rounded'></img>
                                                </div>
                                                : null}
                                            {ancestry.description !== "" ?
                                                <div className="my-2 feature" dangerouslySetInnerHTML={{ __html: ancestry.description }}></div>
                                                :
                                                null
                                            }
                                            <p className='mt-3'><b>Source:</b> {ancestry.source}</p>
                                        </>
                                        : page === "stats" ?
                                            <div className='mt-3'>
                                                <b>Ability Scores: </b>{ancestry.lineage ? "Choose one of: (a) Choose any +2; choose any other +1 (b) Choose three different +1"
                                                    : ((ancestry.strength > 0 ? " STR +" + ancestry.strength : " ") +
                                                        (ancestry.dexterity > 0 ? " DEX +" + ancestry.dexterity : " ") +
                                                        (ancestry.constitution > 0 ? " CON +" + ancestry.constitution : " ") +
                                                        (ancestry.intelligence > 0 ? " INT +" + ancestry.intelligence : " ") +
                                                        (ancestry.wisdom > 0 ? " WIS +" + ancestry.wisdom : " ") +
                                                        (ancestry.charisma > 0 ? " CHA +" + ancestry.charisma : " "))
                                                }
                                                {(ancestry.strength > 0 || ancestry.dexterity > 0 || ancestry.constitution > 0 || ancestry.intelligence > 0 || ancestry.wisdom > 0 || ancestry.charisma > 0) && (ancestry.strength < 0 || ancestry.dexterity < 0 || ancestry.constitution < 0 || ancestry.intelligence < 0 || ancestry.wisdom < 0 || ancestry.charisma < 0) ?
                                                    " and " : null}
                                                {
                                                    ancestry.strength < 0 || ancestry.dexterity < 0 || ancestry.constitution < 0 || ancestry.intelligence < 0 || ancestry.wisdom < 0 || ancestry.charisma < 0 ?
                                                        "choose " + ancestry.abilityChooseNumber + " out of: " +
                                                        ((ancestry.strength < 0 ? " STR +" + ancestry.strength * -1 : " ") +
                                                            (ancestry.dexterity < 0 ? " DEX +" + ancestry.dexterity * -1 : " ") +
                                                            (ancestry.constitution < 0 ? " CON +" + ancestry.constitution * -1 : " ") +
                                                            (ancestry.intelligence < 0 ? " INT +" + ancestry.intelligence * -1 : " ") +
                                                            (ancestry.wisdom < 0 ? " WIS +" + ancestry.wisdom * -1 : " ") +
                                                            (ancestry.charisma < 0 ? " CHA +" + ancestry.charisma * -1 : " "))
                                                        : null
                                                }
                                                <br />
                                                <b>Size: </b>{ancestry.size}<br />
                                                <b>Speed: </b>{ancestry.speed + "ft." +
                                                    (ancestry.flySpeed > 0 ? ", fly " + ancestry.flySpeed + "ft." : "") +
                                                    (ancestry.climbSpeed > 0 ? ", climb " + ancestry.climbSpeed + "ft." : "") +
                                                    (ancestry.swimSpeed > 0 ? ", swim " + ancestry.swimSpeed + "ft." : "") +
                                                    (ancestry.burrowSpeed > 0 ? ", burrow " + ancestry.burrowSpeed + "ft." : "")
                                                }<br />
                                                <b>Languages: </b>{ancestry.langsFixed.split(",").map((d, i) => ((i !== 0 ? " " : "") + d)) + (ancestry.langsChooseNumber > 0 ? " + choose " + ancestry.langsChooseNumber : "")}<br />
                                                <hr />
                                                {
                                                    ancestry.infos !== "" ?
                                                        <>
                                                            <div className="my-2 feature" dangerouslySetInnerHTML={{ __html: ancestry.infos }}></div>
                                                            <hr />
                                                        </>
                                                        : null
                                                }
                                                {
                                                    ancestryData.features.map((feature) => (
                                                        <div className='feature' key={feature.id}>
                                                            <h5>{feature.name}</h5>
                                                            <div className="my-4 feature" dangerouslySetInnerHTML={{ __html: feature.description }}></div>
                                                        </div>

                                                    ))
                                                }
                                                <p className='mt-3'><b>Source:</b> {ancestry.source}</p>
                                            </div>
                                            : null
                                }
                            </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </div>
                : null}
        </Modal>
    )
}
