import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { sendRequestAuth } from '../../services/apiFunctions';

export default function XpChange({ show, char, handleClose, setShowLevelUp, reload }) {

    const [xpChange, setXpChange] = useState(0);

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                handleClose();
                reload();
            } else {
                console.log(data.data.message);
            }
        },
    });

    function handleLevelUp() {
        setShowLevelUp(true);
        handleClose();
    }

    function handleCount(amount) {
        setXpChange(xpChange + amount)
    }

    function handleSubmit() {
        var xpValue = char.xp + xpChange;

        if (xpValue < char.xpForLvl) {
            xpValue = char.xpForLvl;
        } else if (xpValue > 355000) {
            xpValue = 355000;
        }

        const request = {
            name: "changeCharXP",
            param: {
                charID: char.id,
                xp: xpValue
            }
        };
        //console.log(request)
        mutate(request);
    }

    useEffect(() => {
        setXpChange(0);
    }, [show]);


    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Experience</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" />
                            :
                            null
                    }
                    <Row>
                        <Col xs={2} className="p-1">
                            <div className="d-grid gap-2"><Button variant="outline-danger" className="p-2 fw-bold" onClick={() => handleCount(-100)}>-100</Button></div>
                        </Col>
                        <Col xs={2} className="p-1">
                            <div className="d-grid gap-2"><Button variant="outline-danger" className="p-2 fw-bold" onClick={() => handleCount(-10)}>-10</Button></div>
                        </Col>
                        <Col xs={4} className="p-1">
                            <Form.Control className="text-center" type="number" name="name" value={xpChange} onChange={(e) => setXpChange(parseInt(e.target.value))} />
                        </Col>
                        <Col xs={2} className="p-1">
                            <div className="d-grid gap-2"><Button variant="outline-success" className="p-2 fw-bold" onClick={() => handleCount(10)}>+10</Button></div>
                        </Col>
                        <Col xs={2} className="p-1">
                            <div className="d-grid gap-2"><Button variant="outline-success" className="p-2 fw-bold" onClick={() => handleCount(100)}>+100</Button></div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" className="text-start" onClick={handleLevelUp}>
                        Level Up
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="outline-primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
