import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { sendRequestAuth } from '../../services/apiFunctions';

export default function ChangeCoins({ show, handleClose, char, reload }) {
    const [copperChanges, setCopperChanges] = useState(0);
    const [silverChanges, setSilverChanges] = useState(0);
    const [goldChanges, setGoldChanges] = useState(0);
    const [platinumChanges, setPlatinumChanges] = useState(0);
    const [alert, setAlert] = useState("");

    const currencies = ["Copper", "Silver", "Gold", "Platinum"];

    const { mutate } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                reload();
                handleClose();
            } else {
                console.log(data.data.message);
            }
        },
    });

    function handleCount(amount, type) {
        if (type === "Copper") {
            setCopperChanges(copperChanges + amount);
        } else if (type === "Silver") {
            setSilverChanges(silverChanges + amount);
        } else if (type === "Gold") {
            setGoldChanges(goldChanges + amount);
        } else if (type === "Platinum") {
            setPlatinumChanges(platinumChanges + amount);
        }
    }

    function handleChange(amount, type) {
        if (type === "Copper") {
            setCopperChanges(parseInt(amount));
        } else if (type === "Silver") {
            setSilverChanges(parseInt(amount));
        } else if (type === "Gold") {
            setGoldChanges(parseInt(amount));
        } else if (type === "Platinum") {
            setPlatinumChanges(parseInt(amount));
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var coinChanges = copperChanges + silverChanges * 10 + goldChanges * 100 + platinumChanges * 10000
        var newCoins = (char.coins + coinChanges);

        if (newCoins < 0) {
            setAlert("You dont have enough money for that!")
            return;
        }

        const request = {
            name: "editCharCoins",
            param: {
                charID: parseInt(char.id),
                coins: parseInt(newCoins)
            }
        };

        //console.log(request.param);
        mutate(request);
    }

    useEffect(() => {
        setCopperChanges(0);
        setSilverChanges(0);
        setGoldChanges(0);
        setPlatinumChanges(0);
        setAlert("");
    }, [show]);

    return (
        <Modal size="lg" show={show} onHide={() => handleClose()} centered>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Change Pieces</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    {
                        alert !== "" ?
                            <div className="alert alert-danger">
                                {alert}
                            </div>
                            :
                            null
                    }
                    {currencies.map(curr => (
                        <div key={curr}>
                            {curr} Pieces
                            <Row>
                                <Col xs={2} className="p-1">
                                    <div className="d-grid gap-2"><Button variant="outline-danger" size="sm" className="p-2 fw-bold" onClick={() => handleCount(-5, curr)}>-5</Button></div>
                                </Col>
                                <Col xs={2} className="p-1">
                                    <div className="d-grid gap-2"><Button variant="outline-danger" size="sm" className="p-2 fw-bold" onClick={() => handleCount(-1, curr)}>-1</Button></div>
                                </Col>
                                <Col xs={4} className="p-1">
                                    <Form.Control className="text-center" type="number" name="name" value={curr === "Copper" ? copperChanges :
                                        curr === "Silver" ? silverChanges :
                                            curr === "Gold" ? goldChanges :
                                                curr === "Platinum" ? platinumChanges : null} onChange={(e) => handleChange(e.target.value, curr)} />
                                </Col>
                                <Col xs={2} className="p-1">
                                    <div className="d-grid gap-2"><Button variant="outline-success" size="sm" className="p-2 fw-bold" onClick={() => handleCount(1, curr)}>+1</Button></div>
                                </Col>
                                <Col xs={2} className="p-1">
                                    <div className="d-grid gap-2"><Button variant="outline-success" size="sm" className="p-2 fw-bold" onClick={() => handleCount(5, curr)}>+5</Button></div>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        Close
                    </Button>
                    <Button variant="outline-primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
