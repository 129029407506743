import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { logout, sendRequestAuth, } from '../services/apiFunctions';

export default function NewSpell({ show, handleClose, spell, reload }) {

    const [deleteQuestion, setDeleteQuestion] = useState(false);

    const [formData, setFormData] = useState({})
    const [alert, setAlert] = useState("");

    const [classes, setClasses] = useState([]);

    const navigate = useNavigate()

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            setAlert("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                reload();
                handleClose();
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setAlert(data.data.message);
            }
        },
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var request;
        if (spell) {
            request = {
                name: "editSpell",
                param: {
                    id: parseInt(spell.id),
                    name: formData.name,
                    level: parseInt(formData.level),
                    castingTime: formData.castingTime,
                    range: formData.range,
                    school: formData.school,
                    components: formData.components,
                    duration: formData.duration,
                    description: formData.description,
                    descAtHigher: formData.descAtHigher,
                    classes: classes.join()
                }
            };
        } else {
            request = {
                name: "newSpell",
                param: {
                    name: formData.name,
                    level: parseInt(formData.level),
                    castingTime: formData.castingTime,
                    range: formData.range,
                    school: formData.school,
                    components: formData.components,
                    duration: formData.duration,
                    description: formData.description,
                    descAtHigher: formData.descAtHigher,
                    classes: classes.join()
                }
            };
        }
        mutate(request);
    }

    function handleDelete() {
        const request = {
            name: "deleteSpell",
            param: {
                id: spell.id
            }
        };
        mutate(request);
    }

    function handleClassSelect(className) {
        if (classes.includes(className)) {
            setClasses(oldValues => {
                return oldValues.filter(specficClass => specficClass !== className)
            })
        } else {
            setClasses([...classes, className])
        }
    }

    useEffect(() => {
        setAlert("");
        setDeleteQuestion(false);
        setClasses([])

        if (spell) {
            setClasses(spell.classes.split(','))

            setFormData(values => ({ ...values, "name": spell.name }))
            setFormData(values => ({ ...values, "level": spell.level }))
            setFormData(values => ({ ...values, "castingTime": spell.castingTime }))
            setFormData(values => ({ ...values, "range": spell.range }))
            setFormData(values => ({ ...values, "school": spell.school }))
            setFormData(values => ({ ...values, "components": spell.components }))
            setFormData(values => ({ ...values, "duration": spell.duration }))
            setFormData(values => ({ ...values, "description": spell.description }))
            setFormData(values => ({ ...values, "descAtHigher": spell.descAtHigher }))
        } else {
            setFormData(values => ({ ...values, "name": "" }))
            setFormData(values => ({ ...values, "level": 0 }))
            setFormData(values => ({ ...values, "castingTime": "" }))
            setFormData(values => ({ ...values, "range": "" }))
            setFormData(values => ({ ...values, "school": "Abjuration" }))
            setFormData(values => ({ ...values, "components": "" }))
            setFormData(values => ({ ...values, "duration": "" }))
            setFormData(values => ({ ...values, "description": "" }))
            setFormData(values => ({ ...values, "descAtHigher": "" }))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const classNames = ["Artificer", "Bard", "Cleric", "Druid", "Fighter", "Paladin", "Ranger", "Rogue", "Sorcerer", "Warlock", "Wizard"]

    return (
        <Modal size="lg" backdrop="static" show={show} onHide={() => handleClose(false)}>
            <Form onSubmit={handleSubmit} className="mb-3">
                <Modal.Header closeButton>
                    <Modal.Title>{spell ? "Edit " + spell.name : "Add new Spell"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isLoading ? <div className="text-center"><Spinner animation="border" /></div> : null
                    }
                    {
                        alert !== "" ?
                            <div className="alert alert-danger">
                                {alert}
                            </div>
                            :
                            null
                    }
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name="name" defaultValue={spell ? spell.name : null} onChange={handleChange} />
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Level</Form.Label>
                                <Form.Select name="level" defaultValue={spell ? spell.level : 0} onChange={handleChange}>
                                    <option value="0">Cantrip</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>School</Form.Label>
                                <Form.Select name="school" defaultValue={spell ? spell.school : "Abjuration"} onChange={handleChange}>
                                    <option value="Abjuration">Abjuration</option>
                                    <option value="Conjuration">Conjuration</option>
                                    <option value="Divination">Divination</option>
                                    <option value="Enchantment">Enchantment</option>
                                    <option value="Evocation">Evocation</option>
                                    <option value="Illusion">Illusion</option>
                                    <option value="Necromancy">Necromancy</option>
                                    <option value="Transmutation">Transmutation</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Casting Time</Form.Label>
                        <Form.Control type="text" name="castingTime" defaultValue={spell ? spell.castingTime : null} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Range</Form.Label>
                        <Form.Control type="text" name="range" defaultValue={spell ? spell.range : null} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Components</Form.Label>
                        <Form.Control type="text" name="components" defaultValue={spell ? spell.components : null} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Duration</Form.Label>
                        <Form.Control type="text" name="duration" defaultValue={spell ? spell.duration : null} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" name="description" placeholder="Description" defaultValue={spell ? spell.description : null} onChange={handleChange} rows={3} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>At Higher Levels</Form.Label>
                        <Form.Control as="textarea" name="descAtHigher" placeholder="At Higher Levels" defaultValue={spell ? spell.descAtHigher : null} onChange={handleChange} rows={3} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Available to classes</Form.Label><br />
                        {classNames.map(className => (
                            <Form.Check
                                inline
                                checked={classes.includes(className)}
                                label={className}
                                name="classes"
                                type="checkbox"
                                id={className}
                                onClick={() => handleClassSelect(className)}
                                key={className}
                            />
                        ))}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {spell ? deleteQuestion ?
                        <div className='text-center container-fluid'>
                            <hr />
                            <div>
                                <p>Do you really want to delete this spell?</p>
                                <Button variant="outline-primary me-2" onClick={() => setDeleteQuestion(false)}>
                                    Abort
                                </Button>
                                <Button variant="primary" onClick={handleDelete}>
                                    Delete
                                </Button>
                            </div>
                            <hr />
                        </div>
                        :
                        <Button variant="outline-primary" onClick={() => setDeleteQuestion(true)}>
                            Delete
                        </Button>
                        : null}
                    <Button variant="secondary" onClick={() => handleClose(false)}>
                        Abort
                    </Button>
                    <Button variant="primary" type="submit">
                        Save Spell
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
