import { faEye, faHeart, faHeartBroken, faPlus, faShield, faShieldHalved, faSkull } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, OverlayTrigger, ProgressBar, Row, Tooltip } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { API_URL, conditions, getHealthBeat, getHealthColor, getHealthPerc, getModifier, logout, sendRequestAuth } from '../../services/apiFunctions';
import AddMonster from '../AddMonster';
import HealthChange from '../HealthChange';
import MonsterCard from '../MonsterCard'
import ShowCondition from '../ShowCondition';
import EditConditions from '../EditConditions';

export default function Overview() {

    const [chars, setChars] = useState();
    const [companions, setCompanions] = useState();

    const [showHealth, setShowHealth] = useState(false);

    const [condition, setCondition] = useState(null)

    const [showCondition, setShowCondition] = useState(false);
    const handleCloseCondition = () => setShowCondition(false);

    const [showConditionEdit, setShowConditionEdit] = useState(false);
    const handleCloseConditionEdit = () => setShowConditionEdit(false);

    const handleCloseHealth = () => setShowHealth(false);
    const handleShowHealth = () => setShowHealth(true);

    const [showAddComp, setShowAddComp] = useState(false);
    const handleCloseAddComp = () => setShowAddComp(false);

    const [showCompDetails, setShowCompDetails] = useState(false);
    const handleCloseCompDetails = () => setShowCompDetails(false);

    const [currentChar, setCurrentChar] = useState({ id: 0, type: "", hp: 0, maxHP: 0, tempHP: 0, func: "", conditions: "" });

    const gameID = localStorage.getItem("gameID");

    const navigate = useNavigate()

    const { mutate } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setChars(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    const { mutate: mutateComp } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setCompanions(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    function reloadData() {
        var request = {
            name: "getCharsOfGame",
            param: {
                gameID: parseInt(gameID)
            }
        };
        mutate(request);

        request = {
            name: "getCompanionsOfGame",
            param: {
                gameID: parseInt(gameID)
            }
        };
        mutateComp(request);
    }

    useEffect(() => {
        reloadData();
        const interval = setInterval(() => {
            reloadData();
        }, 5000);
        return () => clearInterval(interval);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h5 className='mb-3'>Player Characters</h5>
            {chars ? chars.map((char, index) => (
                <div className={char.hp <= 0 ? 'alert alert-dark text-primary p-1 h4' : 'alert alert-light text-primary bg-white-tp p-1 h4'} key={char.id}>
                    <Row>
                        {char.pic ?
                            <Col className="text-start pe-0">
                                <img src={API_URL + "/playerCharPics/" + char.id + ".png"} alt="" height="100px" className='rounded'></img>
                            </Col>
                            : null}
                        <Col className="d-flex justify-content-center align-items-center">
                            <h2>{char.name}</h2>
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faShieldHalved} className="me-1" /> {char.AC}
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faEye} className="me-1" />

                            {10 + (getModifier(char.wisdom, false) + (char.proficiencyBonus + char.proficiencyBonusMod) * char.perceptionProf)}

                        </Col>
                    </Row>
                    {char.conditions !== "" ?
                        <Row className="my-1 mx-1 p-1 text-center text-primary alert alert-light bg-tp bg-white-tp">
                            {char.conditions.split(",").map(condID => (
                                <Col>
                                    <OverlayTrigger overlay={<Tooltip id={condID}>{conditions.find(cond => cond.id === parseInt(condID)).name}</Tooltip>}>
                                        <div className='cursor-pointer' onClick={() => {
                                            setCurrentChar({ id: char.id, type: "player", hp: char.hp, maxHP: char.maxHP, tempHP: char.tempHP, func: "updateHealthOfComp", conditions: char.conditions });
                                            setCondition(conditions.find(cond => cond.id === parseInt(condID)));
                                            setShowCondition(true);
                                        }}>
                                            <img alt="" id={condID} src={conditions.find(cond => cond.id === parseInt(condID)).pic} style={{ height: "30px", width: "auto" }} />
                                        </div>
                                    </OverlayTrigger>
                                </Col>
                            ))}
                        </Row>
                        : null}
                    <Row className="px-3-5 mt-1">
                        {char.tempHP > 0 ?
                            <ProgressBar style={{ height: "25px" }}
                                className={`cursor-pointer mb-1 ${char.tempHP % 2 === 0 ? "bg-tempHP" : "bg-info"}`}
                                onClick={() => { handleShowHealth(true); setCurrentChar({ id: char.id, type: "player", hp: char.hp, maxHP: char.maxHP, tempHP: char.tempHP, func: "updateHealthOfChar", conditions: char.conditions }); }}>
                                {[...Array(char.tempHP)].map((e, i) =>
                                    <ProgressBar className="skew" variant={i % 2 === 0 ? "info" : "info2"} now={100 / char.tempHP} key={i} />
                                )}
                                <div className="position-absolute bar-lable h5 p-0 text-light fill-available">
                                    <FontAwesomeIcon icon={faShield} /> {char.tempHP}
                                </div>
                            </ProgressBar>
                            : null}
                        <ProgressBar style={{ height: "25px", backgroundColor: "#9b9b9b" }} className="cursor-pointer p-0" onClick={() => { handleShowHealth(true); setCurrentChar({ id: char.id, type: "player", hp: char.hp, maxHP: char.maxHP, tempHP: char.tempHP, func: "updateHealthOfChar", conditions: char.conditions }); }}>
                            <ProgressBar variant={getHealthColor(char.hp, char.maxHP)} now={getHealthPerc(char.hp, char.maxHP)} key={1} />
                            <div className="position-absolute bar-lable h5 p-0 mt-0 text-light fill-available">
                                <FontAwesomeIcon fade={getHealthBeat(char.hp, char.maxHP)} icon={char.hp <= 0 ? faSkull : getHealthPerc(char.hp, char.maxHP) <= 10 ? faHeartBroken : faHeart} /> {char.hp}/{char.maxHP}
                            </div>
                        </ProgressBar>
                    </Row>
                </div>
            )) : null}
            <h5 className='mt-4 mb-3'>Companions</h5>
            {companions ? companions.map((char, index) => (
                <div className={char.hp <= 0 ? 'alert alert-dark text-primary p-1 h4' : 'alert alert-light text-primary bg-white-tp p-1 h4'} key={char.id} >
                    <Row className="cursor-pointer" onClick={() => { setShowCompDetails(true); setCurrentChar(char); }}>
                        {char.pic ?
                            <Col className="text-start pe-0">
                                <img src={API_URL + "/monsterCharPics/" + char.monsterID + ".png"} alt="" height="100px" className='rounded'></img>
                            </Col>
                            : null}
                        <Col className="d-flex justify-content-center align-items-center p-0">
                            <h2>{char.name}</h2>
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faShieldHalved} className="me-1" /> {char.AC}
                        </Col>
                    </Row>
                    {char.conditions !== "" ?
                        <Row className="my-1 mx-1 p-1 text-center text-primary alert alert-light bg-tp bg-white-tp">
                            {char.conditions.split(",").map(condID => (
                                <Col>
                                    <OverlayTrigger overlay={<Tooltip id={condID}>{conditions.find(cond => cond.id === parseInt(condID)).name}</Tooltip>}>
                                        <div className='cursor-pointer' onClick={() => {
                                            setCurrentChar({ id: char.id, type: "companion", hp: char.hp, maxHP: char.maxHP, tempHP: char.tempHP, func: "updateHealthOfComp", conditions: char.conditions });
                                            setCondition(conditions.find(cond => cond.id === parseInt(condID)));
                                            setShowCondition(true);
                                        }}>
                                            <img alt="" id={condID} src={conditions.find(cond => cond.id === parseInt(condID)).pic} style={{ height: "30px", width: "auto" }} />
                                        </div>
                                    </OverlayTrigger>
                                </Col>
                            ))}
                        </Row>
                        : null}
                    <Row className="px-3-5 mt-1">
                        {char.tempHP > 0 ?
                            <ProgressBar style={{ height: "25px" }}
                                className={`cursor-pointer mb-1 ${char.tempHP % 2 === 0 ? "bg-tempHP" : "bg-info"}`}
                                onClick={() => { handleShowHealth(true); setCurrentChar({ id: char.id, type: "companion", hp: char.hp, maxHP: char.maxHP, tempHP: char.tempHP, func: "updateHealthOfComp", conditions: char.conditions }); }}>
                                {[...Array(char.tempHP)].map((e, i) =>
                                    <ProgressBar className="skew" variant={i % 2 === 0 ? "info" : "info2"} now={100 / char.tempHP} key={i} />
                                )}
                                <div className="position-absolute bar-lable h5 p-0 text-light fill-available">
                                    <FontAwesomeIcon icon={faShield} /> {char.tempHP}
                                </div>
                            </ProgressBar>
                            : null}
                        <ProgressBar style={{ height: "25px", backgroundColor: "#9b9b9b" }} className="cursor-pointer p-0" onClick={() => { handleShowHealth(true); setCurrentChar({ id: char.id, type: "companion", hp: char.hp, maxHP: char.maxHP, tempHP: char.tempHP, func: "updateHealthOfComp", conditions: char.conditions }); }}>
                            <ProgressBar variant={getHealthColor(char.hp, char.maxHP)} now={getHealthPerc(char.hp, char.maxHP)} key={1} />
                            <div className="position-absolute bar-lable h5 p-0 mt-0 text-light fill-available">
                                <FontAwesomeIcon fade={getHealthBeat(char.hp, char.maxHP)} icon={char.hp <= 0 ? faSkull : getHealthPerc(char.hp, char.maxHP) <= 10 ? faHeartBroken : faHeart} /> {char.hp}/{char.maxHP}
                            </div>
                        </ProgressBar>
                    </Row>
                </div>
            )) : null}
            <Form.Group className="col-auto ps-1 mb-3">
                <div className="d-grid gap-2">
                    <Button variant="outline-primary" onClick={() => setShowAddComp(true)}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </div>
            </Form.Group>
            <HealthChange show={showHealth} setShowEdit={setShowConditionEdit} charID={currentChar.id} healthData={{ hp: currentChar.hp, maxHP: currentChar.maxHP, tempHP: currentChar.tempHP }} func={currentChar.func} handleClose={handleCloseHealth} reloadHealth={reloadData} />
            <AddMonster show={showAddComp} handleClose={handleCloseAddComp} type="Companions" playerChars={chars} reload={reloadData} />
            {currentChar.id !== 0 ?
                <MonsterCard show={showCompDetails} handleClose={handleCloseCompDetails} monsterID={currentChar.monsterID} charID={currentChar.id} charType={"comp"} />
                : null}
            <ShowCondition show={showCondition} handleClose={handleCloseCondition} setShowEdit={setShowConditionEdit} condition={condition} />
            <EditConditions show={showConditionEdit} handleClose={handleCloseConditionEdit} charConditions={currentChar.conditions.split(",")} charID={currentChar.id} charType={currentChar.type} reload={reloadData} />
        </div>
    )
}
