import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { sendRequestAuth } from '../../services/apiFunctions';

export default function ChangeProficiencies({ show, char, handleClose, reload }) {

    const [formData, setFormData] = useState({});

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                reload();
                handleClose();
            } else {
                console.log(data.data.message);
            }
        },
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const request = {
            name: "editCharProficiencies",
            param: {
                charID: parseInt(char.id),
                armorProficiencies: formData.armorProficiencies,
                weaponProficiencies: formData.weaponProficiencies,
                toolProficiencies: formData.toolProficiencies,
                languageProficiencies: formData.languageProficiencies
            }
        };

        //console.log(request);
        mutate(request);
    }

    useEffect(() => {
        setFormData(values => ({ ...values, "armorProficiencies": char.armorProficiencies }))
        setFormData(values => ({ ...values, "weaponProficiencies": char.weaponProficiencies }))
        setFormData(values => ({ ...values, "toolProficiencies": char.toolProficiencies }))
        setFormData(values => ({ ...values, "languageProficiencies": char.languageProficiencies }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);


    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Change Proficiencies</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-start'>
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" />
                            :
                            null
                    }
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Armor Proficiencies</Form.Label>
                                <Form.Control type="text" name="armorProficiencies" defaultValue={char.armorProficiencies} onChange={(e) => handleChange(e)} required />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Weapon Proficiencies</Form.Label>
                                <Form.Control type="text" name="weaponProficiencies" defaultValue={char.weaponProficiencies} onChange={(e) => handleChange(e)} required />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Tool Proficiencies</Form.Label>
                                <Form.Control type="text" name="toolProficiencies" defaultValue={char.toolProficiencies} onChange={(e) => handleChange(e)} required />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Languages</Form.Label>
                                <Form.Control type="text" name="languageProficiencies" defaultValue={char.languageProficiencies} onChange={(e) => handleChange(e)} required />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="outline-primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
