import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import { Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import useDocumentTitle, { logout, sendRequestAuth } from '../services/apiFunctions'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import ShowClass from '../parts/ShowClass'

export default function Classes() {

    useDocumentTitle("Classes");

    const [dataClasses, setDataClasses] = useState([])
    const [search, setSearch] = useState("")
    const [singleClass, setSingleClasss] = useState()

    const [showClass, setShowClass] = useState(false);
    const handleCloseClass = () => {
        setShowClass(false);
    };

    const navigate = useNavigate()

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            setDataClasses("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setDataClasses(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setDataClasses(data.data.message);
            }
        },
    });

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    useEffect(() => {
        const request = { name: "getUserClasses", param: {} };
        mutate(request);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleClassShowClick(newClass) {
        setSingleClasss(newClass);
        setShowClass(true);
    }

    return (
        <Container className="py-4 text-center">
            <Row>
                <Col></Col>
                <Col xl={8} className="position-relative">
                    <div className="position-absolute top-0 start-0 ps-3">
                        <Link to="/home" className='btn btn-outline-primary text-left'><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    </div>
                    <h2 className='mb-4'>Classes</h2>
                    {dataClasses ? dataClasses.length > 0 ?
                        <div className="d-grid gap-2">
                            <Form.Group className='mb-4'>
                                <Form.Control type="text" name="search" id="search" placeholder="Search..." onChange={handleSearch} />
                            </Form.Group>
                        </div>
                        : null : null}
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" /> : null
                    }
                    {dataClasses ? dataClasses.length > 0 ? dataClasses.filter(singleClass =>
                        singleClass.name.toLowerCase().includes(search.toLowerCase()) || singleClass.source.toLowerCase().includes(search.toLowerCase())).map(d => (
                            <div className={d.public === 0 ? 'alert alert-light text-primary bg-white-tp p-1 cursor-pointer' : 'alert alert-light text-primary p-1 cursor-pointer'}
                                key={d.id}>
                                <Row>
                                    <Col className="text-start py-2 ms-2 pe-0" onClick={() => handleClassShowClick(d)}>
                                        <h4>{d.name}</h4>
                                    </Col>
                                    <Col className="d-flex col-auto align-items-center me-2" onClick={() => handleClassShowClick(d)}>
                                        <i>{d.source}</i>
                                    </Col>
                                </Row>

                            </div>
                        )) : <i>You have no activated sources with backgrounds</i> : null}
                </Col>
                <Col></Col>
            </Row>
            <ShowClass show={showClass} handleClose={handleCloseClass} singleClass={singleClass} />
        </Container>
    )
}
