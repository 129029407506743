import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap'
import useDocumentTitle, { API_URL, logout, sendRequestAuth } from '../services/apiFunctions'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons'
import NewChar from '../parts/NewChar'

export default function Chars() {

    useDocumentTitle("Characters");

    const [data, setData] = useState([])

    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState(false);

    const [char, setChar] = useState({});

    const handleClose = (reload) => {
        const request = { name: "getChars", param: {} };
        mutate(request);
        setShow(false)
        if (reload) {
            window.location.reload(false);
        }
    };

    const navigate = useNavigate()

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setData(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    useEffect(() => {
        const request = { name: "getChars", param: {} };
        mutate(request);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleClick(charID) {
        localStorage.setItem("charID", charID);
        localStorage.setItem("previous", "chars");

        navigate("/characterSheet");
    }

    return (
        <Container className="py-4 text-center">
            <Row>
                <Col></Col>
                <Col xl={8} className="position-relative">
                    <div className="position-absolute top-0 start-0 ps-3">
                        <Link to="/home" className='btn btn-outline-primary text-left'><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    </div>
                    <div className="position-absolute top-0 end-0 pe-3">
                        <Link to="/charBuilder" className='btn btn-outline-primary text-left'><FontAwesomeIcon icon={faPlus} /></Link>
                        {/*<Button variant="outline-primary" onClick={() => { setShow(true); setEdit(false); }}><FontAwesomeIcon icon={faPlus} /></Button>*/}
                    </div>
                    <h2 className='mb-4'>Your Characters</h2>
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" /> :
                            <div>
                                {data ? data.length > 0 ? data.map(d => (
                                    <div className='alert alert-light text-primary bg-white-tp p-1' key={d.id}>
                                        <Row>
                                            {d.pic ?
                                                <Col className="text-start col-auto cursor-pointer" onClick={() => handleClick(d.id)}>
                                                    <img src={API_URL + "/playerCharPics/" + d.id + ".png"} alt="" height="100px" className='rounded'></img>
                                                </Col>
                                                : null}
                                            <Col className="d-flex justify-content-center align-items-center cursor-pointer" onClick={() => handleClick(d.id)}>
                                                <h4>{d.name}</h4>
                                            </Col>
                                            <Col className="d-flex col-auto align-items-center">
                                                <Button className="me-4" variant="outline-primary" onClick={() => { setChar(d); setShow(true); setEdit(true); }}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                )) : <i>Create a character by clicking on the <b>+</b></i> : null}
                            </div>
                    }
                </Col>
                <Col></Col>
            </Row>
            <NewChar show={show} handleClose={handleClose} edit={edit} char={char} />
        </Container>
    )
}
