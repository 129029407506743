import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { API_URL } from '../services/apiFunctions'

export default function ShowFeat({ show, handleClose, feat }) {

    return (
        <Modal size="lg" show={show} onHide={() => handleClose(false)}>
            {feat ?
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title>{feat.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="line-breaks p-3">
                        {feat.pic ?
                            <div className='text-center my-3'>
                                <img src={API_URL + "/featPics/" + feat.id + ".jpg"} style={{ maxHeight: "250px", maxWidth: "100%" }} alt="" className='rounded'></img>
                            </div>
                            : null}
                        <div className="my-2" dangerouslySetInnerHTML={{ __html: feat.description }}>
                        </div>
                        <b>Source:</b> {feat.source === "HB" ? "Custom" : feat.source}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </div>
                : null}
        </Modal>
    )
}
