import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { getDEXforArmor, getDEXStringforArmor, getModifier, getShieldString, sendRequestAuth } from '../../services/apiFunctions';

export default function MiscStats({ show, char, handleClose, reload }) {

    const [formData, setFormData] = useState({});
    const [equipment, setEquipment] = useState([])

    const [armorID, setArmorID] = useState(0)
    const [armorAC, setArmorAC] = useState(0)
    const [armorType, setArmorType] = useState(0)

    const [shieldID, setShieldID] = useState(0)
    const [shieldAC, setShieldAC] = useState(0)

    const { mutate: mutateEquipment, isLoading: isLoadingEquipment } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setEquipment(data.data.result);

                var equip = data.data.result.find(d => d.id === parseInt(char.armor))
                if (equip) {
                    setArmorID(equip.id)
                    setArmorAC(equip.AC)
                    setArmorType(equip.armorType)
                } else {
                    setArmorID(0)
                    setArmorAC(10)
                    setArmorType("")
                }

                equip = data.data.result.find(d => d.id === parseInt(char.shield))
                if (equip) {
                    setShieldID(equip.id)
                    setShieldAC(equip.AC)
                } else {
                    setShieldID(0)
                    setShieldAC(0)
                }
            } else {
                console.log(data.data.message);
            }
        },
    });

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                reload();
                handleClose();
            } else {
                console.log(data.data.message);
            }
        },
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    const handleArmorChange = (e) => {
        var equip = equipment.find(d => d.id === parseInt(e.target.value))

        if (equip) {
            setArmorID(equip.id)
            setArmorAC(equip.AC)
            setArmorType(equip.armorType)
        } else {
            setArmorID(0)
            setArmorAC(10)
            setArmorType("")
        }
    }

    const handleShieldChange = (e) => {
        var equip = equipment.find(d => d.id === parseInt(e.target.value))

        if (equip) {
            setShieldID(equip.id)
            setShieldAC(equip.AC)
        } else {
            setShieldID(0)
            setShieldAC(0)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const request = {
            name: "editCharMiscStats",
            param: {
                charID: parseInt(char.id),
                ac: (armorAC + getDEXforArmor(armorType, char.dexterity) === 0 ? 10 : armorAC + getDEXforArmor(armorType, char.dexterity)) + parseInt(shieldAC) + parseInt(formData.acMod),
                acMod: parseInt(formData.acMod),
                armor: armorID,
                shield: parseInt(shieldID),
                initiative: parseInt(formData.initiative),
                proficiencyBonus: parseInt(formData.proficiencyBonus)
            }
        };

        //console.log(request);
        mutate(request);
    }

    useEffect(() => {
        var request = {
            name: "getEquipmentOfChar",
            param: {
                charID: parseInt(char.id)
            }
        };
        mutateEquipment(request);

        setFormData(values => ({ ...values, "acMod": char.ACmod }))
        setFormData(values => ({ ...values, "initiative": char.initiative }))
        setFormData(values => ({ ...values, "proficiencyBonus": char.proficiencyBonusMod }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);


    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Change Misc Stats</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-start'>
                    {
                        isLoading || isLoadingEquipment ? <Spinner animation="border" className="mb-4" />
                            :
                            null
                    }
                    <Row>
                        <Col>
                            <Form.Group className="mt-3" controlId="armor">
                                <Form.Label>Armor</Form.Label>
                                <Form.Select defaultValue={char.armor} name="armor" onChange={(e) => handleArmorChange(e)}>
                                    <option value="None">None</option>
                                    {equipment.filter(equip => equip.armorType === "light").length > 0 ?
                                        <optgroup label="Light Armor:">
                                            {equipment.filter(equip => equip.armorType === "light").map(equip => (
                                                <option key={equip.id} value={equip.id}>{equip.name}</option>
                                            ))}
                                        </optgroup>
                                        : null}
                                    {equipment.filter(equip => equip.armorType === "medium").length > 0 ?
                                        <optgroup label="Medium Armor:">
                                            {equipment.filter(equip => equip.armorType === "medium").map(equip => (
                                                <option key={equip.id} value={equip.id}>{equip.name}</option>
                                            ))}
                                        </optgroup>
                                        : null}
                                    {equipment.filter(equip => equip.armorType === "heavy").length > 0 ?
                                        <optgroup label="Heavy Armor:">
                                            {equipment.filter(equip => equip.armorType === "heavy").map(equip => (
                                                <option key={equip.id} value={equip.id}>{equip.name}</option>
                                            ))}
                                        </optgroup>
                                        : null}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mt-3" controlId="shield">
                                <Form.Label>Shield</Form.Label>
                                <Form.Select name="shield" defaultValue={char.shield} onChange={(e) => handleShieldChange(e)}>
                                    <option value="0">None</option>
                                    {equipment.filter(equip => equip.armorType === "shield").map(equip => (
                                        <option key={equip.id} value={equip.id}>{equip.name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mt-3" controlId="name">
                                <Form.Label>AC Mod</Form.Label>
                                <Form.Control type="number" name="acMod" defaultValue={char.ACmod} onChange={(e) => handleChange(e)} required />
                            </Form.Group>
                        </Col>
                    </Row>
                    <small className="text-secondary">Armor ({armorAC}){getDEXStringforArmor(armorType, char.dexterity) + getShieldString(shieldAC)} + Mod ({formData.acMod})</small>
                    <Row>
                        <Col>
                            <Form.Group className="mt-3" controlId="name">
                                <Form.Label>Initiative Mod</Form.Label>
                                <Form.Control type="number" name="initiative" defaultValue={char.initiative} onChange={(e) => handleChange(e)} required />
                                <small className="text-secondary">DEX ({getModifier(char.dexterity)}) + Mod ({formData.initiative})</small>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mt-3" controlId="name">
                                <Form.Label>Proficiency Bonus Mod</Form.Label>
                                <Form.Control type="number" name="proficiencyBonus" defaultValue={char.proficiencyBonusMod} onChange={(e) => handleChange(e)} required />
                                <small className="text-secondary">Level Bonus ({char.proficiencyBonus}) + Mod ({formData.proficiencyBonus})</small>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="outline-primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
