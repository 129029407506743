import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { getRandomInt, sendRequestAuth } from '../../services/apiFunctions';
import SelectWeapon from './SelectWeapon';

export default function ClassDetailSelect({ show, handleClose, singleClass, saveClass, backgroundSkills, backgroundToolProficiencies }) {
    const [classData, setClassData] = useState();

    const [page, setPage] = useState("");

    const [skillProficiencies, setSkillProficiencies] = useState()
    const [skillsCount, setSkillsCount] = useState(0)
    const [toolProficiencies, setToolProficiencies] = useState([])
    const [toolsCount, setToolsCount] = useState(0)

    const [goldAlternative, setGoldAlternative] = useState(false)
    const [goldAlternativeAmount, setGoldAlternativeAmount] = useState(0)
    const [goldAltRolls, setGoldAltRolls] = useState([])

    const [chooseEquip1, setChooseEquip1] = useState(null)
    const [chooseEquip1Custom1, setChooseEquip1Custom1] = useState(null)
    const [chooseEquip1Custom2, setChooseEquip1Custom2] = useState(null)
    const [chooseEquip2, setChooseEquip2] = useState(null)
    const [chooseEquip2Custom1, setChooseEquip2Custom1] = useState(null)
    const [chooseEquip2Custom2, setChooseEquip2Custom2] = useState(null)
    const [chooseEquip3, setChooseEquip3] = useState(null)
    const [chooseEquip3Custom1, setChooseEquip3Custom1] = useState(null)
    const [chooseEquip3Custom2, setChooseEquip3Custom2] = useState(null)
    const [chooseEquip4, setChooseEquip4] = useState(null)
    const [chooseEquip4Custom1, setChooseEquip4Custom1] = useState(null)
    const [chooseEquip4Custom2, setChooseEquip4Custom2] = useState(null)

    const [subclass, setSubclass] = useState(null)

    function handleSave() {
        var equip = [];

        if (chooseEquip1 !== null) {
            if (chooseEquip1[0].id === null) {
                equip.push(chooseEquip1Custom1);
                if (chooseEquip1Custom2) {
                    equip.push(chooseEquip1Custom2)
                }
                if (chooseEquip1[1]) {
                    equip.push(chooseEquip1[1]);
                }
            } else {
                equip.push(...chooseEquip1);
            }
        }
        if (chooseEquip2 !== null) {

            if (chooseEquip2[0].id === null) {
                equip.push(chooseEquip2Custom1);
                if (chooseEquip2Custom2) {
                    equip.push(chooseEquip2Custom2)
                }
                if (chooseEquip2[1]) {
                    equip.push(chooseEquip2[1]);
                }
            } else {
                equip.push(...chooseEquip2);
            }
        }
        if (chooseEquip3 !== null) {
            if (chooseEquip3[0].id === null) {
                equip.push(chooseEquip3Custom1);
                if (chooseEquip3Custom2) {
                    equip.push(chooseEquip3Custom2)
                }
                if (chooseEquip3[1]) {
                    equip.push(chooseEquip3[1]);
                }
            } else {
                equip.push(...chooseEquip3);
            }
        }
        if (chooseEquip4 !== null) {
            if (chooseEquip4[0].id === null) {
                equip.push(chooseEquip4Custom1);
                if (chooseEquip4Custom2) {
                    equip.push(chooseEquip4Custom2)
                }
                if (chooseEquip4[1]) {
                    equip.push(chooseEquip4[1]);
                }
            } else {
                equip.push(...chooseEquip4);
            }
        }
        equip.push(...classData.equipFixed);

        //console.log(equip)

        saveClass(skillProficiencies, toolProficiencies.concat(classData.toolProf), equip, goldAlternativeAmount, subclass)
        handleClose(false)
    }

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                update(data.data.result);

                //console.log(data.data.result);
            } else {
                console.log(data.data.message);
            }
        },
    });

    useEffect(() => {
        setPage("");
        setGoldAlternative(false);
        setGoldAlternativeAmount(0);
        setGoldAltRolls([])
        setSkillProficiencies([
            {
                name: "athletics",
                dispName: "Athletics",
                proficiency: backgroundSkills[0].proficiency === 1 ? 1 : 0
            },
            {
                name: "acrobatics",
                dispName: "Acrobatics",
                proficiency: backgroundSkills[1].proficiency === 1 ? 1 : 0
            },
            {
                name: "sleightOfHand",
                dispName: "Sleight of Hand",
                proficiency: backgroundSkills[2].proficiency === 1 ? 1 : 0
            },
            {
                name: "stealth",
                dispName: "Stealth",
                proficiency: backgroundSkills[3].proficiency === 1 ? 1 : 0
            },
            {
                name: "arcana",
                dispName: "Arcana",
                proficiency: backgroundSkills[4].proficiency === 1 ? 1 : 0
            },
            {
                name: "history",
                dispName: "History",
                proficiency: backgroundSkills[5].proficiency === 1 ? 1 : 0
            },
            {
                name: "investigation",
                dispName: "Investigation",
                proficiency: backgroundSkills[6].proficiency === 1 ? 1 : 0
            },
            {
                name: "nature",
                dispName: "Nature",
                proficiency: backgroundSkills[7].proficiency === 1 ? 1 : 0
            },
            {
                name: "religion",
                dispName: "Religion",
                proficiency: backgroundSkills[8].proficiency === 1 ? 1 : 0
            },
            {
                name: "animalHandling",
                dispName: "Animal Handling",
                proficiency: backgroundSkills[9].proficiency === 1 ? 1 : 0
            },
            {
                name: "insight",
                dispName: "Insight",
                proficiency: backgroundSkills[10].proficiency === 1 ? 1 : 0
            },
            {
                name: "medicine",
                dispName: "Medicine",
                proficiency: backgroundSkills[11].proficiency === 1 ? 1 : 0
            },
            {
                name: "perception",
                dispName: "Perception",
                proficiency: backgroundSkills[12].proficiency === 1 ? 1 : 0
            },
            {
                name: "survival",
                dispName: "Survival",
                proficiency: backgroundSkills[13].proficiency === 1 ? 1 : 0
            },
            {
                name: "deception",
                dispName: "Deception",
                proficiency: backgroundSkills[14].proficiency === 1 ? 1 : 0
            },
            {
                name: "intimidation",
                dispName: "Intimidation",
                proficiency: backgroundSkills[15].proficiency === 1 ? 1 : 0
            },
            {
                name: "performance",
                dispName: "Performance",
                proficiency: backgroundSkills[16].proficiency === 1 ? 1 : 0
            },
            {
                name: "persuasion",
                dispName: "Persuasion",
                proficiency: backgroundSkills[17].proficiency === 1 ? 1 : 0
            }
        ]);
        setSkillsCount(0);
        setToolProficiencies(backgroundToolProficiencies);
        setToolsCount(0);

        setChooseEquip1(null);
        setChooseEquip2(null);
        setChooseEquip3(null);
        setChooseEquip4(null);

        if (show === true) {
            const request = {
                name: "getClassData",
                param: {
                    id: singleClass.id
                }
            };
            mutate(request);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    function update(data) {
        setClassData(data);
        if (page === "" && (singleClass.id === 3 || singleClass.id === 10 || singleClass.id === 11)) {
            setPage("subclass");
        } else if ((page === "" || page === "subclass") && singleClass.skillsChooseNumber > 0) {
            setPage("skill");
        } else if ((page === "" || page === "subclass" || page === "skill") && singleClass.toolProfChooseNumber > 0) {
            setPage("tool");
        } else if (page === "" || page === "subclass" || page === "skill" || page === "tool") {
            setPage("equip");
        } else {
            handleSave();
        }
        //console.log(skillProficiencies)
    }

    function handleSkillChange(skillName) {
        const list = [...skillProficiencies];
        list.forEach((skill, index) => {
            if (skill.name === skillName) {
                if (skill.proficiency === 0) {
                    setSkillsCount(skillsCount + 1)
                } else {
                    setSkillsCount(skillsCount - 1)
                }

                list[index]["proficiency"] = skill.proficiency === 0 ? 1 : 0;
            }
        })
        setSkillProficiencies(list);
    }

    function handleToolChange(tool) {
        if (toolProficiencies.some(item => item.name === tool.name)) {
            setToolProficiencies(toolProficiencies.filter(item => item.name !== tool.name))
            setToolsCount(toolsCount - 1)
        } else {
            setToolProficiencies(toolProficiencies.concat(tool))
            setToolsCount(toolsCount + 1)
        }
    }



    function rollGold() {
        var rolls = [];

        for (let i = 0; i < singleClass.alternativeGoldDice; i++) {
            var val = getRandomInt(4);
            rolls.push(val);
        }

        //console.log(rolls)

        setGoldAltRolls(rolls);
        setGoldAlternativeAmount(rolls.reduce((partialSum, a) => partialSum + a, 0) * 10);
    }

    return (
        <Modal size="lg" show={show} backdrop="static" onHide={() => handleClose(false)} centered>
            {singleClass ?
                <div>
                    <Modal.Header>
                        <Modal.Title>{page === "subclass" ?
                            "Choose Subclass"
                            : page === "skill" ?
                                "Choose " + singleClass.skillsChooseNumber + " skill proficienc" + (singleClass.skillsChooseNumber === 1 ? "y" : "ies")
                                : page === "tool" ?
                                    "Choose " + singleClass.toolProfChooseNumber + " tool proficienc" + (singleClass.toolProfChooseNumber === 1 ? "y" : "ies")
                                    : page === "equip" ?
                                        "Choose Equipment"
                                        : "Loading"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="line-breaks p-3">
                        {isLoading ?
                            <Spinner animation="border" className="mb-4" />
                            :
                            <div>
                                {
                                    page === "subclass" ?
                                        classData.subclasses.map((d, i) => (
                                            <div key={i} className="mb-3">
                                                <Form.Check // prettier-ignore
                                                    type="radio"
                                                    id={i + "subclass"}
                                                    name={"subclass"}
                                                    label={d.name}
                                                    checked={subclass === d}
                                                    onChange={e => setSubclass(d)}
                                                />
                                            </div>
                                        ))
                                        : page === "skill" ?
                                            skillProficiencies ? skillProficiencies.map((d, i) => (
                                                <div key={i} className="mb-3">
                                                    <Form.Check // prettier-ignore
                                                        type="checkbox"
                                                        id={d.name}
                                                        label={d.dispName}
                                                        checked={d.proficiency === 1}
                                                        onChange={e => handleSkillChange(d.name)}
                                                        disabled={(!classData.skillsChoose.some(item => item.name === d.dispName) && singleClass.skillsChooseNumber > 0 && singleClass.skillsChoose !== "") || (skillsCount >= singleClass.skillsChooseNumber && d.proficiency === 0) || backgroundSkills.some(item => item.dispName === d.dispName && item.proficiency === 1)}
                                                    />
                                                </div>
                                            )) : null
                                            : page === "tool" ?
                                                <>
                                                    {classData.toolProfChoose.map((d, i) => (
                                                        <div key={i} className="mb-3">
                                                            <Form.Check // prettier-ignore
                                                                type="checkbox"
                                                                id={d.name + "tool"}
                                                                name={d.name + "tool"}
                                                                label={d.name}
                                                                checked={toolProficiencies.some(item => item.name === d.name)}
                                                                onChange={e => handleToolChange(d)}
                                                                disabled={toolsCount >= singleClass.toolProfChooseNumber && !toolProficiencies.some(item => item.name === d.name)}
                                                            />
                                                        </div>
                                                    ))}
                                                    {backgroundToolProficiencies.concat(classData.toolProf).map((d, i) => (
                                                        <div key={i} className="mb-3">
                                                            <Form.Check // prettier-ignore
                                                                type="checkbox"
                                                                id={d.name + "tool"}
                                                                name={d.name + "tool"}
                                                                label={d.name}
                                                                checked={true}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    ))}
                                                </>
                                                : page === "equip" ?
                                                    goldAlternative ?
                                                        <div className='text-center'>
                                                            <p>{singleClass.alternativeGoldDice}d4 x 10</p>
                                                            <h1 className='display-1'>{goldAlternativeAmount}</h1>
                                                            {goldAlternativeAmount > 0 ?
                                                                <p>{"(" + goldAltRolls.map((d, i) => ((i !== 0 ? " + " : "") + d + "")) + ") x 10"}</p>
                                                                : null}
                                                            <Button variant="outline-primary" onClick={() => rollGold()}>Roll</Button>
                                                        </div>
                                                        :
                                                        <>
                                                            <h6>Choose:</h6>
                                                            {classData.equipChoose1.map((d, i) => (
                                                                <div key={i} className="mb-3">
                                                                    <Form.Check // prettier-ignore
                                                                        type="radio"
                                                                        id={i + "equip1"}
                                                                        name={"equipChoose1"}
                                                                        label={d.map((k, p) => ((p !== 0 ? " + " : "") + k.name + " (x" + k.amount + ")"))}
                                                                        checked={chooseEquip1 === d}
                                                                        onChange={e => setChooseEquip1(d)}
                                                                    />
                                                                </div>
                                                            ))}
                                                            {chooseEquip1 && chooseEquip1[0].id === null ?
                                                                <>
                                                                    <SelectWeapon type={chooseEquip1[0].name} setEquip={setChooseEquip1Custom1} />
                                                                    {chooseEquip1[0].amount > 1 ?
                                                                        <SelectWeapon type={chooseEquip1[0].name} setEquip={setChooseEquip1Custom2} />
                                                                        : null
                                                                    }
                                                                </>
                                                                : null}
                                                            {classData.equipChoose2.length > 0 ?
                                                                <>
                                                                    <h6>Choose:</h6>
                                                                    {classData.equipChoose2.map((d, i) => (
                                                                        <div key={i} className="mb-3">
                                                                            <Form.Check // prettier-ignore
                                                                                type="radio"
                                                                                id={i + "equip2"}
                                                                                name={"equipChoose2"}
                                                                                label={d.map((k, p) => ((p !== 0 ? " + " : "") + k.name + " (x" + k.amount + ")"))}
                                                                                checked={chooseEquip2 === d}
                                                                                onChange={e => setChooseEquip2(d)}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </>
                                                                : null}
                                                            {chooseEquip2 && chooseEquip2[0].id === null ?
                                                                <>
                                                                    <SelectWeapon type={chooseEquip2[0].name} setEquip={setChooseEquip2Custom1} />
                                                                    {chooseEquip2[0].amount > 1 ?
                                                                        <SelectWeapon type={chooseEquip2[0].name} setEquip={setChooseEquip2Custom2} />
                                                                        : null
                                                                    }
                                                                </>
                                                                : null}
                                                            {classData.equipChoose3.length > 0 ?
                                                                <>
                                                                    <h6>Choose:</h6>
                                                                    {classData.equipChoose3.map((d, i) => (
                                                                        <div key={i} className="mb-3">
                                                                            <Form.Check // prettier-ignore
                                                                                type="radio"
                                                                                id={i + "equip3"}
                                                                                name={"equipChoose3"}
                                                                                label={d.map((k, p) => ((p !== 0 ? " + " : "") + k.name + " (x" + k.amount + ")"))}
                                                                                checked={chooseEquip3 === d}
                                                                                onChange={e => setChooseEquip3(d)}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </>
                                                                : null}
                                                            {chooseEquip3 && chooseEquip3[0].id === null ?
                                                                <>
                                                                    <SelectWeapon type={chooseEquip3[0].name} setEquip={setChooseEquip3Custom1} />
                                                                    {chooseEquip3[0].amount > 1 ?
                                                                        <SelectWeapon type={chooseEquip3[0].name} setEquip={setChooseEquip3Custom2} />
                                                                        : null
                                                                    }
                                                                </>
                                                                : null}
                                                            {classData.equipChoose4.length > 0 ?
                                                                <>
                                                                    <h6>Choose:</h6>
                                                                    {classData.equipChoose4.map((d, i) => (
                                                                        <div key={i} className="mb-3">
                                                                            <Form.Check // prettier-ignore
                                                                                type="radio"
                                                                                id={i + "equip4"}
                                                                                name={"equipChoose4"}
                                                                                label={d.map((k, p) => ((p !== 0 ? " + " : "") + k.name + " (x" + k.amount + ")"))}
                                                                                checked={chooseEquip4 === d}
                                                                                onChange={e => setChooseEquip4(d)}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </>
                                                                : null}
                                                            {chooseEquip4 && chooseEquip4[0].id === null ?
                                                                <>
                                                                    <SelectWeapon type={chooseEquip4[0].name} setEquip={setChooseEquip4Custom1} />
                                                                    {chooseEquip4[0].amount > 1 ?
                                                                        <SelectWeapon type={chooseEquip4[0].name} setEquip={setChooseEquip4Custom2} />
                                                                        : null
                                                                    }
                                                                </>
                                                                : null}
                                                            {singleClass.equipFixed !== "" ?
                                                                <>
                                                                    <h6>Fixed:</h6>
                                                                    <ul>
                                                                        {
                                                                            classData.equipFixed.map((d) => (
                                                                                <li>{d.name + " (x" + d.amount + ")"}</li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : null}
                                                        </>

                                                    : "Error"}
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {page === "equip" ?
                            <Button
                                variant="outline-primary"
                                onClick={() => { setGoldAlternative(!goldAlternative); setGoldAlternativeAmount(0) }}>
                                {goldAlternative ? "Equipment" : "Gold Alternative"}
                            </Button>
                            : null}
                        <Button
                            variant="primary"
                            onClick={() => update(classData)}
                            disabled={
                                (page === "subclass" && !subclass) ||
                                (page === "skill" && !(skillsCount >= singleClass.skillsChooseNumber)) ||
                                (page === "tool" && !(toolsCount >= singleClass.toolProfChooseNumber)) ||
                                (page === "equip" && !goldAlternative && ((chooseEquip1 === null && singleClass.equipChoose1 !== "") || (chooseEquip2 === null && singleClass.equipChoose2 !== "") || (chooseEquip3 === null && singleClass.equipChoose3 !== "") || (chooseEquip4 === null && singleClass.equipChoose4 !== ""))) ||
                                (page === "equip" && goldAlternative && goldAlternativeAmount === 0) ||
                                isLoading
                            }>
                            Continue
                        </Button>
                    </Modal.Footer>
                </div>
                : null}
        </Modal>
    )
}
