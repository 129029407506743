import React from 'react'
import { Button, Modal } from 'react-bootstrap'

export default function ShowCondition({ show, handleClose, setShowEdit, condition }) {

    function handleEdit() {
        setShowEdit(true)
        handleClose(false)
    }

    return (
        <Modal size="lg" show={show} onHide={() => handleClose(false)}>
            {condition ?
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title>{condition.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="line-breaks p-3">
                        <div className='text-center my-3'>
                            <img src={condition.pic} style={{ maxHeight: "50px", maxWidth: "100%" }} alt="" className='rounded'></img>
                        </div>
                        <div className="my-2" dangerouslySetInnerHTML={{ __html: condition.description }}>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => handleEdit()}>
                            Edit Conditions
                        </Button>
                        <Button variant="secondary" onClick={() => handleClose(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </div>
                : null}
        </Modal>
    )
}
