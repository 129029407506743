import { faShieldHalved, faShieldHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { API_URL, sendRequestAuth } from '../services/apiFunctions';

export default function CharDetails({ show, char, handleClose, type }) {

    const [deleteQuestion, setDeleteQuestion] = useState(false);

    const { mutate, isLoading: isLoadingDelete } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                handleClose(true);
            } else {
                console.log(data.data.message);
            }
        },
    });

    function handleDelete() {
        setDeleteQuestion(false);
        var request;
        if (type === "playerChar") {
            request = {
                name: "deleteChar",
                param: {
                    id: char.id
                }
            };
        } else if (type === "fighter") {
            request = {
                name: "removeFighter",
                param: {
                    id: char.id
                }
            };
        }
        mutate(request);
    }


    return (
        <Modal size="lg" show={show} onHide={handleClose}>
            {char ? <div>
                <Modal.Header closeButton>
                    <Modal.Title>{char.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='text-center'>
                        {char.pic ?
                            <Col>
                                <img src={API_URL + "/playerCharPics/" + (type === "fighter" ? char.charID : char.id) + ".png"} alt="" height="150px" className='rounded'></img>
                            </Col>
                            : null}
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center align-items-center h3 mt-4">
                            <FontAwesomeIcon icon={faShieldHalved} className="me-1" /> {char.AC}
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center h3 mt-4">
                            <FontAwesomeIcon icon={faShieldHeart} className="me-1" /> {char.maxHP}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {isLoadingDelete ?
                        <Modal.Body className="text-center text-primary">
                            <Spinner animation="border" />
                        </Modal.Body>
                        : null}
                    {deleteQuestion ?
                        <div className='text-center container-fluid'>
                            <hr />
                            <div>
                                <p>Do you really want to {type === "fighter" ? "remove" : "delete"} this character?</p>
                                <Button variant="outline-primary me-2" onClick={() => setDeleteQuestion(false)}>
                                    Abort
                                </Button>
                                <Button variant="primary" onClick={handleDelete}>
                                    {type === "fighter" ? "Remove" : "Delete"}
                                </Button>
                            </div>
                            <hr />
                        </div>
                        :
                        <Button variant="outline-primary" onClick={() => setDeleteQuestion(true)}>
                            {type === "fighter" ? "Remove" : "Delete"}
                        </Button>
                    }
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </div> : null
            }
        </Modal>
    )
}
