import React, { useState } from 'react'
import { Button, ButtonGroup, Col, Row } from 'react-bootstrap'
import { getModifier, getRandomInt, removeSmallest } from '../../services/apiFunctions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faDice, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'

export default function AttributesSelect({ saveAttributes, ancestryAbilities }) {
  const [method, setMethod] = useState(0)
  const [points, setPoints] = useState(27)

  const [valArray, setValArray] = useState([8, 10, 12, 13, 14, 15])

  const [abilities, setAbilities] = useState([
    {
      name: "STR",
      value: 8
    },
    {
      name: "DEX",
      value: 8
    },
    {
      name: "CON",
      value: 8
    },
    {
      name: "INT",
      value: 8
    },
    {
      name: "WIS",
      value: 8
    },
    {
      name: "CHA",
      value: 8
    }
  ])

  function handleSave(array, methodAb) {
    var tempAbilities = [];
    abilities.map((d, i) => {
      if (methodAb) {
        tempAbilities.push(array[i] + ancestryAbilities[i])
      } else {
        tempAbilities.push(array[i].value + ancestryAbilities[i])
      }
      return true;
    })
    //console.log(tempAbilities)

    saveAttributes(tempAbilities)
  }

  function handlePointDistrebution(ability, val, currVal) {
    if (val === 1) {
      if (points - 1 >= 0) {
        if (currVal >= 13) {
          setPoints(points - 2)
        } else {
          setPoints(points - 1)
        }
        handleAddToAbility(ability, val)
      }
    } else {
      if (points + 1 <= 27) {
        if (currVal >= 14) {
          setPoints(points + 2)
        } else {
          setPoints(points + 1)
        }
        handleAddToAbility(ability, val)
      }
    }
  }

  function handleSortArray(index, delta) {
    //console.log(valArray[index])

    var tempArray = [];
    valArray.map((d, i) => (
      tempArray.push(valArray[i])
    ))

    if (index + delta < 0 || index + delta === valArray.length) {
      return;
    };

    tempArray[index + delta] = valArray[index];
    tempArray[index] = valArray[index + delta];

    //console.log(tempArray)
    handleSetValArray(tempArray)
  }

  function handleAddToAbility(ability, val) {
    const list = [...abilities];
    list.forEach((a, index) => {
      if (a.name === ability) {
        if (list[index]["value"] + val < 8) {
          list[index]["value"] = 8;
        } else if (list[index]["value"] + val > 30) {
          list[index]["value"] = 30;
        } else {
          list[index]["value"] = list[index]["value"] + val;
        }
      }
    })
    handleSetAbilities(list);
  }

  function handleMethodChange(methodNr) {
    setMethod(methodNr)
    setPoints(27)
    if (methodNr === 2 || methodNr === 4) {
      handleSetAbilities(([
        {
          name: "STR",
          value: 8
        },
        {
          name: "DEX",
          value: 8
        },
        {
          name: "CON",
          value: 8
        },
        {
          name: "INT",
          value: 8
        },
        {
          name: "WIS",
          value: 8
        },
        {
          name: "CHA",
          value: 8
        }
      ]))
    }
  }

  function handleRollVals() {
    var tempArray = [];

    valArray.map((d, i) => {
      tempArray.push(valArray[i])
      return true;
    })

    tempArray.map((d, i) => {
      var rolls = [0, 0, 0, 0]

      rolls.map((k, f) => {
        rolls[f] = getRandomInt(6)
        return true;
      })

      rolls = removeSmallest(rolls);
      //console.log(rolls)
      tempArray[i] = rolls.reduce((partialSum, a) => partialSum + a, 0)

      return true;
    })

    handleSetValArray(tempArray)
  }

  function handleSetAbilities(array) {
    setAbilities(array)
    handleSave(array, false)
  }

  function handleSetValArray(array) {
    setValArray(array)
    handleSave(array, true)
  }

  return (
    <div>
      <ButtonGroup className='mb-5'>
        <Button variant={method === 1 ? "primary" : "outline-primary"} onClick={(e) => { handleSetValArray([8, 10, 12, 13, 14, 15]); handleMethodChange(1); }}>
          Standard Array
        </Button>
        <Button variant={method === 2 ? "primary" : "outline-primary"} onClick={(e) => handleMethodChange(2)}>
          <small>Point Distrebution</small>
        </Button>
        <Button variant={method === 3 ? "primary" : "outline-primary"} onClick={(e) => { handleSetValArray([8, 8, 8, 8, 8, 8]); handleMethodChange(3); }}>
          4d6 (Remove Lowest)
        </Button>
        <Button variant={method === 4 ? "primary" : "outline-primary"} onClick={(e) => handleMethodChange(4)}>
          Custom Attributes
        </Button>
      </ButtonGroup>
      {
        method === 1 || method === 3 ?
          <>
            {
              method === 3 ?
                <Button variant='outline-primary' className='h1 mb-5' size='lg' onClick={(e) => handleRollVals()}>
                  <FontAwesomeIcon icon={faDice} /> Roll
                </Button>
                : null
            }
            {abilities.map((ability, index) => (
              <Row className='h1 mb-4' key={ability.name}>
                <Col xs className='px-0 py-2'>
                  {ability.name}
                </Col>

                <Col xs className='p-0 h3'>
                  <div className='alert alert-light text-primary bg-white-tp p-2'>
                    {valArray[index]}<span className='text-success'> {(ancestryAbilities[index] > 0 ? "+" + ancestryAbilities[index] : "")}</span>{" (" + getModifier(valArray[index] + ancestryAbilities[index], true) + ")"}
                  </div>
                </Col>

                <Col xs="auto" className='text-end py-2 ps-3 pe-1'>
                  <Button variant='primary' className='h1' onClick={(e) => handleSortArray(index, 1)} disabled={index === abilities.length - 1}>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </Button>
                </Col>

                <Col xs="auto" className='text-start py-2 ps-1 pe-3'>
                  <Button variant='primary' className='h1' onClick={(e) => handleSortArray(index, -1)} disabled={index === 0}>
                    <FontAwesomeIcon icon={faChevronUp} />
                  </Button>
                </Col>
              </Row>
            ))}
          </>
          : method === 2 ?
            <>
              <h2 className='mb-5 mt-0'>{points} points</h2>
              {abilities.map((ability, index) => (
                <Row className='h1 mb-4' key={ability.name}>
                  <Col xs className='px-0 py-2'>
                    {ability.name}
                  </Col>
                  <Col xs="auto" className='text-end py-2 ps-3'>
                    <Button variant='primary' className='h1' onClick={(e) => handlePointDistrebution(ability.name, -1, ability.value)} disabled={ability.value <= 8}>
                      <FontAwesomeIcon icon={faMinus} />
                    </Button>
                  </Col>
                  <Col xs className='p-0 h3'>
                    <div className='alert alert-light text-primary bg-white-tp p-2'>
                      {(ability.value)}<span className='text-success'> {(ancestryAbilities[index] > 0 ? "+" + ancestryAbilities[index] : "")}</span>{" (" + getModifier(ability.value + ancestryAbilities[index], true) + ")"}
                    </div>
                  </Col>
                  <Col xs="auto" className='text-start py-2 pe-3'>
                    <Button variant='primary' className='h1' onClick={(e) => handlePointDistrebution(ability.name, 1, ability.value)} disabled={ability.value >= 15 || points === 0}>
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </Col>
                </Row>
              ))}
            </>
            : method === 3 ?
              <p>Order Vals</p>
              : method === 4 ?
                <>
                  {abilities.map((ability, index) => (
                    <Row className='h1 mb-4' key={ability.name}>
                      <Col xs className='px-0 py-2'>
                        {ability.name}
                      </Col>
                      <Col xs="auto" className='text-end py-2 ps-3'>
                        <Button variant='primary' className='h1' onClick={(e) => handleAddToAbility(ability.name, -1)} disabled={ability.value <= 8}>
                          <FontAwesomeIcon icon={faMinus} />
                        </Button>
                      </Col>
                      <Col xs className='p-0 h3'>
                        <div className='alert alert-light text-primary bg-white-tp p-2'>
                          {(ability.value)}<span className='text-success'> {(ancestryAbilities[index] > 0 ? "+" + ancestryAbilities[index] : "")}</span>{" (" + getModifier(ability.value + ancestryAbilities[index], true) + ")"}
                        </div>
                      </Col>
                      <Col xs="auto" className='text-start py-2 pe-3'>
                        <Button variant='primary' className='h1' onClick={(e) => handleAddToAbility(ability.name, 1)} disabled={ability.value >= 30}>
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </>
                : null
      }
    </div >
  )
}
