import { faChevronDown, faChevronUp, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Col, Collapse, Row, Spinner } from 'react-bootstrap';
import ChangeBackground from '../charSheetModals/ChangeBackground';
import ChangeProficiencies from '../charSheetModals/ChangeProficiencies';
import NewFeature from '../charSheetModals/NewFeature';

export default function Features({ char, reload, features, getHeight }) {

    const [feature, setFeature] = useState({});

    const [open, setOpen] = useState([]);

    const [showFeatureEdit, setShowFeatureEdit] = useState(false);
    const handleCloseFeatureEdit = () => setShowFeatureEdit(false);

    const [showBackground, setShowBackground] = useState(false);
    const handleCloseBackground = () => setShowBackground(false);

    const [showProficiencies, setShowProficiencies] = useState(false);
    const handleCloseProficiencies = () => setShowProficiencies(false);

    useEffect(() => {
        if (features) {
            var newOpen = []
            for (let i = 0; i < features.length; i++) {
                newOpen[i] = false;
            }
            setOpen(newOpen);
        }
    }, [features]);

    function calculateHeight() {
        setTimeout(function () {
            getHeight();
        }, 400);
    }

    return (
        <div>
            <div className='text-primary alert alert-light bg-white-tp'>
                <h3 className="text-center mb-3">Features</h3>
                <hr />
                {features ? features.map((feature, index) => (
                    <div key={feature.id} className="text-start mb-2">
                        <Row>
                            <Col className='cursor-pointer ps-3' onClick={() => { setOpen(values => ({ ...values, [index]: !open[index] })); calculateHeight() }}>
                                <h6><FontAwesomeIcon icon={open[index] ? faChevronDown : faChevronUp} /> {feature.name}</h6>
                            </Col>
                            <Col className='col-auto pe-3'>
                                <Button size='sm' variant='outline-primary' onClick={() => { setFeature(feature); setShowFeatureEdit(true); }}>
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                            </Col>
                        </Row>
                        <Collapse in={open[index]}>
                            <div className='m-0 p-0 line-breaks feature'>
                                {feature.origin === "feat" ?
                                    <p className='mb-3'><i>Feat</i></p>
                                    : feature.origin === "pactBoon" ?
                                        <p className='mb-3'><i>Pact Boon</i></p>
                                        : feature.origin === "eldritchInvocation" ?
                                            <p className='mb-3'><i>Eldritch Invocation</i></p>
                                            : feature.origin === "artificerInfusion" ?
                                                <p className='mb-3'><i>Artificer Infusion</i></p>
                                                : feature.origin !== "" ?
                                                    <p className='mb-3'><i>{feature.level + (feature.level === 1 ? "st" : feature.level === 2 ? "nd" : feature.level === 3 ? "rd" : "th") + "-level " +
                                                        (feature.origin === "class" ?
                                                            char.className
                                                            : feature.origin === "subclass" ?
                                                                char.subclassName
                                                                : feature.origin === "ancestry" ?
                                                                    char.ancestryName
                                                                    : feature.origin === "background" ?
                                                                        char.backgroundName
                                                                        : feature.origin)
                                                        + " feature"}</i></p>
                                                    : null
                                }
                                <div className="m-0 p-0" dangerouslySetInnerHTML={{ __html: feature.description }}>
                                </div>
                            </div>
                        </Collapse>
                        <hr />
                    </div>
                ))
                    :
                    <Spinner animation="border" className="my-2" />
                }

                <div className="d-grid gap-2 mt-3">
                    <Button variant="outline-primary" onClick={() => { setFeature(null); setShowFeatureEdit(true); }}>
                        <FontAwesomeIcon icon={faPlus} /> Feature
                    </Button>
                </div>
            </div >
            <div className='text-primary mt-3 alert alert-light bg-white-tp cursor-pointer' onClick={() => { setShowProficiencies(true); }}>
                <h3 className="text-center mb-3">Proficiencies</h3>
                <p className="text-start line-breaks mb-1">
                    <b>Armor:</b> {char.armorProficiencies ? char.armorProficiencies : <i>nothing</i>}
                </p>
                <p className="text-start line-breaks mb-1">
                    <b>Weapons:</b> {char.weaponProficiencies ? char.weaponProficiencies : <i>nothing</i>}
                </p>
                <p className="text-start line-breaks mb-1">
                    <b>Tools:</b> {char.toolProficiencies ? char.toolProficiencies : <i>nothing</i>}
                </p>
                <p className="text-start line-breaks mb-1">
                    <b>Languages:</b> {char.languageProficiencies ? char.languageProficiencies : <i>nothing</i>}
                </p>
            </div>
            <div className='text-primary mt-3 alert alert-light bg-white-tp cursor-pointer' onClick={() => { setShowBackground(true); }}>
                <h3 className="text-center mb-3">Background</h3>
                <p className="text-start line-breaks mb-1">
                    <b>Background:</b> {char.background ? char.background : char.backgroundName ? char.backgroundName : <i>empty</i>}
                </p>
                <p className="text-start line-breaks mb-1">
                    <b>Personality Trait:</b> {char.personalityTrait ? char.personalityTrait : <i>empty</i>}
                </p>
                <p className="text-start line-breaks mb-1">
                    <b>Ideal:</b> {char.ideal ? char.ideal : <i>empty</i>}
                </p>
                <p className="text-start line-breaks mb-1">
                    <b>Bond:</b> {char.bond ? char.bond : <i>empty</i>}
                </p>
                <p className="text-start line-breaks mb-1">
                    <b>Flaw:</b> {char.flaw ? char.flaw : <i>empty</i>}
                </p>
                <p className="text-start line-breaks mb-1">
                    <b>Alignment:</b> {char.alignment ? char.alignment : <i>empty</i>}
                </p>
            </div>
            <NewFeature show={showFeatureEdit} handleClose={handleCloseFeatureEdit} feature={feature} charID={char.id} reload={reload} />
            <ChangeBackground show={showBackground} handleClose={handleCloseBackground} char={char} reload={reload} />
            <ChangeProficiencies show={showProficiencies} handleClose={handleCloseProficiencies} char={char} reload={reload} />
        </div >
    )
}
