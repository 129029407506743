import { faDiceD20, faEyeSlash, faForward, faHeart, faHeartBroken, faPlus, faShield, faShieldHalved, faSkull } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Col, OverlayTrigger, ProgressBar, Row, Spinner, ToggleButton, Tooltip } from 'react-bootstrap'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { API_URL, conditions, getHealthBeat, getHealthColor, getHealthPerc, logout, sendRequestAuth } from '../../services/apiFunctions'
import AddMonster from '../AddMonster'
import CharDetails from '../CharDetails'
import HealthChange from '../HealthChange'
import InitiativeAdd from '../InitiativeAdd'
import MonsterCard from '../MonsterCard'
import ShowCondition from '../ShowCondition'
import EditConditions from '../EditConditions'

export default function Fight() {

    const [condition, setCondition] = useState(null)

    const [showAdd, setShowAdd] = useState(false);
    const handleCloseAdd = () => {
        handleGetFighters();
        setShowAdd(false);
    }

    const [showAddInit, setShowAddInit] = useState(false);
    const handleCloseAddInit = () => {
        handleGetFighters();
        setShowAddInit(false);
    }

    const [showMonsterDetails, setShowMonsterDetails] = useState(false);
    const handleCloseMonsterDetails = () => {
        handleGetFighters();
        setShowMonsterDetails(false)
    };

    const [showCharDetails, setShowCharDetails] = useState(false);
    const handleCloseCharDetails = () => {
        handleGetFighters();
        setShowCharDetails(false);
    }

    const [showCondition, setShowCondition] = useState(false);
    const handleCloseCondition = () => setShowCondition(false);

    const [showConditionEdit, setShowConditionEdit] = useState(false);
    const handleCloseConditionEdit = () => {
        handleGetFighters();
        setShowConditionEdit(false);
    }

    const [showHealth, setShowHealth] = useState(false);
    const handleCloseHealth = () => setShowHealth(false);
    const handleShowHealth = () => setShowHealth(true);

    const [chars, setChars] = useState();
    const [companions, setCompanions] = useState();
    const [fight, setFight] = useState();

    const [currentChar, setCurrentChar] = useState({ id: 0, type: "", hp: 0, maxHP: 0, tempHP: 0, func: "", conditions: "" });

    const gameID = localStorage.getItem("gameID");

    const navigate = useNavigate()

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setChars(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    const { mutate: mutateFight } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result.game);
            if (data.data.status === 200) {
                setFight(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    const { mutate: mutateComp, isLoading: isLoadingComp } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setCompanions(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    const { mutate: mutateChangeFight } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                handleGetFighters();
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    useEffect(() => {
        document.addEventListener('keydown', detectKeyDown, true);

        var request = {
            name: "getCharsOfGame",
            param: {
                gameID: parseInt(gameID)
            }
        };
        mutate(request);

        request = {
            name: "getCompanionsOfGame",
            param: {
                gameID: parseInt(gameID)
            }
        };
        mutateComp(request);

        handleGetFighters();

        const interval = setInterval(() => {
            handleGetFighters();
        }, 5000);

        return () => clearInterval(interval);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleGetFighters() {
        const request = {
            name: "getFight",
            param: {
                gameID: parseInt(gameID)
            }
        };
        mutateFight(request)
    }

    const handleStartFight = (state) => {
        const request = {
            name: "changeFight",
            param: {
                gameID: parseInt(gameID),
                fightActive: state
            }
        };
        mutateChangeFight(request)
    }

    const changeShowAC = (state) => {
        const request = {
            name: "changeShowAC",
            param: {
                gameID: parseInt(gameID),
                showAC: state
            }
        };
        mutateChangeFight(request)
    }

    const changeShowHP = (state) => {
        const request = {
            name: "changeShowHP",
            param: {
                gameID: parseInt(gameID),
                showHP: parseInt(state)
            }
        };
        //console.log(request)
        mutateChangeFight(request)
    }

    const handleNextRound = () => {
        const request = {
            name: "nextRound",
            param: {
                gameID: parseInt(gameID)
            }
        };
        mutateChangeFight(request)
    }

    const detectKeyDown = (e) => {
        if (e.key === "ArrowRight") {
            handleNextRound();
        }
    }

    return (
        <div>
            <h5 className="mb-4">{fight && fight.game.fightActive ? "Fight" : "Prepare Fight"}</h5>
            {isLoading || isLoadingComp ? <Spinner animation="border" className="mb-4" /> :
                <div>
                    {chars && fight ? chars.filter(item => !fight.fighters.some(function (char) { return char.charID === item.id && char.type === "player" })).map(char => (
                        <div className='alert alert-light text-primary bg-white-tp p-1 h4' key={char.id}>
                            <Row>
                                {char.pic ?
                                    <Col className="text-start col-auto pe-0">
                                        <img src={API_URL + "/playerCharPics/" + char.id + ".png"} alt="" height="40px" className='rounded'></img>
                                    </Col>
                                    : null}
                                <Col className="text-start ms-1">
                                    {char.name}
                                </Col>
                                <Col className="col-auto">
                                    <Button variant="outline-primary" onClick={() => { setShowAddInit(true); setCurrentChar({ id: char.id, type: "player", hp: 0, maxHP: 0, tempHP: 0, func: "player", conditions: char.conditions }); }}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    )) : null}

                    {companions && fight ? companions.filter(item => !fight.fighters.some(function (companion) { return companion.charID === item.id && companion.type === "companion" })).map(char => (
                        <div className='alert alert-light text-primary bg-white-tp p-1 h4' key={char.id}>
                            <Row>
                                {char.pic ?
                                    <Col className="text-start col-auto pe-0">
                                        <img src={API_URL + "/monsterCharPics/" + char.monsterID + ".png"} alt="" height="40px" className='rounded'></img>
                                    </Col>
                                    : null}
                                <Col className="text-start ms-1">
                                    {char.name}
                                </Col>
                                <Col className="col-auto">
                                    <Button variant="outline-primary" onClick={() => { setShowAddInit(true); setCurrentChar({ id: char.id, type: "companion", hp: 0, maxHP: 0, tempHP: 0, func: "companion", conditions: char.conditions }); }}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    )) : null}
                </div>
            }
            <div className="d-grid gap-2">
                <Button variant="outline-primary" onClick={() => setShowAdd(true)}>
                    <FontAwesomeIcon icon={faPlus} /> Monsters
                </Button>
            </div>
            <hr className="text-primary"></hr>
            {isLoading || isLoadingComp ? <Spinner animation="border" className="mb-4" /> :
                <div>
                    {fight ? fight.fighters.map((char, index) => (
                        <div className={`alert border text-primary p-1 h4 
                            ${char.hp <= 0 ? 'alert-dark' : 'alert-light bg-white-tp'} 
                            ${index === fight.game.fightNumber && fight.game.fightActive ? 'border border-4 border-primary' : ''}`}
                            key={char.id} >
                            <Row className="cursor-pointer m-0 p-0" onClick={() => { if (char.type === "player") { setShowCharDetails(true) } else { setShowMonsterDetails(true) }; setCurrentChar(char); }}>
                                {char.pic ?
                                    <Col className="text-start ps-0">
                                        {char.type === "player" ?
                                            <img src={API_URL + "/playerCharPics/" + char.charID + ".png"} alt="" height="100px" className='rounded'></img>
                                            : char.type === "monster" ?
                                                <img src={API_URL + "/monsterCharPics/" + char.charID + ".png"} alt="" height="100px" className='rounded'></img>
                                                :
                                                <img src={API_URL + "/monsterCharPics/" + char.monsterID + ".png"} alt="" height="100px" className='rounded'></img>
                                        }
                                    </Col>
                                    : null}
                                <Col className="d-flex justify-content-center align-items-center">
                                    <h2 className={`${char.type === "monster" ? 'text-danger' : 'text-success'}`}>{char.name}</h2>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={faShieldHalved} className="me-1" /> {char.AC}
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={faDiceD20} className="me-1" /> {char.initiative}
                                </Col>
                            </Row>
                            {char.conditions !== "" ?
                                <Row className="my-1 mx-1 p-1 text-center text-primary alert alert-light bg-tp bg-white-tp">
                                    {char.conditions.split(",").map(condID => (
                                        <Col>
                                            <OverlayTrigger overlay={<Tooltip id={condID}>{conditions.find(cond => cond.id === parseInt(condID)).name}</Tooltip>}>
                                                <div className='cursor-pointer' onClick={() => {
                                                    setCurrentChar({ id: char.type === "monster" ? char.id : char.charID, type: char.type, hp: char.hp, maxHP: char.maxHP, tempHP: char.tempHP, func: char.type === "player" ? "updateHealthOfChar" : char.type === "companion" ? "updateHealthOfComp" : "updateHealthOfMonster", conditions: char.conditions });
                                                    setCondition(conditions.find(cond => cond.id === parseInt(condID)));
                                                    setShowCondition(true);
                                                }}>
                                                    <img alt="" id={condID} src={conditions.find(cond => cond.id === parseInt(condID)).pic} style={{ height: "30px", width: "auto" }} />
                                                </div>
                                            </OverlayTrigger>
                                        </Col>
                                    ))}
                                </Row>
                                : null}
                            <Row className="px-3-5 mt-1">
                                {char.tempHP > 0 ?
                                    <ProgressBar style={{ height: "25px" }}
                                        className={`cursor-pointer mb-1 ${char.tempHP % 2 === 0 ? "bg-tempHP" : "bg-info"}`}
                                        onClick={() => { handleShowHealth(true); setCurrentChar({ id: char.type === "monster" ? char.id : char.charID, type: char.type, hp: char.hp, maxHP: char.maxHP, tempHP: char.tempHP, func: char.type === "player" ? "updateHealthOfChar" : char.type === "companion" ? "updateHealthOfComp" : "updateHealthOfMonster", conditions: char.conditions }); }}>
                                        {[...Array(char.tempHP)].map((e, i) =>
                                            <ProgressBar className="skew" variant={i % 2 === 0 ? "info" : "info2"} now={100 / char.tempHP} key={i} />
                                        )}
                                        <div className="position-absolute bar-lable h5 p-0 text-light fill-available">
                                            <FontAwesomeIcon icon={faShield} /> {char.tempHP}
                                        </div>
                                    </ProgressBar>
                                    : null}
                                <ProgressBar style={{ height: "25px", backgroundColor: "#9b9b9b" }} className="cursor-pointer p-0" onClick={() => { handleShowHealth(true); setCurrentChar({ id: char.type === "monster" ? char.id : char.charID, type: char.type, hp: char.hp, maxHP: char.maxHP, tempHP: char.tempHP, func: char.type === "player" ? "updateHealthOfChar" : char.type === "companion" ? "updateHealthOfComp" : "updateHealthOfMonster", conditions: char.conditions }); }}>
                                    <ProgressBar variant={getHealthColor(char.hp, char.maxHP)} now={getHealthPerc(char.hp, char.maxHP)} key={1} />
                                    <div className="position-absolute bar-lable h5 p-0 mt-0 text-light fill-available">
                                        <FontAwesomeIcon fade={getHealthBeat(char.hp, char.maxHP)} icon={char.hp <= 0 ? faSkull : getHealthPerc(char.hp, char.maxHP) <= 10 ? faHeartBroken : faHeart} /> {char.hp}/{char.maxHP}
                                    </div>
                                </ProgressBar>
                            </Row>
                        </div>
                    )) : null}
                    <hr className="text-primary"></hr>
                    {fight ?
                        <div>
                            <Row className="pb-4">
                                <Col className="d-grid gap-2">
                                    <ButtonGroup>
                                        <ToggleButton
                                            type="radio"
                                            variant={'outline-primary'}
                                            checked={!fight.game.showAC}
                                            onClick={(e) => { changeShowAC(false) }}
                                        >
                                            <FontAwesomeIcon icon={faEyeSlash} />
                                        </ToggleButton>
                                        <ToggleButton
                                            type="radio"
                                            variant={'outline-primary'}
                                            checked={fight.game.showAC}
                                            onClick={(e) => { changeShowAC(true) }}
                                        >
                                            <FontAwesomeIcon icon={faShieldHalved} />
                                        </ToggleButton>
                                    </ButtonGroup>
                                </Col>
                                <Col className="d-grid gap-2">
                                    <ButtonGroup>
                                        <ToggleButton
                                            type="radio"
                                            variant={'outline-primary'}
                                            checked={fight.game.showHP === -1}
                                            onClick={(e) => { changeShowHP(-1) }}
                                        >
                                            <FontAwesomeIcon icon={faEyeSlash} />
                                        </ToggleButton>
                                        <ToggleButton
                                            type="radio"
                                            variant={'outline-primary'}
                                            checked={fight.game.showHP === 0}
                                            onClick={(e) => { changeShowHP(0) }}
                                        >
                                            <FontAwesomeIcon icon={faHeartBroken} />
                                        </ToggleButton>
                                        <ToggleButton
                                            type="radio"
                                            variant={'outline-primary'}
                                            checked={fight.game.showHP === 1}
                                            onClick={(e) => { changeShowHP(1) }}
                                        >
                                            <FontAwesomeIcon icon={faHeart} />
                                        </ToggleButton>
                                    </ButtonGroup>
                                </Col>
                                {fight.game.fightActive ?
                                    <Col className="d-grid gap-2">
                                        <Button variant="outline-primary" onClick={handleNextRound}>
                                            <FontAwesomeIcon icon={faForward} />
                                        </Button>
                                    </Col>
                                    : null}
                            </Row>
                            {fight.game.fightActive ?
                                <Row className="d-grid gap-2 p-2">
                                    <Button variant="primary" onClick={() => handleStartFight(false)}>
                                        End Fight
                                    </Button>
                                </Row>
                                : null}
                        </div>
                        : null}
                    {!(fight && fight.game.fightActive) ?
                        <div className="d-grid gap-2">
                            <Button variant="primary" onClick={() => handleStartFight(true)}>
                                Start Fight
                            </Button>
                        </div>
                        : null}
                </div>
            }
            <HealthChange show={showHealth} charID={currentChar.id} setShowEdit={setShowConditionEdit} healthData={{ hp: currentChar.hp, maxHP: currentChar.maxHP, tempHP: currentChar.tempHP }} func={currentChar.func} handleClose={handleCloseHealth} reloadHealth={handleGetFighters} />
            <AddMonster show={showAdd} handleClose={handleCloseAdd} playerChars={chars} type="Fight" />
            <InitiativeAdd show={showAddInit} handleClose={handleCloseAddInit} char={currentChar} />
            {currentChar.id !== 0 ?
                <MonsterCard show={showMonsterDetails} handleClose={handleCloseMonsterDetails} monsterID={currentChar.type === "monster" ? currentChar.charID : currentChar.monsterID} charID={currentChar.id} charName={currentChar.name} charType={"fighter"} />
                : null}
            <CharDetails show={showCharDetails} char={currentChar} handleClose={handleCloseCharDetails} type="fighter" />
            <ShowCondition show={showCondition} handleClose={handleCloseCondition} setShowEdit={setShowConditionEdit} condition={condition} />
            <EditConditions show={showConditionEdit} handleClose={handleCloseConditionEdit} charConditions={currentChar.conditions.split(",")} charID={currentChar.id} charType={currentChar.type} />
        </div>
    )
}
