import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { sendRequestAuth } from '../../services/apiFunctions';

export default function BackgroundDetailSelect({ show, handleClose, background, saveBackground }) {

    const [backgroundData, setBackgroundData] = useState();

    const [page, setPage] = useState("");

    const [skillProficiencies, setSkillProficiencies] = useState()
    const [skillsCount, setSkillsCount] = useState(0)
    const [toolProficiencies, setToolProficiencies] = useState([])
    const [toolsCount, setToolsCount] = useState(0)
    const [languages, setLanguages] = useState([])
    const [langsCount, setLangsCount] = useState(0)
    const [equip, setEquip] = useState([])
    const [equipCount, setEquipCount] = useState(0)
    const [personalityTrait, setPersonalityTrait] = useState("")
    const [ideal, setIdeal] = useState("")
    const [bond, setBond] = useState("")
    const [flaw, setFlaw] = useState("")

    const languageList = ["Abyssal", "Celestial", "Common", "Deep Speech", "Draconic", "Dwarvish", "Elvish", "Giant", "Gnomish", "Goblin", "Halfling", "Infernal", "Orc", "Primordial", "Sylvan", "Undercommon"]

    function handleSave() {
        const tools = toolProficiencies.concat(backgroundData.toolsFixed);
        const equipment = equip.concat(backgroundData.equipFixed);

        saveBackground(languages, skillProficiencies, tools, equipment, personalityTrait, ideal, bond, flaw)
        handleClose(false)
    }

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                update(data.data.result);

                //console.log(data.data.result)

                setSkillProficiencies([
                    {
                        name: "athletics",
                        dispName: "Athletics",
                        proficiency: data.data.result.skillsFixed.some(item => item.name === "Athletics") ? 1 : 0
                    },
                    {
                        name: "acrobatics",
                        dispName: "Acrobatics",
                        proficiency: data.data.result.skillsFixed.some(item => item.name === "Acrobatics") ? 1 : 0
                    },
                    {
                        name: "sleightOfHand",
                        dispName: "Sleight of Hand",
                        proficiency: data.data.result.skillsFixed.some(item => item.name === "Sleight of Hand") ? 1 : 0
                    },
                    {
                        name: "stealth",
                        dispName: "Stealth",
                        proficiency: data.data.result.skillsFixed.some(item => item.name === "Stealth") ? 1 : 0
                    },
                    {
                        name: "arcana",
                        dispName: "Arcana",
                        proficiency: data.data.result.skillsFixed.some(item => item.name === "Arcana") ? 1 : 0
                    },
                    {
                        name: "history",
                        dispName: "History",
                        proficiency: data.data.result.skillsFixed.some(item => item.name === "History") ? 1 : 0
                    },
                    {
                        name: "investigation",
                        dispName: "Investigation",
                        proficiency: data.data.result.skillsFixed.some(item => item.name === "Investigation") ? 1 : 0
                    },
                    {
                        name: "nature",
                        dispName: "Nature",
                        proficiency: data.data.result.skillsFixed.some(item => item.name === "Nature") ? 1 : 0
                    },
                    {
                        name: "religion",
                        dispName: "Religion",
                        proficiency: data.data.result.skillsFixed.some(item => item.name === "Religion") ? 1 : 0
                    },
                    {
                        name: "animalHandling",
                        dispName: "Animal Handling",
                        proficiency: data.data.result.skillsFixed.some(item => item.name === "Animal Handling") ? 1 : 0
                    },
                    {
                        name: "insight",
                        dispName: "Insight",
                        proficiency: data.data.result.skillsFixed.some(item => item.name === "Insight") ? 1 : 0
                    },
                    {
                        name: "medicine",
                        dispName: "Medicine",
                        proficiency: data.data.result.skillsFixed.some(item => item.name === "Medicine") ? 1 : 0
                    },
                    {
                        name: "perception",
                        dispName: "Perception",
                        proficiency: data.data.result.skillsFixed.some(item => item.name === "Perception") ? 1 : 0
                    },
                    {
                        name: "survival",
                        dispName: "Survival",
                        proficiency: data.data.result.skillsFixed.some(item => item.name === "Survival") ? 1 : 0
                    },
                    {
                        name: "deception",
                        dispName: "Deception",
                        proficiency: data.data.result.skillsFixed.some(item => item.name === "Deception") ? 1 : 0
                    },
                    {
                        name: "intimidation",
                        dispName: "Intimidation",
                        proficiency: data.data.result.skillsFixed.some(item => item.name === "Intimidation") ? 1 : 0
                    },
                    {
                        name: "performance",
                        dispName: "Performance",
                        proficiency: data.data.result.skillsFixed.some(item => item.name === "Performance") ? 1 : 0
                    },
                    {
                        name: "persuasion",
                        dispName: "Persuasion",
                        proficiency: data.data.result.skillsFixed.some(item => item.name === "Persuasion") ? 1 : 0
                    }
                ]);
            } else {
                console.log(data.data.message);
            }
        },
    });

    useEffect(() => {
        setPage("");
        setSkillsCount(0);
        setLangsCount(0);
        setLanguages([])
        setToolsCount(0);
        setToolProficiencies([]);
        setEquipCount([]);
        setEquip([]);
        setPersonalityTrait("");
        setIdeal("");
        setBond("");
        setFlaw("");

        if (show === true) {
            const request = {
                name: "getBackgroundData",
                param: {
                    id: background.id
                }
            };
            mutate(request);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    function update(data) {
        setBackgroundData(data);
        if (page === "" && data.skillsChoose.length > 0) {
            setPage("skill");
        } else if ((page === "" || page === "skill") && background.toolsChooseNumber > 0) {
            setPage("tool");
        } else if ((page === "" || page === "skill" || page === "tool") && background.langsChooseNumber > 0) {
            setPage("lang");
        } else if ((page === "" || page === "skill" || page === "tool" || page === "lang") && data.equipChoose.length > 0) {
            setPage("equip");
        } else if ((page === "" || page === "skill" || page === "tool" || page === "lang" || page === "equip") && data.characteristics.filter(item => item.type === "personalityTrait").length > 0) {
            setPage("personalityTrait");
        } else if ((page === "" || page === "skill" || page === "tool" || page === "lang" || page === "equip" || page === "personalityTrait") && data.characteristics.filter(item => item.type === "ideal").length > 0) {
            setPage("ideal");
        } else if ((page === "" || page === "skill" || page === "tool" || page === "lang" || page === "equip" || page === "personalityTrait" || page === "ideal") && data.characteristics.filter(item => item.type === "bond").length > 0) {
            setPage("bond");
        } else if ((page === "" || page === "skill" || page === "tool" || page === "lang" || page === "equip" || page === "personalityTrait" || page === "ideal" || page === "bond") && data.characteristics.filter(item => item.type === "flaw").length > 0) {
            setPage("flaw");
        } else {
            handleSave();
        }
        //console.log(skillProficiencies)
    }

    function handleSkillChange(skillName) {
        const list = [...skillProficiencies];
        list.forEach((skill, index) => {
            if (skill.name === skillName) {
                if (skill.proficiency === 0) {
                    setSkillsCount(skillsCount + 1)
                } else {
                    setSkillsCount(skillsCount - 1)
                }

                list[index]["proficiency"] = skill.proficiency === 0 ? 1 : 0;
            }
        })
        setSkillProficiencies(list);
    }

    function handleLangChange(langName) {
        if (languages.some(item => item === langName)) {
            setLanguages(languages.filter(item => item !== langName))
            setLangsCount(langsCount - 1)
        } else {
            setLanguages(languages.concat(langName))
            setLangsCount(langsCount + 1)
        }

    }

    function handleToolChange(tool) {
        if (toolProficiencies.some(item => item.name === tool.name)) {
            setToolProficiencies(toolProficiencies.filter(item => item.name !== tool.name))
            setToolsCount(toolsCount - 1)
        } else {
            setToolProficiencies(toolProficiencies.concat(tool))
            setToolsCount(toolsCount + 1)
        }
    }

    function handleEquipChange(equipItem) {
        if (equip.some(item => item.name === equipItem.name)) {
            setEquip(equip.filter(item => item.name !== equipItem.name))
            setEquipCount(equipCount - 1)
        } else {
            setEquip(equip.concat(equipItem))
            setEquipCount(equipCount + 1)
        }
    }

    function handleCharacteristicChange(type, characteristic) {
        if (type === "personalityTrait") {
            setPersonalityTrait(characteristic);
        } else if (type === "ideal") {
            setIdeal(characteristic);
        } else if (type === "bond") {
            setBond(characteristic);
        } else if (type === "flaw") {
            setFlaw(characteristic);
        }
    }

    return (
        <Modal size="lg" show={show} backdrop="static" onHide={() => handleClose(false)} centered>
            {background ?
                <div>
                    <Modal.Header>
                        <Modal.Title>{page === "skill" ?
                            "Choose " + background.skillsChooseNumber + " skill proficienc" + (background.skillsChooseNumber === 1 ? "y" : "ies")
                            : page === "tool" ?
                                "Choose " + background.toolsChooseNumber + " tool proficienc" + (background.toolsChooseNumber === 1 ? "y" : "ies")
                                : page === "lang" ?
                                    "Choose " + background.langsChooseNumber + " language" + (background.langsChooseNumber === 1 ? "" : "s")
                                    : page === "equip" ?
                                        "Choose an item"
                                        : page === "personalityTrait" ?
                                            "Choose a personality trait"
                                            : page === "ideal" ?
                                                "Choose an ideal"
                                                : page === "bond" ?
                                                    "Choose a bond"
                                                    : page === "flaw" ?
                                                        "Choose a flaw"
                                                        : "Loading"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="line-breaks p-3">
                        {isLoading ?
                            <Spinner animation="border" className="mb-4" />
                            :
                            <div>
                                {page === "skill" ?
                                    skillProficiencies ? skillProficiencies.map((d, i) => (
                                        <div key={i} className="mb-3">
                                            <Form.Check // prettier-ignore
                                                type="checkbox"
                                                id={d.name + "skill"}
                                                name={d.name + "skill"}
                                                label={d.dispName}
                                                checked={d.proficiency === 1}
                                                onChange={e => handleSkillChange(d.name)}
                                                disabled={!backgroundData.skillsChoose.some(item => item.name === d.dispName) || (skillsCount >= background.skillsChooseNumber && d.proficiency === 0)}
                                            />
                                        </div>
                                    )) : null
                                    : page === "tool" ?
                                        <>
                                            {backgroundData.toolsChoose.map((d, i) => (
                                                <div key={i} className="mb-3">
                                                    <Form.Check // prettier-ignore
                                                        type="checkbox"
                                                        id={d.name + "tool"}
                                                        name={d.name + "tool"}
                                                        label={d.name}
                                                        checked={toolProficiencies.some(item => item.name === d.name)}
                                                        onChange={e => handleToolChange(d)}
                                                        disabled={toolsCount >= background.toolsChooseNumber && !toolProficiencies.some(item => item.name === d.name)}
                                                    />
                                                </div>
                                            ))}
                                            {backgroundData.toolsFixed.map((d, i) => (
                                                <div key={i} className="mb-3">
                                                    <Form.Check // prettier-ignore
                                                        type="checkbox"
                                                        id={d.name + "tool"}
                                                        name={d.name + "tool"}
                                                        label={d.name}
                                                        checked={true}
                                                        disabled={true}
                                                    />
                                                </div>
                                            ))}
                                        </>
                                        : page === "lang" ?
                                            background.langsChoose !== "" ?
                                                background.langsChoose.split(";").map((d, i) => (
                                                    <div key={i} className="mb-3">
                                                        <Form.Check // prettier-ignore
                                                            type="checkbox"
                                                            id={i + "lang"}
                                                            label={d}
                                                            checked={languages.some(item => item === d)}
                                                            onChange={e => handleLangChange(d)}
                                                            disabled={langsCount >= background.langsChooseNumber && !languages.some(item => item === d)}
                                                        />
                                                    </div>
                                                ))
                                                :
                                                languageList.map((d, i) => (
                                                    <div key={i} className="mb-3">
                                                        <Form.Check // prettier-ignore
                                                            type="checkbox"
                                                            id={i + "lang"}
                                                            label={d}
                                                            checked={languages.some(item => item === d)}
                                                            onChange={e => handleLangChange(d)}
                                                            disabled={langsCount >= background.langsChooseNumber && !languages.some(item => item === d)}
                                                        />
                                                    </div>
                                                ))
                                            : page === "equip" ?
                                                backgroundData.equipChoose.map((d, i) => (
                                                    <div key={i} className="mb-3">
                                                        <Form.Check // prettier-ignore
                                                            type="checkbox"
                                                            id={d.name + "equip"}
                                                            name={d.name + "equip"}
                                                            label={d.name}
                                                            checked={equip.some(item => item.name === d.name)}
                                                            onChange={e => handleEquipChange(d)}
                                                            disabled={equipCount >= 1 && !equip.some(item => item.name === d.name)}
                                                        />
                                                    </div>
                                                ))
                                                : page === "personalityTrait" ?
                                                    backgroundData.characteristics.filter(item => item.type === "personalityTrait").map((d, i) => (
                                                        <div key={i} onClick={e => handleCharacteristicChange("personalityTrait", d)}>
                                                            <Row className={`cursor-pointer py-3 px-2 m-0 ${d.id === personalityTrait.id ? 'bg-primary text-white' : null}`}>
                                                                <Col className="d-flex col-auto align-items-center">
                                                                    {i + 1}
                                                                </Col>
                                                                <Col className="d-flex align-items-center">
                                                                    <p className='m-0'>{d.description}</p>
                                                                </Col>
                                                            </Row>
                                                            <hr className="m-0" />
                                                        </div>
                                                    ))
                                                    : page === "ideal" ?
                                                        backgroundData.characteristics.filter(item => item.type === "ideal").map((d, i) => (
                                                            <div key={i} onClick={e => handleCharacteristicChange("ideal", d)}>
                                                                <Row className={`cursor-pointer py-3 px-2 m-0 ${d.id === ideal.id ? 'bg-primary text-white' : null}`}>
                                                                    <Col className="d-flex col-auto align-items-center">
                                                                        {i + 1}
                                                                    </Col>
                                                                    <Col className="d-flex align-items-center">
                                                                        <p className='m-0'>{d.description}</p>
                                                                    </Col>
                                                                </Row>
                                                                <hr className="m-0" />
                                                            </div>
                                                        ))
                                                        : page === "bond" ?
                                                            backgroundData.characteristics.filter(item => item.type === "bond").map((d, i) => (
                                                                <div key={i} onClick={e => handleCharacteristicChange("bond", d)}>
                                                                    <Row className={`cursor-pointer py-3 px-2 m-0 ${d.id === bond.id ? 'bg-primary text-white' : null}`}>
                                                                        <Col className="d-flex col-auto align-items-center">
                                                                            {i + 1}
                                                                        </Col>
                                                                        <Col className="d-flex align-items-center">
                                                                            <p className='m-0'>{d.description}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr className="m-0" />
                                                                </div>
                                                            ))
                                                            : page === "flaw" ?
                                                                backgroundData.characteristics.filter(item => item.type === "flaw").map((d, i) => (
                                                                    <div key={i} onClick={e => handleCharacteristicChange("flaw", d)}>
                                                                        <Row className={`cursor-pointer py-3 px-2 m-0 ${d.id === flaw.id ? 'bg-primary text-white' : null}`}>
                                                                            <Col className="d-flex col-auto align-items-center">
                                                                                {i + 1}
                                                                            </Col>
                                                                            <Col className="d-flex align-items-center">
                                                                                <p className='m-0'>{d.description}</p>
                                                                            </Col>
                                                                        </Row>
                                                                        <hr className="m-0" />
                                                                    </div>
                                                                ))
                                                                : "Error"}
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {page === "personalityTrait" || page === "ideal" || page === "bond" || page === "flaw" ?
                            <Button
                                variant="outline-primary"
                                onClick={() => update(backgroundData)} >
                                Skip {page === "personalityTrait" ? "personality trait" : page === "ideal" ? "ideal" : page === "bond" ? "bond" : page === "flaw" ? "flaw" : null}
                            </Button>
                            : null}
                        <Button
                            variant="primary"
                            onClick={() => update(backgroundData)}
                            disabled={
                                (page === "skill" && !(skillsCount >= background.skillsChooseNumber)) ||
                                (page === "tool" && !(toolsCount >= background.toolsChooseNumber)) ||
                                (page === "lang" && !(langsCount >= background.langsChooseNumber)) ||
                                (page === "equip" && !(equipCount >= 1)) ||
                                (page === "personalityTrait" && personalityTrait === "") ||
                                (page === "ideal" && ideal === "") ||
                                (page === "bond" && bond === "") ||
                                (page === "flaw" && flaw === "") ||
                                isLoading
                            }>

                            Continue
                        </Button>
                    </Modal.Footer>
                </div>
                : null}
        </Modal>
    )
}
