import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { API_URL, logout, sendRequestAuth, uploadCharPic } from '../services/apiFunctions';

export default function NewChar({ show, handleClose, edit, char }) {

    const [formData, setFormData] = useState({})
    const [alert, setAlert] = useState("");

    const [deleteQuestion, setDeleteQuestion] = useState(false);
    const [removeQuestion, setRemoveQuestion] = useState(false);

    const navigate = useNavigate()

    const { mutate: mutateUpload, isLoading: isLoadingUpload } = useMutation(uploadCharPic, {
        onError: () => {
            setAlert("An error occured while uploading data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.id);
            if (data.data.status === 200) {
                if (edit) {
                    handleClose(true);
                } else {
                    handleClose(false);
                }
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setAlert(data.data.message);
            }
        },
    });

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            setAlert("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result.charID);
            if (data.data.status === 200) {
                var formDataObj = new FormData();
                var imagefile = document.querySelector('#pic');
                formDataObj.append("pic", imagefile.files[0]);
                formDataObj.append("charID", data.data.result.charID);
                if (imagefile.files[0]) {
                    caches.delete(API_URL + "/playerCharPics/" + data.data.result.charID + ".png");
                    mutateUpload(formDataObj);
                } else {
                    handleClose(false);
                }
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setAlert(data.data.message);
            }
        },
    });

    const { mutate: mutateDelete } = useMutation(sendRequestAuth, {
        onError: () => {
            setAlert("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                handleClose(false);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setAlert(data.data.message);
            }
        },
    });

    function handleDelete() {
        const request = {
            name: "deleteChar",
            param: {
                id: char.id
            }
        };
        mutateDelete(request);
        navigate("/chars");
    }

    function handleRemove() {
        const request = {
            name: "removeCharFromGame",
            param: {
                id: char.id
            }
        };
        mutateDelete(request);
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var request;
        if (edit) {
            request = {
                name: "editChar",
                param: {
                    id: parseInt(char.id),
                    name: formData.name
                }
            };
        } else {
            request = {
                name: "newChar",
                param: {
                    name: formData.name,
                    ac: parseInt(10),
                    hp: parseInt(10)
                }
            };
        }

        //console.log(request.param);
        mutate(request);
    }

    useEffect(() => {
        setAlert("");
        setDeleteQuestion(false);
        if (edit) {
            setFormData(values => ({ ...values, "name": char.name }))
        } else {
            setFormData(values => ({ ...values, "name": "" }))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <Modal size="lg" backdrop="static" show={show} onHide={() => handleClose(false)}>
            <Form onSubmit={handleSubmit} className="mb-3">
                <Modal.Header closeButton>
                    <Modal.Title>{edit ? "Edit " + char.name : "Create new Character"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isLoading || isLoadingUpload ? <div className="text-center"><Spinner animation="border" /></div> : null
                    }
                    {
                        alert !== "" ?
                            <div className="alert alert-danger">
                                {alert}
                            </div>
                            :
                            null
                    }
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name="name" defaultValue={edit ? char.name : null} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Image</Form.Label>
                        <Form.Control type="file" name="pic" id="pic" accept="image/png, image/jpeg" onChange={handleChange} />
                        {edit ? <small>You can leave the image field empty, if you don't want to change the image.</small> : null}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {edit ? char.gameID !== 0 ? removeQuestion ?
                        <div className='text-center container-fluid'>
                            <hr />
                            <div>
                                <p>Do you really want to remove "{char.name}" from their game? <br />(The character will <b>not</b> be deleted!)</p>
                                <Button variant="outline-primary me-2" onClick={() => setRemoveQuestion(false)}>
                                    Abort
                                </Button>
                                <Button variant="primary" onClick={handleRemove}>
                                    Remove
                                </Button>
                            </div>
                            <hr />
                        </div>
                        :
                        <Button variant="outline-primary" onClick={() => setRemoveQuestion(true)}>
                            Remove from Game
                        </Button>
                        : null : null}
                    {edit ? deleteQuestion ?
                        <div className='text-center container-fluid'>
                            <hr />
                            <div>
                                <p>Do you really want delete this char?</p>
                                <Button variant="outline-primary me-2" onClick={() => setDeleteQuestion(false)}>
                                    Abort
                                </Button>
                                <Button variant="primary" onClick={handleDelete}>
                                    Delete
                                </Button>
                            </div>
                            <hr />
                        </div>
                        :
                        <Button variant="outline-primary" onClick={() => setDeleteQuestion(true)}>
                            Delete
                        </Button>
                        : null}
                    <Button variant="secondary" onClick={() => handleClose(false)}>
                        Abort
                    </Button>
                    <Button variant="primary" type="submit">
                        Save Character
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
