import { faArrowLeft, faChessKnight, faClipboardList, faEye, faGear } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useMutation } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import Overview from '../parts/gameControl/Overview'
import Fight from '../parts/gameControl/Fight'
import Showcase from '../parts/gameControl/Showcase'
import useDocumentTitle, { logout, sendRequestAuth } from '../services/apiFunctions'
import { useSwipeable } from 'react-swipeable'
import GameSettings from '../parts/GameSettings'

export default function GameControl() {

    useDocumentTitle("Game Control");

    const [page, setPage] = useState("Overview");
    const [data, setData] = useState("")

    const navigate = useNavigate()

    const gameID = localStorage.getItem("gameID");

    const [showSettings, setShowSettings] = useState(false);
    const handleCloseSettings = () => {
        setShowSettings(false);
        load();
    }

    const { mutate } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setData(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    function load() {
        const request = {
            name: "getGame",
            param: {
                gameID: parseInt(gameID)
            }
        };
        mutate(request);
    }

    useEffect(() => {
        load();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlers = useSwipeable({
        onSwipedLeft: (e) => {
            if (page === "Overview") {
                setPage("PrepFight");
            } else if (page === "PrepFight") {
                setPage("Showcase");
            }
        },
        onSwipedRight: (e) => {
            if (page === "Showcase") {
                setPage("PrepFight");
            } else if (page === "PrepFight") {
                setPage("Overview");
            }
        }
    });

    return (
        <Container className="py-4 text-center" {...handlers} style={{ minHeight: "100vh" }}>
            <Row>
                <Col></Col>
                <Col xl={8} className="position-relative">
                    <div className="position-absolute top-0 start-0 ps-3">
                        <Link to="/games" className='btn btn-outline-primary text-left'><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    </div>
                    <div className="position-absolute top-0 end-0 pe-3">
                        <Button variant="outline-primary" onClick={() => setShowSettings(true)}>
                            <FontAwesomeIcon icon={faGear} />
                        </Button>
                    </div>
                    <h2 className='mt-0 mb-4 mx-5'>{data.name}</h2>
                    {
                        page === "Overview" ?
                            <Overview />
                            : page === "PrepFight" ?
                                <Fight />
                                : page === "Showcase" ?
                                    <Showcase />
                                    : null
                    }
                </Col>
                <Col></Col>
            </Row>
            <div className='mb-5'></div>
            <div className="fixed-bottom text-primary h1 bg-pergament mb-0 pb-2">
                <hr className="mt-0 mb-2"></hr>
                <Row>
                    <Col></Col>
                    <Col xl={8}>
                        <Row>
                            <p className="col cursor-pointer border-end border-primary mb-0" onClick={() => setPage("Overview")}>
                                <FontAwesomeIcon className={page === "Overview" ? "link-primary" : "link-secondary"} icon={faClipboardList} />
                            </p>
                            <p className="col cursor-pointer border-end border-primary mb-0" onClick={() => setPage("PrepFight")}>
                                <FontAwesomeIcon className={page === "PrepFight" ? "link-primary" : "link-secondary"} icon={faChessKnight} />
                            </p>
                            <p className="col cursor-pointer mb-0" onClick={() => setPage("Showcase")}>
                                <FontAwesomeIcon className={page === "Showcase" ? "link-primary" : "link-secondary"} icon={faEye} />
                            </p>
                        </Row>
                    </Col>
                    <Col></Col>
                </Row>
            </div>
            <GameSettings show={showSettings} handleClose={handleCloseSettings} game={data} />
        </Container>

    )
}
