import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { API_URL, logout, sendRequestAuth } from '../../services/apiFunctions';

export default function Showcase() {
    const [showcase, setShowcase] = useState()
    const [dataMonsters, setDataMonsters] = useState("")
    const [search, setSearch] = useState("")

    const gameID = localStorage.getItem("gameID");

    const navigate = useNavigate()

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setDataMonsters(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    const { mutate: mutateShowcase, isLoading: isLoadingShowcase } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setShowcase(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    const { mutate: mutateChange, isLoading: isLoadingChange } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            if (data.data.status === 200) {
                //console.log(data.data.result);
                const request = { name: "getShowcase", param: { gameID: gameID } };
                mutateShowcase(request);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    useEffect(() => {
        var request = { name: "getMonsters", param: {} };
        mutate(request);

        request = { name: "getShowcase", param: { gameID: gameID } };
        mutateShowcase(request);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleAdd(id) {
        var request = { name: "addToShowcase", param: { charID: parseInt(id), gameID: parseInt(gameID) } };
        //console.log(request)
        mutateChange(request);
    }

    function handleRemove(id) {
        var request = { name: "removeFromShowcase", param: { charID: parseInt(id), gameID: parseInt(gameID) } };
        //console.log(request)
        mutateChange(request);
    }

    return (
        <div>
            <h4>Monsters in Showcase</h4>
            {showcase ?
                <div>
                    {
                        isLoadingShowcase || isLoadingChange ? <Spinner animation="border" className="mb-4" /> : null
                    }
                    {showcase ? showcase.map(d => (
                        <div className='alert alert-light text-primary bg-white-tp p-1' key={d.id} >
                            <Row>
                                <Col className="text-start col-2">
                                    {d.pic ?
                                        <img src={API_URL + "/monsterCharPics/" + d.id + ".png"} alt="" height="75px" className='rounded'></img>
                                        : null}
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center">
                                    <h4>{d.name}</h4>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center">
                                    <Button variant="outline-primary me-3" onClick={() => handleRemove(d.id)}>
                                        <FontAwesomeIcon icon={faMinus} />
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    )) : null}
                </div>
                :
                "No Monsters in Showcase"}
            <h4 className='mb-3 mt-3'>All Monsters</h4>
            <div className="d-grid gap-2">
                <Form.Group className='mb-4'>
                    <Form.Control type="text" name="search" id="search" placeholder="Search..." onChange={handleSearch} />
                </Form.Group>
            </div>
            {
                isLoading ? <Spinner animation="border" className="mb-4" /> : null
            }
            {dataMonsters ? dataMonsters.filter(monster => monster.name.toLowerCase().includes(search.toLowerCase()) && !showcase.some(item => monster.id === item.id)).map(d => (
                <div className='alert alert-light text-primary bg-white-tp p-1' key={d.id} >
                    <Row>
                        <Col className="text-start col-auto">
                            {d.pic ?
                                <img src={API_URL + "/monsterCharPics/" + d.id + ".png"} alt="" height="75px" className='rounded'></img>
                                : null}
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center">
                            <h4>{d.name}</h4>
                        </Col>
                        <Col className="col-auto d-flex justify-content-center align-items-center">
                            <Button variant="outline-primary me-3" onClick={() => { handleAdd(d.id) }}>
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </Col>
                    </Row>
                </div>
            )) : null}
        </div>
    )
}
