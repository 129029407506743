import React, { useEffect, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { sendRequestAuth } from '../services/apiFunctions';

export default function MonsterCard({ show, monsterID, charID, charType, handleClose, charName }) {

    const [deleteQuestion, setDeleteQuestion] = useState(false);

    function getStat(value) {
        var mod = Math.floor((value - 10) / 2);
        if (mod >= 0) {
            mod = "+" + mod;
        }
        return (value + " (" + mod + ")");
    }

    function getCR(cr) {
        switch (cr) {
            case "0":
                return "10 XP";
            case "1/8":
                return "1/8 (25 XP)";
            case "1/4":
                return "1/4 (50 XP)";
            case "1/2":
                return "1/2 (100 XP)";
            case "1":
                return "1 (200 XP)";
            case "2":
                return "2 (450 XP)";
            case "3":
                return "3 (700 XP)";
            case "4":
                return "4 (1,100 XP)";
            case "5":
                return "5 (1,800 XP)";
            case "6":
                return "6 (2,300 XP)";
            case "7":
                return "7 (2,900 XP)";
            case "8":
                return "8 (3,900 XP)";
            case "9":
                return "9 (5,000 XP)";
            case "10":
                return "10 (5,900 XP)";
            case "11":
                return "11 (7,200 XP)";
            case "12":
                return "12 (8,400 XP)";
            case "13":
                return "13 (10,000 XP)";
            case "14":
                return "14 (11,500 XP)";
            case "15":
                return "15 (13,000 XP)";
            case "16":
                return "16 (15,000 XP)";
            case "17":
                return "17 (18,000 XP)";
            case "18":
                return "18 (20,000 XP)";
            case "19":
                return "19 (22,000 XP)";
            case "20":
                return "20 (25,000 XP)";
            case "21":
                return "21 (33,000 XP)";
            case "22":
                return "22 (41,000 XP)";
            case "23":
                return "23 (50,000 XP)";
            case "24":
                return "24 (62,000 XP)";
            case "30":
                return "30 (155,000 XP)";
            default:
                return "OVER 9000!"
        }
    }

    function getActionCount(type) {
        var count = 0;
        if (actions) {
            actions.forEach(e => {
                if (e.type === type) {
                    count++;
                }
            })
        }
        return count;
    }

    const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                handleClose(true);
            } else {
                console.log(data.data.message);
            }
        },
    });

    function handleDelete() {
        setDeleteQuestion(false);
        var request = {};
        if (charType === "comp") {
            request = {
                name: "removeCompanion",
                param: {
                    id: charID
                }
            };
        } else if (charType === "fighter") {
            request = {
                name: "removeFighter",
                param: {
                    id: charID
                }
            };
        }

        mutateDelete(request);
    }

    const [monster, setMonster] = useState();

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                setMonster(data.data.result);
            } else {
                console.log(data.data.message);
            }
        },
    });

    const [actions, setActions] = useState([]);

    const { mutate: mutateActions } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                setActions(data.data.result);
            } else {
                console.log(data.data.message);
            }
        },
    });

    useEffect(() => {
        if (show === true) {
            const request = {
                name: "getMonster",
                param: {
                    id: monsterID
                }
            };
            mutate(request);

            const request2 = {
                name: "getMonsterActions",
                param: {
                    id: monsterID
                }
            };
            mutateActions(request2);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);


    return (
        <Modal size="lg" show={show} onHide={handleClose}>
            {monster ? <div>
                <Modal.Header closeButton>
                    <Modal.Title>{charType === "fighter" || charType === "compPlayer" ? charName : monster.name}</Modal.Title>
                </Modal.Header>
                {isLoading ?
                    <Modal.Body className="bg-pergament text-center text-primary">
                        <Spinner animation="border" className="my-5" />
                    </Modal.Body>
                    :
                    <Modal.Body className="bg-pergament line-breaks">
                        <div className="p-2 monster-stats">
                            <div className="name">{monster.name}</div>
                            <div className="description"><i>{monster.size + " " + monster.monsterType + ", " + monster.alignment}</i></div>

                            <div className="gradient"></div>

                            <div className="red">
                                <div>
                                    <span className="fw-bold">Armor Class </span>
                                    <span>
                                        {
                                            monster.armorType === "" ?
                                                monster.AC : monster.AC + " (" + monster.armorType + ")"
                                        }
                                    </span>
                                </div>
                                <div>
                                    <span className="fw-bold">Hit Points </span>
                                    <span>
                                        {Math.floor(((Number(monster.HPdice) + 1) / 2) * Number(monster.HPmultiplier) + Number(monster.HPadd)) + " (" + monster.HPmultiplier + "d" + monster.HPdice + "+" + monster.HPadd + ")"}
                                    </span>
                                </div>
                                <div>
                                    <span className="fw-bold">Speed </span>
                                    <span>
                                        {monster.speed + "ft."}
                                        {monster.flySpeed !== 0 ? ", fly " + monster.flySpeed + " ft." : null}
                                        {monster.hover ? " (hover)" : null}
                                        {monster.burrowSpeed !== 0 ? ", burrow " + monster.burrowSpeed + " ft." : null}
                                        {monster.climbSpeed !== 0 ? ", climb " + monster.climbSpeed + " ft." : null}
                                        {monster.swimSpeed !== 0 ? ", swim " + monster.swimSpeed + " ft." : null}
                                    </span>
                                </div>
                            </div>

                            <div className="gradient"></div>

                            <table className='text-center'>
                                <thead>
                                    <tr>
                                        <th>STR</th>
                                        <th>DEX</th>
                                        <th>CON</th>
                                        <th>INT</th>
                                        <th>WIS</th>
                                        <th>CHA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{getStat(monster.strength)}</td>
                                        <td>{getStat(monster.dexterity)}</td>
                                        <td>{getStat(monster.constitution)}</td>
                                        <td>{getStat(monster.intelligence)}</td>
                                        <td>{getStat(monster.wisdom)}</td>
                                        <td>{getStat(monster.charisma)}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div className="gradient"></div>

                            {monster.savingThrows !== "" ?
                                <div className='red'>
                                    <span className="fw-bold red">Saving Throws </span>
                                    <span> {monster.savingThrows} </span>
                                </div>
                                : null}

                            {monster.skills !== "" ?
                                <div className='red'>
                                    <span className="fw-bold red">Skills </span>
                                    <span> {monster.skills} </span>
                                </div>
                                : null}
                            {monster.dmgVulnerabilities !== "" ?
                                <div className='red'>
                                    <span className="fw-bold red">Damange Vulnerabilities </span>
                                    <span> {monster.dmgVulnerabilities} </span>
                                </div>
                                : null}
                            {monster.dmgResistances !== "" ?
                                <div className='red'>
                                    <span className="fw-bold red">Damange Resistances </span>
                                    <span> {monster.dmgResistances} </span>
                                </div>
                                : null}
                            {monster.dmgImmunities !== "" ?
                                <div className='red'>
                                    <span className="fw-bold red">Damange Immunities </span>
                                    <span> {monster.dmgImmunities} </span>
                                </div>
                                : null}
                            {monster.conditionImmunities !== "" ?
                                <div className='red'>
                                    <span className="fw-bold red">Condition Immunities </span>
                                    <span> {monster.conditionImmunities} </span>
                                </div>
                                : null}
                            {monster.senses !== "" ?
                                <div className='red'>
                                    <span className="fw-bold red">Senses </span>
                                    <span> {monster.senses} </span>
                                </div>
                                : null}
                            <div className='red'>
                                <span className="fw-bold red">Languages </span>
                                <span>{monster.languages !== "" ? monster.languages : "--"}</span>
                            </div>

                            <div className='red'>
                                <span className="fw-bold">Challenge </span>
                                <span> {getCR(monster.CR)}</span>
                            </div>

                            {monster.proficiencyBonus !== 0 ?
                                <div className='red'>
                                    <span className="fw-bold">Proficiency Bonus </span>
                                    <span> {monster.proficiencyBonus} </span>
                                </div>
                                : null
                            }

                            <div className="gradient"></div>

                            {actions !== null && actions !== "" ? actions.map(action => (
                                action.type === "passive" ?
                                    <div className="mt-1" key={action.id}>
                                        <span className="attackname">{action.name}. </span>
                                        <span className="description">{action.description}</span>
                                    </div>
                                    : null
                            )) : null
                            }

                            {getActionCount("action") > 0 ?
                                <div>
                                    <div className="actions red">Actions</div>
                                    <div className="hr"></div>
                                </div>
                                : null}

                            {actions !== null && actions !== "" ? actions.map(action => (
                                action.type === "action" ?
                                    <div className="mt-1" key={action.id}>
                                        <span className="attackname">{action.name}. </span>
                                        <span className="description">{action.description}</span>
                                    </div>
                                    : null
                            )) : null
                            }

                            {getActionCount("reaction") > 0 ?
                                <div>
                                    <div className="actions red">Reactions</div>
                                    <div className="hr"></div>
                                </div>
                                : null}

                            {actions !== null && actions !== "" ? actions.map(action => (
                                action.type === "reaction" ?
                                    <div className="mt-1" key={action.id}>
                                        <span className="attackname">{action.name}. </span>
                                        <span className="description">{action.description}</span>
                                    </div>
                                    : null
                            )) : null
                            }

                            {getActionCount("legendary") > 0 ?
                                <div>
                                    <div className="actions red">Legendary Actions</div>
                                    <div className="hr"></div>
                                    <div className="mt-1">
                                        {monster.legendaryDesc}
                                    </div>
                                </div>
                                : null}

                            {actions !== null && actions !== "" ? actions.map(action => (
                                action.type === "legendary" ?
                                    <div className="mt-1" key={action.id}>
                                        <span className="attackname">{action.name}. </span>
                                        <span className="description">{action.description}</span>
                                    </div>
                                    : null
                            )) : null
                            }

                            {monster.lairActions !== "" ?
                                <div>
                                    <div className="actions red">Lair Actions</div>
                                    <div className="hr"></div>
                                    <div className="mt-1">
                                        {monster.lairActions}
                                    </div>
                                </div>
                                : null}

                            {monster.equipment !== "" ?
                                <div>
                                    <div className="actions red">Equipment</div>
                                    <div className="hr"></div>
                                    <div className="mt-1">
                                        {monster.equipment}
                                    </div>
                                </div>
                                : null}
                        </div>
                    </Modal.Body>
                }
                <Modal.Footer>
                    {isLoadingDelete ?
                        <Modal.Body className="text-center text-primary">
                            <Spinner animation="border" />
                        </Modal.Body>
                        : null}
                    {charType === "comp" || charType === "fighter" ? deleteQuestion ?
                        <div className='text-center container-fluid'>
                            <hr />
                            <div>
                                <p>Do you really want to remove this {charType === "fighter" ? "monster from fight" : "companion"}?</p>
                                <Button variant="outline-primary me-2" onClick={() => setDeleteQuestion(false)}>
                                    Abort
                                </Button>
                                <Button variant="primary" onClick={handleDelete}>
                                    Remove
                                </Button>
                            </div>
                            <hr />
                        </div>
                        :
                        <Button variant="outline-primary" onClick={() => setDeleteQuestion(true)}>
                            Remove
                        </Button>
                        : null}
                    <Button variant="secondary" onClick={() => handleClose(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </div> : null
            }
        </Modal>
    )
}
