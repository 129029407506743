import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Collapse, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { sendRequestAuth } from '../services/apiFunctions';

export default function HealthChange({ show, charID, healthData, handleClose, reloadHealth, func, setShowEdit }) {

    const [hpChange, setHpChange] = useState(0);
    const [maxHp, setMaxHp] = useState(0);
    const [addTempHP, setAddTempHP] = useState(0);
    const [open, setOpen] = useState(false);

    function handleEditConditions() {
        setShowEdit(true)
        handleClose(false)
    }

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                handleClose();
                reloadHealth();
            } else {
                console.log(data.data.message);
            }
        },
    });

    function handleCount(amount) {
        setHpChange(hpChange + amount)
    }

    function handleSubmit() {
        var newTempHP = 0;
        var healthValue = parseInt(healthData.hp);


        newTempHP = parseInt(healthData.tempHP) + parseInt(addTempHP);

        if (hpChange >= 0) {
            healthValue = healthData.hp + hpChange;

            if (healthValue > healthData.maxHP) {
                healthValue = healthData.maxHP;
            }
        } else {
            newTempHP = newTempHP + hpChange;

            if (newTempHP <= 0) {
                healthValue = healthData.hp + newTempHP;
                if (healthValue < 0) {
                    healthValue = 0;
                }
                newTempHP = 0;
            }
        }


        const request = {
            name: func,
            param: {
                charID: charID,
                hp: healthValue,
                maxHP: maxHp,
                tempHP: newTempHP
            }
        };
        //console.log(request)
        mutate(request);
    }

    useEffect(() => {
        setHpChange(0);
        setMaxHp(healthData.maxHP);
        setAddTempHP(0);
        setOpen(false);
        //console.log(healthData)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);


    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Change HP</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" />
                            :
                            null
                    }
                    <Row>
                        <Col xs={2} className="p-1">
                            <div className="d-grid gap-2"><Button variant="outline-danger" className="p-3 fw-bold" onClick={() => handleCount(-5)}>-5</Button></div>
                        </Col>
                        <Col xs={2} className="p-1">
                            <div className="d-grid gap-2"><Button variant="outline-danger" className="p-3 fw-bold" onClick={() => handleCount(-1)}>-1</Button></div>
                        </Col>
                        <Col xs={4} className="p-1">
                            <Alert variant={hpChange === 0 ? "dark" : hpChange > 0 ? "success" : "danger"} className="h5">{hpChange}</Alert>
                        </Col>
                        <Col xs={2} className="p-1">
                            <div className="d-grid gap-2"><Button variant="outline-success" className="p-3 fw-bold" onClick={() => handleCount(1)}>+1</Button></div>
                        </Col>
                        <Col xs={2} className="p-1">
                            <div className="d-grid gap-2"><Button variant="outline-success" className="p-3 fw-bold" onClick={() => handleCount(5)}>+5</Button></div>
                        </Col>
                    </Row>
                    <Row className='text-start p-1'>
                        <Button variant="outline-secondary" size="sm" onClick={() => setOpen(!open)}><FontAwesomeIcon icon={open ? faChevronDown : faChevronUp} /></Button>
                        <Collapse in={open}>
                            <div id="example-collapse-text">
                                <Form.Group className="my-3" controlId="name">
                                    <Form.Label>Max HP</Form.Label>
                                    <Form.Control type="number" name="maxHp" value={maxHp} onChange={(e) => setMaxHp(e.target.value)} required />
                                </Form.Group>
                                <Form.Group className="my-3" controlId="name">
                                    <Form.Label>Add temporary HP</Form.Label>
                                    <Form.Control type="number" name="tempHP" value={addTempHP} onChange={(e) => setAddTempHP(e.target.value)} required />
                                </Form.Group>
                            </div>
                        </Collapse>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => handleEditConditions()}>
                        Edit Conditions
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="outline-primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
