import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { API_URL, getSpellLevelString, logout, sendRequestAuth, } from '../services/apiFunctions';

export default function ShowSpell({ show, handleClose, spell, charID, reload, removeButton = true }) {

    const [deleteQuestion, setDeleteQuestion] = useState(false);
    const [prepared, setPrepared] = useState(false);

    const navigate = useNavigate()

    const { mutate } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                reload();
                handleClose();
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    function handleDelete() {
        const request = {
            name: "removeSpell",
            param: {
                spellID: spell.id,
                charID: charID,
            }
        };
        //console.log(request);
        mutate(request);
    }

    function handleEdit() {
        const request = {
            name: "editCharSpell",
            param: {
                charID: parseInt(charID),
                spellID: parseInt(spell.id),
                prepared: prepared,
            }
        };
        console.log(request);
        mutate(request);
    }

    useEffect(() => {
        setPrepared(false)
        setDeleteQuestion(false)
        if (removeButton) {
            setPrepared(spell.prepared === 1 ? true : false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, spell]);

    return (
        <Modal size="lg" show={show} onHide={() => handleClose(false)}>
            {spell ?
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title>{spell.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="line-breaks p-3">
                        {spell.pic ?
                            <div className='text-center my-3'>
                                <img src={API_URL + "/spellPics/" + spell.id + ".jpg"} style={{ maxHeight: "250px", maxWidth: "100%" }} alt="" className='rounded'></img>
                            </div>
                            : null}
                        <p className='fst-italic'>
                            {
                                spell.level === 0 ?
                                    "Cantrip"
                                    :
                                    getSpellLevelString(spell.level) + " " + spell.school.toLowerCase()
                            }
                        </p>
                        <b>School: </b>{spell.school}<br />
                        <b>Casting Time: </b>{spell.castingTime}<br />
                        <b>Range: </b>{spell.range}<br />
                        <b>Components: </b>{spell.components}<br />
                        <b>Duration: </b>{spell.duration}<br />

                        <div className="mt-2" dangerouslySetInnerHTML={{ __html: spell.description }}>
                        </div>

                        {spell.descAtHigher !== "" ?
                            <p className="mt-2">
                                <b>At Higher Levels:</b> {spell.descAtHigher}
                            </p>
                            : null}
                        <br />
                        <b>Classes: </b>{spell.classes.replaceAll(",", ", ")}<br />
                        <br />
                        <b>Source: </b>{spell.source === "HB" ? "Custom" : spell.source}<br />
                        {removeButton ?
                            <>
                                <hr />
                                <Form.Check // prettier-ignore
                                    type="checkbox"
                                    id={"prepared"}
                                    name={"prepared"}
                                    label={"Spell prepared"}
                                    checked={prepared}
                                    onChange={e => setPrepared(!prepared)}
                                />
                            </>
                            : null}
                    </Modal.Body>
                    <Modal.Footer>
                        {removeButton ? deleteQuestion ?
                            <div className='text-center container-fluid'>
                                <hr />
                                <div>
                                    <p>Do you really want to remove this spell from this char?</p>
                                    <Button variant="outline-primary me-2" onClick={() => setDeleteQuestion(false)}>
                                        Abort
                                    </Button>
                                    <Button variant="primary" onClick={handleDelete}>
                                        Remove
                                    </Button>
                                </div>
                                <hr />
                            </div>
                            :
                            <Button variant="outline-primary" onClick={() => setDeleteQuestion(true)}>
                                Remove
                            </Button>
                            : null}
                        <Button variant="secondary" onClick={() => handleClose(false)}>
                            Close
                        </Button>
                        {removeButton ?
                            <Button variant="primary" onClick={() => handleEdit(true)}>
                                Save
                            </Button>
                            : null}
                    </Modal.Footer>
                </div>
                : null}
        </Modal>
    )
}
