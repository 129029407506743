import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { Col, Container, Form, Row, Table } from 'react-bootstrap'
import useDocumentTitle, { API_URL, getDEXforArmor, getModifier, logout, removeDuplicates, sendRequestAuth, uploadCharPic } from '../services/apiFunctions'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import BackgroundSelect from '../parts/charBuilder/BackgroundSelect'
import ClassSelect from '../parts/charBuilder/ClassSelect'
import AncestrySelect from '../parts/charBuilder/AncestrySelect'
import AttributesSelect from '../parts/charBuilder/AttributesSelect'

export default function CharBuilder() {

    useDocumentTitle("Character Builder");

    const [page, setPage] = useState(0);

    const [formData, setFormData] = useState({})
    const [alert, setAlert] = useState("");

    const [alignment, setAlignment] = useState("Lawful Good");

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    const [selectedBackground, setSelectedBackground] = useState({ id: 0 })
    const [backgroundLanguages, setBackgroundLanguages] = useState([])
    const [backgroundSkillProficiencies, setBackgroundSkillProficiencies] = useState([])
    const [backgroundToolProficiencies, setBackgroundToolProficiencies] = useState([])
    const [backgroundEquip, setBackgroundEquip] = useState([])
    const [personalityTrait, setPersonalityTrait] = useState("")
    const [ideal, setIdeal] = useState("")
    const [bond, setBond] = useState("")
    const [flaw, setFlaw] = useState("")

    function saveBackground(langs, skills, tools, equip, pTrait, ideal, bond, flaw) {
        setBackgroundLanguages(langs);
        setBackgroundSkillProficiencies(skills);
        setBackgroundToolProficiencies(tools);
        setBackgroundEquip(equip);
        setPersonalityTrait(pTrait);
        setIdeal(ideal);
        setBond(bond);
        setFlaw(flaw);

        setSelectedClass({ id: 0 });
        setSelectedAncestry({ id: 0 });
    }

    const [selectedClass, setSelectedClass] = useState({ id: 0 })
    const [classSkillProficiencies, setClassSkillProficiencies] = useState([])
    const [classToolProficiencies, setClassToolProficiencies] = useState([])
    const [classEquip, setEquip] = useState([])
    const [goldAlternative, setGoldAlternative] = useState(0)
    const [subclass, setSubclass] = useState(null)
    const [armor, setArmor] = useState(0)
    const [shield, setShield] = useState(0)

    function saveClass(skills, tools, equip, goldAlternative, subclass) {
        setClassSkillProficiencies(skills);
        setClassToolProficiencies(tools);
        setEquip(equip);
        setGoldAlternative(goldAlternative);
        setSubclass(subclass)

        var tempArmor = equip.find(e => e.armorType === "light" || e.armorType === "medium" || e.armorType === "heavy");
        var tempShield = equip.find(e => e.armorType === "shield");

        setArmor(tempArmor ? tempArmor : { id: 0, AC: 0, armorType: "" })
        setShield(tempShield ? tempShield : { id: 0, AC: 0, armorType: "" })

        setSelectedAncestry({ id: 0 });
    }

    const [selectedAncestry, setSelectedAncestry] = useState({ id: 0 })
    const [ancestryLanguages, setAncestryLanguages] = useState([])
    const [ancestryAbilityValList, setAbilityValList] = useState([])
    const [ancestrySkillProficiencies, setAncestrySkillProficiencies] = useState([])
    const [ancestryToolProficiencies, setAncestryToolProficiencies] = useState([])
    const [ancestryFeat, setAncestryFeat] = useState([])

    function saveAncestry(langs, abilityValList, skillProficiencies, toolProficiencies, chosenFeat) {

        //console.log(abilityValList)
        setAncestryLanguages(langs)
        setAbilityValList(abilityValList)
        setAncestrySkillProficiencies(skillProficiencies)
        setAncestryToolProficiencies(toolProficiencies)
        setAncestryFeat(chosenFeat)
    }

    const [attributes, setAttributes] = useState(null)

    function saveAttributes(abilities) {
        setAttributes(abilities)
    }

    const navigate = useNavigate()

    const { mutate: mutateUpload } = useMutation(uploadCharPic, {
        onError: () => {
            setAlert("An error occured while uploading data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.id);
            if (data.data.status === 200) {
                navigate("/chars");
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setAlert(data.data.message);
            }
        },
    });

    const { mutate } = useMutation(sendRequestAuth, {
        onError: () => {
            setAlert("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result.charID);
            if (data.data.status === 200) {
                var formDataObj = new FormData();
                var imagefile = document.querySelector('#pic');
                formDataObj.append("pic", imagefile.files[0]);
                formDataObj.append("charID", data.data.result.charID);
                if (imagefile.files[0]) {
                    caches.delete(API_URL + "/playerCharPics/" + data.data.result.charID + ".png");
                    mutateUpload(formDataObj);
                } else {
                    navigate("/chars");
                }
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setAlert(data.data.message);
            }
        },
    });

    function finish() {


        var request = {
            name: "newCharFull",
            param: {
                name: formData.name,
                classID: selectedClass.id,
                className: selectedClass.name,
                subclassID: subclass ? subclass.id : 0,
                backgroundID: selectedBackground.id,
                ancestryID: selectedAncestry.id,
                hp: selectedClass.hitDice + getModifier(attributes[2]),
                hitDice: selectedClass.hitDice,
                AC: (armor.AC + getDEXforArmor(armor.armorType, attributes[1]) === 0 ? 10 : armor.AC + getDEXforArmor(armor.armorType, attributes[1])) + parseInt(shield.AC),
                armor: armor.id,
                shield: shield.id,
                alignment: alignment,
                speed: selectedAncestry.speed,
                flySpeed: selectedAncestry.flySpeed,
                climbSpeed: selectedAncestry.climbSpeed,
                swimSpeed: selectedAncestry.swimSpeed,
                strength: attributes[0],
                dexterity: attributes[1],
                constitution: attributes[2],
                intelligence: attributes[3],
                wisdom: attributes[4],
                charisma: attributes[5],
                strengthProf: selectedClass.savingThrows.split(";").some(e => e === "STR"),
                dexterityProf: selectedClass.savingThrows.split(";").some(e => e === "DEX"),
                constitutionProf: selectedClass.savingThrows.split(";").some(e => e === "CON"),
                intelligenceProf: selectedClass.savingThrows.split(";").some(e => e === "INT"),
                wisdomProf: selectedClass.savingThrows.split(";").some(e => e === "WIS"),
                charismaProf: selectedClass.savingThrows.split(";").some(e => e === "CHA"),
                athleticsProf: ancestrySkillProficiencies.find(e => e.name === "athletics").proficiency,
                acrobaticsProf: ancestrySkillProficiencies.find(e => e.name === "acrobatics").proficiency,
                sleightOfHandProf: ancestrySkillProficiencies.find(e => e.name === "sleightOfHand").proficiency,
                stealthProf: ancestrySkillProficiencies.find(e => e.name === "stealth").proficiency,
                arcanaProf: ancestrySkillProficiencies.find(e => e.name === "arcana").proficiency,
                historyProf: ancestrySkillProficiencies.find(e => e.name === "history").proficiency,
                investigationProf: ancestrySkillProficiencies.find(e => e.name === "investigation").proficiency,
                natureProf: ancestrySkillProficiencies.find(e => e.name === "nature").proficiency,
                religionProf: ancestrySkillProficiencies.find(e => e.name === "religion").proficiency,
                animalHandlingProf: ancestrySkillProficiencies.find(e => e.name === "animalHandling").proficiency,
                insightProf: ancestrySkillProficiencies.find(e => e.name === "insight").proficiency,
                medicineProf: ancestrySkillProficiencies.find(e => e.name === "medicine").proficiency,
                perceptionProf: ancestrySkillProficiencies.find(e => e.name === "perception").proficiency,
                survivalProf: ancestrySkillProficiencies.find(e => e.name === "survival").proficiency,
                deceptionProf: ancestrySkillProficiencies.find(e => e.name === "deception").proficiency,
                intimidationProf: ancestrySkillProficiencies.find(e => e.name === "intimidation").proficiency,
                performanceProf: ancestrySkillProficiencies.find(e => e.name === "performance").proficiency,
                persuasionProf: ancestrySkillProficiencies.find(e => e.name === "persuasion").proficiency,
                coins: (selectedBackground.gold + goldAlternative) * 100,
                spellAbility: selectedClass.spellAbility,
                personalityTrait: personalityTrait ? personalityTrait.description : "",
                ideal: ideal ? ideal.description : "",
                bond: bond ? bond.description : "",
                flaw: flaw ? flaw.description : "",
                armorProficiencies: selectedClass.armorProf.split(";").join(", "),
                weaponProficiencies: removeDuplicates(selectedClass.weaponProf.split(";").concat(selectedAncestry.weaponProf.split(";"))).join(", "),
                toolProficiencies: ancestryToolProficiencies.map((e, i) => e.name + " ").join(", "),
                languageProficiencies: ancestryLanguages.join(", "),
                equipment: classEquip.concat(backgroundEquip),
                feat: ancestryFeat ? ancestryFeat : null,
            }
        };

        //console.log(request.param);
        mutate(request);
    }



    function handlePage(val) {
        if (page + val < 0) {
            setPage(0)
        } else if (page + val > 4) {
            setPage(4)
        } else {
            if ((page + val === 0) || (page + val === 1 && selectedBackground.id !== 0) || (page + val === 2 && selectedClass.id !== 0) || (page + val === 3 && selectedAncestry.id !== 0) || (page + val === 4 && attributes !== null)) {
                setPage(page + val)
            }
        }
    }

    return (
        <Container className="py-4 text-center">
            <Row>
                <Col></Col>
                <Col xl={8} className="position-relative">
                    <div className="position-absolute top-0 start-0 ps-3">
                        <Link to="/chars" className='btn btn-outline-primary text-left'><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    </div>
                    <h2 >Character Builder</h2>
                    <p className='mb-4'>Choose {
                        page === 0 ?
                            "Background"
                            : page === 1 ?
                                "Class"
                                : page === 2 ?
                                    "Ancestry"
                                    : page === 3 ?
                                        "Attributes"
                                        : page === 4 ?
                                            "Name and Alignment"
                                            : null
                    }</p>
                </Col>
                <Col></Col>
            </Row>
            {
                page === 0 ?
                    <BackgroundSelect selectedBackground={selectedBackground} setSelectedBackground={setSelectedBackground} saveBackground={saveBackground} />
                    : page === 1 ?
                        <ClassSelect selectedClass={selectedClass} setSelectedClass={setSelectedClass} saveClass={saveClass} backgroundSkills={backgroundSkillProficiencies} backgroundToolProficiencies={backgroundToolProficiencies} />
                        : page === 2 ?
                            <AncestrySelect selectedAncestry={selectedAncestry} setSelectedAncestry={setSelectedAncestry} saveAncestry={saveAncestry} backgroundLanguages={backgroundLanguages} classSkills={classSkillProficiencies} classToolProficiencies={classToolProficiencies} />
                            : page === 3 ?
                                <AttributesSelect saveAttributes={saveAttributes} ancestryAbilities={ancestryAbilityValList} />
                                : page === 4 ?
                                    <div className='text-start'>
                                        {
                                            alert !== "" ?
                                                <div className="alert alert-danger">
                                                    {alert}
                                                </div>
                                                :
                                                null
                                        }
                                        <Form.Group className="mb-3" controlId="name">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" name="name" onChange={handleChange} value={formData.name} required />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Image</Form.Label>
                                            <Form.Control type="file" name="pic" id="pic" accept="image/png, image/jpeg" onChange={handleChange} />
                                            <small>Image is not required.</small>
                                        </Form.Group>
                                        <p>Alignment</p>
                                        <Table className='text-center'>
                                            <tbody>
                                                <tr>
                                                    <td className={`border border-1 border-primary cursor-pointer ${alignment === "Lawful Good" ? 'bg-darker text-light' : null}`}
                                                        onClick={() => setAlignment("Lawful Good")}>
                                                        Lawful <br />Good
                                                    </td>
                                                    <td className={`border border-1 border-primary cursor-pointer ${alignment === "Neutral Good" ? 'bg-darker text-light' : null}`}
                                                        onClick={() => setAlignment("Neutral Good")}>
                                                        Neutral <br />Good
                                                    </td>
                                                    <td className={`border border-1 border-primary cursor-pointer ${alignment === "Chaotic Good" ? 'bg-darker text-light' : null}`}
                                                        onClick={() => setAlignment("Chaotic Good")}>
                                                        Chaotic <br />Good
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={`border border-1 border-primary cursor-pointer ${alignment === "Lawful Neutral" ? 'bg-darker text-light' : null}`}
                                                        onClick={() => setAlignment("Lawful Neutral")}>
                                                        Lawful <br />Neutral
                                                    </td>
                                                    <td className={`border border-1 border-primary cursor-pointer ${alignment === "True Neutral" ? 'bg-darker text-light' : null}`}
                                                        onClick={() => setAlignment("True Neutral")}>
                                                        True <br />Neutral
                                                    </td>
                                                    <td className={`border border-1 border-primary cursor-pointer ${alignment === "Chaotic Neutral" ? 'bg-darker text-light' : null}`}
                                                        onClick={() => setAlignment("Chaotic Neutral")}>
                                                        Chaotic <br />Neutral
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={`border border-1 border-primary cursor-pointer ${alignment === "Lawful Evil" ? 'bg-darker text-light' : null}`}
                                                        onClick={() => setAlignment("Lawful Evil")}>
                                                        Lawful <br />Evil
                                                    </td>
                                                    <td className={`border border-1 border-primary cursor-pointer ${alignment === "Neutral Evil" ? 'bg-darker text-light' : null}`}
                                                        onClick={() => setAlignment("Neutral Evil")}>
                                                        Neutral <br />Evil
                                                    </td>
                                                    <td className={`border border-1 border-primary cursor-pointer ${alignment === "Chaotic Evil" ? 'bg-darker text-light' : null}`}
                                                        onClick={() => setAlignment("Chaotic Evil")}>
                                                        Chaotic <br />Evil
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                    : null
            }
            <div className='mb-5'></div>
            <div className="fixed-bottom text-primary h1 bg-pergament mb-0 pb-2">
                <hr className="mt-0 mb-2"></hr>
                <Row>
                    <Col></Col>
                    <Col xl={8}>
                        <Row>
                            <p className={`col border-end border-primary mb-0 ${page > 0 ? "cursor-pointer" : null}`} onClick={() => handlePage(-1)}>
                                {page > 0 ?
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                    : null
                                }
                            </p>
                            <p className={`col mb-0 ${(page === 0 && selectedBackground.id === 0) || (page === 1 && selectedClass.id === 0) || (page === 2 && selectedAncestry.id === 0) || (page === 3 && attributes === null) ? "text-red-tp" : "cursor-pointer"}`} onClick={() => { page < 4 ? handlePage(1) : finish() }}>
                                {page < 4 ?
                                    <FontAwesomeIcon icon={faArrowRight} />
                                    :
                                    "Finish"
                                }
                            </p>
                        </Row>
                    </Col>
                    <Col></Col>
                </Row>
            </div>
        </Container>
    )
}
