import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { API_URL } from '../services/apiFunctions'

export default function ShowItem({ show, handleClose, item }) {

    return (
        <Modal size="lg" show={show} onHide={() => handleClose(false)}>
            {item ?
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title>{item.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="line-breaks p-3">
                        {item.pic ?
                            <div className='text-center my-3'>
                                <img src={API_URL + "/itemPics/" + item.id + ".jpg"} style={{ maxHeight: "250px", maxWidth: "100%" }} alt="" className='rounded'></img>
                            </div>
                            : null}
                        <p>
                            <i>
                                {
                                    (item.type ? item.type : "") +
                                    (item.type && item.rarity !== "none" && item.rarity !== "unknown" && item.rarity !== "unknown (magic)" ? ", " : "") +
                                    (item.rarity !== "none" && item.rarity !== "unknown" && item.rarity !== "unknown (magic)" ? item.rarity : "") +
                                    (item.attunement ? " (" + item.attunement + ")" : "")
                                }
                            </i>
                        </p>
                        {
                            item.weightText || item.valueText ?
                                <p>
                                    {
                                        (item.weightText ? item.weightText : "") +
                                        (item.weightText && item.valueText ? ", " : "") +
                                        (item.valueText ? item.valueText : "")
                                    }<br />
                                </p>
                                : null
                        }
                        {
                            item.properties ?
                                <p>
                                    <b>{item.properties}</b>
                                </p>
                                : null
                        }

                        <div className="my-2" dangerouslySetInnerHTML={{ __html: item.description }}>
                        </div>

                        <b>Source:</b> {item.source === "HB" ? "Custom" : item.source}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </div>
                : null}
        </Modal>
    )
}
