import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Col, Form, ListGroup, Modal, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { API_URL, removeDuplicates, sendRequestAuth } from '../../services/apiFunctions';

export default function AncestryDetailSelect({ show, handleClose, ancestry, saveAncestry, backgroundLanguages, classSkills, classToolProficiencies }) {

    const [ancestryData, setAncestryData] = useState();

    const [page, setPage] = useState("");

    const [skillProficiencies, setSkillProficiencies] = useState()
    const [skillsCount, setSkillsCount] = useState(0)

    const [languages, setLanguages] = useState([])
    const [langsCount, setLangsCount] = useState(0)
    const [toolProficiencies, setToolProficiencies] = useState([])
    const [toolsCount, setToolsCount] = useState(0)

    const [selectedAbilities, setSelectedAbilities] = useState([])
    const [selectedAbilitiesFeat, setSelectedAbilitiesFeat] = useState([])
    const [abilityCount, setAbilityCount] = useState(0)
    const [linageOption, setLinageOption] = useState(0)
    const [abilityChooseNumber, setAbilityChooseNumber] = useState(0)
    const [selectedSecondAbility, setSelectedSecondAbility] = useState("")


    const languageList = ["Abyssal", "Celestial", "Common", "Deep Speech", "Draconic", "Dwarvish", "Elvish", "Giant", "Gnomish", "Goblin", "Halfling", "Infernal", "Orc", "Primordial", "Sylvan", "Undercommon"]

    const [abilityList, setAbilityList] = useState(null);

    function handleSave(data, al) {
        var langs = removeDuplicates(languages.concat(backgroundLanguages).concat(ancestry.langsFixed.split(",")))
        var tools = toolProficiencies.concat(data.toolProfFixed)

        const tempAbilityList = ancestry.skillsProfChooseNumber === 0 && ancestry.toolProfChooseNumber === 0 && ancestry.featChooseNumber === 0 && ancestry.langsChooseNumber === 0 && ancestry.abilityChooseNumber === 0 && ancestry.lineage === 0 ? al : abilityList

        var abilityValList = [0, 0, 0, 0, 0, 0]

        tempAbilityList.map((ability, index) => {
            if (linageOption === 0) {
                if (ability.value >= 0) {
                    abilityValList[index] = ability.value
                } else if (selectedAbilities.some(d => d === ability.name)) {
                    abilityValList[index] = ability.value * -1
                }
            } else if (linageOption === 1) {
                if (selectedAbilities[0] === ability.name) {
                    abilityValList[index] = 2
                } else if (selectedSecondAbility === ability.name) {
                    abilityValList[index] = 1
                }
            } else if (linageOption === 2) {
                if (selectedAbilities.some(d => d === ability.name)) {
                    abilityValList[index] = 1
                }
            }

            if (selectedAbilitiesFeat.some(d => d === ability.name)) {
                abilityValList[index] = abilityValList[index] + 1
            }

            return true;
        })

        if (chosenFeat.fixedAbilities !== "") {
            if (chosenFeat.fixedAbilities === "STR") {
                abilityValList[0] = abilityValList[0] + 1;
            } else if (chosenFeat.fixedAbilities === "DEX") {
                abilityValList[1] = abilityValList[1] + 1;
            } else if (chosenFeat.fixedAbilities === "CON") {
                abilityValList[2] = abilityValList[2] + 1;
            } else if (chosenFeat.fixedAbilities === "INT") {
                abilityValList[3] = abilityValList[3] + 1;
            } else if (chosenFeat.fixedAbilities === "WIS") {
                abilityValList[4] = abilityValList[4] + 1;
            } else if (chosenFeat.fixedAbilities === "CHA") {
                abilityValList[5] = abilityValList[5] + 1;
            }
        }

        console.log("TEST")
        console.log(abilityValList)
        //console.log(toolProficiencies.concat(ancestryData.toolProfFixed))

        saveAncestry(langs, abilityValList, skillProficiencies, tools, chosenFeat)
        handleClose(false)
    }

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                var al = ([
                    {
                        name: "STR",
                        value: ancestry.strength
                    },
                    {
                        name: "DEX",
                        value: ancestry.dexterity
                    },
                    {
                        name: "CON",
                        value: ancestry.constitution
                    },
                    {
                        name: "INT",
                        value: ancestry.intelligence
                    },
                    {
                        name: "WIS",
                        value: ancestry.wisdom
                    },
                    {
                        name: "CHA",
                        value: ancestry.charisma
                    }
                ])
                setAbilityList(al)
                update(data.data.result, al);
            } else {
                console.log(data.data.message);
            }
        },
    });

    const [dataFeats, setDataFeats] = useState("")
    const [chosenFeat, setChosenFeat] = useState()
    const [featSearch, setFeatSearch] = useState("")

    const handleFeatSearch = (e) => {
        setFeatSearch(e.target.value);
    }

    const { mutate: mutateFeats } = useMutation(sendRequestAuth, {
        onError: () => {
            setDataFeats("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setDataFeats(data.data.result);
            } else {
                setDataFeats(data.data.message);
            }
        },
    });

    useEffect(() => {
        setPage("");
        setChosenFeat()
        setFeatSearch("")
        setSkillProficiencies([
            {
                name: "athletics",
                dispName: "Athletics",
                proficiency: classSkills[0].proficiency === 1 ? 1 : 0
            },
            {
                name: "acrobatics",
                dispName: "Acrobatics",
                proficiency: classSkills[1].proficiency === 1 ? 1 : 0
            },
            {
                name: "sleightOfHand",
                dispName: "Sleight of Hand",
                proficiency: classSkills[2].proficiency === 1 ? 1 : 0
            },
            {
                name: "stealth",
                dispName: "Stealth",
                proficiency: classSkills[3].proficiency === 1 ? 1 : 0
            },
            {
                name: "arcana",
                dispName: "Arcana",
                proficiency: classSkills[4].proficiency === 1 ? 1 : 0
            },
            {
                name: "history",
                dispName: "History",
                proficiency: classSkills[5].proficiency === 1 ? 1 : 0
            },
            {
                name: "investigation",
                dispName: "Investigation",
                proficiency: classSkills[6].proficiency === 1 ? 1 : 0
            },
            {
                name: "nature",
                dispName: "Nature",
                proficiency: classSkills[7].proficiency === 1 ? 1 : 0
            },
            {
                name: "religion",
                dispName: "Religion",
                proficiency: classSkills[8].proficiency === 1 ? 1 : 0
            },
            {
                name: "animalHandling",
                dispName: "Animal Handling",
                proficiency: classSkills[9].proficiency === 1 ? 1 : 0
            },
            {
                name: "insight",
                dispName: "Insight",
                proficiency: classSkills[10].proficiency === 1 ? 1 : 0
            },
            {
                name: "medicine",
                dispName: "Medicine",
                proficiency: classSkills[11].proficiency === 1 ? 1 : 0
            },
            {
                name: "perception",
                dispName: "Perception",
                proficiency: classSkills[12].proficiency === 1 ? 1 : 0
            },
            {
                name: "survival",
                dispName: "Survival",
                proficiency: classSkills[13].proficiency === 1 ? 1 : 0
            },
            {
                name: "deception",
                dispName: "Deception",
                proficiency: classSkills[14].proficiency === 1 ? 1 : 0
            },
            {
                name: "intimidation",
                dispName: "Intimidation",
                proficiency: classSkills[15].proficiency === 1 ? 1 : 0
            },
            {
                name: "performance",
                dispName: "Performance",
                proficiency: classSkills[16].proficiency === 1 ? 1 : 0
            },
            {
                name: "persuasion",
                dispName: "Persuasion",
                proficiency: classSkills[17].proficiency === 1 ? 1 : 0
            }
        ]);
        setSkillsCount(0)
        setLangsCount(0);
        setLanguages([]);
        setSelectedAbilities([]);
        setSelectedAbilitiesFeat([]);
        setAbilityCount(0);
        setLinageOption(0);
        setAbilityChooseNumber(ancestry ? ancestry.abilityChooseNumber : 0);
        setSelectedSecondAbility("");
        setToolProficiencies(classToolProficiencies);
        setToolsCount(0);

        //console.log(classSkills);

        if (show === true) {
            const request = {
                name: "getAncestryData",
                param: {
                    id: ancestry.id
                }
            };
            mutate(request);

            const request2 = { name: "getUserFeats", param: {} };
            mutateFeats(request2);
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    function update(data, al) {
        setAncestryData(data);
        if (page === "" && ancestry.skillsProfChooseNumber > 0) {
            setPage("skills");
        } else if ((page === "" || page === "skills") && ancestry.toolProfChooseNumber > 0) {
            setPage("tools");
        } else if ((page === "" || page === "skills" || page === "tools") && ancestry.featChooseNumber > 0) {
            setPage("feats");
        } else if ((page === "" || page === "skills" || page === "tools" || page === "feats") && (chosenFeat && chosenFeat.chooseAbilityNumber > 0)) {
            setPage("featScore");
        } else if ((page === "" || page === "skills" || page === "tools" || page === "feats" || page === "featScore") && ancestry.langsChooseNumber > 0) {
            setPage("lang");
        } else if ((page === "" || page === "skills" || page === "tools" || page === "feats" || page === "featScore" || page === "lang") && (ancestry.abilityChooseNumber > 0 || ancestry.lineage === 1)) {
            setAbilityCount(0);
            setPage("abilities");
        } else {
            handleSave(data, al);
        }
        //console.log(skillProficiencies)
    }

    function handleLangChange(langName) {
        if (languages.some(item => item === langName)) {
            setLanguages(languages.filter(item => item !== langName))
            setLangsCount(langsCount - 1)
        } else {
            setLanguages(languages.concat(langName))
            setLangsCount(langsCount + 1)
        }

    }

    function handleSkillChange(skillName) {
        const list = [...skillProficiencies];
        list.forEach((skill, index) => {
            if (skill.name === skillName) {
                if (skill.proficiency === 0) {
                    setSkillsCount(skillsCount + 1)
                } else {
                    setSkillsCount(skillsCount - 1)
                }

                list[index]["proficiency"] = skill.proficiency === 0 ? 1 : 0;
            }
        })
        setSkillProficiencies(list);
    }

    function handleToolChange(tool) {
        if (toolProficiencies.some(item => item.name === tool.name)) {
            setToolProficiencies(toolProficiencies.filter(item => item.name !== tool.name))
            setToolsCount(toolsCount - 1)
        } else {
            setToolProficiencies(toolProficiencies.concat(tool))
            setToolsCount(toolsCount + 1)
        }
    }

    function handleAbilityClick(ability) {
        setSelectedAbilities(selectedAbilities.concat(ability));
        setAbilityCount(abilityCount + 1);
    }

    function handleAbilityClickFeat(ability) {
        setSelectedAbilitiesFeat(selectedAbilities.concat(ability));
        setAbilityCount(abilityCount + 1);
    }

    function reset(pageAbilities) {
        setSelectedAbilities([]);
        setAbilityCount(0);
        setLinageOption(0);
        setAbilityChooseNumber(ancestry.abilityChooseNumber);
        setSelectedSecondAbility("");

        if (!pageAbilities) {
            setSelectedAbilitiesFeat([]);
        }
    }

    function handleLinageOption(value) {
        setLinageOption(value)
        if (value === 1) {
            setAbilityChooseNumber(1);
        } else if (value === 2) {
            setAbilityChooseNumber(3);
        }
    }

    return (
        <Modal size="lg" show={show} backdrop="static" onHide={() => handleClose(false)} centered>
            {ancestry ?
                <div>
                    <Modal.Header>
                        <Modal.Title>{page === "skills" ?
                            "Choose " + ancestry.skillsProfChooseNumber + " skill proficienc" + (ancestry.skillsProfChooseNumber === 1 ? "y" : "ies")
                            : page === "tools" ?
                                "Choose " + ancestry.toolProfChooseNumber + " tool proficienc" + (ancestry.toolProfChooseNumber === 1 ? "y" : "ies")
                                : page === "feats" ?
                                    "Choose " + ancestry.featChooseNumber + " feat" + (ancestry.featChooseNumber === 1 ? "" : "s")
                                    : page === "featScore" ?
                                        "Choose Ability Score Improvement from Feat"
                                        : page === "lang" ?
                                            "Choose " + ancestry.langsChooseNumber + " language" + (ancestry.langsChooseNumber === 1 ? "" : "s")
                                            : page === "abilities" ?
                                                "Choose " + (ancestry.lineage === 1 ? "an" : ancestry.abilityChooseNumber) + " ability improvement" + (ancestry.abilityChooseNumber === 1 || ancestry.lineage === 1 ? "" : "s")
                                                : "Loading"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="line-breaks p-3">
                        {isLoading ?
                            <Spinner animation="border" className="mb-4" />
                            :
                            <div>
                                {page === "skills" ?
                                    skillProficiencies ? skillProficiencies.map((d, i) => (
                                        <div key={i} className="mb-3">
                                            <Form.Check // prettier-ignore
                                                type="checkbox"
                                                id={d.name}
                                                label={d.dispName}
                                                checked={d.proficiency === 1}
                                                onChange={e => handleSkillChange(d.name)}
                                                disabled={(!ancestryData.skillsProfChoose.some(item => item.name === d.dispName) && ancestry.skillsProfChooseNumber > 0 && ancestry.skillsProfChoose !== "") || (skillsCount >= ancestry.skillsProfChooseNumber && d.proficiency === 0) || classSkills.some(item => item.dispName === d.dispName && item.proficiency === 1)}
                                            />
                                        </div>
                                    )) : null
                                    : page === "tools" ?
                                        <>
                                            {ancestryData.toolProfChoose.map((d, i) => (
                                                <div key={i} className="mb-3">
                                                    <Form.Check // prettier-ignore
                                                        type="checkbox"
                                                        id={d.name + "tool"}
                                                        name={d.name + "tool"}
                                                        label={d.name}
                                                        checked={toolProficiencies.some(item => item.name === d.name)}
                                                        onChange={e => handleToolChange(d)}
                                                        disabled={toolsCount >= ancestry.toolProfChooseNumber && !toolProficiencies.some(item => item.name === d.name)}
                                                    />
                                                </div>
                                            ))}
                                            {classToolProficiencies.concat(ancestryData.toolProfFixed).map((d, i) => (
                                                <div key={i} className="mb-3">
                                                    <Form.Check // prettier-ignore
                                                        type="checkbox"
                                                        id={d.name + "tool"}
                                                        name={d.name + "tool"}
                                                        label={d.name}
                                                        checked={true}
                                                        disabled={true}
                                                    />
                                                </div>
                                            ))}
                                        </>
                                        : page === "feats" ?
                                            <>
                                                {dataFeats ? dataFeats.length > 0 ?
                                                    <div className="d-grid gap-2">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Control type="text" name="search" id="search" placeholder="Search..." onChange={handleFeatSearch} />
                                                        </Form.Group>
                                                    </div>
                                                    : null : null}
                                                <div className="chooseList border rounded">
                                                    <ListGroup defaultActiveKey={"#" + (chosenFeat ? chosenFeat.id : 0)}>
                                                        {dataFeats ? dataFeats.length > 0 ? dataFeats.filter(feat => feat.name.toLowerCase().includes(featSearch.toLowerCase())).map(d => (
                                                            <ListGroup.Item key={d.id} href={"#" + d.id} action onClick={() => setChosenFeat(d)}>
                                                                <Row>
                                                                    <Col className="text-start fs-bold pe-0">
                                                                        {d.name}
                                                                    </Col>
                                                                    <Col className="text-end pe-2">
                                                                        {d.source}
                                                                    </Col>
                                                                </Row>
                                                            </ListGroup.Item>
                                                        )) : <i>Create a feat first</i> : null}
                                                    </ListGroup>
                                                </div>
                                                {chosenFeat ?
                                                    <>
                                                        {chosenFeat.pic ?
                                                            <div className='text-center my-3'>
                                                                <img src={API_URL + "/featPics/" + chosenFeat.id + ".jpg"} style={{ maxHeight: "250px", maxWidth: "100%" }} alt="" className='rounded'></img>
                                                            </div>
                                                            : null}
                                                        <div className="mt-3 line-breaks">
                                                            <div className="mt-2" dangerouslySetInnerHTML={{ __html: chosenFeat.description }}></div>
                                                            <b>Source: </b>{chosenFeat.source === "HB" ? "Custom" : chosenFeat.source}<br />
                                                        </div>
                                                    </>
                                                    : null}
                                            </>
                                            : page === "featScore" ?
                                                <div className='text-center'>
                                                    {abilityList.map((ability, index) => (
                                                        <Row className='mb-4 h1 mt-2' key={ability.name}>
                                                            <Col>
                                                                {ability.name}
                                                            </Col>
                                                            <Col>
                                                                <Button variant={selectedAbilitiesFeat.some(d => d === ability.name) ? "primary" : "outline-primary"} size="lg" onClick={(e) => handleAbilityClickFeat(ability.name)} disabled={selectedAbilitiesFeat.some(d => d === ability.name) || abilityCount >= 1 || (chosenFeat.chooseAbilities !== "" && !chosenFeat.chooseAbilities.split(",").includes(ability.name))}>
                                                                    {(abilityCount >= 1 && !selectedAbilitiesFeat.some(d => d === ability.name)) || (chosenFeat.chooseAbilities !== "" && !chosenFeat.chooseAbilities.split(",").includes(ability.name)) ? 0 : "+1"}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </div>
                                                : page === "lang" ?
                                                    ancestry.langsChoose !== "" ?
                                                        removeDuplicates(ancestry.langsChoose.split(",").concat(backgroundLanguages).concat(ancestry.langsFixed.split(","))).map((d, i) => (
                                                            <div key={i} className="mb-3">
                                                                <Form.Check // prettier-ignore
                                                                    type="checkbox"
                                                                    id={i + "lang"}
                                                                    label={d}
                                                                    checked={languages.some(item => item === d) || backgroundLanguages.some(item => item === d) || ancestry.langsFixed.split(",").some(item => item === d)}
                                                                    onChange={e => handleLangChange(d)}
                                                                    disabled={(langsCount >= ancestry.langsChooseNumber && !languages.some(item => item === d)) || backgroundLanguages.some(item => item === d) || ancestry.langsFixed.split(",").some(item => item === d)}
                                                                />
                                                            </div>
                                                        ))
                                                        :
                                                        removeDuplicates(languageList.concat(backgroundLanguages).concat(ancestry.langsFixed.split(","))).map((d, i) => (
                                                            <div key={i} className="mb-3">
                                                                <Form.Check // prettier-ignore
                                                                    type="checkbox"
                                                                    id={i + "lang"}
                                                                    label={d}
                                                                    checked={languages.some(item => item === d) || backgroundLanguages.some(item => item === d) || ancestry.langsFixed.split(",").some(item => item === d)}
                                                                    onChange={e => handleLangChange(d)}
                                                                    disabled={(langsCount >= ancestry.langsChooseNumber && !languages.some(item => item === d)) || backgroundLanguages.some(item => item === d) || ancestry.langsFixed.split(",").some(item => item === d)}
                                                                />
                                                            </div>
                                                        ))
                                                    : page === "abilities" ?
                                                        <div className='h1 text-center'>
                                                            {
                                                                ancestry.lineage === 1 ?
                                                                    <ButtonGroup className='mb-3'>
                                                                        <Button variant={linageOption === 1 ? "primary" : "outline-primary"} onClick={(e) => handleLinageOption(1)} disabled={linageOption > 0}>
                                                                            Choose any +2 and any other +1
                                                                        </Button>
                                                                        <Button variant={linageOption === 2 ? "primary" : "outline-primary"} onClick={(e) => handleLinageOption(2)} disabled={linageOption > 0}>
                                                                            Choose three different +1
                                                                        </Button>
                                                                    </ButtonGroup>
                                                                    : null
                                                            }
                                                            {ancestry.lineage === 0 || (ancestry.lineage === 1 && linageOption > 0) ?
                                                                abilityList.map(ability => (
                                                                    <Row className='mb-4' key={ability.name}>
                                                                        <Col>
                                                                            {ability.name}
                                                                        </Col>
                                                                        <Col>
                                                                            {
                                                                                ability.value >= 0 && ancestry.lineage === 0 ?
                                                                                    <h1>{ability.value}</h1>
                                                                                    :
                                                                                    <Button variant={selectedAbilities.some(d => d === ability.name) ? "primary" : "outline-primary"} size="lg" onClick={(e) => handleAbilityClick(ability.name)} disabled={selectedAbilities.some(d => d === ability.name) || abilityCount >= abilityChooseNumber}>
                                                                                        {abilityCount >= abilityChooseNumber && !selectedAbilities.some(d => d === ability.name) ? 0 : "+" + (linageOption === 1 ? 2 : linageOption === 2 ? 1 : ability.value * -1)}
                                                                                    </Button>
                                                                            }
                                                                        </Col>
                                                                        {
                                                                            linageOption === 1 && selectedAbilities.length >= 1 ?
                                                                                <Col>
                                                                                    {
                                                                                        selectedAbilities[0] !== ability.name ?
                                                                                            <Button variant={selectedSecondAbility === ability.name ? "primary" : "outline-primary"} size="lg" onClick={(e) => setSelectedSecondAbility(ability.name)} disabled={selectedSecondAbility !== ""}>
                                                                                                {selectedSecondAbility !== "" && selectedSecondAbility !== ability.name ? 0 : "+1"}
                                                                                            </Button>
                                                                                            : null
                                                                                    }
                                                                                </Col>
                                                                                : null
                                                                        }
                                                                    </Row>
                                                                ))
                                                                : null
                                                            }
                                                        </div>
                                                        : "Error"}
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            page === "skills" && ancestryData && ancestryData.skillsProfChoose.every(item => skillProficiencies.some(d => d.dispName === item.name && d.proficiency === 1)) && ancestry.skillsProfChoose !== "" ?
                                <p className='me-2'>All available options have been chosen</p>
                                : null

                        }
                        {
                            (page === "abilities" && (abilityCount >= abilityChooseNumber && !(ancestry.lineage === 1 && linageOption === 0))) || (page === "featScore" && (abilityCount >= 1)) ?
                                <Button
                                    variant="outline-primary"
                                    onClick={() => reset(page === "abilities")}
                                >
                                    Reset
                                </Button>
                                : null
                        }
                        <Button
                            variant="primary"
                            onClick={() => update(ancestryData)}
                            disabled={
                                isLoading || (
                                    ((page === "skills" && !(skillsCount >= ancestry.skillsProfChooseNumber)) && !(ancestryData && ancestryData.skillsProfChoose.every(item => skillProficiencies.some(d => d.dispName === item.name && d.proficiency === 1)) && ancestry.skillsProfChoose !== "")) ||
                                    (page === "tools" && !(toolsCount >= ancestry.toolProfChooseNumber)) ||
                                    (page === "lang" && !(langsCount >= ancestry.langsChooseNumber)) ||
                                    (page === "feats" && !chosenFeat) ||
                                    (page === "abilities" && !(abilityCount >= abilityChooseNumber)) ||
                                    (page === "abilities" && ancestry.lineage === 1 && linageOption === 0) ||
                                    (page === "abilities" && linageOption === 1 && selectedSecondAbility === ""))
                            }>
                            Continue
                        </Button>
                    </Modal.Footer>
                </div>
                : null}
        </Modal>
    )
}
