import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import { Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import useDocumentTitle, { logout, sendRequestAuth } from '../services/apiFunctions'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import ShowAncestry from '../parts/ShowAncestry'
import ShowSelectAncestry from '../parts/ShowSelectAncestry'

export default function Ancestries() {

    useDocumentTitle("Ancestries");

    const [dataAncestries, setDataAncestries] = useState([])
    const [search, setSearch] = useState("")
    const [ancestry, setAncestry] = useState()
    const [showDuplicates, setShowDuplicates] = useState(false)

    const [showAncestry, setShowAncestry] = useState(false);
    const handleCloseAncestry = () => {
        setShowAncestry(false);
    };

    const [showSelectAncestry, setShowSelectAncestry] = useState(false);
    const handleCloseSelectAncestry = () => {
        setShowSelectAncestry(false);
    };

    const navigate = useNavigate()

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            setDataAncestries("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setDataAncestries(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setDataAncestries(data.data.message);
            }
        },
    });

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    useEffect(() => {
        const request = { name: "getUserAncestries", param: {} };
        mutate(request);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleAncestryShowClick(ancestry) {
        setAncestry(ancestry);
        if (dataAncestries.some(d => d.parentID === ancestry.id)) {
            setShowSelectAncestry(true);
        } else {
            setShowAncestry(true);
        }
    }

    return (
        <Container className="py-4 text-center">
            <Row>
                <Col></Col>
                <Col xl={8} className="position-relative">
                    <div className="position-absolute top-0 start-0 ps-3">
                        <Link to="/home" className='btn btn-outline-primary text-left'><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    </div>
                    <h2 className='mb-4'>Ancestries</h2>
                    {dataAncestries ? dataAncestries.length > 0 ?
                        <div className="d-grid gap-2">
                            <Form.Group>
                                <Form.Control type="text" name="search" id="search" placeholder="Search..." onChange={handleSearch} />
                            </Form.Group>
                            <Form.Check
                                className='mb-4 text-start big-checkbox '
                                type="switch"
                                id="showDuplicates"
                                label="Show Duplicates"
                                checked={showDuplicates}
                                onChange={e => setShowDuplicates(!showDuplicates)}
                            />
                        </div>
                        : null : null}
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" /> : null
                    }
                    {dataAncestries ? dataAncestries.length > 0 ? dataAncestries.filter((ancestry, index) =>
                        (ancestry.name.toLowerCase().includes(search.toLowerCase()) || ancestry.source.toLowerCase().includes(search.toLowerCase())) && (showDuplicates || ((index === 0 || dataAncestries[index - 1].name !== ancestry.name) && ancestry.importanceWeight !== -1)) && ancestry.parentID === 0).map(d => (
                            <div className={d.public === 0 ? 'alert alert-light text-primary bg-white-tp p-1 cursor-pointer' : 'alert alert-light text-primary p-1 cursor-pointer'}
                                key={d.id}>
                                <Row onClick={() => handleAncestryShowClick(d)}>
                                    <Col className="text-start py-2 ms-2 pe-0">
                                        <h4>{d.name}</h4>
                                    </Col>
                                    {
                                        dataAncestries.some(e => e.parentID === d.id) ?
                                            <Col className='text-start py-2 ms-2 pe-0'>
                                                <i>Has Sub-Ancestries</i>
                                            </Col>
                                            : null
                                    }
                                    <Col className="d-flex col-auto align-items-center me-2">
                                        <i>{d.source}</i>
                                    </Col>
                                </Row>

                            </div>
                        )) : <i>You have no activated sources with ancestries</i> : null}
                </Col>
                <Col></Col>
            </Row>
            <ShowSelectAncestry show={showSelectAncestry} handleClose={handleCloseSelectAncestry} ancestry={ancestry} setAncestry={setAncestry} ancestries={dataAncestries} setShowAncestry={setShowAncestry} showDuplicates={showDuplicates} />
            <ShowAncestry show={showAncestry} handleClose={handleCloseAncestry} ancestry={ancestry} />
        </Container>
    )
}
