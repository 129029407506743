import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import useDocumentTitle, { API_URL, logout, sendRequestAuth } from '../services/apiFunctions'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons'
import MonsterCard from '../parts/MonsterCard'
import NewMonster from '../parts/NewMonster'

export default function Monsters() {

    useDocumentTitle("Monsters");

    const [dataMonsters, setDataMonsters] = useState("")
    const [search, setSearch] = useState("")

    const [show, setShow] = useState(false);
    const [showNew, setShowNew] = useState(false);

    const [monsterID, setMonsterID] = useState(0);
    const [currentMonster, setCurrentMonster] = useState(null);

    const handleClose = (load = false) => {
        if (load) {
            const request = { name: "getMonsters", param: {} };
            mutate(request);
        }
        setShow(false);
    };

    const handleCloseNew = (load = false) => {
        if (load) {
            const request = { name: "getMonsters", param: {} };
            mutate(request);
        }
        setShowNew(false)
    };

    const navigate = useNavigate()

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            setDataMonsters("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setDataMonsters(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setDataMonsters(data.data.message);
            }
        },
    });

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    useEffect(() => {
        const request = { name: "getMonsters", param: {} };
        mutate(request);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container className="py-4 text-center">
            <Row>
                <Col></Col>
                <Col xl={8} className="position-relative">
                    <div className="position-absolute top-0 start-0 ps-3">
                        <Link to="/home" className='btn btn-outline-primary text-left'><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    </div>
                    <div className="position-absolute top-0 end-0 pe-3">
                        <Button variant="outline-primary" onClick={() => { setCurrentMonster(null); setShowNew(true); }}><FontAwesomeIcon icon={faPlus} /></Button>
                    </div>
                    <h2 className='mb-4'>Your Monsters</h2>
                    {dataMonsters ? dataMonsters.length > 0 ?
                        <div className="d-grid gap-2">
                            <Form.Group className='mb-4'>
                                <Form.Control type="text" name="search" id="search" placeholder="Search..." onChange={handleSearch} />
                            </Form.Group>
                        </div>
                        : null : null}
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" /> : null
                    }
                    {dataMonsters ? dataMonsters.length > 0 ? dataMonsters.filter(monster => monster.name.toLowerCase().includes(search.toLowerCase())).map(d => (
                        <div className='alert alert-light text-primary bg-white-tp p-1 cursor-pointer' key={d.id} >
                            <Row>
                                {d.pic ?
                                    <Col className="col-auto text-start" onClick={() => { setMonsterID(d.id); setShow(true); }}>
                                        <img src={API_URL + "/monsterCharPics/" + d.id + ".png"} alt="" height="100px" className='rounded'></img>
                                    </Col>
                                    : null}
                                <Col className={d.pic ? "text-start mt-4" : "text-start ms-3 my-2"} onClick={() => { setMonsterID(d.id); setShow(true); }}>
                                    <h4>{d.name}</h4>
                                </Col>
                                <Col className="d-flex col-auto align-items-center">
                                    <Button className="me-4" variant="outline-primary" onClick={() => { setCurrentMonster(d); setShowNew(true); }}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    )) : <i>Create a monster by clicking on the <b>+</b></i> : null}
                </Col>
                <Col></Col>
            </Row>
            <NewMonster show={showNew} handleClose={handleCloseNew} monster={currentMonster} />
            {monsterID !== 0 ?
                <MonsterCard show={show} monsterID={monsterID} charID={0} charType={"monster"} handleClose={handleClose} />
                : null}
        </Container>
    )
}
