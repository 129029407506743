import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import useDocumentTitle, { logout, sendRequestAuth } from '../services/apiFunctions'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons'
import ShowFeat from '../parts/ShowFeat'
import NewFeat from '../parts/NewFeat'

export default function Feats() {

    useDocumentTitle("Feats");

    const [dataFeats, setDataFeats] = useState("")
    const [search, setSearch] = useState("")
    const [feat, setFeat] = useState()

    const [showFeat, setShowFeat] = useState(false);
    const [showFeatEdit, setShowFeatEdit] = useState(false);

    function reload() {
        const request = { name: "getUserFeats", param: {} };
        mutate(request);
    }

    const handleCloseFeat = () => {
        setShowFeat(false);
    };

    const handleCloseFeatEdit = () => {
        setShowFeatEdit(false)
    };

    const navigate = useNavigate()

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            setDataFeats("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setDataFeats(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setDataFeats(data.data.message);
            }
        },
    });

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    useEffect(() => {
        const request = { name: "getUserFeats", param: {} };
        mutate(request);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleFeatEditClick(item) {
        setFeat(item);
        if (parseInt(localStorage.getItem('userID')) === item.userID) {
            setShowFeatEdit(true)
        }
    }

    function handleFeatShowClick(feat) {
        setFeat(feat);
        //console.log(feat);
        setShowFeat(true);
    }

    return (
        <Container className="py-4 text-center">
            <Row>
                <Col></Col>
                <Col xl={8} className="position-relative">
                    <div className="position-absolute top-0 start-0 ps-3">
                        <Link to="/home" className='btn btn-outline-primary text-left'><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    </div>
                    <div className="position-absolute top-0 end-0 pe-3">
                        <Button variant="outline-primary" onClick={() => { setFeat(null); setShowFeatEdit(true); }}><FontAwesomeIcon icon={faPlus} /></Button>
                    </div>
                    <h2 className='mb-4'>Your Feats</h2>
                    {dataFeats ? dataFeats.length > 0 ?
                        <div className="d-grid gap-2">
                            <Form.Group className='mb-4'>
                                <Form.Control type="text" name="search" id="search" placeholder="Search..." onChange={handleSearch} />
                            </Form.Group>
                        </div>
                        : null : null}
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" /> : null
                    }
                    {dataFeats ? dataFeats.length > 0 ? dataFeats.filter(feat =>
                        feat.name.toLowerCase().includes(search.toLowerCase()) || feat.source.toLowerCase().includes(search.toLowerCase())).map(d => (
                            <div className={d.public === 0 ? 'alert alert-light text-primary bg-white-tp p-1 cursor-pointer' : 'alert alert-light text-primary p-1 cursor-pointer'}
                                key={d.id}>
                                <Row>
                                    <Col className="text-start py-2 ms-2 pe-0" onClick={() => handleFeatShowClick(d)}>
                                        <h4>{d.name}</h4>
                                    </Col>
                                    <Col className="d-flex col-auto align-items-center me-2" onClick={() => handleFeatShowClick(d)}>
                                        <i>{d.source}</i>
                                    </Col>
                                    {parseInt(localStorage.getItem('userID')) === d.userID ?
                                        <Col className="d-flex col-auto align-items-center">
                                            <Button className="me-4" variant="outline-primary" onClick={() => { handleFeatEditClick(d); }}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                        </Col>
                                        : null}
                                </Row>

                            </div>
                        )) : <i>Create a feat by clicking on the <b>+</b></i> : null}
                </Col>
                <Col></Col>
            </Row>
            <ShowFeat show={showFeat} handleClose={handleCloseFeat} feat={feat} removeButton={false} />
            <NewFeat show={showFeatEdit} handleClose={handleCloseFeatEdit} feat={feat} reload={reload} />
        </Container>
    )
}
