import { faArrowLeft, faBed, faChild, faClipboardList, faFire, faGear, faToolbox, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { useSwipeable } from 'react-swipeable'
import Front from '../parts/charSheet/Front'
import Inventory from '../parts/charSheet/Inventory'
import Spells from '../parts/charSheet/Spells'
import Stats from '../parts/charSheet/Stats'
import NewChar from '../parts/NewChar'
import useDocumentTitle, { logout, sendRequestAuth } from '../services/apiFunctions'
import { motion } from 'framer-motion'
import Features from '../parts/charSheet/Features'
import Rest from '../parts/charSheetModals/Rest'

export default function CharacterSheet() {

    useDocumentTitle("Character Sheet");

    const [page, setPage] = useState(0);
    const [char, setChar] = useState("");
    const [weapons, setWeapons] = useState([]);
    const [spells, setSpells] = useState([]);
    const [features, setFeatures] = useState([]);
    const [companions, setCompanions] = useState();
    const [heightVal, setHeightVal] = useState(2000);
    const [showHidden, setShowHidden] = useState(false);

    const [showRest, setShowRest] = useState(false);
    const handleCloseRest = () => setShowRest(false);

    const [coins, setCoins] = useState({ copper: 0, silver: 0, gold: 0, platinum: 0 })

    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = (reload) => {
        setShowEdit(false)
        loadChar();
        if (reload) {
            window.location.reload(false);
        }
    };

    const navigate = useNavigate()

    const charID = localStorage.getItem("charID");

    function changePage(newPage) {
        setPage(newPage)
        getHeight(newPage)
    }

    const { mutate: mutateFeatures } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setFeatures(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    function loadFeatures() {
        var request = {
            name: "getFeaturesOfChar",
            param: {
                charID: parseInt(charID)
            }
        };
        mutateFeatures(request);
    }

    const { mutate: mutateComp } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setCompanions(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    const { mutate: mutateWeapons, isLoading: isLoadingWeapons } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setWeapons(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    const { mutate: mutateSpells } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setSpells(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setChar(data.data.result);
                calculateCoins(data.data.result);
                loadWeapons();
                loadSpells();
                loadCompanions();
                loadFeatures();
                if (page === "") {
                    changePage(0);
                }
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    function loadChar(fullReload = false) {
        const request = {
            name: "getCharWithGame",
            param: {
                charID: parseInt(charID)
            }
        };
        mutate(request);
        getHeight();

        if (fullReload) {
            window.location.reload(false);
        }
    }

    function loadWeapons() {
        const request = {
            name: "getWeapons",
            param: {
                charID: parseInt(charID)
            }
        };
        mutateWeapons(request);
    }

    function loadSpells() {
        const request = {
            name: "getSpellsOfChar",
            param: {
                charID: parseInt(charID)
            }
        };
        mutateSpells(request);
        getHeight();
    }

    function loadCompanions() {
        const request = {
            name: "getCompanionsOfchar",
            param: {
                charID: parseInt(charID)
            }
        };
        mutateComp(request);
    }

    useEffect(() => {
        loadChar()
        getHeight()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getHeight()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weapons, spells, companions, showHidden]);

    function getHeight(pageOverwrite = null) {
        if (document.getElementById('slide' + (pageOverwrite || pageOverwrite === 0 ? pageOverwrite : page))) {
            setHeightVal(document.getElementById('slide' + (pageOverwrite || pageOverwrite === 0 ? pageOverwrite : page)).offsetHeight)
        }
    }

    function calculateCoins(thisChar) {
        var copper = 0;
        var silver = 0;
        var gold = 0;
        var platinum = 0;

        platinum = Math.floor(thisChar.coins / 10000);
        gold = Math.floor(thisChar.coins / 100) - platinum * 100;
        silver = Math.floor(thisChar.coins / 10) - gold * 10 - platinum * 1000;
        copper = thisChar.coins - silver * 10 - gold * 100 - platinum * 10000;

        setCoins({ copper: copper, silver: silver, gold: gold, platinum: platinum });
    }

    const handlers = useSwipeable({
        onSwipedLeft: (e) => {
            if (page === 0) {
                changePage(1);
            } else if (page === 1) {
                changePage(2);
            } else if (page === 2) {
                changePage(3);
            } else if (page === 3) {
                changePage(4);
            }
        },
        onSwipedRight: (e) => {
            if (page === 4) {
                changePage(3);
            } else if (page === 3) {
                changePage(2);
            } else if (page === 2) {
                changePage(1);
            } else if (page === 1) {
                changePage(0);
            }
        }
    }
    );

    return (
        <Container className="py-4 text-center" {...handlers} style={{ minHeight: "100vh" }}>
            <Row>
                <Col></Col>
                <Col xl={8} className="position-relative">
                    <div className="position-absolute top-0 start-0 ps-3">
                        <Link to={"/" + localStorage.getItem("previous")} className='btn btn-outline-primary text-left'><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    </div>
                    <div className="position-absolute top-0 end-0 pe-3" >
                        <Button variant="outline-primary" onClick={() => { setShowEdit(true); }}>
                            <FontAwesomeIcon icon={faGear} />
                        </Button><br />
                        <Button className='mt-2' variant='outline-primary' onClick={(e) => setShowRest(true)} style={{ width: "42px" }}>
                            <FontAwesomeIcon icon={faBed} />
                        </Button>
                    </div>
                    <p className='mb-0'>{char.gameName ? char.gameName : "No game connected to this char"}</p>
                    <h2 className='mt-0 mb-0'>{char.name}</h2>
                    <p className='mt-0 mb-3'><small>{char.ancestryName + " - " + (char.subclassName ? char.subclassName + " " : "") + char.className}</small></p>
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" /> : null}

                    {char ?
                        <div className="position-relative" style={{ overflow: "hidden", height: heightVal + "px" }}>
                            <motion.div
                                id='slide0'
                                className="position-absolute px-1"
                                initial={{ x: (page * -100) + "%" }}
                                animate={{ x: (page * -100) + "%" }}
                                transition={{ type: "tween" }}
                                style={{ width: "100%" }}>
                                {true ?
                                    <Front char={char} reload={loadChar} reloadComp={loadCompanions} weapons={weapons} isLoadingWeapons={isLoadingWeapons} companions={companions} features={features} showHidden={showHidden} setShowHidden={setShowHidden} />
                                    : null}
                            </motion.div>
                            <motion.div
                                id='slide1'
                                className="position-absolute px-1"
                                initial={{ x: ((page * -100) + 100) + "%" }}
                                animate={{ x: ((page * -100) + 100) + "%" }}
                                transition={{ type: "tween" }}
                                style={{ width: "100%" }}>
                                {char !== "" ?
                                    <Stats char={char} reload={loadChar} />
                                    : null}
                            </motion.div>
                            <motion.div
                                id='slide2'
                                className="position-absolute px-1"
                                initial={{ x: ((page * -100) + 200) + "%" }}
                                animate={{ x: ((page * -100) + 200) + "%" }}
                                transition={{ type: "tween" }}
                                style={{ width: "100%" }}>
                                {char !== "" ?
                                    <Spells char={char} spells={spells} reload={loadChar} />
                                    : null}
                            </motion.div>
                            <motion.div
                                id='slide3'
                                className="position-absolute px-1"
                                initial={{ x: ((page * -100) + 300) + "%" }}
                                animate={{ x: ((page * -100) + 300) + "%" }}
                                transition={{ type: "tween" }}
                                style={{ width: "100%" }}>
                                {char !== "" ?
                                    <Features char={char} reload={loadChar} features={features} getHeight={getHeight} />
                                    : null}
                            </motion.div>
                            <motion.div
                                id='slide4'
                                className="position-absolute px-1"
                                initial={{ x: ((page * -100) + 400) + "%" }}
                                animate={{ x: ((page * -100) + 400) + "%" }}
                                transition={{ type: "tween" }}
                                style={{ width: "100%" }}>
                                {char !== "" ?
                                    <Inventory char={char} coins={coins} reload={loadChar} />
                                    : null}
                            </motion.div>
                        </div>
                        : null}
                </Col>
                <Col></Col>
            </Row>
            <div className='mb-5'></div>
            <div className="fixed-bottom text-primary h1 bg-pergament pb-2" style={{ marginBottom: "-1px" }}>
                <hr className="mt-0 mb-2"></hr>
                <Row>
                    <Col></Col>
                    <Col xl={8}>
                        <Row>
                            <p className="col cursor-pointer border-end border-primary mb-0" onClick={() => changePage(0)}>
                                <FontAwesomeIcon className={page === 0 ? "link-primary" : "link-secondary"} icon={faChild} />
                            </p>
                            <p className="col cursor-pointer border-end border-primary mb-0" onClick={() => changePage(1)}>
                                <FontAwesomeIcon className={page === 1 ? "link-primary" : "link-secondary"} icon={faClipboardList} />
                            </p>
                            <p className="col cursor-pointer border-end border-primary mb-0" onClick={() => changePage(2)}>
                                <FontAwesomeIcon className={page === 2 ? "link-primary" : "link-secondary"} icon={faWandMagicSparkles} />
                            </p>
                            <p className="col cursor-pointer border-end border-primary mb-0" onClick={() => changePage(3)}>
                                <FontAwesomeIcon className={page === 3 ? "link-primary" : "link-secondary"} icon={faFire} />
                            </p>
                            <p className="col cursor-pointer mb-0" onClick={() => changePage(4)}>
                                <FontAwesomeIcon className={page === 4 ? "link-primary" : "link-secondary"} icon={faToolbox} />
                            </p>
                        </Row>
                    </Col>
                    <Col></Col>
                </Row>
            </div>
            <NewChar show={showEdit} handleClose={handleCloseEdit} edit={true} char={char} />
            <Rest show={showRest} handleClose={handleCloseRest} char={char} reload={loadChar} />
        </Container>
    )
}
