import { faArrowUp19, faFont, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, ListGroup, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { API_URL, logout, sendRequestAuth } from '../../services/apiFunctions';
import { useNavigate } from 'react-router-dom';

export default function NewEquipment({ show, char, handleClose, reload, equipment }) {

    const [deleteQuestion, setDeleteQuestion] = useState(false);
    const [dataItems, setDataItems] = useState([])
    const [search, setSearch] = useState("")
    const [chosenItem, setChosenItem] = useState("")
    const [customName, setCustomName] = useState("")
    const [formData, setFormData] = useState()
    const [addCustom, setAddCustom] = useState(false)

    const navigate = useNavigate()

    const { mutate: mutateChange } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                reload();
                handleClose(true);
            } else {
                console.log(data.data.message);
            }
        },
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        var request;
        if (equipment) {
            request = {
                name: "editEquipment",
                param: {
                    id: parseInt(equipment.equipID),
                    name: customName,
                    amount: formData.amount
                }
            };
        } else {
            if (addCustom) {
                request = {
                    name: "addEquipment",
                    param: {
                        charID: parseInt(char.id),
                        name: search,
                        amount: formData.amount
                    }
                };
            } else {
                request = {
                    name: "addEquipment",
                    param: {
                        charID: parseInt(char.id),
                        itemID: chosenItem.id,
                        name: customName,
                        amount: formData.amount,
                        weaponAbilityScore: chosenItem.weaponAbilityScore,
                        weaponMod: chosenItem.weaponMod,
                        weaponRange: chosenItem.weaponRange,
                        weaponDamageType: chosenItem.weaponDamageType,
                        weaponDamageDiceMultiplier: chosenItem.weaponDamageDiceMultiplier,
                        weaponDamageDice: chosenItem.weaponDamageDice,
                        type: chosenItem.type
                    }
                };
            }
        }


        //console.log(request);
        mutateChange(request);
    }

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            setDataItems("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setDataItems(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setDataItems(data.data.message);
            }
        },
    })

    useEffect(() => {
        setDeleteQuestion(false);
        setAddCustom(false);
        setSearch("")

        if (equipment) {
            setChosenItem(equipment)
            setFormData(values => ({ ...values, "amount": equipment.amount }));

            setCustomName(equipment.equipmentName !== "" ? equipment.equipmentName : equipment.name)
        } else {
            setChosenItem(null)
            setFormData(values => ({ ...values, "amount": 1 }));

            const request = { name: "getUserItems", param: {} };
            mutate(request);
        }



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    function handleDelete() {
        const request = {
            name: "deleteEquipment",
            param: {
                id: parseInt(equipment.equipID)
            }
        };
        mutateChange(request);
    }


    return (
        <Modal size="lg" show={show} onHide={() => handleClose(false)} centered>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>{equipment && chosenItem ? <h4 className='red'>{chosenItem.name !== "" ? chosenItem.name ? chosenItem.name : equipment.equipmentName : chosenItem.equipmentName}</h4> : "Add Equipment"}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-start'>
                    {!equipment ?
                        <div className='mb-4'>
                            {dataItems ? dataItems.length > 0 ?
                                <div className="d-grid gap-2">
                                    <Form.Group className='mb-2'>
                                        <Form.Control type="text" name="search" id="search" placeholder="Search..." onChange={handleSearch} />
                                    </Form.Group>
                                </div>
                                : null : null}
                            <div className="chooseList border rounded">
                                {isLoading ?
                                    <Modal.Body className="text-center text-primary">
                                        <Spinner animation="border" className="my-3" />
                                    </Modal.Body>
                                    :
                                    <ListGroup defaultActiveKey={"#" + (chosenItem ? chosenItem.id : 0)}>
                                        {dataItems ? dataItems.length > 0 ? dataItems.filter(item => item.name.toLowerCase().includes(search.toLowerCase())).map(d => (
                                            <ListGroup.Item key={d.id} href={"#" + d.id} action onClick={() => { setChosenItem(d); setAddCustom(false); setCustomName(d.name); }}>
                                                <Row>
                                                    <Col className="text-start pe-0">
                                                        {d.name}
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        )) : <i>Create an item first</i> : null}
                                    </ListGroup>
                                }
                            </div>
                            {search.length && !addCustom > 0 ?
                                <div className="d-grid gap-2 mt-2">
                                    <Button variant='outline-primary' onClick={() => { setAddCustom(true); }}>
                                        <FontAwesomeIcon icon={faPlus} /> Add item with name "{search}"
                                    </Button>
                                </div>
                                : null}
                        </div>
                        : null}
                    {chosenItem || addCustom ?
                        <div>
                            {equipment && equipment.pic ?
                                <div className='text-center my-3'>
                                    <img src={API_URL + "/itemPics/" + equipment.id + ".jpg"} style={{ maxHeight: "250px", maxWidth: "100%" }} alt="" className='rounded'></img>
                                </div>
                                : null}
                            {!equipment || addCustom ? <h4 className='red'>{addCustom ? search : chosenItem.name}</h4> : null}
                            {!addCustom && ((equipment && equipment.name !== "") || !equipment) ?
                                <div className="line-breaks rounded">
                                    <p>
                                        <i>
                                            {
                                                (chosenItem.type ? chosenItem.type : "") +
                                                (chosenItem.type && chosenItem.rarity !== "none" && chosenItem.rarity !== "unknown" && chosenItem.rarity !== "unknown (magic)" ? ", " : "") +
                                                (chosenItem.rarity !== "none" && chosenItem.rarity !== "unknown" && chosenItem.rarity !== "unknown (magic)" && chosenItem.rarity ? chosenItem.rarity : "") +
                                                (chosenItem.attunement ? " (" + chosenItem.attunement + ")" : "")
                                            }
                                        </i>
                                    </p>
                                    {
                                        chosenItem.weightText || chosenItem.valueText ?
                                            <p>
                                                {
                                                    (chosenItem.weightText ? chosenItem.weightText : "") +
                                                    (chosenItem.weightText && chosenItem.valueText ? ", " : "") +
                                                    (chosenItem.valueText ? chosenItem.valueText : "")
                                                }<br />
                                            </p>
                                            : null
                                    }
                                    {
                                        chosenItem.properties ?
                                            <p>
                                                <b>{chosenItem.properties}</b>
                                            </p>
                                            : null
                                    }

                                    <div className="my-2" dangerouslySetInnerHTML={{ __html: chosenItem.description }}>
                                    </div>

                                    <b>Source:</b> {chosenItem.source === "HB" ? "Custom" : chosenItem.source}
                                </div>
                                : null}
                            {!addCustom ?
                                <Row className="my-3">
                                    <div className="col-auto h2">
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={<Tooltip>Custom Name</Tooltip>}
                                        >
                                            <FontAwesomeIcon icon={faFont} />
                                        </OverlayTrigger>
                                    </div>
                                    <Col>
                                        <Form.Control type="text" name="customName" value={customName} onChange={(e) => setCustomName(e.target.value)} />
                                    </Col>
                                </Row>
                                : null}
                            <Row className="my-3">
                                <div className="col-auto h2">
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip>How many to add</Tooltip>}
                                    >
                                        <FontAwesomeIcon icon={faArrowUp19} />
                                    </OverlayTrigger>
                                </div>
                                <Col>
                                    <Form.Control type="number" min="1" name="amount" defaultValue={equipment ? equipment.amount : 1} onChange={handleChange} />
                                </Col>
                            </Row>
                        </div>
                        : null}
                </Modal.Body>
                <Modal.Footer>
                    {equipment ? deleteQuestion ?
                        <div className='text-center container-fluid'>
                            <hr />
                            <div>
                                <p>Do you really want to remove this equipment?</p>
                                <Button variant="outline-primary me-2" onClick={() => setDeleteQuestion(false)}>
                                    Abort
                                </Button>
                                <Button variant="primary" onClick={handleDelete}>
                                    Remove
                                </Button>
                            </div>
                            <hr />
                        </div>
                        :
                        <Button variant="outline-primary" onClick={() => setDeleteQuestion(true)}>
                            Remove
                        </Button>
                        : null}
                    <Button variant="secondary" onClick={() => handleClose(false)}>
                        Close
                    </Button>
                    <Button variant="outline-primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
