import { faCheck, faDiceD20, faHeart, faHeartBroken, faShield, faShieldHalved, faSkull, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, OverlayTrigger, ProgressBar, Row, Tooltip } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import useDocumentTitle, { API_URL, conditions, getHealthBeat, getHealthColor, getHealthPerc, getHealthText, sendRequestAuth } from '../services/apiFunctions';
import healSound from "../assets/heal.wav"
import damageSound from "../assets/damage.wav"

export default function Board() {

    useDocumentTitle("Board");

    const [formCode, setFormCode] = useState("");
    const [board, setBoard] = useState();
    const [alert, setAlert] = useState("");
    const [active, setActive] = useState(false);

    const healSoundRef = useRef(new Audio(healSound));
    const damageSoundRef = useRef(new Audio(damageSound));

    let { gameCode } = useParams();

    const navigate = useNavigate()

    const { mutate } = useMutation(sendRequestAuth, {
        onError: (err) => {
            setAlert("An error occured while fetching data:" + err);
            setActive(false);
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setActive(true);
                checkHealthChange(board, data.data.result);
                setBoard(data.data.result);
            } else {
                setAlert(data.data.message);
                setActive(false);
            }
        },
    });

    function checkHealthChange(oldBoard, newBoard) {
        var healSound = false;
        var damageSound = false;

        if (active) {
            //console.log(oldBoard)

            if (typeof oldBoard.fighters !== 'undefined' && typeof newBoard.fighters !== 'undefined' && oldBoard.fighters.length === newBoard.fighters.length) {
                oldBoard.fighters.forEach((fighter, index) => {
                    if (board.game.showHP >= 0 && fighter.type !== "player") {
                        if (fighter.hp > newBoard.fighters[index].hp) {
                            damageSound = true;
                        } else if (fighter.hp < newBoard.fighters[index].hp) {
                            healSound = true;
                        }
                    }
                });
            }

            if (typeof oldBoard.chars !== 'undefined' && typeof newBoard.chars !== 'undefined' && oldBoard.chars.length === newBoard.chars.length) {
                oldBoard.chars.forEach((char, index) => {
                    if (char.hp > newBoard.chars[index].hp) {
                        damageSound = true;
                    } else if (char.hp < newBoard.chars[index].hp) {
                        healSound = true;
                    }
                });
            }

            if (typeof oldBoard.companions !== 'undefined' && typeof newBoard.companions !== 'undefined' && oldBoard.companions.length === newBoard.companions.length) {
                oldBoard.companions.forEach((comp, index) => {
                    if (comp.hp > newBoard.companions[index].hp) {
                        damageSound = true;
                    } else if (comp.hp < newBoard.companions[index].hp) {
                        healSound = true;
                    }
                });
            }

            if (damageSound) {
                //console.log("Damage")
                damageSoundRef.current.play();
            }

            if (healSound) {
                //console.log("Heal")
                healSoundRef.current.play();
            }
        }
    }

    function getBoard() {

        const request = {
            name: "getBoard",
            param: {
                gameCode: gameCode ? gameCode : ""
            }
        };
        mutate(request);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate("/board/" + formCode);
        window.location.reload();
    }

    useEffect(() => {
        getBoard();
        const interval = setInterval(() => {
            getBoard();
        }, 1000);
        return () => clearInterval(interval);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInputChange = (e) => {
        const result = e.target.value.toUpperCase();
        setFormCode(result);
    };

    return (
        <div>
            {active ?
                <Container className="py-3 text-center m-0">
                    {board.showcase.length > 0 ?
                        <Row className="vw100">
                            {board.showcase.map((char) => (
                                <Col className="position-relative" key={char.id}>
                                    <img src={API_URL + "/monsterCharPics/" + char.id + ".png"} alt="" className='rounded showcase-img'></img>
                                    <h2 className="position-absolute top-100 start-50 translate-middle text-primary p-2 mt-5">{char.name}</h2>
                                </Col>
                            ))}
                        </Row>
                        : board.game.fightActive ?
                            <div className='vw98' style={{ columnCount: Math.ceil(board.fighters.length / 6) }}>
                                {board ? board.fighters.map((char, index) => (
                                    <div className={`alert border text-primary p-1 h4 column-break-avoid 
                                                            ${char.hp <= 0 ? 'alert-dark' : 'alert-light bg-white-tp'} 
                                                            ${index === board.game.fightNumber && board.game.fightActive ? 'border border-4 border-primary' : ''}`}
                                        key={char.id} >
                                        <Row>
                                            {char.pic ?
                                                <Col className="text-start">
                                                    {char.type === "player" ?
                                                        <img src={API_URL + "/playerCharPics/" + char.charID + ".png"} alt="" height="100px" className='rounded'></img>
                                                        : char.type === "monster" ?
                                                            <img src={API_URL + "/monsterCharPics/" + char.charID + ".png"} alt="" height="100px" className='rounded'></img>
                                                            :
                                                            <img src={API_URL + "/monsterCharPics/" + char.monsterID + ".png"} alt="" height="100px" className='rounded'></img>
                                                    }
                                                </Col>
                                                : null}
                                            <Col className="d-flex justify-content-center align-items-center">
                                                <h2 className={`${char.type === "monster" ? 'text-danger' : 'text-success'}`}>{char.name}</h2>
                                            </Col>
                                            {board.game.showAC || char.type !== "monster" ?
                                                <Col className="d-flex justify-content-center align-items-center">
                                                    <FontAwesomeIcon icon={faShieldHalved} className="me-1" /> {char.AC}
                                                </Col>
                                                : null}
                                            <Col className="d-flex justify-content-center align-items-center">
                                                <FontAwesomeIcon icon={faDiceD20} className="me-1" /> {char.initiative}
                                            </Col>
                                        </Row>
                                        {char.conditions !== "" ?
                                            <Row className="my-2 mx-1 py-2 text-center text-primary alert alert-light bg-white-tp">
                                                {char.conditions.split(",").map(condID => (
                                                    <Col>
                                                        <OverlayTrigger overlay={<Tooltip id={condID}>{conditions.find(cond => cond.id === parseInt(condID)).name}</Tooltip>}>
                                                            <img alt="" id={condID} src={conditions.find(cond => cond.id === parseInt(condID)).pic} style={{ height: "30px", width: "auto" }} />
                                                        </OverlayTrigger>
                                                    </Col>
                                                ))}
                                            </Row>
                                            : null}
                                        {board.game.showHP >= 0 || char.type !== "monster" ?
                                            <Row className="px-3-5 mt-1">
                                                {char.tempHP > 0 ?
                                                    <ProgressBar style={{ height: "25px" }}
                                                        className={`cursor-pointer mb-1 ${char.tempHP % 2 === 0 ? "bg-tempHP" : "bg-info"}`}>
                                                        {[...Array(char.tempHP)].map((e, i) =>
                                                            <ProgressBar className="skew" variant={i % 2 === 0 ? "info" : "info2"} now={100 / char.tempHP} key={i} />
                                                        )}
                                                        <div className="position-absolute bar-lable h5 p-0 text-light fill-available">
                                                            <FontAwesomeIcon icon={faShield} /> {char.tempHP}
                                                        </div>
                                                    </ProgressBar>
                                                    : null}
                                                <ProgressBar style={{ height: "25px", backgroundColor: "#9b9b9b" }} className="p-0">
                                                    <ProgressBar variant={getHealthColor(char.hp, char.maxHP)} now={board.game.showHP === 1 || char.type !== "monster" ? getHealthPerc(char.hp, char.maxHP) : 100} />
                                                    <div className="position-absolute bar-lable h5 p-0 mt-0 text-light fill-available">
                                                        <FontAwesomeIcon fade={getHealthBeat(char.hp, char.maxHP)} icon={char.hp <= 0 ? faSkull : getHealthPerc(char.hp, char.maxHP) <= 10 ? faHeartBroken : faHeart} /> {board.game.showHP === 1 || char.type !== "monster" ? char.hp + "/" + char.maxHP : getHealthText(char.hp, char.maxHP)}
                                                    </div>
                                                </ProgressBar>
                                            </Row>
                                            : null}
                                        {char.type === "player" && char.hp <= 0 ?
                                            <Row className='mt-2 mb-1'>
                                                <Col><FontAwesomeIcon icon={faCheck} /></Col>
                                                <Col className={`${char.deathSuccess >= 1 ? 'bg-success' : 'bg-light'} p-0 me-1 bg-trans`}></Col>
                                                <Col className={`${char.deathSuccess >= 2 ? 'bg-success' : 'bg-light'} p-0 me-1 bg-trans`}></Col>
                                                <Col className={`${char.deathSuccess >= 3 ? 'bg-success' : 'bg-light'} p-0 me-2 bg-trans`}></Col>

                                                <Col className={`${char.deathFail >= 3 ? 'bg-danger' : 'bg-light'} p-0 me-1 ms-2 bg-trans`}></Col>
                                                <Col className={`${char.deathFail >= 2 ? 'bg-danger' : 'bg-light'} p-0 me-1 bg-trans`}></Col>
                                                <Col className={`${char.deathFail >= 1 ? 'bg-danger' : 'bg-light'} p-0 me-1 bg-trans`}></Col>
                                                <Col><FontAwesomeIcon icon={faXmark} /></Col>
                                            </Row>
                                            : null
                                        }
                                    </div>
                                )) : null}
                            </div>
                            :
                            <Row className="vw100">
                                <Col>
                                    {board ? board.chars.map((char, index) => (
                                        <div className={char.hp <= 0 ? 'alert alert-dark text-primary p-1 h4' : 'alert alert-light text-primary bg-white-tp p-1 h4'} key={char.id}>
                                            <Row>
                                                {char.pic ?
                                                    <Col className="text-start">
                                                        <img src={API_URL + "/playerCharPics/" + char.id + ".png"} alt="" height="100px" className='rounded'></img>
                                                    </Col>
                                                    : null}
                                                <Col className="d-flex justify-content-center align-items-center">
                                                    <h2>{char.name}</h2>
                                                </Col>
                                                <Col className="d-flex justify-content-center align-items-center">
                                                    <FontAwesomeIcon icon={faShieldHalved} className="me-1" /> {char.AC}
                                                </Col>
                                            </Row>
                                            {char.conditions !== "" ?
                                                <Row className="my-2 mx-1 py-2 text-center text-primary alert alert-light bg-white-tp">
                                                    {char.conditions.split(",").map(condID => (
                                                        <Col>
                                                            <OverlayTrigger overlay={<Tooltip id={condID}>{conditions.find(cond => cond.id === parseInt(condID)).name}</Tooltip>}>
                                                                <img alt="" id={condID} src={conditions.find(cond => cond.id === parseInt(condID)).pic} style={{ height: "30px", width: "auto" }} />
                                                            </OverlayTrigger>
                                                        </Col>
                                                    ))}
                                                </Row>
                                                : null}
                                            <Row className="px-3-5 mt-1">
                                                {char.tempHP > 0 ?
                                                    <ProgressBar style={{ height: "25px" }}
                                                        className={`pb-0 mb-1 ${char.tempHP % 2 === 0 ? "bg-tempHP" : "bg-info"}`}>
                                                        {[...Array(char.tempHP)].map((e, i) =>
                                                            <ProgressBar className="skew" variant={i % 2 === 0 ? "info" : "info2"} now={100 / char.tempHP} key={i} />
                                                        )}
                                                        <div className="position-absolute bar-lable h5 p-0 text-light fill-available">
                                                            <FontAwesomeIcon icon={faShield} /> {char.tempHP}
                                                        </div>
                                                    </ProgressBar>
                                                    : null}
                                                <ProgressBar style={{ height: "25px", backgroundColor: "#9b9b9b" }} className="p-0">
                                                    <ProgressBar variant={getHealthColor(char.hp, char.maxHP)} now={getHealthPerc(char.hp, char.maxHP)} key={1} />
                                                    <div className="position-absolute bar-lable h5 p-0 mt-0 text-light fill-available">
                                                        <FontAwesomeIcon fade={getHealthBeat(char.hp, char.maxHP)} icon={char.hp <= 0 ? faSkull : getHealthPerc(char.hp, char.maxHP) <= 10 ? faHeartBroken : faHeart} /> {char.hp}/{char.maxHP}
                                                    </div>
                                                </ProgressBar>
                                            </Row>
                                        </div>
                                    )) : null}
                                </Col>
                                {board && board.companions.length > 0 ?
                                    <Col>
                                        {board.companions.map((char, index) => (
                                            <div className={char.hp <= 0 ? 'alert alert-dark text-primary p-1 h4' : 'alert alert-light text-primary bg-white-tp p-1 h4'} key={char.id}>
                                                <Row>
                                                    {char.pic ?
                                                        <Col className="text-start">
                                                            <img src={API_URL + "/monsterCharPics/" + char.monsterID + ".png"} alt="" height="100px" className='rounded'></img>
                                                        </Col>
                                                        : null}
                                                    <Col className="d-flex justify-content-center align-items-center">
                                                        <h2>{char.name}</h2>
                                                    </Col>
                                                    <Col className="d-flex justify-content-center align-items-center">
                                                        <FontAwesomeIcon icon={faShieldHalved} className="me-1" /> {char.AC}
                                                    </Col>
                                                </Row>
                                                {char.conditions !== "" ?
                                                    <Row className="my-2 mx-1 py-2 text-center text-primary alert alert-light bg-white-tp">
                                                        {char.conditions.split(",").map(condID => (
                                                            <Col>
                                                                <OverlayTrigger overlay={<Tooltip id={condID}>{conditions.find(cond => cond.id === parseInt(condID)).name}</Tooltip>}>
                                                                    <img alt="" id={condID} src={conditions.find(cond => cond.id === parseInt(condID)).pic} style={{ height: "30px", width: "auto" }} />
                                                                </OverlayTrigger>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                    : null}
                                                <Row className="px-3-5 mt-1">
                                                    {char.tempHP > 0 ?
                                                        <ProgressBar style={{ height: "25px" }}
                                                            className={`pb-0 mb-1 ${char.tempHP % 2 === 0 ? "bg-tempHP" : "bg-info"}`}>
                                                            {[...Array(char.tempHP)].map((e, i) =>
                                                                <ProgressBar className="skew" variant={i % 2 === 0 ? "info" : "info2"} now={100 / char.tempHP} key={i} />
                                                            )}
                                                            <div className="position-absolute bar-lable h5 p-0 text-light fill-available">
                                                                <FontAwesomeIcon icon={faShield} /> {char.tempHP}
                                                            </div>
                                                        </ProgressBar>
                                                        : null}
                                                    <ProgressBar style={{ height: "25px", backgroundColor: "#9b9b9b" }} className="p-0">
                                                        <ProgressBar variant={getHealthColor(char.hp, char.maxHP)} now={getHealthPerc(char.hp, char.maxHP)} key={1} />
                                                        <div className="position-absolute bar-lable h5 p-0 mt-0 text-light fill-available">
                                                            <FontAwesomeIcon fade={getHealthBeat(char.hp, char.maxHP)} icon={char.hp <= 0 ? faSkull : getHealthPerc(char.hp, char.maxHP) <= 10 ? faHeartBroken : faHeart} /> {char.hp}/{char.maxHP}
                                                        </div>
                                                    </ProgressBar>
                                                </Row>
                                            </div>
                                        ))}
                                    </Col>
                                    : null}
                            </Row>
                    }
                </Container>
                :
                <Container className="py-5 text-center">
                    <Row>
                        <Col></Col>
                        <Col xl={8}>
                            <h1 className="mb-5">Board</h1>
                            {
                                alert !== "" ?
                                    <div className="alert alert-danger">
                                        {alert}
                                    </div>
                                    :
                                    null
                            }
                            <Form onSubmit={handleSubmit} className="mb-3">
                                <Form.Group className="mb-3">
                                    <Form.Label>Code</Form.Label>
                                    <Form.Control className="text-center" type="text" name="email" maxLength="4" id="emailInput" value={formCode} onChange={handleInputChange} />
                                </Form.Group>
                                <div className="d-grid gap-2">
                                    <Button variant="primary" type="submit">
                                        Show
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
            }
        </div>
    )
}
