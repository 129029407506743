import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { sendRequestAuth } from '../../services/apiFunctions';

export default function ChangeSpellStats({ show, char, handleClose, reload }) {

    const [formData, setFormData] = useState({});

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                reload();
                handleClose();
            } else {
                console.log(data.data.message);
            }
        },
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const request = {
            name: "editSpellStats",
            param: {
                charID: parseInt(char.id),
                spellAbility: formData.spellAbility
            }
        };

        //console.log(request);
        mutate(request);
    }

    useEffect(() => {
        setFormData(values => ({ ...values, "spellAbility": char.spellAbility }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);


    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Change Spell Stats</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-start'>
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" />
                            :
                            null
                    }
                    <Row>
                        <Col>
                            <Form.Group className="my-3" controlId="name">
                                <Form.Label>Spellcasting Ability</Form.Label>
                                <Form.Select name="spellAbility" defaultValue={char.spellAbility} onChange={handleChange}>
                                    <option value="strength">Strength</option>
                                    <option value="dexterity">Dexterity</option>
                                    <option value="constitution">Constitution</option>
                                    <option value="intelligence">Intelligence</option>
                                    <option value="wisdom">Wisdom</option>
                                    <option value="charisma">Charisma</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="outline-primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
