import React, { useEffect, useState } from 'react'
import { Button, Col, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { sendRequestAuth } from '../../services/apiFunctions';

export default function ChangeSpeed({ show, char, handleClose, reload }) {

    const [formData, setFormData] = useState({});
    const [hover, setHover] = useState({});

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                reload();
                handleClose();
            } else {
                console.log(data.data.message);
            }
        },
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const request = {
            name: "editSpeed",
            param: {
                charID: parseInt(char.id),
                speed: parseInt(formData.speed),
                flySpeed: parseInt(formData.flySpeed),
                climbSpeed: parseInt(formData.climbSpeed),
                swimSpeed: parseInt(formData.swimSpeed),
                hover: hover
            }
        };

        //console.log(request);
        mutate(request);
    }

    useEffect(() => {
        setHover(char.hover === 1 ? true : false)
        setFormData(values => ({ ...values, "speed": char.speed }))
        setFormData(values => ({ ...values, "flySpeed": char.flySpeed }))
        setFormData(values => ({ ...values, "climbSpeed": char.climbSpeed }))
        setFormData(values => ({ ...values, "swimSpeed": char.swimSpeed }))
        setFormData(values => ({ ...values, "hover": char.hover }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);


    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Change Speed</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-start'>
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" />
                            :
                            null
                    }
                    <Row>
                        <Col xs={4}>
                            <Form.Group className="my-3" controlId="name">
                                <Form.Label>Walk</Form.Label>
                                <Form.Control type="number" name="speed" step="5" defaultValue={char.speed} onChange={(e) => handleChange(e)} required />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="my-3" controlId="name">
                                <Form.Label>Fly</Form.Label>
                                <InputGroup className="mb-3">
                                    <Button
                                        variant={hover ? 'outline-secondary' : 'secondary'}
                                        checked={!hover}
                                        onClick={() => { setHover(false) }}>
                                        Fly
                                    </Button>
                                    <Button
                                        variant={!hover ? 'outline-secondary' : 'secondary'}
                                        checked={hover}
                                        onClick={() => { setHover(true) }}>
                                        Hover
                                    </Button>
                                    <Form.Control type="number" name="flySpeed" step="5" defaultValue={char.flySpeed} onChange={(e) => handleChange(e)} required />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="my-3" controlId="name">
                                <Form.Label>Climb</Form.Label>
                                <Form.Control type="number" name="climbSpeed" step="5" defaultValue={char.climbSpeed} onChange={(e) => handleChange(e)} required />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="my-3" controlId="name">
                                <Form.Label>Swim</Form.Label>
                                <Form.Control type="number" name="swimSpeed" step="5" defaultValue={char.swimSpeed} onChange={(e) => handleChange(e)} required />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="outline-primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
