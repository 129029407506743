import { faPlus, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { getModifierOfScore, getSpellLevelString } from '../../services/apiFunctions';
import AddSpell from '../charSheetModals/AddSpell';
import ChangeSpellSlots from '../charSheetModals/ChangeSpellSlots';
import ChangeSpellStats from '../charSheetModals/ChangeSpellStats';
import ShowSpell from '../ShowSpell';

export default function Spells({ char, reload, spells }) {

    const [spellAbility, setSpellAbility] = useState("");
    const [spell, setSpell] = useState("");
    const [spellSlots, setSpellSlots] = useState({ level: 0, spellSlots: 0, maxSpellSlots: 0 });

    const [showSpellStats, setShowSpellStats] = useState(false);
    const handleCloseSpellStats = () => setShowSpellStats(false);

    const [showSpell, setShowSpell] = useState(false);
    const handleCloseSpell = () => setShowSpell(false);

    const [showAddSpell, setShowAddSpell] = useState(false);
    const handleCloseAddSpell = () => setShowAddSpell(false);

    const [showSpellSlots, setShowSpellSlots] = useState(false);
    const handleCloseSpellSlots = () => setShowSpellSlots(false);

    useEffect(() => {
        if (char.spellAbility === "strength") {
            setSpellAbility("STR");
        } else if (char.spellAbility === "dexterity") {
            setSpellAbility("DEX");
        } else if (char.spellAbility === "constitution") {
            setSpellAbility("CON");
        } else if (char.spellAbility === "intelligence") {
            setSpellAbility("INT");
        } else if (char.spellAbility === "wisdom") {
            setSpellAbility("WIS");
        } else if (char.spellAbility === "charisma") {
            setSpellAbility("CHA");
        } else {
            setSpellAbility("");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    const spellLevel = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

    function getSpellSlot(level) {
        if (level === 1) {
            return char.spellSlot1;
        } else if (level === 2) {
            return char.spellSlot2;
        } else if (level === 3) {
            return char.spellSlot3;
        } else if (level === 4) {
            return char.spellSlot4;
        } else if (level === 5) {
            return char.spellSlot5;
        } else if (level === 6) {
            return char.spellSlot6;
        } else if (level === 7) {
            return char.spellSlot7;
        } else if (level === 8) {
            return char.spellSlot8;
        } else if (level === 9) {
            return char.spellSlot9;
        } else {
            return 99;
        }
    }

    function getSpellSlotMax(level) {
        if (level === 1) {
            return char.spellSlotMax1;
        } else if (level === 2) {
            return char.spellSlotMax2;
        } else if (level === 3) {
            return char.spellSlotMax3;
        } else if (level === 4) {
            return char.spellSlotMax4;
        } else if (level === 5) {
            return char.spellSlotMax5;
        } else if (level === 6) {
            return char.spellSlotMax6;
        } else if (level === 7) {
            return char.spellSlotMax7;
        } else if (level === 8) {
            return char.spellSlotMax8;
        } else if (level === 9) {
            return char.spellSlotMax9;
        } else {
            return 99;
        }
    }

    return (
        <div>
            <Row>
                <Col>
                    <div className='text-primary mb-1 alert alert-light bg-white-tp cursor-pointer' onClick={() => { setShowSpellStats(true); }}>
                        <Row className='mb-1'>
                            <Col>Spellcasting Ability</Col>
                            <Col>Spell Attack Bonus</Col>
                            <Col>Spell Save DC</Col>
                        </Row>
                        <Row className='h4'>
                            <Col>{spellAbility}</Col>
                            <Col>+{(char.proficiencyBonus + char.proficiencyBonusMod) + getModifierOfScore(char, char.spellAbility)}</Col>
                            <Col>{8 + (char.proficiencyBonus + char.proficiencyBonusMod) + getModifierOfScore(char, char.spellAbility)} </Col>
                        </Row>

                        <Row className='mb-1'>
                            {char.cantripsKnown > 0 ?
                                <Col>Cantrips Known</Col>
                                : null}
                            {char.spellsKnown > 0 ?
                                <Col>Spells Known</Col>
                                : null}
                        </Row>
                        <Row className='h4'>
                            {char.cantripsKnown > 0 ?
                                <Col>{char.cantripsKnown}</Col>
                                : null}
                            {char.spellsKnown > 0 ?
                                <Col>{char.spellsKnown} </Col>
                                : null}
                        </Row>

                    </div>
                </Col>
            </Row>
            <hr className="text-primary" />
            {spellLevel.map((level) => (
                <div key={level}>
                    {char["spellSlotMax" + level] > 0 || spells.filter(spell => spell.level === level).length > 0 ?
                        <div>
                            {level === 0 ?
                                <h5 className="text-primary mb-3 mt-3">
                                    Cantrips
                                </h5>
                                :
                                <h5 className="text-primary mb-3 mt-3 cursor-pointer" onClick={() => { setSpellSlots({ level: level, spellSlots: getSpellSlot(level), maxSpellSlots: getSpellSlotMax(level) }); setShowSpellSlots(true); }}>
                                    {getSpellLevelString(level)} spells - {getSpellSlot(level)}/{getSpellSlotMax(level)}
                                </h5>
                            }
                        </div>
                        : null}
                    {spells.filter(spell => spell.level === level).map((spell) => (
                        <Row key={spell.id}>
                            <Col>
                                <div className='text-primary alert alert-light bg-white-tp cursor-pointer' onClick={() => { setSpell(spell); setShowSpell(true); }}>
                                    <Row className=''>
                                        <Col className="" >
                                            <h4 className="py-1 mb-0">{spell.name}</h4>
                                        </Col>
                                        {spell.prepared ?
                                            <Col className="d-flex col-auto align-items-center">
                                                <h4 className="py-1 mb-0"><FontAwesomeIcon icon={faStar} /></h4>
                                            </Col>
                                            : null}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    ))}
                    {char["spellSlotMax" + level] > 0 || spells.filter(spell => spell.level === level).length > 0 ?
                        <hr className="text-primary" />
                        : null}
                </div>
            ))}
            <div className="d-grid gap-2 mt-3">
                <Button variant="outline-primary" onClick={() => { setShowAddSpell(true); }}>
                    <FontAwesomeIcon icon={faPlus} /> Spell
                </Button>
            </div>
            <ChangeSpellStats show={showSpellStats} handleClose={handleCloseSpellStats} char={char} reload={reload} />
            <ShowSpell show={showSpell} handleClose={handleCloseSpell} spell={spell} charID={char.id} reload={reload} />
            <AddSpell show={showAddSpell} handleClose={handleCloseAddSpell} char={char} reload={reload} charSpells={spells} />
            <ChangeSpellSlots show={showSpellSlots} handleClose={handleCloseSpellSlots} charID={char.id} spellSlots={spellSlots} reload={reload} />
        </div >
    )
}
