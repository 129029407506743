import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap'
import useDocumentTitle, { logout, sendRequestAuth } from '../services/apiFunctions'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPlus, faHatWizard } from '@fortawesome/free-solid-svg-icons'
import NewGame from '../parts/NewGame'

export default function Games() {

    useDocumentTitle("Games");

    const [data, setData] = useState("");

    const [showNew, setShowNew] = useState(false);

    const handleCloseNew = (load = false) => {
        if (load) {
            const request = { name: "getGames", param: {} };
            mutate(request);
        }
        setShowNew(false)
    };
    const handleShowNew = () => setShowNew(true);

    const navigate = useNavigate()

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            setData("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setData(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setData(data.data.message);
            }
        },
    });

    useEffect(() => {
        const request = { name: "getGames", param: {} };
        mutate(request);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleClick(masterID, charID, gameID) {
        localStorage.setItem("charID", charID);
        localStorage.setItem("gameID", gameID);
        localStorage.setItem("previous", "games");

        if (masterID === parseInt(localStorage.getItem("userID"))) {
            navigate("/gameControl");
        } else {
            navigate("/characterSheet");
        }
    }



    return (
        <Container className="py-4 text-center">
            <Row>
                <Col></Col>
                <Col xl={8} className="position-relative">
                    <div className="position-absolute top-0 start-0 ps-3">
                        <Link to="/home" className='btn btn-outline-primary text-left'><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    </div>
                    <div className="position-absolute top-0 end-0 pe-3">
                        <Button variant="outline-primary" onClick={handleShowNew}><FontAwesomeIcon icon={faPlus} /></Button>
                    </div>
                    <h2 className='mb-4'>Your Games</h2>
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" /> : null
                    }
                    {data ? data.length > 0 ? data.map(game => (
                        <div className='alert alert-light text-primary bg-white-tp p-1 cursor-pointer ps-3' key={game.id} onClick={() => handleClick(game.masterID, game.charID, game.id)}>
                            <Row>
                                {game.masterID === parseInt(localStorage.getItem("userID")) ?
                                    <Col className="col-auto my-2">
                                        <h4><FontAwesomeIcon icon={faHatWizard} /></h4>
                                    </Col>
                                    :
                                    null
                                }
                                <Col className="col text-start my-2">
                                    <h4>{game.name}</h4>
                                </Col>
                                <Col xs="2" className="text-end my-2 me-2">
                                    {game.code}
                                </Col>
                            </Row>
                        </div>
                    )) : <i>Join or create a game by clicking on the <b>+</b></i> : null}
                </Col>
                <Col></Col>
            </Row>
            <NewGame show={showNew} handleClose={handleCloseNew} />
        </Container>
    )
}
