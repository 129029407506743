import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom'
import useDocumentTitle, { logout, sendRequestAuth } from '../services/apiFunctions';

export default function Settings() {

    useDocumentTitle("Settings");

    const [alert, setAlert] = useState("");
    const [alertColor, setAlertColor] = useState("danger");

    const [oldPass, setOldPass] = useState("");
    const [pass, setPass] = useState("");
    const [pass2, setPass2] = useState("");

    const [dataSources, setDataSources] = useState()

    const navigate = useNavigate()

    function handleLogout() {
        logout();
        navigate("/");
    }

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            setAlert("An error occured while fetching data.");
            setAlertColor("danger");
        },
        onSuccess: (data) => {
            //console.log(data.data.result)
            if (data.data.status === 200) {
                setAlert("Changed password successfully");
                setAlertColor("success");
            } else {
                setAlert(data.data.message);
                setAlertColor("danger");
            }
        },
    });

    const handleChangePassword = (e) => {
        e.preventDefault();
        if (pass === pass2) {
            const request = {
                name: "changePassword",
                param: {
                    oldPass: oldPass,
                    pass: pass
                }
            };
            //console.log(request)
            mutate(request);
        } else {
            setAlert("Passwords do not match");
            setAlertColor("danger");
        }
        setOldPass("");
        setPass("");
        setPass2("");
    }

    const { mutate: mutateSources, isLoading: isLoadingSources } = useMutation(sendRequestAuth, {
        onError: () => {
            setDataSources("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setDataSources(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setDataSources(data.data.message);
            }
        },
    });

    useEffect(() => {
        const request = { name: "getUserSources", param: {} };
        mutateSources(request);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Container className="py-4 text-center">
                <Row>
                    <Col></Col>
                    <Col xl={8} className="position-relative">
                        <div className="position-absolute top-0 start-0 ps-3">
                            <Link to="/home" className='btn btn-outline-primary text-left'><FontAwesomeIcon icon={faArrowLeft} /></Link>
                        </div>
                        <h2 className="mb-4">Settings</h2>
                        {
                            isLoading ? <Spinner animation="border" /> : null
                        }
                        {
                            alert !== "" ?
                                <div className={"alert alert-" + alertColor}>
                                    {alert}
                                </div>
                                :
                                null
                        }
                        <Form onSubmit={handleChangePassword} className="mb-3">
                            <Form.Group className="mb-3" controlId="oldPass">
                                <Form.Label>Old Password</Form.Label>
                                <Form.Control type="password" name="oldPass" value={oldPass} onChange={(e) => setOldPass(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="pass">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control type="password" name="pass" value={pass} onChange={(e) => setPass(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="pass2">
                                <Form.Label>Repeat new Password</Form.Label>
                                <Form.Control type="password" name="pass2" value={pass2} onChange={(e) => setPass2(e.target.value)} />
                            </Form.Group>
                            <div className="d-grid gap-2">
                                <Button variant="primary" type="submit">
                                    Change Password
                                </Button>
                            </div>
                        </Form>
                        <hr className='my-4'></hr>
                        <b>Activated Sources</b>
                        <div className='mt-2'>
                            {
                                isLoadingSources ? <Spinner animation="border" className="mb-4" /> : null
                            }
                            {
                                dataSources ? dataSources.length === 0 ?
                                    <i className='m-0'>No sources activated</i>
                                    : dataSources.map(source => (
                                        <p className='m-0'>{source.name}</p>
                                    )) : null
                            }
                        </div>
                        <hr className='my-4'></hr>
                        <div className="d-grid gap-2">
                            <Button onClick={handleLogout} variant="outline-primary" size="sm" className=''>Logout</Button>
                        </div>
                        <hr className='my-4'></hr>
                        <p>
                            <b>© AlpakaGameStudios</b><br />
                            Developed by Layla Schmidt
                        </p>
                        <hr className='my-4'></hr>
                        <p>
                            <a className="link-primary" target="_blank" rel="noreferrer" href="https://alpakagamestudios.de/impressum.php">Imprint</a><br />
                            <a className="link-primary" target="_blank" rel="noreferrer" href="https://alpakagamestudios.de/datenschutz.php">Privacy Policy</a>
                        </p>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>

        </div>
    )
}
