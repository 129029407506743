import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { logout, sendRequestAuth, } from '../../services/apiFunctions';

export default function NewFeature({ show, handleClose, charID, feature, reload }) {

    const [deleteQuestion, setDeleteQuestion] = useState(false);

    const [formData, setFormData] = useState({})
    const [alert, setAlert] = useState("");

    const navigate = useNavigate()

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            setAlert("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                reload();
                handleClose();
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setAlert(data.data.message);
            }
        },
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var request;
        if (feature) {
            request = {
                name: "editFeature",
                param: {
                    id: parseInt(feature.id),
                    name: formData.name,
                    description: formData.description
                }
            };
        } else {
            request = {
                name: "newFeature",
                param: {
                    charID: parseInt(charID),
                    name: formData.name,
                    description: formData.description
                }
            };
        }

        //console.log(request.param);
        mutate(request);
    }

    function handleDelete() {
        const request = {
            name: "deleteFeature",
            param: {
                id: feature.id
            }
        };
        mutate(request);
    }

    useEffect(() => {
        setAlert("");
        setDeleteQuestion(false);

        if (feature) {
            setFormData(values => ({ ...values, "name": feature.name }))
            setFormData(values => ({ ...values, "description": feature.description }))
        } else {
            setFormData(values => ({ ...values, "name": "" }))
            setFormData(values => ({ ...values, "description": "" }))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <Modal size="lg" backdrop="static" show={show} onHide={() => handleClose(false)}>
            <Form onSubmit={handleSubmit} className="mb-3">
                <Modal.Header closeButton>
                    <Modal.Title>{feature ? "Edit " + feature.name : "Add new Feature"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isLoading ? <div className="text-center"><Spinner animation="border" /></div> : null
                    }
                    {
                        alert !== "" ?
                            <div className="alert alert-danger">
                                {alert}
                            </div>
                            :
                            null
                    }
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name="name" defaultValue={feature ? feature.name : null} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows={3} name="description" defaultValue={feature ? feature.description : null} onChange={handleChange} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {feature ? deleteQuestion ?
                        <div className='text-center container-fluid'>
                            <hr />
                            <div>
                                <p>Do you really want to delete this feature?</p>
                                <Button variant="outline-primary me-2" onClick={() => setDeleteQuestion(false)}>
                                    Abort
                                </Button>
                                <Button variant="primary" onClick={handleDelete}>
                                    Delete
                                </Button>
                            </div>
                            <hr />
                        </div>
                        :
                        <Button variant="outline-primary" onClick={() => setDeleteQuestion(true)}>
                            Delete
                        </Button>
                        : null}
                    <Button variant="secondary" onClick={() => handleClose(false)}>
                        Abort
                    </Button>
                    <Button variant="primary" type="submit">
                        Save Feature
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
