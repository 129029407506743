import React from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { sendRequestAuth } from '../../services/apiFunctions';

export default function Rest({ show, char, handleClose, reload }) {


    const { mutate } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                reload(true);
                handleClose();
            } else {
                console.log(data.data.message);
            }
        },
    });

    const handleDoRest = (type) => {
        const request = {
            name: "doRest",
            param: {
                charID: parseInt(char.id),
                type: type
            }
        };

        //console.log(request);
        mutate(request);
    }

    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Rest</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-start'>
                    <Row>
                        <Col>
                            <div className="d-grid gap-2">
                                <Button variant="outline-primary" onClick={() => handleDoRest("short")}>
                                    Short Rest
                                </Button>
                            </div>
                        </Col>
                        <Col>
                            <div className="d-grid gap-2">
                                <Button variant="outline-primary" onClick={() => handleDoRest("long")}>
                                    Long Rest
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
