import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './pages/Login'
import Chars from './pages/Chars'
import Register from './pages/Register'
import Home from './pages/Home'
import Monsters from './pages/Monsters'
import Games from './pages/Games'
import GameControl from './pages/GameControl'
import CharacterSheet from './pages/CharacterSheet'
import Board from './pages/Board'
import Settings from './pages/Settings'
import SpellList from './pages/SpellList'
import Items from './pages/Items'
import CharBuilder from './pages/CharBuilder'
import Backgrounds from './pages/Backgrounds'
import Classes from './pages/Classes'
import Ancestries from './pages/Ancestries'
import Feats from './pages/Feats'

export default function App() {

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/chars" element={<Chars />} />
        <Route exact path="/charBuilder" element={<CharBuilder />} />
        <Route exact path="/monsters" element={<Monsters />} />
        <Route exact path="/spells" element={<SpellList />} />
        <Route exact path="/items" element={<Items />} />
        <Route exact path="/feats" element={<Feats />} />
        <Route exact path="/backgrounds" element={<Backgrounds />} />
        <Route exact path="/classes" element={<Classes />} />
        <Route exact path="/ancestries" element={<Ancestries />} />
        <Route exact path="/games" element={<Games />} />
        <Route exact path="/gameControl" element={<GameControl />} />
        <Route exact path="/characterSheet" element={<CharacterSheet />} />
        <Route exact path="/board" element={<Board />} />
        <Route exact path="/board/:gameCode" element={<Board />} />
      </Routes>
    </Router>
  )
}
