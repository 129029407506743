import { faPlus, faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { logout, sendRequestAuth } from '../services/apiFunctions';

export default function NewGame({ show, handleClose }) {

    const [formData, setFormData] = useState({})
    const [charData, setCharData] = useState()
    const [alert, setAlert] = useState("");
    const [selection, setSelection] = useState(0)

    const navigate = useNavigate()

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            setAlert("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                handleClose(true);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setAlert(data.data.message);
            }
        },
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    const handleSubmitCreate = (e) => {
        e.preventDefault();

        if (formData.password !== "") {
            const request = {
                name: "createGame",
                param: {
                    name: formData.name,
                    password: formData.password
                }
            };
            mutate(request);
        } else {
            setAlert("Password can't be empty!");
        }
    }

    const handleSubmitJoin = (e) => {
        e.preventDefault();

        if (formData.charID !== "---") {
            const request = {
                name: "joinGame",
                param: {
                    code: formData.code.toUpperCase(),
                    password: formData.password,
                    charID: formData.charID
                }
            };

            mutate(request);
        } else {
            setAlert("No character selected!");
        }
    }

    useEffect(() => {
        setSelection(0);
        setAlert("");
        setFormData({});
        const request = { name: "getChars", param: {} };
        mutateChars(request);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const { mutate: mutateChars, isLoading: isLoadingChars } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setCharData(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    return (
        <Modal size="lg" backdrop="static" show={show} onHide={handleClose}>
            <Form className="mb-3">
                <Modal.Header closeButton>
                    {
                        selection === 0 ?
                            <Modal.Title>New Game</Modal.Title>
                            :
                            selection === 1 ?
                                <Modal.Title>Join Game</Modal.Title>
                                :
                                <Modal.Title>Create Game</Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>
                    {
                        isLoading ? <div className="text-center"><Spinner animation="border" /></div> : null
                    }
                    {
                        alert !== "" ?
                            <div className="alert alert-danger">
                                {alert}
                            </div>
                            :
                            null
                    }
                    {
                        selection === 0 ?
                            <div className="text-center">
                                <Button variant="outline-primary m-3 p-5" onClick={() => setSelection(1)}>
                                    <h1><FontAwesomeIcon icon={faRightToBracket} /></h1>
                                    Join Game
                                </Button>
                                <Button variant="outline-primary m-3 p-5" onClick={() => setSelection(2)}>
                                    <h1><FontAwesomeIcon icon={faPlus} /></h1>
                                    Create Game
                                </Button>
                            </div>
                            :
                            selection === 1 ?
                                <div>
                                    <Form.Group className="mb-3" controlId="code">
                                        <Form.Label>Code</Form.Label>
                                        <Form.Control type="text" name="code" maxlength="4" onChange={handleChange} required />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" name="password" onChange={handleChange} required />
                                    </Form.Group>
                                    {
                                        isLoadingChars ?
                                            <Spinner animation="border" className="mb-4" />
                                            :
                                            <Form.Group className="mb-3" controlId="charID">
                                                <Form.Label>Assign Character</Form.Label>
                                                <Form.Select name="charID" onChange={handleChange} required>
                                                    <option value="---">---</option>
                                                    {charData ? charData.filter(char => char.gameID === 0).map(char => (
                                                        <option key={char.id} value={char.id}>{char.name}</option>
                                                    )) : null}
                                                </Form.Select>
                                            </Form.Group>
                                    }
                                </div>
                                :
                                <div>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" name="name" onChange={handleChange} required />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" name="password" onChange={handleChange} required />
                                    </Form.Group>
                                </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose(false)}>
                        Close
                    </Button>
                    {
                        selection === 0 ?
                            null
                            :
                            selection === 1 ?
                                <Button type="submit" variant="primary" onClick={handleSubmitJoin}>
                                    Join
                                </Button>
                                :
                                <Button type="submit" variant="primary" onClick={handleSubmitCreate}>
                                    Create
                                </Button>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
