import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import useDocumentTitle, { getSpellLevelString, logout, sendRequestAuth } from '../services/apiFunctions'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons'
import NewSpell from '../parts/NewSpell'
import ShowSpell from '../parts/ShowSpell'

export default function SpellList() {

    useDocumentTitle("Spells");

    const [dataSpells, setDataSpells] = useState("")
    const [search, setSearch] = useState("")
    const [classSelect, setClassSelect] = useState("All")
    const [levelSelect, setLevelSelect] = useState("All")

    const classNames = ["Artificer", "Bard", "Cleric", "Druid", "Paladin", "Ranger", "Sorcerer", "Warlock", "Wizard"]


    const [spell, setSpell] = useState()

    const [showSpell, setShowSpell] = useState(false);
    const [showSpellEdit, setShowSpellEdit] = useState(false);

    function reload() {
        const request = { name: "getUserSpells", param: {} };
        mutate(request);
    }

    const handleCloseSpell = () => {
        setShowSpell(false);
    };

    const handleCloseSpellEdit = () => {
        setShowSpellEdit(false)
    };

    const navigate = useNavigate()

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            setDataSpells("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setDataSpells(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setDataSpells(data.data.message);
            }
        },
    });

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    useEffect(() => {
        const request = { name: "getUserSpells", param: {} };
        mutate(request);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleSpellEditClick(spell) {
        setSpell(spell);
        if (parseInt(localStorage.getItem('userID')) === spell.userID) {
            setShowSpellEdit(true)
        }
    }

    function handleSpellShowClick(spell) {
        setSpell(spell);
        setShowSpell(true)
    }

    return (
        <Container className="py-4 text-center">
            <Row>
                <Col></Col>
                <Col xl={8} className="position-relative">
                    <div className="position-absolute top-0 start-0 ps-3">
                        <Link to="/home" className='btn btn-outline-primary text-left'><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    </div>
                    <div className="position-absolute top-0 end-0 pe-3">
                        <Button variant="outline-primary" onClick={() => { setSpell(null); setShowSpellEdit(true); }}><FontAwesomeIcon icon={faPlus} /></Button>
                    </div>
                    <h2 className='mb-4'>Your Spells</h2>
                    {dataSpells ? dataSpells.length > 0 ?
                        <div className="d-grid gap-2">
                            <Form.Group className='mb-4'>
                                <Form.Control type="text" name="search" id="search" placeholder="Search..." onChange={handleSearch} />
                                <Form.Select className="mt-1" name="className" defaultValue="All" onChange={(e) => setClassSelect(e.target.value)}>
                                    <option value="All">All Classes</option>
                                    {classNames.map(className => (
                                        <option key={className} value={className}>{className}</option>
                                    ))}
                                    <option value="Fighter">Fighter - Eldritch Knight</option>
                                    <option value="Rogue">Rogue - Arcane Trickster</option>
                                </Form.Select>
                                <Form.Select className="mt-1" name="className" defaultValue="All" onChange={(e) => setLevelSelect(e.target.value)}>
                                    <option value="All">All Levels</option>
                                    <option value="0">Cantrips</option>
                                    {[...Array(9)].map((level, index) => (
                                        <option key={index} value={index + 1}>{getSpellLevelString(index + 1)}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </div>
                        : null : null}
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" /> : null
                    }
                    {dataSpells ? dataSpells.length > 0 ? dataSpells.filter(spell =>
                        (spell.name.toLowerCase().includes(search.toLowerCase()) || spell.source.toLowerCase().includes(search.toLowerCase())) &&
                        (classSelect === "All" || spell.classes.includes(classSelect)) && (levelSelect === "All" || spell.level === parseInt(levelSelect))).map(d => (
                            <div className={d.public === 0 ? 'alert alert-light text-primary bg-white-tp p-1 cursor-pointer' : 'alert alert-light text-primary p-1 cursor-pointer'}
                                key={d.id}>
                                <Row>
                                    <Col className="text-start py-2 ms-2 pe-0" onClick={() => handleSpellShowClick(d)}>
                                        <h4>{d.name}</h4>
                                    </Col>
                                    <Col className="text-end py-2 me-2 ps-0" onClick={() => handleSpellShowClick(d)}>
                                        <i>
                                            {
                                                d.level === 0 ?
                                                    "Cantrip"
                                                    :
                                                    getSpellLevelString(d.level) + " " + d.school.toLowerCase()
                                            }
                                        </i>
                                    </Col>
                                    {parseInt(localStorage.getItem('userID')) === d.userID ?
                                        <Col className="d-flex col-auto align-items-center">
                                            <Button className="me-4" variant="outline-primary" onClick={() => { handleSpellEditClick(d); }}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                        </Col>
                                        : null}
                                </Row>

                            </div>
                        )) : <i>Create a spell by clicking on the <b>+</b></i> : null}
                </Col>
                <Col></Col>
            </Row>
            <NewSpell show={showSpellEdit} handleClose={handleCloseSpellEdit} spell={spell} reload={reload} />
            <ShowSpell show={showSpell} handleClose={handleCloseSpell} spell={spell} removeButton={false} />
            {/*
            <NewMonster show={showNew} handleClose={handleCloseNew} />
            <MonsterCard show={show} monsterID={monsterID} charID={0} charType={"monster"} handleClose={handleClose} />*/}
        </Container>
    )
}
