import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { getModifier } from '../../services/apiFunctions';
import ChangeAbilityScores from '../charSheetModals/ChangeAbilityScores';
import ChangeSkill from '../charSheetModals/ChangeSkill';

export default function Stats({ char, reload }) {
    const [abilityName, setAbilityName] = useState("");
    const [abilityDispName, setAbilityDispName] = useState("");
    const [proficiency, setProficiency] = useState();

    const [showStat, setShowStat] = useState(false);
    const handleCloseStat = () => setShowStat(false);

    const [showSkill, setShowSkill] = useState(false);
    const handleCloseSkill = () => setShowSkill(false);

    const abilities = [
        {
            name: "strength",
            dispName: "Strength",
            value: char.strength,
            proficiency: char.strengthProf,
            skills: [
                {
                    name: "athletics",
                    dispName: "Athletics",
                    proficiency: char.athleticsProf
                }
            ]
        },
        {
            name: "constitution",
            dispName: "Constitution",
            value: char.constitution,
            proficiency: char.constitutionProf,
            skills: [
            ]
        },
        {
            name: "dexterity",
            dispName: "Dexterity",
            value: char.dexterity,
            proficiency: char.dexterityProf,
            skills: [
                {
                    name: "acrobatics",
                    dispName: "Acrobatics",
                    proficiency: char.acrobaticsProf
                },
                {
                    name: "sleightOfHand",
                    dispName: "Sleight of Hand",
                    proficiency: char.sleightOfHandProf
                },
                {
                    name: "stealth",
                    dispName: "Stealth",
                    proficiency: char.stealthProf
                }
            ]
        },
        {
            name: "intelligence",
            dispName: "Intelligence",
            value: char.intelligence,
            proficiency: char.intelligenceProf,
            skills: [
                {
                    name: "arcana",
                    dispName: "Arcana",
                    proficiency: char.arcanaProf
                },
                {
                    name: "history",
                    dispName: "History",
                    proficiency: char.historyProf
                },
                {
                    name: "investigation",
                    dispName: "Investigation",
                    proficiency: char.investigationProf
                },
                {
                    name: "nature",
                    dispName: "Nature",
                    proficiency: char.natureProf
                },
                {
                    name: "religion",
                    dispName: "Religion",
                    proficiency: char.religionProf
                }
            ]
        },
        {
            name: "wisdom",
            dispName: "Wisdom",
            value: char.wisdom,
            proficiency: char.wisdomProf,
            skills: [
                {
                    name: "animalHandling",
                    dispName: "Animal Handling",
                    proficiency: char.animalHandlingProf
                },
                {
                    name: "insight",
                    dispName: "Insight",
                    proficiency: char.insightProf
                },
                {
                    name: "medicine",
                    dispName: "Medicine",
                    proficiency: char.medicineProf
                },
                {
                    name: "perception",
                    dispName: "Perception",
                    proficiency: char.perceptionProf
                },
                {
                    name: "survival",
                    dispName: "Survival",
                    proficiency: char.survivalProf
                }
            ]
        },
        {
            name: "charisma",
            dispName: "Charisma",
            value: char.charisma,
            proficiency: char.charismaProf,
            skills: [
                {
                    name: "deception",
                    dispName: "Deception",
                    proficiency: char.deceptionProf
                },
                {
                    name: "intimidation",
                    dispName: "Intimidation",
                    proficiency: char.intimidationProf
                },
                {
                    name: "performance",
                    dispName: "Performance",
                    proficiency: char.performanceProf
                },
                {
                    name: "persuasion",
                    dispName: "Persuasion",
                    proficiency: char.persuasionProf
                }
            ]
        }
    ]

    return (
        <div>
            <Row className="px-2">
                {abilities.map(ability => (
                    <Col xs={6} key={ability.name} className="px-1" style={{ marginTop: ability.name === "intelligence" ? "margin-top: -2.5em" : "" }}>
                        <div className='alert alert-light text-primary bg-white-tp px-0 '>
                            <div onClick={() => { setAbilityName(ability.name); setShowStat(true); }} className='cursor-pointer mb-2'>
                                <h3 className='mb-3'>{ability.dispName}</h3>

                                <Row className='px-2 mb-1'>
                                    <Col className='p-0'>Value</Col>
                                    <Col className='p-0'>Mod</Col>
                                    <Col className='p-0'>Save</Col>
                                </Row>
                                <Row className='h4 px-2'>
                                    <Col>{ability.value}</Col>
                                    <Col>{getModifier(ability.value, true)}</Col>
                                    <Col>
                                        {getModifier(ability.value, false) + (char.proficiencyBonus + char.proficiencyBonusMod) * ability.proficiency > 0 ? "+" : null}
                                        {getModifier(ability.value, false) + (char.proficiencyBonus + char.proficiencyBonusMod) * ability.proficiency}
                                    </Col>
                                </Row>
                            </div>
                            <hr className='text-primary' />
                            {ability.skills.map(skill => (
                                <Row className={`py-2 mx-0 cursor-pointer ${skill.proficiency > 0 ? 'bg-dark-tp' : null}`} onClick={() => { setAbilityName(skill.name); setAbilityDispName(skill.dispName); setProficiency(skill.proficiency); setShowSkill(true); }} key={skill.name}>
                                    <Col className='text-start pe-0'>{skill.dispName}</Col>
                                    <Col className='text-end ps-0'>
                                        <b>
                                            {getModifier(ability.value, false) + (char.proficiencyBonus + char.proficiencyBonusMod) * skill.proficiency > 0 ? "+" : null}
                                            {getModifier(ability.value, false) + (char.proficiencyBonus + char.proficiencyBonusMod) * skill.proficiency}
                                        </b>
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    </Col>
                ))}
            </Row>
            <ChangeAbilityScores show={showStat} handleClose={handleCloseStat} char={char} reload={reload} abilityName={abilityName} />
            <ChangeSkill show={showSkill} handleClose={handleCloseSkill} proficiency={proficiency} char={char} reload={reload} skillName={abilityName} abilityDispName={abilityDispName} />
        </div >
    )
}
