import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Form, Modal, Spinner, ToggleButton } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { sendRequestAuth } from '../../services/apiFunctions';

export default function ChangeSkill({ show, char, proficiency, handleClose, skillName, abilityDispName, reload }) {

    const [newProficiency, setNewProficient] = useState();

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                reload();
                handleClose();
            } else {
                console.log(data.data.message);
            }
        },
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        const request = {
            name: "editSkill",
            param: {
                charID: parseInt(char.id),
                name: skillName,
                proficiency: parseInt(newProficiency)
            }
        };
        //console.log(request)
        mutate(request);
    }

    useEffect(() => {
        setNewProficient(proficiency);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);


    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Change proficiency of {abilityDispName}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" />
                            :
                            null
                    }
                    <Form.Group className="my-3" controlId="name">
                        <ButtonGroup>
                            <ToggleButton
                                type="radio"
                                variant={'outline-primary'}
                                checked={newProficiency === 0}
                                onClick={(e) => { setNewProficient(0) }}
                            >
                                Non Proficient
                            </ToggleButton>
                            <ToggleButton
                                type="radio"
                                variant={'outline-primary'}
                                checked={newProficiency === 1}
                                onClick={(e) => { setNewProficient(1) }}
                            >
                                Proficient
                            </ToggleButton>
                            <ToggleButton
                                type="radio"
                                variant={'outline-primary'}
                                checked={newProficiency === 2}
                                onClick={(e) => { setNewProficient(2) }}
                            >
                                Expertise
                            </ToggleButton>
                        </ButtonGroup>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="outline-primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
