import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useDocumentTitle from '../services/apiFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAppleWhole, faDice, faDisplay, faDna, faFileLines, faFire, faGear, faGhost, faShieldHalved, faUserNinja, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';

export default function Home() {

  useDocumentTitle("");

  return (
    <div>
      <Container className="py-5 text-center">
        <Row>
          <Col></Col>
          <Col xl={8}>
            <h1 className="mb-5 text-primary">
              <img width="50px" className="me-2" src="/logo-transparent.png" alt="" />
              <span className='align-middle'><b>DigitalP&P</b></span>
            </h1>
            <div className="d-grid gap-2">
              <Link to="/games" className='btn btn-lg btn-outline-primary'><b><FontAwesomeIcon icon={faDice} /> Games</b></Link>
              <Link to="/chars" className='btn btn-lg btn-outline-primary'><b><FontAwesomeIcon icon={faUserNinja} /> Characters</b></Link>
              <Link to="/monsters" className='btn btn-lg btn-outline-primary mt-4'><b><FontAwesomeIcon icon={faGhost} /> Monsters</b></Link>
              <Link to="/spells" className='btn btn-lg btn-outline-primary'><b><FontAwesomeIcon icon={faWandMagicSparkles} /> Spells</b></Link>
              <Link to="/items" className='btn btn-lg btn-outline-primary'><b><FontAwesomeIcon icon={faAppleWhole} /> Items</b></Link>
              <Link to="/feats" className='btn btn-lg btn-outline-primary'><b><FontAwesomeIcon icon={faFire} /> Feats</b></Link>
              <Link to="/backgrounds" className='btn btn-lg btn-outline-primary mt-4'><b><FontAwesomeIcon icon={faFileLines} /> Backgrounds</b></Link>
              <Link to="/classes" className='btn btn-lg btn-outline-primary'><b><FontAwesomeIcon icon={faShieldHalved} /> Classes</b></Link>
              <Link to="/ancestries" className='btn btn-lg btn-outline-primary'><b><FontAwesomeIcon icon={faDna} /> Ancestries</b></Link>
              <Link to="/board" target="_blank" rel="noopener noreferrer" className='btn btn-lg btn-outline-primary mt-4'><b><FontAwesomeIcon icon={faDisplay} /> Board</b></Link>
              <Link to="/settings" className='btn btn-lg btn-outline-primary'><b><FontAwesomeIcon icon={faGear} /> Settings</b></Link>
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Container>

    </div>
  )
}
