import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { logout, sendRequestAuth, } from '../services/apiFunctions';

export default function NewItem({ show, handleClose, item, reload }) {

    const [deleteQuestion, setDeleteQuestion] = useState(false);

    const [formData, setFormData] = useState({})
    const [alert, setAlert] = useState("");

    const navigate = useNavigate()

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            setAlert("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                reload();
                handleClose();
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setAlert(data.data.message);
            }
        },
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var weightText = parseFloat(formData.weight) === 0 ? null : formData.weight + " " + formData.weightUnit;
        var weightVal = parseFloat(formData.weight) === 0 ? 0 : formData.weightUnit === "lb." ? formData.weight : formData.weight / 16;
        var valueText = parseFloat(formData.value) === 0 ? null : formData.value + " " + formData.valueUnit;

        var request;
        if (item) {
            request = {
                name: "editItem",
                param: {
                    id: parseInt(item.id),
                    name: formData.name,
                    rarity: formData.rarity,
                    type: formData.type,
                    attunement: formData.attunement,
                    properties: formData.properties,
                    weightText: weightText,
                    weightVal: parseFloat(weightVal),
                    valueText: valueText,
                    description: formData.description
                }
            };
        } else {
            request = {
                name: "newItem",
                param: {
                    name: formData.name,
                    rarity: formData.rarity,
                    type: formData.type,
                    attunement: formData.attunement,
                    properties: formData.properties,
                    weightText: weightText,
                    weightVal: parseFloat(weightVal),
                    valueText: valueText,
                    description: formData.description
                }
            };
        }
        //console.log(request.param)
        mutate(request);
    }

    function handleDelete() {
        const request = {
            name: "deleteItem",
            param: {
                id: item.id
            }
        };
        mutate(request);
    }

    useEffect(() => {
        setAlert("");
        setDeleteQuestion(false);

        //console.log("'" + item.valueText + "'");

        if (item) {
            setFormData(values => ({ ...values, "name": item.name }))
            setFormData(values => ({ ...values, "rarity": item.rarity }))
            setFormData(values => ({ ...values, "type": item.type }))
            setFormData(values => ({ ...values, "attunement": item.attunement }))
            setFormData(values => ({ ...values, "properties": item.properties }))
            setFormData(values => ({ ...values, "weight": item.weightText.endsWith("lb.") ? item.weightVal : item.weightVal * 16 }))
            setFormData(values => ({ ...values, "weightUnit": item.weightText !== "" ? item.weightText.substr(item.weightText.length - 3) : "lb." }))
            setFormData(values => ({ ...values, "value": item.valueText !== "" ? parseFloat(item.valueText.slice(0, -3)) : 0 }))
            setFormData(values => ({ ...values, "valueUnit": item.valueText !== "" ? item.valueText.substr(item.valueText.length - 2) : "cp" }))
            setFormData(values => ({ ...values, "description": item.description }))
        } else {
            setFormData(values => ({ ...values, "name": "" }))
            setFormData(values => ({ ...values, "rarity": "" }))
            setFormData(values => ({ ...values, "type": "" }))
            setFormData(values => ({ ...values, "attunement": "" }))
            setFormData(values => ({ ...values, "properties": "" }))
            setFormData(values => ({ ...values, "weight": 0 }))
            setFormData(values => ({ ...values, "weightUnit": "lb." }))
            setFormData(values => ({ ...values, "value": 0 }))
            setFormData(values => ({ ...values, "valueUnit": "cp" }))
            setFormData(values => ({ ...values, "description": "" }))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <Modal size="lg" backdrop="static" show={show} onHide={() => handleClose(false)}>
            <Form onSubmit={handleSubmit} className="mb-3">
                <Modal.Header closeButton>
                    <Modal.Title>{item ? "Edit " + item.name : "Add new Item"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isLoading ? <div className="text-center"><Spinner animation="border" /></div> : null
                    }
                    {
                        alert !== "" ?
                            <div className="alert alert-danger">
                                {alert}
                            </div>
                            :
                            null
                    }
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name="name" defaultValue={item ? item.name : null} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Rarity</Form.Label>
                        <Form.Select name="rarity" defaultValue={item ? item.rarity : "common"} onChange={handleChange}>
                            <option value="none">none</option>
                            <option value="unknown">unknown</option>
                            <option value="unknown (magic)">unknown (magic)</option>
                            <option value="common">common</option>
                            <option value="uncommon">uncommon</option>
                            <option value="rare">rare</option>
                            <option value="very rare">very rare</option>
                            <option value="legendary">legendary</option>
                            <option value="artifact">artifact</option>
                            <option value="varies">varies</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Type</Form.Label>
                        <Form.Control type="text" name="type" placeholder="weapon" defaultValue={item ? item.type : null} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Attunement</Form.Label>
                        <Form.Control type="text" name="attunement" placeholder="requires attunement by a wizard" defaultValue={item ? item.attunement : null} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Properties</Form.Label>
                        <Form.Control type="text" name="properties" placeholder="1d6, bludgeoning" defaultValue={item ? item.properties : null} onChange={handleChange} />
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Weight</Form.Label>
                                <Form.Control type="number" name="weight" step="0.001" defaultValue={item ? item.weightText.endsWith("lb.") ? item.weightVal : item.weightVal * 16 : 0} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Unit</Form.Label>
                                <Form.Select name="weightUnit" defaultValue={item ? item.weightText.substr(item.weightText.length - 3) : "lb."} onChange={handleChange}>
                                    <option value="lb.">lb.</option>
                                    <option value="oz.">oz.</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Value</Form.Label>
                                <Form.Control type="number" name="value" defaultValue={item ? (item.valueText !== "" ? parseFloat(item.valueText.slice(0, -3)) : 0) : 0} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Unit</Form.Label>
                                <Form.Select name="valueUnit" defaultValue={item ? item.valueText.substr(item.valueText.length - 2) : "cp"} onChange={handleChange}>
                                    <option value="cp">cp</option>
                                    <option value="sp">sp</option>
                                    <option value="gp">gp</option>
                                    <option value="pp">pp</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" name="description" placeholder="Description" defaultValue={item ? item.description : null} onChange={handleChange} rows={3} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {item ? deleteQuestion ?
                        <div className='text-center container-fluid'>
                            <hr />
                            <div>
                                <p>Do you really want to delete this item?</p>
                                <Button variant="outline-primary me-2" onClick={() => setDeleteQuestion(false)}>
                                    Abort
                                </Button>
                                <Button variant="primary" onClick={handleDelete}>
                                    Delete
                                </Button>
                            </div>
                            <hr />
                        </div>
                        :
                        <Button variant="outline-primary" onClick={() => setDeleteQuestion(true)}>
                            Delete
                        </Button>
                        : null}
                    <Button variant="secondary" onClick={() => handleClose(false)}>
                        Abort
                    </Button>
                    <Button variant="primary" type="submit">
                        Save Item
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
