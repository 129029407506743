import React from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'

export default function ShowSelectAncestry({ show, handleClose, ancestry, setAncestry, ancestries, setShowAncestry, setShowAncestryDetail, detail = false, showDuplicates }) {

    function handleAncestryShowClick(e) {
        //console.log(detail)

        setAncestry(e);

        if (detail) {
            setShowAncestryDetail(true)
        } else {
            setShowAncestry(true);
        }

        handleClose(false);
    }

    return (
        <Modal size="lg" show={show} onHide={() => handleClose(false)}>
            {ancestry ?
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title>{ancestry.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="line-breaks p-3">
                        <div className='alert alert-light text-primary p-1 cursor-pointer'>
                            <Row>
                                <Col className="text-start py-2 ms-2 pe-0" onClick={() => handleAncestryShowClick(ancestry)}>
                                    <h4>{ancestry.name}</h4>
                                </Col>
                                <Col className="d-flex col-auto align-items-center me-2" onClick={() => handleAncestryShowClick(ancestry)}>
                                    <i>{ancestry.source}</i>
                                </Col>
                            </Row>
                        </div>
                        <hr />
                        {
                            ancestries ? ancestries.filter((element, index) => element.parentID === ancestry.id && (showDuplicates || (index === 0 || ancestries[index - 1].name !== element.name))).map((d, i) => (
                                <>
                                    {i !== 0 ? <hr /> : null}
                                    <div className='alert alert-light text-primary p-1 cursor-pointer' key={d.id}>
                                        <Row>
                                            <Col className="text-start py-2 ms-2 pe-0" onClick={() => handleAncestryShowClick(d)}>
                                                <h4>{d.name}</h4>
                                            </Col>
                                            <Col className="d-flex col-auto align-items-center me-2" onClick={() => handleAncestryShowClick(d)}>
                                                <i>{d.source}</i>
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                            ))
                                : null
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </div>
                : null}
        </Modal>
    )
}
