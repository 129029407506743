import React, { useEffect, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { sendRequestAuth } from '../services/apiFunctions';

export default function ShowBackground({ show, handleClose, background }) {

    const [backgroundData, setBackgroundData] = useState();

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setBackgroundData(data.data.result);
            } else {
                console.log(data.data.message);
            }
        },
    });

    useEffect(() => {
        if (show === true) {
            const request = {
                name: "getBackgroundData",
                param: {
                    id: background.id
                }
            };
            mutate(request);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <Modal size="lg" show={show} onHide={() => handleClose(false)}>
            {background ?
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title>{background.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="line-breaks p-3">
                        {isLoading ?
                            <Spinner animation="border" className="mb-4" />
                            :
                            <div>
                                {backgroundData ?
                                    <p>
                                        {background.skillsChoose !== "" || background.skillsFixed !== "" ?
                                            <span>
                                                <b className='me-1'>Skill Proficiencies:</b>
                                                {backgroundData.skillsFixed.length > 0 ? backgroundData.skillsFixed.map((d, i) => ((i !== 0 ? ", " : "") + d.name)) : null}
                                                {backgroundData.skillsFixed.length > 0 && backgroundData.skillsChoose.length > 0 ? ". And " : null}
                                                {
                                                    backgroundData.skillsChoose.length > 0 ?
                                                        typeof backgroundData.skillsChoose[0] !== "object" ?
                                                            "choose " + background.skillsChooseNumber + " " + backgroundData.skillsChoose.map((d, i) => ((i !== 0 ? ", " : "") + d)) + " skill of your choice"
                                                            :
                                                            "choose " + background.skillsChooseNumber + " out of: " + backgroundData.skillsChoose.map((d, i) => ((i !== 0 ? ", " : "") + d.name))
                                                        : null
                                                }<br />
                                            </span>
                                            : null}

                                        {background.langsChoose !== "" || background.langsChooseNumber > 0 ?
                                            <span>
                                                <b>Languages:</b> {background.langsChoose === "" ? background.langsChooseNumber + " of your choice" : "Choose " + background.langsChooseNumber + " out of " + background.langsChoose.split(";").map((d, i) => ((i !== 0 ? ", " : "") + d))}<br />
                                            </span>
                                            : null}

                                        {backgroundData.toolsFixed.length > 0 || backgroundData.toolsChoose.length > 0 ?
                                            <span>
                                                <b className='me-1'>Tool Proficiencies:</b>
                                                {backgroundData.toolsFixed.length > 0 ? backgroundData.toolsFixed.map((d, i) => ((i !== 0 ? ", " : "") + d.name)) : null}
                                                {backgroundData.toolsFixed.length > 0 && backgroundData.toolsChoose.length > 0 ? ". And " : null}
                                                {backgroundData.toolsChoose.length > 0 ? "choose " + background.toolsChooseNumber + " out of: " + backgroundData.toolsChoose.map((d, i) => ((i !== 0 ? ", " : "") + d.name)) : null}<br />
                                            </span>
                                            : null}

                                        {backgroundData.equipFixed.length > 0 || backgroundData.equipChoose.length > 0 ?
                                            <span>
                                                <b className='me-1'>Equipment:</b>
                                                {backgroundData.equipFixed.length > 0 ? backgroundData.equipFixed.map((d, i) => ((i !== 0 ? ", " : "") + d.name + (d.amount > 1 ? " (x" + d.amount + ")" : ""))) : null}
                                                {backgroundData.equipFixed.length > 0 && backgroundData.equipChoose.length > 0 ? ". And " : null}
                                                {backgroundData.equipChoose.length > 0 ? "choose 1 out of: " + backgroundData.equipChoose.map((d, i) => ((i !== 0 ? ", " : "") + d.name + (d.amount > 1 ? " (x" + d.amount + ")" : ""))) : null}<br />
                                            </span>
                                            : null}

                                        {background.gold > 0 ?
                                            <span>
                                                <b className='me-1'>Coins:</b> {background.gold}gp
                                            </span>
                                            : null}
                                    </p>
                                    : null}

                                <hr />

                                <div className="my-2 fst-italic" dangerouslySetInnerHTML={{ __html: background.description }}>
                                </div>

                                <hr />

                                {backgroundData ?
                                    <div>
                                        {backgroundData.features.map(feature => (
                                            <div>
                                                <h5>{feature.name}</h5>
                                                <div className="my-2" dangerouslySetInnerHTML={{ __html: feature.description }}></div>
                                            </div>
                                        ))}

                                        {backgroundData.characteristics.length > 0 ?
                                            <div>
                                                <hr />

                                                <h5>Suggested Characteristics</h5>
                                                <p>{background.suggestedCharacteristics}</p>

                                                {backgroundData.characteristics.filter(characteristic => characteristic.type === "personalityTrait").length > 0 ?
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <th>d{backgroundData.characteristics.filter(characteristic => characteristic.type === "personalityTrait").length}</th>
                                                                <th>Personality Trait</th>
                                                            </tr>
                                                            {backgroundData.characteristics.filter(characteristic => characteristic.type === "personalityTrait").map((characteristic, index) => (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{characteristic.description}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    : null}

                                                {backgroundData.characteristics.filter(characteristic => characteristic.type === "ideal").length > 0 ?
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <th>d{backgroundData.characteristics.filter(characteristic => characteristic.type === "ideal").length}</th>
                                                                <th>Ideal</th>
                                                            </tr>
                                                            {backgroundData.characteristics.filter(characteristic => characteristic.type === "ideal").map((characteristic, index) => (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{characteristic.description}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    : null}

                                                {backgroundData.characteristics.filter(characteristic => characteristic.type === "bond").length > 0 ?
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <th>d{backgroundData.characteristics.filter(characteristic => characteristic.type === "bond").length}</th>
                                                                <th>Bond</th>
                                                            </tr>
                                                            {backgroundData.characteristics.filter(characteristic => characteristic.type === "bond").map((characteristic, index) => (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{characteristic.description}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    : null}

                                                {backgroundData.characteristics.filter(characteristic => characteristic.type === "flaw").length > 0 ?
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <th>d{backgroundData.characteristics.filter(characteristic => characteristic.type === "flaw").length}</th>
                                                                <th>Flaw</th>
                                                            </tr>
                                                            {backgroundData.characteristics.filter(characteristic => characteristic.type === "flaw").map((characteristic, index) => (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{characteristic.description}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    : null}
                                            </div>
                                            : null}
                                    </div>
                                    : null}

                                <b>Source:</b> {background.source}
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </div>
                : null}
        </Modal>
    )
}
