import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { sendRequestAuth } from '../../services/apiFunctions';

export default function ChangeBackground({ show, char, handleClose, reload }) {

    const [formData, setFormData] = useState({});

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                reload();
                handleClose();
            } else {
                console.log(data.data.message);
            }
        },
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))

        //console.log(name + " " + value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const request = {
            name: "editCharBackground",
            param: {
                charID: parseInt(char.id),
                background: formData.background,
                personalityTrait: formData.personalityTrait,
                ideal: formData.ideal,
                bond: formData.bond,
                flaw: formData.flaw,
                alignment: formData.alignment
            }
        };

        //console.log(request);
        mutate(request);
    }

    useEffect(() => {
        setFormData(values => ({ ...values, "background": char.background }))
        setFormData(values => ({ ...values, "personalityTrait": char.personalityTrait }))
        setFormData(values => ({ ...values, "ideal": char.ideal }))
        setFormData(values => ({ ...values, "bond": char.bond }))
        setFormData(values => ({ ...values, "flaw": char.flaw }))
        setFormData(values => ({ ...values, "alignment": char.alignment }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);


    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Change Background</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-start'>
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" />
                            :
                            null
                    }
                    {/*<Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Background Name</Form.Label>
                                <Form.Control type="text" name="background" defaultValue={char.background} onChange={(e) => handleChange(e)} required />
                            </Form.Group>
                        </Col>
                </Row>*/}
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Personality Trait</Form.Label>
                                <Form.Control as="textarea" rows={3} name="personalityTrait" defaultValue={char.personalityTrait} onChange={(e) => handleChange(e)} required />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Ideal</Form.Label>
                                <Form.Control as="textarea" rows={3} name="ideal" defaultValue={char.ideal} onChange={(e) => handleChange(e)} required />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Bond</Form.Label>
                                <Form.Control as="textarea" rows={3} name="bond" defaultValue={char.bond} onChange={(e) => handleChange(e)} required />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Flaw</Form.Label>
                                <Form.Control as="textarea" rows={3} name="flaw" defaultValue={char.flaw} onChange={(e) => handleChange(e)} required />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Alignment</Form.Label>
                                <Form.Select name="alignment" defaultValue={char.alignment} onChange={(e) => handleChange(e)} required>
                                    <option value=""></option>
                                    <option value="Lawful Good">Lawful Good</option>
                                    <option value="Neutral Good">Neutral Good</option>
                                    <option value="Chaotic Good">Chaotic Good</option>
                                    <option value="Lawful Neutral">Lawful Neutral</option>
                                    <option value="Neutral">Neutral</option>
                                    <option value="Chaotic Neutral">Chaotic Neutral</option>
                                    <option value="Lawful Evil">Lawful Evil</option>
                                    <option value="Neutral Evil">Neutral Evil</option>
                                    <option value="Chaotic Evil">Chaotic Evil</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="outline-primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
