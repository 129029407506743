import React, { useEffect, useState } from 'react'
import ClassDetailSelect from './ClassDetailSelect';
import { useMutation } from 'react-query';
import { logout, sendRequestAuth } from '../../services/apiFunctions';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Col, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import ShowClass from '../ShowClass';

export default function ClassSelect({ selectedClass, setSelectedClass, saveClass, backgroundSkills, backgroundToolProficiencies }) {

  const [dataClasses, setDataClasses] = useState([])
  const [search, setSearch] = useState("")
  const [singleClass, setSingleClass] = useState()

  const [showClass, setShowClass] = useState(false);
  const handleCloseClass = () => {
    setShowClass(false);
  };

  const [showClassDetail, setShowClassDetail] = useState(false);
  const handleCloseClassDetail = () => {
    setSelectedClass(singleClass)
    setShowClassDetail(false);
  };

  const navigate = useNavigate()

  const { mutate, isLoading } = useMutation(sendRequestAuth, {
    onError: () => {
      setDataClasses("An error occured while fetching data.");
    },
    onSuccess: (data) => {
      //console.log(data.data.result);
      if (data.data.status === 200) {
        setDataClasses(data.data.result);
      } else if (data.data.status === 302) {
        logout();
        navigate("/");
      } else {
        setDataClasses(data.data.message);
      }
    },
  });

  const handleSearch = (e) => {
    setSearch(e.target.value);
  }

  useEffect(() => {
    const request = { name: "getUserClasses", param: {} };
    mutate(request);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClassShowClick(singleClass) {
    setSingleClass(singleClass);
    setShowClass(true);
  }

  return (
    <div>

      {dataClasses ? dataClasses.length > 0 ?
        <div className="d-grid gap-2">
          <Form.Group className='mb-4'>
            <Form.Control type="text" name="search" id="search" placeholder="Search..." onChange={handleSearch} />
          </Form.Group>
        </div>
        : null : null}
      {
        isLoading ? <Spinner animation="border" className="mb-4" /> : null
      }
      {dataClasses ? dataClasses.length > 0 ? dataClasses.filter(singleClass =>
        singleClass.name.toLowerCase().includes(search.toLowerCase())).map(d => (
          <div className={d.id === selectedClass.id ? 'alert alert-light text-primary bg-white-tp p-1' : 'alert alert-light text-primary p-1'}
            key={d.id}>
            <Row>
              <Col className="text-start py-2 ms-2 pe-0 cursor-pointer" onClick={() => { setSingleClass(d); setShowClassDetail(true); }}>
                <h4>{d.name}</h4>
              </Col>
              <Col className="d-flex col-auto align-items-center me-2">
                <Button className="" variant="outline-primary" onClick={() => { handleClassShowClick(d); }}>
                  <FontAwesomeIcon icon={faBook} />
                </Button>
              </Col>
            </Row>
          </div>
        )) : <i>You have no activated sources with classes</i> : null}
      <ShowClass show={showClass} handleClose={handleCloseClass} singleClass={singleClass} />
      <ClassDetailSelect show={showClassDetail} handleClose={handleCloseClassDetail} singleClass={singleClass} saveClass={saveClass} backgroundSkills={backgroundSkills} backgroundToolProficiencies={backgroundToolProficiencies} />
    </div>
  )
}
