import { faDiceD20 } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { sendRequestAuth } from '../services/apiFunctions';

export default function InitiativeAdd({ show, char, handleClose }) {

    const [initiative, setInitiative] = useState({});

    const gameID = localStorage.getItem("gameID");

    const { mutate } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                handleClose();
            } else {
                console.log(data.data.message);
            }
        },
    });

    function handleSubmit() {
        const request = {
            name: "addToFight",
            param: {
                charID: parseInt(char.id),
                gameID: parseInt(gameID),
                type: char.func,
                initiative: initiative
            }
        };
        //console.log(request)
        mutate(request);
    }

    useEffect(() => {
        setInitiative(0);
    }, [show]);


    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Add {char.func} to fight</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <Row className="mb-3">
                        <div className="col-auto h2">
                            <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip>Initiative</Tooltip>}
                            >
                                <FontAwesomeIcon icon={faDiceD20} />
                            </OverlayTrigger>
                        </div>
                        <Col>
                            <Form.Control type="number" name="initiative" value={initiative} onChange={(e) => setInitiative(e.target.value)} required autofocus />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="outline-primary" onClick={handleSubmit}>
                        Add
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
