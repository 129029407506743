import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { logout, sendRequestAuth } from '../services/apiFunctions';

export default function GameSettings({ show, game, handleClose }) {

    const [formData, setFormData] = useState({});
    const [alert, setAlert] = useState("");

    const [deleteQuestion, setDeleteQuestion] = useState(false);

    const navigate = useNavigate()

    const gameID = localStorage.getItem("gameID");

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            setAlert("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                handleClose(true);
                if (deleteQuestion) {
                    navigate("/games");
                }
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setAlert(data.data.message);
            }
        },
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const request = {
            name: "changeGame",
            param: {
                gameID: gameID,
                name: formData.name,
                password: formData.password
            }
        };
        mutate(request);
    }

    function handleDelete() {
        const request = {
            name: "deleteGame",
            param: {
                id: gameID
            }
        };
        mutate(request);
    }

    useEffect(() => {
        setAlert("");
        setDeleteQuestion(false)
        setFormData(values => ({ ...values, "name": game.name }))
        setFormData(values => ({ ...values, "password": "" }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <Modal size="lg" backdrop="static" show={show} onHide={handleClose}>
            <Form className="mb-3">
                <Modal.Header closeButton>
                    <Modal.Title>Game Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isLoading ? <div className="text-center"><Spinner animation="border" /></div> : null
                    }
                    {
                        alert !== "" ?
                            <div className="alert alert-danger">
                                {alert}
                            </div>
                            :
                            null
                    }
                    <div>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="name" defaultValue={game.name} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3 mt-5">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" name="password" placeholder="••••••••••" onChange={handleChange} required />
                            <small>Leave empty if you don't want to change the password</small>
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {deleteQuestion ?
                        <div className='text-center container-fluid'>
                            <hr />
                            <div>
                                <p>Do you really want to delete this game?</p>
                                <Button variant="outline-primary me-2" onClick={() => setDeleteQuestion(false)}>
                                    Abort
                                </Button>
                                <Button variant="primary" onClick={handleDelete}>
                                    Delete
                                </Button>
                            </div>
                            <hr />
                        </div>
                        :
                        <Button variant="outline-primary" onClick={() => setDeleteQuestion(true)}>
                            Delete
                        </Button>
                    }
                    <Button variant="secondary" onClick={() => handleClose(false)}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
