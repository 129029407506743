import React, { useEffect, useState } from 'react'
import { Button, Col, Form, ListGroup, Modal, Row } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getSpellLevelString, logout, sendRequestAuth, } from '../../services/apiFunctions';

export default function AddSpell({ show, handleClose, char, reload, charSpells }) {

    const [dataSpells, setDataSpells] = useState("")

    const [search, setSearch] = useState("")

    const [showOnlyClass, setShowOnlyClass] = useState(true)

    const [chosenSpell, setChosenSpell] = useState("")

    const navigate = useNavigate()

    const { mutate } = useMutation(sendRequestAuth, {
        onError: () => {
            setDataSpells("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setDataSpells(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setDataSpells(data.data.message);
            }
        },
    });

    const handleSubmit = (e) => {
        var request = {
            name: "addSpell",
            param: {
                charID: char.id,
                spellID: chosenSpell.id
            }
        };
        //console.log(request.param);
        mutate(request);
        reload();
        handleClose();
    }

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    useEffect(() => {
        setChosenSpell(null);

        const request = { name: "getUserSpells", param: {} };
        mutate(request);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <Modal size="lg" show={show} onHide={() => handleClose(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add spell to char</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {dataSpells ? dataSpells.length > 0 ?
                    <div className="d-grid gap-2">
                        <Form.Group className='mb-2'>
                            <Form.Control type="text" name="search" id="search" placeholder="Search..." onChange={handleSearch} />
                            <Form.Check
                                className="mt-2 ms-1"
                                checked={showOnlyClass}
                                label="Only show spells of your class"
                                name="showOnlyClass"
                                type="checkbox"
                                id="showOnlyClass"
                                onChange={() => setShowOnlyClass(!showOnlyClass)}
                            />
                        </Form.Group>
                    </div>
                    : null : null}
                <div className="chooseList border rounded">
                    <ListGroup defaultActiveKey={"#" + (chosenSpell ? chosenSpell.id : 0)}>
                        {dataSpells ? dataSpells.length > 0 ? dataSpells.filter(spell => spell.name.toLowerCase().includes(search.toLowerCase()) &&
                            charSpells.filter(e => e.id === spell.id).length === 0 &&
                            (spell.classes.includes(char.className) || !showOnlyClass)).map(d => (
                                <ListGroup.Item key={d.id} href={"#" + d.id} action onClick={() => setChosenSpell(d)}>
                                    <Row>
                                        <Col className="text-start pe-0">
                                            {d.name}
                                        </Col>
                                        <Col className="text-end fst-italic ps-0">
                                            {
                                                d.level === 0 ?
                                                    "Cantrip"
                                                    :
                                                    getSpellLevelString(d.level) + " " + d.school.toLowerCase()
                                            }
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            )) : <i>Create a spell first</i> : null}
                    </ListGroup>
                </div>
                {chosenSpell ?
                    <div className="mt-3 line-breaks">
                        <b>School: </b>{chosenSpell.school}<br />
                        <b>Casting Time: </b>{chosenSpell.castingTime}<br />
                        <b>Range: </b>{chosenSpell.range}<br />
                        <b>Components: </b>{chosenSpell.components}<br />
                        <b>Duration: </b>{chosenSpell.duration}<br />
                        <div className="mt-2" dangerouslySetInnerHTML={{ __html: chosenSpell.description }}></div>
                        {chosenSpell.descAtHigher !== "" ?
                            <p className="mt-2">
                                <b>At Higher Levels:</b> {chosenSpell.descAtHigher}
                            </p>
                            : null}
                        <br />
                        <b>Source: </b>{chosenSpell.source === "HB" ? "Custom" : chosenSpell.source}<br />
                    </div>
                    : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleClose(false)}>
                    Close
                </Button>
                {chosenSpell ?
                    <Button variant="primary" onClick={() => handleSubmit()}>
                        Add Spell
                    </Button>
                    : null}
            </Modal.Footer>
        </Modal>
    )
}
