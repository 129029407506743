import axios from "axios";
import { useEffect, useRef } from "react";
import Blinded from "../assets/Conditions/Blinded.png"
import Charmed from "../assets/Conditions/Charmed.png"
import Deafened from "../assets/Conditions/Deafened.png"
import Frightened from "../assets/Conditions/Frightened.png"
import Grappled from "../assets/Conditions/Grappled.png"
import Incapacitated from "../assets/Conditions/Incapacitated.png"
import Invisible from "../assets/Conditions/Invisible.png"
import Paralyzed from "../assets/Conditions/Paralyzed.png"
import Petrified from "../assets/Conditions/Petrified.png"
import Poisoned from "../assets/Conditions/Poisoned.png"
import Prone from "../assets/Conditions/Prone.png"
import Restrained from "../assets/Conditions/Restrained.png"
import Stunned from "../assets/Conditions/Stunned.png"
import Unconcious from "../assets/Conditions/Unconcious.png"

//export const API_URL = process.env.NODE_ENV !== 'production' ? "http://localhost" : "https://digitalpaper.alpakagamestudios.de/api";
export const API_URL = "https://digitalpnp.com/api";

export const sendRequestAuth = (request) => {
    const token = localStorage.getItem('token');
    return axios.post(API_URL + '/', request, { headers: { 'Authorization': `Bearer ${token}` } });
}

export const sendRequest = (request) => {
    return axios.post(API_URL + '/', request);
}

export const uploadCharPic = (request) => {
    const token = localStorage.getItem('token');
    return axios.post(API_URL + '/?name=uploadCharPic', request, { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
}

export const uploadMonsterPic = (request) => {
    const token = localStorage.getItem('token');
    return axios.post(API_URL + '/?name=uploadMonsterPic', request, { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
}

export function logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userID');
}

export function getHealthPerc(hp, maxHP) {
    var healthPercent = hp / maxHP * 100;

    if (healthPercent < 0) {
        return 0;
    } else if (healthPercent > 100) {
        return 100;
    }
    return healthPercent;
}

export function getHealthColor(hp, maxHP) {
    var perc = getHealthPerc(hp, maxHP);
    if (perc <= 0) {
        return "dark";
    } else if (perc <= 10) {
        return "danger";
    } else if (perc <= 25) {
        return "orange";
    } else if (perc <= 50) {
        return "warning";
    } else {
        return "success";
    }
}

export function getHealthText(hp, maxHP) {
    var perc = getHealthPerc(hp, maxHP);
    if (perc <= 0) {
        return "Dead";
    } else if (perc <= 10) {
        return "Critical Health";
    } else if (perc <= 25) {
        return "Low Health";
    } else if (perc <= 50) {
        return "Wounded";
    } else {
        return "Healthy";
    }
}

export function getHealthBeat(hp, maxHP) {
    var perc = getHealthPerc(hp, maxHP);
    if (perc <= 25 && perc > 0) {
        return true;
    } else {
        return false;
    }
}

export function getModifier(value, plus = false) {
    if (plus) {
        var val = Math.floor((value - 10) / 2);
        if (val > 0) {
            return "+" + val;
        } else {
            return "" + val;
        }
    } else {
        return Math.floor((value - 10) / 2);
    }
}

export function getModifierOfScore(char, score) {
    if (score === "strength") {
        return getModifier(char.strength)
    } else if (score === "dexterity") {
        return getModifier(char.dexterity)
    } else if (score === "constitution") {
        return getModifier(char.constitution)
    } else if (score === "intelligence") {
        return getModifier(char.intelligence)
    } else if (score === "wisdom") {
        return getModifier(char.wisdom)
    } else if (score === "charisma") {
        return getModifier(char.charisma)
    } else {
        return 0
    }
}

export function getArmorAC(armor) {
    if (armor === "None") {
        return 10
    } else if (armor === "Padded") {
        return 11
    } else if (armor === "Leather") {
        return 11
    } else if (armor === "Studded leather") {
        return 12
    } else if (armor === "Hide") {
        return 12
    } else if (armor === "Chain shirt") {
        return 13
    } else if (armor === "Scale mail") {
        return 14
    } else if (armor === "Breastplate") {
        return 14
    } else if (armor === "Half plate") {
        return 15
    } else if (armor === "Ring mail") {
        return 14
    } else if (armor === "Chain mail") {
        return 16
    } else if (armor === "Splint") {
        return 17
    } else if (armor === "Plate") {
        return 18
    } else {
        return 0
    }
}

export function getDEXforArmor(armorType, dex) {
    if (armorType === "light") {
        return getModifier(dex);
    } else if (armorType === "medium") {
        var val = getModifier(dex);
        if (val > 2) {
            val = 2;
        }
        return val;
    } else {
        return 0
    }
}

export function getDEXStringforArmor(armorType, dex) {
    if (armorType === "light") {
        return " + DEX (" + getModifier(dex) + ")";
    } else if (armorType === "medium") {
        var val = getModifier(dex);
        if (val > 2) {
            val = 2;
        }
        return " + DEX max. 2 (" + val + ")";;
    } else {
        return ""
    }
}

export function getShieldString(shield) {
    if (shield > 0) {
        return " + Shield (" + shield + ")"
    } else {
        return ""
    }
}

export function getSpellLevelString(level) {
    return level === 0 ?
        "Cantrip"
        :
        level + (level === 1 ? "st" : level === 2 ? "nd" : level === 3 ? "rd" : "th") + "-level"
}

export default function useDocumentTitle(title = "", prevailOnUnmount = false) {
    const defaultTitle = useRef(document.title);

    useEffect(() => {
        if (title === "") {
            document.title = "DigitalP&P";
        } else {
            document.title = "DigitalP&P - " + title;
        }

        // eslint-disable-next-line
    }, [title]);

    useEffect(() => () => {
        if (!prevailOnUnmount) {
            document.title = defaultTitle.current;
        }

        // eslint-disable-next-line
    }, [])
}

export const simpleMeleeWeapons = [
    {
        id: 297,
        name: "Club",
        amount: 1
    },
    {
        id: 337,
        name: "Dagger",
        amount: 1
    },
    {
        id: 542,
        name: "Greatclub",
        amount: 1
    },
    {
        id: 574,
        name: "Handaxe",
        amount: 1
    },
    {
        id: 695,
        name: "Javelin",
        amount: 1
    },
    {
        id: 731,
        name: "Light hammer",
        amount: 1
    },
    {
        id: 758,
        name: "Mace",
        amount: 1
    },
    {
        id: 1022,
        name: "Quarterstaff",
        amount: 1
    },
    {
        id: 1185,
        name: "Sickle",
        amount: 1
    },
    {
        id: 1231,
        name: "Spear",
        amount: 1
    }
]

export const simpleRangedWeapons = [
    {
        id: 730,
        name: "Light Crossbow",
        amount: 1
    },
    {
        id: 349,
        name: "Dart",
        amount: 1
    },
    {
        id: 1181,
        name: "Shortbow",
        amount: 1
    },
    {
        id: 1209,
        name: "Sling",
        amount: 1
    }
]

export const martialMeleeWeapons = [
    {
        id: 147,
        name: "Battleaxe",
        amount: 1
    },
    {
        id: 489,
        name: "Flail",
        amount: 1
    },
    {
        id: 520,
        name: "Glaive",
        amount: 1
    },
    {
        id: 541,
        name: "Greataxe",
        amount: 1
    },
    {
        id: 544,
        name: "Greatsword",
        amount: 1
    },
    {
        id: 565,
        name: "Halberd",
        amount: 1
    },
    {
        id: 717,
        name: "Lance",
        amount: 1
    },
    {
        id: 747,
        name: "Longsword",
        amount: 1
    },
    {
        id: 792,
        name: "Maul",
        amount: 1
    },
    {
        id: 826,
        name: "Morningstar",
        amount: 1
    },
    {
        id: 919,
        name: "Pike",
        amount: 1
    },
    {
        id: 1031,
        name: "Rapier",
        amount: 1
    },
    {
        id: 1129,
        name: "Scimitar",
        amount: 1
    },
    {
        id: 1182,
        name: "Shortsword",
        amount: 1
    },
    {
        id: 1386,
        name: "Trident",
        amount: 1
    },
    {
        id: 1444,
        name: "War pick",
        amount: 1
    },
    {
        id: 1446,
        name: "Warhammer",
        amount: 1
    },
    {
        id: 1464,
        name: "Whip",
        amount: 1
    }
]

export const martialRangedWeapons = [
    {
        id: 189,
        name: "Blowgun",
        amount: 1
    },
    {
        id: 571,
        name: "Hand Crossbow",
        amount: 1
    },
    {
        id: 584,
        name: "Heavy Crossbow",
        amount: 1
    },
    {
        id: 744,
        name: "Longbow",
        amount: 1
    },
    {
        id: 845,
        name: "Net",
        amount: 1
    }
]

export const musicInstruments = [
    {
        id: 130,
        name: "Bagpipes",
        amount: 1
    },
    {
        id: 409,
        name: "Drum",
        amount: 1
    },
    {
        id: 410,
        name: "Dulcimer",
        amount: 1
    },
    {
        id: 500,
        name: "Flute",
        amount: 1
    },
    {
        id: 613,
        name: "Horn",
        amount: 1
    },
    {
        id: 754,
        name: "Lute",
        amount: 1
    },
    {
        id: 756,
        name: "Lyre",
        amount: 1
    },
    {
        id: 899,
        name: "Pan Flute",
        amount: 1
    },
    {
        id: 1169,
        name: "Shawm",
        amount: 1
    },
    {
        id: 1411,
        name: "Viol",
        amount: 1
    }
]

export function removeDuplicates(arr) {
    return arr.filter((item,
        index) => arr.indexOf(item) === index && item !== "");
}

export function moveArrElem(array, index, delta) {
    var tempArray = array;
    var newIndex = index + delta;

    if (newIndex < 0 || newIndex === tempArray.length) { //Already at the top or bottom.
        return array;
    };

    var indexes = [index, newIndex].sort(); //Sort the indixes
    tempArray.splice(indexes[0], 2, tempArray[indexes[1]], tempArray[indexes[0]]); //Replace from lowest index, two elements, reverting the order

    return tempArray;
};

export function getRandomInt(max) {
    return Math.floor(Math.random() * max) + 1;
}

export function removeSmallest(arr) {
    var min = Math.min(...arr);
    var alreadyOneFiltered = false;
    return arr.filter(e => {
        if (e !== min || alreadyOneFiltered) {
            return true;
        } else {
            alreadyOneFiltered = true;
            return false
        }
    });
}

export const conditions = [
    {
        id: 1,
        name: "Blinded",
        description: "<ul><li>A blinded creature can’t see and automatically fails any ability check that requires sight.</li><li>Attack rolls against the creature have advantage, and the creature’s attack rolls have disadvantage.</li></ul>",
        pic: Blinded
    },
    {
        id: 2,
        name: "Charmed",
        description: "<ul><li>A charmed creature can’t attack the charmer or target the charmer with harmful abilities or magical effects.</li><li>The charmer has advantage on any ability check to interact socially with the creature.</li></ul>",
        pic: Charmed
    },
    {
        id: 3,
        name: "Deafened",
        description: "<ul><li>A deafened creature can’t hear and automatically fails any ability check that requires hearing.</li></ul>",
        pic: Deafened
    },
    {
        id: 4,
        name: "Frightened",
        description: "<ul><li>A frightened creature has disadvantage on ability checks and attack rolls while the source of its fear is within line of sight.</li><li>The creature can’t willingly move closer to the source of its fear.</li></ul>",
        pic: Frightened
    },
    {
        id: 5,
        name: "Grappled",
        description: "<ul><li>A grappled creature’s speed becomes 0, and it can’t benefit from any bonus to its speed.</li><li>The condition ends if the grappler is incapacitated (see the condition).</li><li>The condition also ends if an effect removes the grappled creature from the reach of the grappler or grappling effect, such as when a creature is hurled away by the thunderwave spell.</li></ul>",
        pic: Grappled
    },
    {
        id: 6,
        name: "Incapacitated",
        description: "<ul><li>An incapacitated creature can’t take actions or reactions.</li></ul>",
        pic: Incapacitated
    },
    {
        id: 7,
        name: "Invisible",
        description: "<ul><li>An invisible creature is impossible to see without the aid of magic or a special sense. For the purpose of hiding, the creature is heavily obscured. The creature’s location can be detected by any noise it makes or any tracks it leaves.</li><li>Attack rolls against the creature have disadvantage, and the creature’s attack rolls have advantage.</li></ul>",
        pic: Invisible
    },
    {
        id: 8,
        name: "Paralyzed",
        description: "<ul><li>A paralyzed creature is incapacitated (see the condition) and can’t move or speak.</li><li>The creature automatically fails Strength and Dexterity saving throws.</li><li>Attack rolls against the creature have advantage.</li><li>Any attack that hits the creature is a critical hit if the attacker is within 5 feet of the creature.</li></ul>",
        pic: Paralyzed
    },
    {
        id: 9,
        name: "Petrified",
        description: "<ul><li>A petrified creature is transformed, along with any nonmagical object it is wearing or carrying, into a solid inanimate substance (usually stone). Its weight increases by a factor of ten, and it ceases aging.</li><li>The creature is incapacitated (see the condition), can’t move or speak, and is unaware of its surroundings.</li><li>Attack rolls against the creature have advantage.</li><li>The creature automatically fails Strength and Dexterity saving throws.</li><li>The creature has resistance to all damage.</li><li>The creature is immune to poison and disease, although a poison or disease already in its system is suspended, not neutralized.</li></ul>",
        pic: Petrified
    },
    {
        id: 10,
        name: "Poisoned",
        description: "<ul><li>A poisoned creature has disadvantage on attack rolls and ability checks.</li></ul>",
        pic: Poisoned
    },
    {
        id: 11,
        name: "Prone",
        description: "<ul><li>A prone creature’s only movement option is to crawl, unless it stands up and thereby ends the condition.</li><li>The creature has disadvantage on attack rolls.</li><li>An attack roll against the creature has advantage if the attacker is within 5 feet of the creature. Otherwise, the attack roll has disadvantage.</li></ul>",
        pic: Prone
    },
    {
        id: 12,
        name: "Restrained",
        description: "<ul><li>A restrained creature’s speed becomes 0, and it can’t benefit from any bonus to its speed.</li><li>Attack rolls against the creature have advantage, and the creature’s attack rolls have disadvantage.</li><li>The creature has disadvantage on Dexterity saving throws.</li></ul>",
        pic: Restrained
    },
    {
        id: 13,
        name: "Stunned",
        description: "<ul><li>A stunned creature is incapacitated (see the condition), can’t move, and can speak only falteringly.</li><li>The creature automatically fails Strength and Dexterity saving throws.</li><li>Attack rolls against the creature have advantage.</li></ul>",
        pic: Stunned
    },
    {
        id: 14,
        name: "Unconcious",
        description: "<ul><li>An unconscious creature is incapacitated (see the condition), can’t move or speak, and is unaware of its surroundings</li><li>The creature drops whatever it’s holding and falls prone.</li><li>The creature automatically fails Strength and Dexterity saving throws.</li><li>Attack rolls against the creature have advantage.</li><li>Any attack that hits the creature is a critical hit if the attacker is within 5 feet of the creature.</li></ul>",
        pic: Unconcious
    }
]