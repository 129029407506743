import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Collapse, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { sendRequestAuth } from '../../services/apiFunctions';

export default function ChangeHitDice({ show, char, handleClose, reload }) {

    const [hitDiceChange, setHitDiceChange] = useState(0);
    const [hitDice, setHitDice] = useState(0);
    const [open, setOpen] = useState(false);

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                reload();
                handleClose();
            } else {
                console.log(data.data.message);
            }
        },
    });

    function handleCount(amount) {
        setHitDiceChange(hitDiceChange + amount)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var amount = char.hitDiceAmount + hitDiceChange;

        if (amount > char.level) {
            amount = char.level;
        } else if (amount < 0) {
            amount = 0;
        }

        const request = {
            name: "editHitDice",
            param: {
                charID: parseInt(char.id),
                hitDice: parseInt(hitDice),
                hitDiceAmount: parseInt(amount)
            }
        };

        //console.log(request);
        mutate(request);
    }

    useEffect(() => {
        setHitDiceChange(0);
        setOpen(false);
        setHitDice(char.hitDice);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);


    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Change Hit Dice</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-start'>
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" />
                            :
                            null
                    }
                    <Row>
                        <Col xs={4} className="p-1">
                            <div className="d-grid gap-2"><Button variant="outline-danger" className="p-3 fw-bold" onClick={() => handleCount(-1)}>-1</Button></div>
                        </Col>
                        <Col xs={4} className="p-1 text-center">
                            <Alert variant={hitDiceChange === 0 ? "dark" : hitDiceChange > 0 ? "success" : "danger"} className="h5">{hitDiceChange}</Alert>
                        </Col>
                        <Col xs={4} className="p-1">
                            <div className="d-grid gap-2"><Button variant="outline-success" className="p-3 fw-bold" onClick={() => handleCount(1)}>+1</Button></div>
                        </Col>
                    </Row>
                    <Row>
                        <Button variant="outline-secondary" size="sm" onClick={() => setOpen(!open)}><FontAwesomeIcon icon={open ? faChevronDown : faChevronUp} /></Button>
                        <Collapse in={open}>
                            <div id="example-collapse-text">
                                <Col>
                                    <Form.Group className="my-3" controlId="name">
                                        <Form.Label>Hit Dice</Form.Label>
                                        <Form.Select aria-label="Default select example" defaultValue={hitDice} onChange={(e) => setHitDice(e.target.value)}>
                                            <option value="4">1d4</option>
                                            <option value="6">1d6</option>
                                            <option value="8">1d8</option>
                                            <option value="10">1d10</option>
                                            <option value="12">1d12</option>
                                            <option value="20">1d20</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </div>
                        </Collapse>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="outline-primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
