import React from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { sendRequestAuth } from '../../services/apiFunctions';

export default function ChangeDeathSaves({ show, char, handleClose, reload }) {


    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data);
            if (data.data.status === 200) {
                reload();
                handleClose();
            } else {
                console.log(data.data.message);
            }
        },
    });

    function handleSuccess() {
        var value = char.deathSuccess + 1
        if (value > 3) {
            value = 3;
        }
        handleSubmit(value, char.deathFail)
    }

    function handleFail() {
        var value = char.deathFail + 1
        if (value > 3) {
            value = 3;
        }
        handleSubmit(char.deathSuccess, value)
    }

    function handleReset() {
        handleSubmit(0, 0)
    }

    const handleSubmit = (success, fail) => {
        const request = {
            name: "editDeathSaves",
            param: {
                charID: parseInt(char.id),
                deathSuccess: parseInt(success),
                deathFail: parseInt(fail)
            }
        };

        //console.log(request);
        mutate(request);
    }

    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Change Hit Dice</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-start'>
                    {
                        isLoading ? <Spinner animation="border" className="mb-4" />
                            :
                            null
                    }
                    <Row>
                        <Col>
                            <div className="d-grid gap-2">
                                <Button variant="outline-success" onClick={handleSuccess}>
                                    Success
                                </Button>
                            </div>
                        </Col>
                        <Col>
                            <div className="d-grid gap-2">
                                <Button variant="outline-danger" onClick={handleFail}>
                                    Fail
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="outline-primary" onClick={handleReset}>
                        Reset
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
