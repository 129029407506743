import React, { useEffect, useState } from 'react'
import ShowAncestry from '../ShowAncestry';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { logout, sendRequestAuth } from '../../services/apiFunctions';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import ShowSelectAncestry from '../ShowSelectAncestry';
import AncestryDetailSelect from './AncestryDetailSelect';

export default function AncestrySelect({ selectedAncestry, setSelectedAncestry, saveAncestry, backgroundLanguages, classSkills, classToolProficiencies }) {
  const [dataAncestries, setDataAncestries] = useState([])
  const [search, setSearch] = useState("")
  const [ancestry, setAncestry] = useState()
  const [detail, setDetail] = useState(false)

  const [showDuplicates, setShowDuplicates] = useState(false)

  const [showAncestry, setShowAncestry] = useState(false);
  const handleCloseAncestry = () => {
    setShowAncestry(false);
  };

  const [showAncestryDetail, setShowAncestryDetail] = useState(false);
  const handleCloseAncestryDetail = () => {
    setSelectedAncestry(ancestry)
    setShowAncestryDetail(false);
  };

  const [showSelectAncestry, setShowSelectAncestry] = useState(false);
  const handleCloseSelectAncestry = () => {
    setShowSelectAncestry(false);
  };

  const navigate = useNavigate()

  const { mutate, isLoading } = useMutation(sendRequestAuth, {
    onError: () => {
      setDataAncestries("An error occured while fetching data.");
    },
    onSuccess: (data) => {
      //console.log(data.data.result);
      if (data.data.status === 200) {
        setDataAncestries(data.data.result);
      } else if (data.data.status === 302) {
        logout();
        navigate("/");
      } else {
        setDataAncestries(data.data.message);
      }
    },
  });

  const handleSearch = (e) => {
    setSearch(e.target.value);
  }

  useEffect(() => {
    const request = { name: "getUserAncestries", param: {} };
    mutate(request);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleAncestryShowClick(ancestry) {
    setAncestry(ancestry);
    setDetail(false);
    if (dataAncestries.some(d => d.parentID === ancestry.id)) {
      setShowSelectAncestry(true);
    } else {
      setShowAncestry(true);
    }
  }

  function handleAncestryDetailClick(ancestry) {
    setAncestry(ancestry);
    setDetail(true);
    if (dataAncestries.some(d => d.parentID === ancestry.id)) {
      setShowSelectAncestry(true);
    } else {
      setShowAncestryDetail(true);
    }
  }

  return (
    <div>
      {dataAncestries ? dataAncestries.length > 0 ?
        <div className="d-grid gap-2">
          <Form.Group>
            <Form.Control type="text" name="search" id="search" placeholder="Search..." onChange={handleSearch} />
          </Form.Group>
          <Form.Check
            className='mb-4 text-start big-checkbox '
            type="switch"
            id="showDuplicates"
            label="Show Duplicates"
            checked={showDuplicates}
            onChange={e => setShowDuplicates(!showDuplicates)}
          />
        </div>
        : null : null}
      {
        isLoading ? <Spinner animation="border" className="mb-4" /> : null
      }
      {dataAncestries ? dataAncestries.length > 0 ? dataAncestries.filter((ancestry, index) =>
        ancestry.name.toLowerCase().includes(search.toLowerCase()) && (showDuplicates || ((index === 0 || dataAncestries[index - 1].name !== ancestry.name) && ancestry.importanceWeight !== -1)) && ancestry.parentID === 0).map(d => (
          <div className={d.id === selectedAncestry.id || d.id === selectedAncestry.parentID ? 'alert alert-light text-primary bg-white-tp p-1' : 'alert alert-light text-primary p-1'}
            key={d.id}>
            <Row>
              <Col className="text-start py-2 ms-2 pe-0 cursor-pointer" onClick={() => { handleAncestryDetailClick(d); }}>
                <h4>{d.name}</h4>
              </Col>
              {
                dataAncestries.some(e => e.parentID === d.id) ?
                  <Col className='text-start py-2 ms-2 pe-0 cursor-pointer' onClick={() => { handleAncestryDetailClick(d); }}>
                    <i>Has Sub-Ancestries</i>
                  </Col>
                  : null
              }
              <Col className="d-flex col-auto align-items-center me-2">
                <Button className="" variant="outline-primary" onClick={() => { handleAncestryShowClick(d); }}>
                  <FontAwesomeIcon icon={faBook} />
                </Button>
              </Col>
            </Row>

          </div>
        )) : <i>You have no activated sources with ancestries</i> : null}
      <ShowAncestry show={showAncestry} handleClose={handleCloseAncestry} ancestry={ancestry} />
      <ShowSelectAncestry show={showSelectAncestry} handleClose={handleCloseSelectAncestry} ancestry={ancestry} setAncestry={setAncestry} ancestries={dataAncestries} setShowAncestry={setShowAncestry} setShowAncestryDetail={setShowAncestryDetail} detail={detail} showDuplicates={showDuplicates} />
      <AncestryDetailSelect show={showAncestryDetail} handleClose={handleCloseAncestryDetail} ancestry={ancestry} saveAncestry={saveAncestry} backgroundLanguages={backgroundLanguages} classSkills={classSkills} classToolProficiencies={classToolProficiencies} />
      {/*<BackgroundDetailSelect show={showBackgroundDetail} handleClose={handleCloseBackgroundDetail} background={selectedBackground} saveBackground={saveBackground}/>*/}
    </div>
  )
}
