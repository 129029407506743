import React, { useEffect, useState } from 'react'
import { martialMeleeWeapons, martialRangedWeapons, musicInstruments, simpleMeleeWeapons, simpleRangedWeapons } from '../../services/apiFunctions';
import { Form } from 'react-bootstrap';

export default function SelectWeapon({ type, setEquip }) {

    const [weapons, setWeapons] = useState(simpleMeleeWeapons);

    useEffect(() => {
        var fullWeaponList = [];

        if (type === "any simple weapon") {
            fullWeaponList.push(...simpleMeleeWeapons);
            fullWeaponList.push(...simpleRangedWeapons);
        } else if (type === "any simple melee weapon") {
            fullWeaponList.push(...simpleMeleeWeapons);
        } else if (type === "any martial weapon") {
            fullWeaponList.push(...martialMeleeWeapons);
            fullWeaponList.push(...martialRangedWeapons);
        } else if (type === "any martial melee weapon") {
            fullWeaponList.push(...martialMeleeWeapons);
        } else if (type === "any musical instrument") {
            fullWeaponList.push(...musicInstruments);
        }

        setWeapons(fullWeaponList);
        setEquip(fullWeaponList[0])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);


    return (
        <Form.Select className="mb-3" onChange={(e) => { setEquip(weapons[e.target.value]) }}>
            {
                weapons ? weapons.map((weapon, i) => (
                    <option key={i} value={i}>{weapon.name}</option>
                )) : null
            }
        </Form.Select>
    )
}
