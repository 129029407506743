import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Col, Collapse, Form, ListGroup, Modal, Row, Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query';
import { API_URL, getModifier, getRandomInt, sendRequestAuth } from '../../services/apiFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faChevronDown, faChevronUp, faInfo } from '@fortawesome/free-solid-svg-icons';
import ShowFeat from '../ShowFeat';

export default function LevelUp({ show, handleClose, char, reload, features }) {

    const [page, setPage] = useState("check");
    const [HPincrease, setHPincrease] = useState(0);
    const [newLevel, setNewLevel] = useState(char.level + 1);
    const [subclass, setSubclass] = useState({ id: 0 })
    const [classData, setClassData] = useState();

    const [showFeature, setShowFeature] = useState(false);
    const handleCloseFeature = () => setShowFeature(false);
    const [selectedFeature, setSelectedFeature] = useState();

    const [abilityOption, setAbilityOption] = useState(0);
    const [abilityCount, setAbilityCount] = useState(0);

    const [dataFeats, setDataFeats] = useState([]);
    const [chosenFeat, setChosenFeat] = useState();
    const [featSearch, setFeatSearch] = useState("");

    const [infusions, setInfusions] = useState([]);
    const [infusionCount, setInfusionCount] = useState(0);

    const [invocations, setInvocations] = useState([]);
    const [invocationCount, setInvocationCount] = useState(0);

    const [replaceFeature, setReplaceFeature] = useState(null);
    const [replaceFeatureQuestion, setReplaceFeatureQuestion] = useState(false);

    const [pactBoon, setPactBoon] = useState(null);

    const [dataLevels, setDataLevels] = useState([]);

    const abilityList = [
        {
            name: "STR",
            fullName: "strength",
            value: char.strength
        },
        {
            name: "DEX",
            fullName: "dexterity",
            value: char.dexterity
        },
        {
            name: "CON",
            fullName: "constitution",
            value: char.constitution
        },
        {
            name: "INT",
            fullName: "intelligence",
            value: char.intelligence
        },
        {
            name: "WIS",
            fullName: "wisdom",
            value: char.wisdom
        },
        {
            name: "CHA",
            fullName: "charisma",
            value: char.charisma
        }
    ]

    const [abilities, setAbilities] = useState([0, 0, 0, 0, 0, 0])

    const [open, setOpen] = useState([]);

    function handleLevelUp() {
        const request = {
            name: "doLevelUp",
            param: {
                char: char,
                xp: char.xp - char.xpForLvl < char.xpToNext ? char.xpForLvl + char.xpToNext : char.xp,
                newLevel: newLevel,
                subclassID: subclass.id,
                healthIncrease: HPincrease,
                features: classData.features.filter(feature => !feature.readOnly && feature.level === newLevel && ((feature.type === "subclass" && feature.originID === subclass.id) || feature.type !== "subclass")),
                levelCol: dataLevels[newLevel - 1],
                abilityUpgrades: abilities,
                feat: chosenFeat,
                invocations: invocations,
                pactBoon: pactBoon,
                infusions: infusions,
                replaceFeature: replaceFeature
            }
        };

        //console.log(request.param);
        mutateLevelUp(request);
    }

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                update(data.data.result);

                //console.log(data.data.result);
            } else {
                console.log(data.data.message);
            }
        },
    });

    const { mutate: mutateFeats } = useMutation(sendRequestAuth, {
        onError: () => {
            setDataFeats("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setDataFeats(data.data.result);
            } else {
                setDataFeats(data.data.message);
            }
        },
    });

    const { mutate: mutateLevels } = useMutation(sendRequestAuth, {
        onError: () => {
            setDataFeats("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setDataLevels(data.data.result);
            } else {
                setDataLevels(data.data.message);
            }
        },
    });

    const { mutate: mutateLevelUp } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                reload(true)
                handleClose(false)

                //console.log(data.data.result);
            } else {
                console.log(data.data.message);
            }
        },
    });

    useEffect(() => {
        setPage("");
        setHPincrease(0);
        setSubclass({ id: 0 });
        setOpen([]);
        setAbilityOption(0);
        setAbilities([0, 0, 0, 0, 0, 0]);
        setAbilityCount(0);
        setDataFeats([]);
        setChosenFeat();
        setFeatSearch("");
        setPactBoon(null);
        setInfusions([]);
        setInfusionCount(0);
        setInvocations([]);
        setInvocationCount(0);
        setReplaceFeature(null);
        setReplaceFeatureQuestion(false);
        setNewLevel(char.level + 1);


        setSubclass({ id: char.subclassID, name: char.subclassName });

        if (show === true) {
            const request = {
                name: "getClassData",
                param: {
                    id: char.classID
                }
            };
            mutate(request);
        }

        const request2 = { name: "getUserFeats", param: {} };
        mutateFeats(request2);

        const request3 = {
            name: "getLevelTableOfClass",
            param: {
                classID: char.classID
            }
        };
        mutateLevels(request3);

        //console.log(request3)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const handleFeatSearch = (e) => {
        setFeatSearch(e.target.value);
    }

    function update(data) {
        setClassData(data);
        if (page === "" && ((newLevel === 2 && (char.classID === 4 || char.classID === 12)) || (newLevel === 3 && (char.classID === 1 || char.classID === 2 || char.classID === 5 || char.classID === 6 || char.classID === 7 || char.classID === 8 || char.classID === 9 || char.classID === 13)))) {
            setPage("subclass");
        } else if ((page === "subclass" || page === "") && features.some(inv => inv.origin === "eldritchInvocation" || inv.origin === "artificerInfusion")) {
            setPage("replaceFeature");
        } else if ((page === "subclass" || page === "" || page === "replaceFeature") && (char.classID === 11 && (dataLevels[char.level - 1].invocations < dataLevels[newLevel - 1].invocations || replaceFeatureQuestion))) {
            setPage("invocations");
        } else if ((page === "subclass" || page === "" || page === "invocations" || page === "replaceFeature") && (char.classID === 13 && (dataLevels[char.level - 1].infusionsKnown < dataLevels[newLevel - 1].infusionsKnown || replaceFeatureQuestion))) {
            setPage("infusions");
        } else if ((page === "subclass" || page === "" || page === "invocations" || page === "replaceFeature" || page === "infusions") && char.classID === 11 && newLevel === 3) {
            setPage("pactBoon");
        } else if ((page === "subclass" || page === "" || page === "invocations" || page === "replaceFeature" || page === "infusions" || page === "pactBoon") && (newLevel === 4 || newLevel === 8 || newLevel === 12 || newLevel === 16 || newLevel === 19)) {
            setPage("abilityScore");
        } else if ((page === "subclass" || page === "" || page === "invocations" || page === "replaceFeature" || page === "infusions" || page === "pactBoon" || page === "abilityScore") && (chosenFeat && chosenFeat.chooseAbilityNumber > 0)) {
            setPage("featScore");
        } else if (page === "subclass" || page === "" || page === "invocations" || page === "replaceFeature" || page === "infusions" || page === "pactBoon" || page === "abilityScore" || page === "featScore") {
            setPage("health");
        } else if (page === "subclass" || page === "" || page === "invocations" || page === "replaceFeature" || page === "infusions" || page === "pactBoon" || page === "abilityScore" || page === "featScore" || page === "health") {
            setPage("summary");
        } else {
            handleLevelUp();
        }
        //console.log(subclass)
    }

    function reset(feat) {
        setAbilityOption(0)
        setAbilities([0, 0, 0, 0, 0, 0]);
        setAbilityCount(0);
        setFeatSearch("");

        if (!feat) {
            setChosenFeat();
        }
    }

    function handleAbilityOption(val) {
        setAbilityOption(val)
    }

    function handleAbilityClick(index, val) {
        let tempList = abilities;
        tempList[index] = val;
        setAbilities(tempList);
        setAbilityCount(abilityCount + 1);
    }

    function handleInfusionChange(infusion) {
        if (infusions.some(inf => inf.name === infusion.name)) {
            setInfusions(infusions.filter(inf => inf.name !== infusion.name))
            setInfusionCount(infusionCount - 1)
        } else {
            setInfusions(infusions.concat(infusion))
            setInfusionCount(infusionCount + 1)
        }
    }

    function handleInvocationChange(invocation) {
        if (invocations.some(inv => inv.name === invocation.name)) {
            setInvocations(invocations.filter(inv => inv.name !== invocation.name))
            setInvocationCount(invocationCount - 1)
        } else {
            setInvocations(invocations.concat(invocation))
            setInvocationCount(invocationCount + 1)
        }
    }

    function handleSetChosenFeat(feat) {
        setChosenFeat(feat);
        if (feat.fixedAbilities !== "") {
            if (feat.fixedAbilities === "STR" && char.strength < 20) {
                setAbilities([1, 0, 0, 0, 0, 0]);
            } else if (feat.fixedAbilities === "DEX" && char.dexterity < 20) {
                setAbilities([0, 1, 0, 0, 0, 0]);
            } else if (feat.fixedAbilities === "CON" && char.constitution < 20) {
                setAbilities([0, 0, 1, 0, 0, 0]);
            } else if (feat.fixedAbilities === "INT" && char.intelligence < 20) {
                setAbilities([0, 0, 0, 1, 0, 0]);
            } else if (feat.fixedAbilities === "WIS" && char.wisdom < 20) {
                setAbilities([0, 0, 0, 0, 1, 0]);
            } else if (feat.fixedAbilities === "CHA" && char.charisma < 20) {
                setAbilities([0, 0, 0, 0, 0, 1]);
            }
        }
    }

    return (
        <Modal size="lg" show={show} backdrop="static" onHide={() => handleClose(false)} centered>
            {char ?
                <div>
                    <Modal.Header>
                        <Modal.Title>{page === "subclass" ?
                            "Select Subclass"
                            : page === "replaceFeature" ?
                                "Replace " + (char.classID === 11 ? "Eldritch Invocation" : "Artificer Infusion")
                                : page === "invocations" ?
                                    "Select " + (dataLevels[newLevel - 1].invocations - dataLevels[char.level - 1].invocations + (replaceFeatureQuestion ? 1 : 0)) + " Eldritch Invocation"
                                    : page === "infusions" ?
                                        "Select " + (dataLevels[newLevel - 1].infusionsKnown - dataLevels[char.level - 1].infusionsKnown + (replaceFeatureQuestion ? 1 : 0)) + " Artificer Infusion" + (dataLevels[newLevel - 1].infusionsKnown - dataLevels[char.level - 1].infusionsKnown === 1 ? "" : "s")
                                        : page === "pactBoon" ?
                                            "Select Pact Boon"
                                            : page === "abilityScore" ?
                                                "Ability Score Improvement"
                                                : page === "featScore" ?
                                                    "Ability Score Improvement from Feat"
                                                    : page === "health" ?
                                                        "Health"
                                                        : page === "summary" ?
                                                            "Summary"
                                                            : null}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="line-breaks p-3">
                        {isLoading ?
                            <Spinner animation="border" className="mb-4" />
                            :
                            <div>
                                {page === "subclass" ?
                                    classData.subclasses.map((d, i) => (
                                        <div key={i} className="mb-3">
                                            <Form.Check // prettier-ignore
                                                type="radio"
                                                id={i + "subclass"}
                                                name={"subclass"}
                                                label={d.name}
                                                checked={subclass === d}
                                                onChange={e => setSubclass(d)}
                                            />
                                        </div>
                                    ))
                                    : page === "replaceFeature" ?
                                        !replaceFeatureQuestion ?
                                            <div className='text-center'>
                                                <p>Replace 1 {char.classID === 11 ? "Eldritch Invocation" : "Artificer Infusion"}?</p>
                                                <Button variant="outline-primary" onClick={() => setReplaceFeatureQuestion(true)}>
                                                    Yes
                                                </Button>
                                                <p className='mt-3'>Otherwise, click on Continue</p>
                                            </div>
                                            :
                                            features.filter(d => d.origin === "artificerInfusion" || d.origin === "eldritchInvocation").map((d, i) => (
                                                <div key={i} className="mb-3 display-inline">
                                                    <Form.Check // prettier-ignore
                                                        type="radio"
                                                        id={i + "invocation"}
                                                        name={"invocation"}
                                                        label={
                                                            <p>
                                                                {d.name}
                                                                <Button variant="outline-primary ms-2" onClick={() => { setSelectedFeature(d); setShowFeature(true) }}>
                                                                    <FontAwesomeIcon icon={faInfo} />
                                                                </Button>
                                                            </p>
                                                        }
                                                        checked={replaceFeature === d}
                                                        onChange={e => setReplaceFeature(d)}
                                                    />

                                                </div>
                                            ))
                                        : page === "invocations" ?
                                            classData.eldritchInvocation.map((d, i) => (
                                                <div key={i} className="mb-3 display-inline">
                                                    <Form.Check // prettier-ignore
                                                        type="checkbox"
                                                        id={i + "invocation"}
                                                        name={"invocation"}
                                                        label={
                                                            <p>
                                                                {d.name}
                                                                <Button variant="outline-primary ms-2" onClick={() => { setSelectedFeature(d); setShowFeature(true) }}>
                                                                    <FontAwesomeIcon icon={faInfo} />
                                                                </Button>
                                                            </p>
                                                        }
                                                        checked={invocations.some(inv => inv.name === d.name) || features.some(inv => inv.origin === "eldritchInvocation" && inv.name === d.name && (!replaceFeature || inv.name !== replaceFeature.name))}
                                                        onChange={e => handleInvocationChange(d)}
                                                        disabled={(invocationCount >= (dataLevels[newLevel - 1].invocations - dataLevels[char.level - 1].invocations) + (replaceFeatureQuestion ? 1 : 0) && !invocations.some(inv => inv.name === d.name)) || features.some(inv => inv.origin === "eldritchInvocation" && inv.name === d.name)}
                                                    />

                                                </div>
                                            ))
                                            : page === "infusions" ?
                                                classData.artificerInfusion.map((d, i) => (
                                                    <div key={i} className="mb-3 display-inline">
                                                        <Form.Check // prettier-ignore
                                                            type="checkbox"
                                                            id={i + "infusion"}
                                                            name={"infusion"}
                                                            label={
                                                                <p>
                                                                    {d.name}
                                                                    <Button variant="outline-primary ms-2" onClick={() => { setSelectedFeature(d); setShowFeature(true) }}>
                                                                        <FontAwesomeIcon icon={faInfo} />
                                                                    </Button>
                                                                </p>
                                                            }
                                                            checked={infusions.some(inf => inf.name === d.name) || features.some(inf => inf.origin === "artificerInfusion" && inf.name === d.name && (!replaceFeature || inf.name !== replaceFeature.name))}
                                                            onChange={e => handleInfusionChange(d)}
                                                            disabled={(infusionCount >= (dataLevels[newLevel - 1].infusionsKnown - dataLevels[char.level - 1].infusionsKnown) + (replaceFeatureQuestion ? 1 : 0) && !infusions.some(inf => inf.name === d.name)) || features.some(inv => inv.origin === "artificerInfusion" && inv.name === d.name)}
                                                        />

                                                    </div>
                                                ))
                                                : page === "subclass" ?
                                                    classData.subclasses.map((d, i) => (
                                                        <div key={i} className="mb-3">
                                                            <Form.Check // prettier-ignore
                                                                type="radio"
                                                                id={i + "subclass"}
                                                                name={"subclass"}
                                                                label={d.name}
                                                                checked={subclass === d}
                                                                onChange={e => setSubclass(d)}
                                                            />
                                                        </div>
                                                    ))
                                                    : page === "pactBoon" ?
                                                        <>
                                                            {classData.pactBoons.map((d, i) => (
                                                                <div key={i} className="mb-3">
                                                                    <Form.Check // prettier-ignore
                                                                        type="radio"
                                                                        id={i + "pactBoon"}
                                                                        name={"pactBoon"}
                                                                        label={d.name}
                                                                        checked={pactBoon === d}
                                                                        onChange={e => setPactBoon(d)}
                                                                    />
                                                                </div>
                                                            ))}
                                                            {pactBoon ?
                                                                <div className="mt-3 line-breaks">
                                                                    <div className="mt-2" dangerouslySetInnerHTML={{ __html: pactBoon.description }}></div>
                                                                    <b>Source: </b>{pactBoon.source === "HB" ? "Custom" : pactBoon.source}<br />
                                                                </div>
                                                                : null}
                                                        </>
                                                        : page === "abilityScore" ?
                                                            <div className='text-center'>
                                                                <ButtonGroup className='mb-3'>
                                                                    <Button variant={abilityOption === 1 ? "primary" : "outline-primary"} onClick={(e) => handleAbilityOption(1)} disabled={abilityOption > 0}>
                                                                        Choose any +2
                                                                    </Button>
                                                                    <Button variant={abilityOption === 2 ? "primary" : "outline-primary"} onClick={(e) => handleAbilityOption(2)} disabled={abilityOption > 0}>
                                                                        Choose two different +1
                                                                    </Button>
                                                                    <Button variant={abilityOption === 3 ? "primary" : "outline-primary"} onClick={(e) => handleAbilityOption(3)} disabled={abilityOption > 0}>
                                                                        Choose Feat
                                                                    </Button>
                                                                </ButtonGroup>
                                                                {abilityOption === 1 || abilityOption === 2 ? abilityList.map((ability, index) => (
                                                                    <Row className='mb-4 mt-2 h1' key={ability.name}>
                                                                        <Col>
                                                                            {ability.name + " (" + char[ability.fullName] + ")"}
                                                                        </Col>
                                                                        <Col>
                                                                            <Button variant={abilities[index] > 0 ? "primary" : "outline-primary"} size="lg" onClick={(e) => handleAbilityClick(index, abilityOption === 1 ? 2 : 1)} disabled={(abilityOption === 1 && abilityCount >= 1) || (abilityOption === 2 && abilityCount >= 2) || abilities[index] > 0}>
                                                                                {"+" + (abilityOption === 1 ? 2 : 1)}
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                )) : abilityOption === 3 ?
                                                                    <div className='text-start'>
                                                                        {dataFeats ? dataFeats.length > 0 ?
                                                                            <div className="d-grid gap-2">
                                                                                <Form.Group className='mb-2'>
                                                                                    <Form.Control type="text" name="search" id="search" placeholder="Search..." onChange={handleFeatSearch} />
                                                                                </Form.Group>
                                                                            </div>
                                                                            : null : null}
                                                                        <div className="chooseList border rounded">
                                                                            <ListGroup defaultActiveKey={"#" + (chosenFeat ? chosenFeat.id : 0)}>
                                                                                {dataFeats ? dataFeats.length > 0 ? dataFeats.filter(feat => feat.name.toLowerCase().includes(featSearch.toLowerCase())).map(d => (
                                                                                    <ListGroup.Item key={d.id} href={"#" + d.id} action onClick={() => handleSetChosenFeat(d)}>
                                                                                        <Row>
                                                                                            <Col className="text-start fs-bold pe-0">
                                                                                                {d.name}
                                                                                            </Col>
                                                                                            <Col className="text-end pe-2">
                                                                                                {d.source}
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </ListGroup.Item>
                                                                                )) : <i>Create a feat first</i> : null}
                                                                            </ListGroup>
                                                                        </div>
                                                                        {chosenFeat ?
                                                                            <>
                                                                                {chosenFeat.pic ?
                                                                                    <div className='text-center my-3'>
                                                                                        <img src={API_URL + "/featPics/" + chosenFeat.id + ".jpg"} style={{ maxHeight: "250px", maxWidth: "100%" }} alt="" className='rounded'></img>
                                                                                    </div>
                                                                                    : null}
                                                                                <div className="mt-3 line-breaks">
                                                                                    <div className="mt-2" dangerouslySetInnerHTML={{ __html: chosenFeat.description }}></div>
                                                                                    <b>Source: </b>{chosenFeat.source === "HB" ? "Custom" : chosenFeat.source}<br />
                                                                                </div>
                                                                            </>
                                                                            : null}
                                                                    </div>
                                                                    : null}

                                                            </div>
                                                            : page === "featScore" ?
                                                                <div className='text-center'>
                                                                    {abilityList.map((ability, index) => (
                                                                        <Row className='mb-4 h1 mt-2' key={ability.name}>
                                                                            <Col>
                                                                                {ability.name + " (" + char[ability.fullName] + ")"}
                                                                            </Col>
                                                                            <Col>
                                                                                <Button variant={abilities[index] > 0 ? "primary" : "outline-primary"} size="lg"
                                                                                    onClick={(e) => handleAbilityClick(index, 1)}
                                                                                    disabled={abilityCount >= 1 || (chosenFeat.chooseAbilities !== "" && !chosenFeat.chooseAbilities.split(",").includes(ability.name)) || char[ability.fullName] >= 20}>
                                                                                    {(abilityCount >= 1 || (chosenFeat.chooseAbilities !== "" && !chosenFeat.chooseAbilities.split(",").includes(ability.name)) || char[ability.fullName] >= 20) && abilities[index] === 0 ? "0" : "+1"}
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    ))}
                                                                </div>
                                                                : page === "health" ?
                                                                    <div className='text-center'>
                                                                        <p>1d{char.hitDice} + {getModifier(char.constitution)}</p>
                                                                        <h1 className='display-1 mb-4'>{HPincrease}</h1>
                                                                        <Button variant="outline-primary me-2" onClick={() => setHPincrease((Math.ceil((char.hitDice + 1) / 2)) + getModifier(char.constitution))}>Average</Button>
                                                                        <Button variant="outline-primary" onClick={() => setHPincrease(getRandomInt(char.hitDice) + getModifier(char.constitution))}>Roll</Button>
                                                                    </div>
                                                                    : page === "summary" ?
                                                                        <div className='text-center'>
                                                                            <p>
                                                                                <b>Max HP: </b><br />
                                                                                {char.maxHP}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{char.maxHP + HPincrease}
                                                                            </p>
                                                                            {abilities[0] > 0 ?
                                                                                <p>
                                                                                    <b>STR: </b><br />
                                                                                    {char.strength}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{char.strength + abilities[0]}
                                                                                </p>
                                                                                : null}
                                                                            {abilities[1] > 0 ?
                                                                                <p>
                                                                                    <b>DEX: </b><br />
                                                                                    {char.dexterity}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{char.dexterity + abilities[1]}
                                                                                </p>
                                                                                : null}
                                                                            {abilities[2] > 0 ?
                                                                                <p>
                                                                                    <b>CON: </b><br />
                                                                                    {char.constitution}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{char.constitution + abilities[2]}
                                                                                </p>
                                                                                : null}
                                                                            {abilities[3] > 0 ?
                                                                                <p>
                                                                                    <b>INT: </b><br />
                                                                                    {char.intelligence}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{char.intelligence + abilities[3]}
                                                                                </p>
                                                                                : null}
                                                                            {abilities[4] > 0 ?
                                                                                <p>
                                                                                    <b>WIS: </b><br />
                                                                                    {char.wisdom}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{char.wisdom + abilities[4]}
                                                                                </p>
                                                                                : null}
                                                                            {abilities[5] > 0 ?
                                                                                <p>
                                                                                    <b>CHA: </b><br />
                                                                                    {char.charisma}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{char.charisma + abilities[5]}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].profBonus < dataLevels[newLevel - 1].profBonus ?
                                                                                <p>
                                                                                    <b>Proficiency Bonus: </b><br />
                                                                                    {"+" + dataLevels[char.level - 1].profBonus}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{"+" + dataLevels[newLevel - 1].profBonus}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].rages < dataLevels[newLevel - 1].rages ?
                                                                                <p>
                                                                                    <b>Rages: </b><br />
                                                                                    {dataLevels[char.level - 1].rages}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].rages}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].rageDmg < dataLevels[newLevel - 1].rageDmg ?
                                                                                <p>
                                                                                    <b>Rage Damage: </b><br />
                                                                                    {"+" + dataLevels[char.level - 1].rageDmg}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{"+" + dataLevels[newLevel - 1].rageDmg}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].inspirationDice < dataLevels[newLevel - 1].inspirationDice ?
                                                                                <p>
                                                                                    <b>Inspiration Dice: </b><br />
                                                                                    {"1d" + dataLevels[char.level - 1].inspirationDice}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{"1d" + dataLevels[newLevel - 1].inspirationDice}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].songOfRest < dataLevels[newLevel - 1].songOfRest ?
                                                                                <p>
                                                                                    <b>Song of Rest: </b><br />
                                                                                    {"1d" + dataLevels[char.level - 1].songOfRest}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{"1d" + dataLevels[newLevel - 1].songOfRest}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].channelDivinity < dataLevels[newLevel - 1].channelDivinity ?
                                                                                <p>
                                                                                    <b>Channel Divinity: </b><br />
                                                                                    {dataLevels[char.level - 1].channelDivinity + "/Rest"}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].channelDivinity + "/Rest"}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].actionSurge < dataLevels[newLevel - 1].actionSurge ?
                                                                                <p>
                                                                                    <b>Action Surge: </b><br />
                                                                                    {dataLevels[char.level - 1].actionSurge}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].actionSurge}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].indomitable < dataLevels[newLevel - 1].indomitable ?
                                                                                <p>
                                                                                    <b>Indomitable: </b><br />
                                                                                    {dataLevels[char.level - 1].indomitable}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].indomitable}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].martialArtsDie < dataLevels[newLevel - 1].martialArtsDie ?
                                                                                <p>
                                                                                    <b>Martial Arts: </b><br />
                                                                                    {"1d" + dataLevels[char.level - 1].martialArtsDie}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{"1d" + dataLevels[newLevel - 1].martialArtsDie}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].kiPoints < dataLevels[newLevel - 1].kiPoints ?
                                                                                <p>
                                                                                    <b>Ki Points: </b><br />
                                                                                    {dataLevels[char.level - 1].kiPoints}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].kiPoints}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].unarmoredMovement < dataLevels[newLevel - 1].unarmoredMovement ?
                                                                                <p>
                                                                                    <b>Unarmored Movement: </b><br />
                                                                                    {dataLevels[char.level - 1].unarmoredMovement + " ft."}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].unarmoredMovement + " ft."}
                                                                                </p>
                                                                                : null}
                                                                            {char.classID === 7 ?
                                                                                <p>
                                                                                    <b>Lay on Hands: </b><br />
                                                                                    {char.level * 5}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{newLevel * 5}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].sneakAttack < dataLevels[newLevel - 1].sneakAttack ?
                                                                                <p>
                                                                                    <b>Sneak Attack: </b><br />
                                                                                    {dataLevels[char.level - 1].sneakAttack + "d6"}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].sneakAttack + "d6"}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].sorceryPoints < dataLevels[newLevel - 1].sorceryPoints ?
                                                                                <p>
                                                                                    <b>Sorcery Points: </b><br />
                                                                                    {dataLevels[char.level - 1].sorceryPoints}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].sorceryPoints}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].infusionsKnown < dataLevels[newLevel - 1].infusionsKnown ?
                                                                                <p>
                                                                                    <b>Infusions Known: </b><br />
                                                                                    {dataLevels[char.level - 1].infusionsKnown}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].infusionsKnown}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].infusedItems < dataLevels[newLevel - 1].infusedItems ?
                                                                                <p>
                                                                                    <b>Infused Items: </b><br />
                                                                                    {dataLevels[char.level - 1].infusedItems}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].infusedItems}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].cantripsKnown < dataLevels[newLevel - 1].cantripsKnown ?
                                                                                <p>
                                                                                    <b>Cantrips Known: </b><br />
                                                                                    {dataLevels[char.level - 1].cantripsKnown}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].cantripsKnown}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].spellsKnown < dataLevels[newLevel - 1].spellsKnown ?
                                                                                <p>
                                                                                    <b>Spells Known: </b><br />
                                                                                    {dataLevels[char.level - 1].spellsKnown}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].spellsKnown}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].spellSlots < dataLevels[newLevel - 1].spellSlots ?
                                                                                <p>
                                                                                    <b>Spell Slots: </b><br />
                                                                                    {dataLevels[char.level - 1].spellSlots}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].spellSlots}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].slotLevel < dataLevels[newLevel - 1].slotLevel ?
                                                                                <p>
                                                                                    <b>Slot Level: </b><br />
                                                                                    {dataLevels[char.level - 1].slotLevel === 1 ? "1st" : dataLevels[char.level - 1].slotLevel === 2 ? "2nd" : dataLevels[char.level - 1].slotLevel === 3 ? "3rd" : dataLevels[char.level - 1].slotLevel + "th"}
                                                                                    <FontAwesomeIcon icon={faArrowRight} className='mx-2' />
                                                                                    {dataLevels[newLevel - 1].slotLevel === 1 ? "1st" : dataLevels[newLevel - 1].slotLevel === 2 ? "2nd" : dataLevels[newLevel - 1].slotLevel === 3 ? "3rd" : dataLevels[newLevel - 1].slotLevel + "th"}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].spellSlotLvl1 < dataLevels[newLevel - 1].spellSlotLvl1 ?
                                                                                <p>
                                                                                    <b>1st-Level Spell Slots: </b><br />
                                                                                    {dataLevels[char.level - 1].spellSlotLvl1}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].spellSlotLvl1}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].spellSlotLvl2 < dataLevels[newLevel - 1].spellSlotLvl2 ?
                                                                                <p>
                                                                                    <b>2nd-Level Spell Slots: </b><br />
                                                                                    {dataLevels[char.level - 1].spellSlotLvl2}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].spellSlotLvl2}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].spellSlotLvl3 < dataLevels[newLevel - 1].spellSlotLvl3 ?
                                                                                <p>
                                                                                    <b>3rd-Level Spell Slots: </b><br />
                                                                                    {dataLevels[char.level - 1].spellSlotLvl3}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].spellSlotLvl3}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].spellSlotLvl4 < dataLevels[newLevel - 1].spellSlotLvl4 ?
                                                                                <p>
                                                                                    <b>4th-Level Spell Slots: </b><br />
                                                                                    {dataLevels[char.level - 1].spellSlotLvl4}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].spellSlotLvl4}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].spellSlotLvl5 < dataLevels[newLevel - 1].spellSlotLvl5 ?
                                                                                <p>
                                                                                    <b>5th-Level Spell Slots: </b><br />
                                                                                    {dataLevels[char.level - 1].spellSlotLvl5}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].spellSlotLvl5}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].spellSlotLvl6 < dataLevels[newLevel - 1].spellSlotLvl6 ?
                                                                                <p>
                                                                                    <b>6th-Level Spell Slots: </b><br />
                                                                                    {dataLevels[char.level - 1].spellSlotLvl6}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].spellSlotLvl6}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].spellSlotLvl7 < dataLevels[newLevel - 1].spellSlotLvl7 ?
                                                                                <p>
                                                                                    <b>7th-Level Spell Slots: </b><br />
                                                                                    {dataLevels[char.level - 1].spellSlotLvl7}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].spellSlotLvl7}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].spellSlotLvl8 < dataLevels[newLevel - 1].spellSlotLvl8 ?
                                                                                <p>
                                                                                    <b>8th-Level Spell Slots: </b><br />
                                                                                    {dataLevels[char.level - 1].spellSlotLvl8}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].spellSlotLvl8}
                                                                                </p>
                                                                                : null}
                                                                            {dataLevels[char.level - 1].spellSlotLvl9 < dataLevels[newLevel - 1].spellSlotLvl9 ?
                                                                                <p>
                                                                                    <b>9th-Level Spell Slots: </b><br />
                                                                                    {dataLevels[char.level - 1].spellSlotLvl9}<FontAwesomeIcon icon={faArrowRight} className='mx-2' />{dataLevels[newLevel - 1].spellSlotLvl9}
                                                                                </p>
                                                                                : null}
                                                                            <hr />
                                                                            {classData ? classData.features.filter(feature => feature.level === newLevel && ((feature.type === "subclass" && feature.originID === subclass.id) || feature.type !== "subclass")).concat(chosenFeat).concat(invocations).concat(pactBoon).concat(infusions).filter(feature => feature).map((feature, index) => (
                                                                                <div key={feature.id} className="text-start mb-2 mt-4">
                                                                                    <Row>
                                                                                        <Col className='cursor-pointer ps-3' onClick={() => setOpen(values => ({ ...values, [index]: !open[index] }))}>
                                                                                            <h6><FontAwesomeIcon icon={open[index] ? faChevronDown : faChevronUp} /> {feature.name}</h6>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Collapse in={open[index]}>
                                                                                        <div className='m-0 p-0 line-breaks feature'>
                                                                                            {feature.type === "feat" ?
                                                                                                <p className='mb-3'><i>Feat</i></p>
                                                                                                : feature.type === "pactBoon" ?
                                                                                                    <p className='mb-3'><i>Pact Boon</i></p>
                                                                                                    : feature.type === "eldritchInvocation" ?
                                                                                                        <p className='mb-3'><i>Eldritch Invocation</i></p>
                                                                                                        : feature.type === "artificerInfusion" ?
                                                                                                            <p className='mb-3'><i>Artificer Infusion</i></p>
                                                                                                            : feature.type !== "" ?
                                                                                                                <p className='mb-3'><i>{feature.level + (feature.level === 1 ? "st" : feature.level === 2 ? "nd" : feature.level === 3 ? "rd" : "th") + "-level " +
                                                                                                                    (feature.type === "class" ?
                                                                                                                        char.className
                                                                                                                        : feature.type === "subclass" ?
                                                                                                                            subclass.name
                                                                                                                            : feature.type === "ancestry" ?
                                                                                                                                char.ancestryName
                                                                                                                                : feature.type === "background" ?
                                                                                                                                    char.backgroundName
                                                                                                                                    : feature.type)
                                                                                                                    + " feature"}</i></p>
                                                                                                                : null
                                                                                            }
                                                                                            <div className="m-0 p-0" dangerouslySetInnerHTML={{ __html: feature.description }}>
                                                                                            </div>
                                                                                            {
                                                                                                feature.id === 887 ? classData.pactBoons.filter(pactBoon => pactBoon.type === "pactBoon").map(pactBoon => (
                                                                                                    <div key={pactBoon.id}>
                                                                                                        <h6 className='mt-3 mb-2' id={pactBoon.id}>{pactBoon.name}</h6>
                                                                                                        <div dangerouslySetInnerHTML={{ __html: pactBoon.description }}></div>
                                                                                                    </div>
                                                                                                )) : null
                                                                                            }
                                                                                        </div>
                                                                                    </Collapse>
                                                                                    <hr />
                                                                                </div>
                                                                            )) : null}
                                                                            <div className="alert alert-warning">
                                                                                Do you really want to level up?<br />
                                                                                <b>This can't be undone!</b>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                }
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            (page === "abilityScore" && abilityOption !== 0) || page === "featScore" ?
                                <Button
                                    variant="outline-primary"
                                    onClick={() => reset(page === "featScore")}
                                >
                                    Reset
                                </Button>
                                : null
                        }
                        <Button
                            variant="secondary"
                            onClick={() => handleClose()}>
                            Abort
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => update(classData)}
                            disabled={(page === "health" && !(HPincrease > 0)) ||
                                (page === "subclass" && subclass.id === 0) ||
                                (page === "replaceFeature" && !replaceFeature && replaceFeatureQuestion) ||
                                (page === "invocations" && !(invocationCount >= (dataLevels[newLevel - 1].invocations - dataLevels[char.level - 1].invocations))) ||
                                (page === "infusions" && !(infusionCount >= (dataLevels[newLevel - 1].infusionsKnown - dataLevels[char.level - 1].infusionsKnown))) ||
                                (page === "pactBoon" && !pactBoon) ||
                                (page === "abilityScore" && abilityOption === 0) ||
                                (page === "abilityScore" && ((abilityOption === 1 && abilityCount < 1) || (abilityOption === 2 && abilityCount < 2))) ||
                                (page === "abilityScore" && abilityOption === 3 && !chosenFeat)}>

                            {page === "check" ? "Yes" : "Continue"}
                        </Button>
                    </Modal.Footer>
                    <ShowFeat show={showFeature} handleClose={handleCloseFeature} feat={selectedFeature} />
                </div>
                : null}
        </Modal>
    )
}
