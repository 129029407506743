import React, { useEffect, useState } from 'react'
import { logout, sendRequestAuth } from '../../services/apiFunctions';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Col, Form, Row, Spinner, Button } from 'react-bootstrap';
import ShowBackground from '../ShowBackground';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import BackgroundDetailSelect from './BackgroundDetailSelect';

export default function BackgroundSelect({ selectedBackground, setSelectedBackground, saveBackground }) {

    const [dataBackgrounds, setDataBackgrounds] = useState([])
    const [search, setSearch] = useState("")
    const [background, setBackground] = useState()

    const [showBackground, setShowBackground] = useState(false);
    const handleCloseBackground = () => {
        setShowBackground(false);
    };

    const [showBackgroundDetail, setShowBackgroundDetail] = useState(false);
    const handleCloseBackgroundDetail = () => {
        setSelectedBackground(background)
        setShowBackgroundDetail(false);
    };

    const navigate = useNavigate()

    const { mutate, isLoading } = useMutation(sendRequestAuth, {
        onError: () => {
            setDataBackgrounds("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setDataBackgrounds(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                setDataBackgrounds(data.data.message);
            }
        },
    });

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    useEffect(() => {
        const request = { name: "getUserBackgrounds", param: {} };
        mutate(request);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleBackgroundShowClick(background) {
        setBackground(background);
        setShowBackground(true);
    }

    return (
        <div>
            {dataBackgrounds ? dataBackgrounds.length > 0 ?
                <div className="d-grid gap-2">
                    <Form.Group className='mb-4'>
                        <Form.Control type="text" name="search" id="search" placeholder="Search..." onChange={handleSearch} />
                    </Form.Group>
                </div>
                : null : null}
            {
                isLoading ? <Spinner animation="border" className="mb-4" /> : null
            }
            {dataBackgrounds ? dataBackgrounds.length > 0 ? dataBackgrounds.filter(background =>
                background.name.toLowerCase().includes(search.toLowerCase())).map(d => (
                    <div className={d.id === selectedBackground.id ? 'alert alert-light text-primary bg-white-tp p-1' : 'alert alert-light text-primary p-1'}
                        key={d.id}>
                        <Row>
                            <Col className="text-start py-2 ms-2 pe-0 cursor-pointer" onClick={() => { setBackground(d); setShowBackgroundDetail(true); }}>
                                <h4>{d.name}</h4>
                            </Col>
                            <Col className="d-flex col-auto align-items-center me-2">
                                <Button className="" variant="outline-primary" onClick={() => { handleBackgroundShowClick(d); }}>
                                    <FontAwesomeIcon icon={faBook} />
                                </Button>
                            </Col>
                        </Row>

                    </div>
                )) : <i>You have no activated sources with backgrounds</i> : null}
            <ShowBackground show={showBackground} handleClose={handleCloseBackground} background={background} />
            <BackgroundDetailSelect show={showBackgroundDetail} handleClose={handleCloseBackgroundDetail} background={background} saveBackground={saveBackground} />
        </div>
    )
}
