import { faCheck, faHeart, faHeartBroken, faPlus, faShield, faShieldHalved, faSkull, faUpLong, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, OverlayTrigger, ProgressBar, Row, Spinner, Tooltip } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { API_URL, conditions, getHealthBeat, getHealthColor, getHealthPerc, getModifier, getModifierOfScore, logout, sendRequestAuth } from '../../services/apiFunctions';
import ChangeCustom from '../charSheetModals/ChangeCustom';
import ChangeDeathSaves from '../charSheetModals/ChangeDeathSaves';
import ChangeHitDice from '../charSheetModals/ChangeHitDice';
import ChangeSpeed from '../charSheetModals/ChangeSpeed';
import MiscStats from '../charSheetModals/MiscStats';
import NewWeapon from '../charSheetModals/NewWeapon';
import XpChange from '../charSheetModals/XpChange';
import HealthChange from '../HealthChange';
import MonsterCard from '../MonsterCard';
import LevelUp from '../charSheetModals/LevelUp';
import ShowCondition from '../ShowCondition';
import EditConditions from '../EditConditions';

export default function Front({ char, reload, reloadComp, weapons, companions, isLoadingWeapons, features, showHidden, setShowHidden }) {
    const [health, setHealth] = useState("")
    const [weapon, setWeapon] = useState("")
    const [customCounter, setCustomCounter] = useState([])
    const [selectedCounter, setSelectedCounter] = useState([])
    const [condition, setCondition] = useState(null)
    const [conditionChangePlayer, setConditionChangePlayer] = useState(true)

    const [currentChar, setCurrentChar] = useState({ id: 0, hp: 0, maxHP: 0, tempHP: 0, func: "", conditions: "" });

    const [showHealth, setShowHealth] = useState(false);
    const handleCloseHealth = (reloadChar = false) => setShowHealth(false);
    const handleShowHealth = () => setShowHealth(true);

    const [showMisc, setShowMisc] = useState(false);
    const handleCloseMisc = () => setShowMisc(false);

    const [showCondition, setShowCondition] = useState(false);
    const handleCloseCondition = () => setShowCondition(false);

    const [showConditionEdit, setShowConditionEdit] = useState(false);
    const handleCloseConditionEdit = () => setShowConditionEdit(false);

    const [showXp, setShowXp] = useState(false);
    const handleCloseXp = () => setShowXp(false);

    const [showSpeed, setShowSpeed] = useState(false);
    const handleCloseSpeed = () => setShowSpeed(false);

    const [showHitDice, setShowHitDice] = useState(false);
    const handleCloseHitDice = () => setShowHitDice(false);

    const [showDeath, setShowDeath] = useState(false);
    const handleCloseDeath = () => setShowDeath(false);

    const [showWeapon, setShowWeapon] = useState(false);
    const handleCloseWeapon = () => setShowWeapon(false);

    const [showCustom, setShowCustom] = useState(false);
    const handleCloseCustom = (reload) => {
        setShowCustom(false);
        if (reload) {
            reloadCounter();
        }
    }

    const [showCompDetails, setShowCompDetails] = useState(false);
    const handleCloseCompDetails = () => setShowCompDetails(false);

    const [showLevelUp, setShowLevelUp] = useState(false);
    const handleCloseLevelUp = () => setShowLevelUp(false);

    const navigate = useNavigate()

    const { mutate: mutateHealth } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setHealth(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    function reloadHealth() {
        if (char) {
            const request2 = {
                name: "getHPofChar",
                param: {
                    charID: parseInt(char.id)
                }
            };
            mutateHealth(request2);
            reloadComp()
        }
    }

    const { mutate: mutateCustomCounter } = useMutation(sendRequestAuth, {
        onError: () => {
            console.log("An error occured while fetching data.");
        },
        onSuccess: (data) => {
            //console.log(data.data.result);
            if (data.data.status === 200) {
                setCustomCounter(data.data.result);
            } else if (data.data.status === 302) {
                logout();
                navigate("/");
            } else {
                console.log(data.data.message);
            }
        },
    });

    function reloadCounter() {
        const request2 = {
            name: "getCustomCounter",
            param: {
                charID: parseInt(char.id)
            }
        };
        mutateCustomCounter(request2);
    }

    useEffect(() => {
        reloadHealth(char.id);
        reloadCounter()

        const interval = setInterval(() => {
            reloadHealth(char.id);
        }, 5000);
        return () => clearInterval(interval);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Row className='mb-3 alert alert-light bg-white-tp mx-0'>
                {char.pic ?
                    <Col sm={2} className="text-center p-0">
                        <img src={API_URL + "/playerCharPics/" + char.id + ".png"} alt="" height="100px" className='rounded'></img>
                    </Col>
                    : null}
                <Col className="text-center text-primary cursor-pointer " onClick={() => { setShowMisc(true); }}>
                    <Row className=''>
                        <Col>Armor Class</Col>
                        <Col>Initiative</Col>
                    </Row>
                    <Row className='h3'>
                        <Col>{char.AC}</Col>
                        <Col>{getModifier(char.dexterity) + char.initiative > 0 ? "+" : ""}{getModifier(char.dexterity) + char.initiative}</Col>
                    </Row>
                    <Row className=''>
                        <Col>Passive Perception</Col>
                        <Col>Proficiency Bonus</Col>
                    </Row>
                    <Row className='h3'>
                        <Col>{10 + getModifier(char.wisdom) + (char.proficiencyBonus + char.proficiencyBonusMod) * char.perceptionProf}</Col>
                        <Col>+{char.proficiencyBonus + char.proficiencyBonusMod}</Col>
                    </Row>
                </Col>
                <ProgressBar style={{ height: "30px", backgroundColor: "#9b9b9b" }} className="cursor-pointer p-0 mt-2" onClick={() => { setShowXp(true); }}>
                    <ProgressBar striped variant={"warning"} now={char.level === 20 ? 100 : (char.xp - char.xpForLvl) / char.xpToNext * 100} key={1} />
                    <div className="position-absolute bar-lable  p-0 mt-1 text-light fill-available">
                        <b>Level {char.level}{char.level === 20 ? null : " - " + (char.xp - char.xpForLvl) + "/" + char.xpToNext}</b>
                    </div>
                </ProgressBar>
                {char.xp - char.xpForLvl >= char.xpToNext ?
                    <Button className='mt-2' variant='outline-primary' onClick={(e) => setShowLevelUp(true)}>
                        Level Up <FontAwesomeIcon icon={faUpLong} bounce={true} style={{ "--fa-animation-duration": "1.5s" }} />
                    </Button>
                    : null}
            </Row>
            {char.conditions !== "" ?
                <Row className="mt-2 mb-3 mx-0 py-2 text-center text-primary alert alert-light bg-white-tp">
                    {char.conditions.split(",").map(condID => (
                        <Col>
                            <OverlayTrigger overlay={<Tooltip id={condID}>{conditions.find(cond => cond.id === parseInt(condID)).name}</Tooltip>}>
                                <div className='cursor-pointer' onClick={() => { setCondition(conditions.find(cond => cond.id === parseInt(condID))); setShowCondition(true); setConditionChangePlayer(true); }}>
                                    <img alt="" id={condID} src={conditions.find(cond => cond.id === parseInt(condID)).pic} style={{ height: "40px", width: "auto" }} />
                                </div>
                            </OverlayTrigger>
                        </Col>
                    ))}
                </Row>
                : null}
            {health.tempHP > 0 ?
                <ProgressBar style={{ height: "30px" }}
                    className={`cursor-pointer mb-1 ${health.tempHP % 2 === 0 ? "bg-tempHP" : "bg-info"}`}
                    onClick={() => { handleShowHealth(true); setCurrentChar({ id: char.id, hp: health.hp, maxHP: health.maxHP, tempHP: health.tempHP, func: "updateHealthOfChar" }); setConditionChangePlayer(true); }}>
                    {[...Array(health.tempHP)].map((e, i) =>
                        <ProgressBar className="skew" variant={i % 2 === 0 ? "info" : "info2"} now={100 / health.tempHP} key={i} />
                    )}
                    <div className="position-absolute bar-lable h4 p-0 text-light fill-available">
                        <FontAwesomeIcon icon={faShield} /> {health.tempHP}
                    </div>
                </ProgressBar>
                : null}
            <ProgressBar style={{ height: "40px", backgroundColor: "#9b9b9b" }} className="cursor-pointer p-0" onClick={() => { handleShowHealth(true); setCurrentChar({ id: char.id, hp: health.hp, maxHP: health.maxHP, tempHP: health.tempHP, func: "updateHealthOfChar" }); setConditionChangePlayer(true); }}>
                <ProgressBar variant={getHealthColor(health.hp, health.maxHP)} now={getHealthPerc(health.hp, health.maxHP)} key={1} />
                <div className="position-absolute bar-lable h5 p-0 mt-0 text-light fill-available">
                    <FontAwesomeIcon className="mt-2" fade={getHealthBeat(health.hp, health.maxHP)} icon={health.hp <= 0 ? faHeartBroken : getHealthPerc(health.hp, health.maxHP) <= 10 ? faHeartBroken : faHeart} /> {health.hp}/{health.maxHP}
                </div>
            </ProgressBar>

            <div className='text-primary mt-3 mb-0 alert alert-light bg-white-tp cursor-pointer py-2' onClick={() => { setShowSpeed(true); }}>
                <Row className=''>
                    <Col>Walk</Col>
                    <Col>{char.hover ? "Hover" : "Fly"}</Col>
                    <Col>Climb</Col>
                    <Col>Swim</Col>
                </Row>
                <Row className='h5'>
                    <Col>{char.speed} ft.</Col>
                    <Col>{char.flySpeed} ft.</Col>
                    <Col>{char.climbSpeed} ft.</Col>
                    <Col>{char.swimSpeed} ft.</Col>
                </Row>
            </div>

            {[...Array(Math.ceil(customCounter.length / 2))].map((e, i) => (
                <Row className="mt-2" key={i}>
                    <Col className={customCounter[(i * 2) + 1] ? "pe-1" : "py-1"}>
                        <div className='text-primary my-0 alert alert-light bg-white-tp cursor-pointer py-2' onClick={() => { setSelectedCounter(customCounter[i * 2]); setShowCustom(true); }}>
                            <Row className=''>
                                <Col>{customCounter[i * 2].name}</Col>
                            </Row>
                            <Row className='h5 mt-1'>
                                <Col>{(customCounter[i * 2].infoTextPos === 1 ? customCounter[i * 2].infoText : "") +
                                    customCounter[i * 2].val +
                                    (customCounter[i * 2].infoTextPos === 2 ? customCounter[i * 2].infoText : (customCounter[i * 2].infoTextPos === 0 ? "/" : "") +
                                        (customCounter[i * 2].maxValAbility !== "" ?
                                            (customCounter[i * 2].maxValAbility === "charisma1" ?
                                                (getModifier(char["charisma"]) + 1 > 1 ? getModifier(char["charisma"]) + 1 : 1)
                                                : customCounter[i * 2].maxValAbility === "level5" ?
                                                    (char.level * 5)
                                                    :
                                                    (getModifier(char[customCounter[i * 2].maxValAbility]) > 1 ? getModifier(char[customCounter[i * 2].maxValAbility]) : 1))
                                            : (customCounter[i * 2].infoTextPos === 0 ? customCounter[i * 2].maxVal : "")))}</Col>
                            </Row>
                        </div>
                    </Col>
                    {customCounter[(i * 2) + 1] ?
                        <Col className="ps-1">
                            <div className='text-primary my-0 alert alert-light bg-white-tp cursor-pointer py-2' onClick={() => { setSelectedCounter(customCounter[(i * 2) + 1]); setShowCustom(true); }}>
                                <Row className=''>
                                    <Col>{customCounter[(i * 2) + 1].name}</Col>
                                </Row>
                                <Row className='h5 mt-1'>
                                    <Col>{(customCounter[(i * 2) + 1].infoTextPos === 1 ? customCounter[(i * 2) + 1].infoText : "") +
                                        customCounter[(i * 2) + 1].val +
                                        (customCounter[(i * 2) + 1].infoTextPos === 2 ? customCounter[(i * 2) + 1].infoText : (customCounter[(i * 2) + 1].infoTextPos === 0 ? "/" : "") +
                                            (customCounter[(i * 2) + 1].maxValAbility !== "" ?
                                                (customCounter[(i * 2) + 1].maxValAbility === "charisma1" ?
                                                    (getModifier(char["charisma"]) + 1 > 1 ? getModifier(char["charisma"]) + 1 : 1)
                                                    : customCounter[(i * 2) + 1].maxValAbility === "level5" ?
                                                        (char.level * 5)
                                                        :
                                                        (getModifier(char[customCounter[(i * 2) + 1].maxValAbility]) > 1 ? getModifier(char[customCounter[(i * 2) + 1].maxValAbility]) : 1))
                                                : (customCounter[(i * 2) + 1].infoTextPos === 0 ? customCounter[(i * 2) + 1].maxVal : "")))}</Col>
                                </Row>
                            </div>
                        </Col>
                        : null}
                </Row>
            ))}
            <Row className="mt-2">
                <Col className="pe-1">
                    <div className='text-primary my-0 alert alert-light bg-white-tp cursor-pointer py-2' onClick={() => { setShowHitDice(true); }}>
                        <Row className=''>
                            <Col>Hit Dice</Col>
                        </Row>
                        <Row className='h5 mt-1'>
                            <Col>1d{char.hitDice}</Col>
                            <Col>{char.hitDiceAmount}/{char.level}</Col>
                        </Row>
                    </div>
                </Col>
                <Col className="ps-1">
                    <div className='text-primary my-0 alert alert-light bg-white-tp cursor-pointer py-2' onClick={() => { setShowDeath(true); }}>
                        <Row className=''>
                            <Col>Death Saves</Col>
                        </Row>
                        <Row className='mt-1'>
                            <Row className=''>
                                <Col><FontAwesomeIcon icon={faCheck} /></Col>
                                <Col className={`${char.deathSuccess >= 1 ? 'bg-success' : 'bg-light'} p-0 me-1`}></Col>
                                <Col className={`${char.deathSuccess >= 2 ? 'bg-success' : 'bg-light'} p-0 me-1`}></Col>
                                <Col className={`${char.deathSuccess >= 3 ? 'bg-success' : 'bg-light'} p-0 me-1`}></Col>
                            </Row>
                            <Row className='mt-1'>
                                <Col><FontAwesomeIcon icon={faXmark} /></Col>
                                <Col className={`${char.deathFail >= 1 ? 'bg-danger' : 'bg-light'} p-0 me-1`}></Col>
                                <Col className={`${char.deathFail >= 2 ? 'bg-danger' : 'bg-light'} p-0 me-1`}></Col>
                                <Col className={`${char.deathFail >= 3 ? 'bg-danger' : 'bg-light'} p-0 me-1`}></Col>
                            </Row>
                        </Row>
                    </div>
                </Col>
            </Row>

            <hr className='text-primary' />
            {
                isLoadingWeapons ? <Spinner animation="border" className="mb-4" />
                    :
                    weapons.filter(weapon => weapon.hidden === 1).length > 0 ?
                        <Form.Check // prettier-ignore
                            className='mb-3 text-start'
                            type="checkbox"
                            id={"hidden"}
                            name={"hidden"}
                            label={"Show hidden weapons"}
                            checked={showHidden}
                            onChange={e => setShowHidden(!showHidden)}
                        />
                        : null
            }
            {weapons ? weapons.filter(weapon => showHidden || weapon.hidden === 0).map((weapon) => (
                <div key={weapon.id} className='text-primary mt-1 p-0 alert alert-light bg-white-tp cursor-pointer' onClick={() => { setWeapon(weapon); setShowWeapon(true); }}>
                    <Row>
                        <Col className="py-3 m-0">
                            <h5 className='my-0 p-0'>{weapon.name}</h5>
                        </Col>
                    </Row>
                    <hr className="text-primary m-0" />
                    <Row>
                        <Col className="border-end border-primary py-3 m-0">
                            Attack
                            <h5>
                                {getModifierOfScore(char, weapon.attackScore) + weapon.attackMod + weapon.proficient * (char.proficiencyBonus + char.proficiencyBonusMod) > 0 ? "+" : null}
                                {getModifierOfScore(char, weapon.attackScore) + weapon.attackMod + weapon.proficient * (char.proficiencyBonus + char.proficiencyBonusMod)}
                            </h5>
                        </Col>
                        <Col className="border-end border-primary py-2 m-0">
                            Damage
                            <h5 className='m-0'>
                                {weapon.damageDiceMultiplier}d{weapon.damageDice}
                                {getModifierOfScore(char, weapon.damageScore) + weapon.damageMod >= 0 ? "+" : null}
                                {getModifierOfScore(char, weapon.damageScore) + weapon.damageMod}
                            </h5>
                            <i>{weapon.damageType}</i>
                        </Col>
                        <Col className="py-3 m-0">
                            Range:
                            <h5>{weapon.attackRange} ft.</h5>
                        </Col>
                    </Row>
                </div>
            )) : null}
            <div className="d-grid gap-2">
                <Button variant="outline-primary" onClick={() => { setWeapon(null); setShowWeapon(true); }}>
                    <FontAwesomeIcon icon={faPlus} /> Weapon
                </Button>
            </div>

            {companions && companions.length > 0 ?
                <div>
                    <hr className='text-primary' />
                    {companions.map((comp, index) => (
                        <div className={comp.hp <= 0 ? 'alert alert-dark text-primary p-1 h4' : 'alert alert-light text-primary bg-white-tp p-1 h4'} key={comp.id} >
                            <Row className="cursor-pointer" onClick={() => { setShowCompDetails(true); setCurrentChar(comp); }}>
                                {comp.pic ?
                                    <Col className="text-start">
                                        <img src={API_URL + "/monsterCharPics/" + comp.monsterID + ".png"} alt="" height="100px" className='rounded'></img>
                                    </Col>
                                    : null}
                                <Col className="d-flex justify-content-center align-items-center">
                                    <h2>{comp.name}</h2>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={faShieldHalved} className="me-1" /> {comp.AC}
                                </Col>
                            </Row>
                            {comp.conditions !== "" ?
                                <Row className="my-1 mx-1 p-1 text-center text-primary alert alert-light bg-tp bg-white-tp">
                                    {comp.conditions.split(",").map(condID => (
                                        <Col>
                                            <OverlayTrigger overlay={<Tooltip id={condID}>{conditions.find(cond => cond.id === parseInt(condID)).name}</Tooltip>}>
                                                <div className='cursor-pointer' onClick={() => {
                                                    setCurrentChar(comp);
                                                    setConditionChangePlayer(false);
                                                    setCondition(conditions.find(cond => cond.id === parseInt(condID)));
                                                    setShowCondition(true);
                                                }}>
                                                    <img alt="" id={condID} src={conditions.find(cond => cond.id === parseInt(condID)).pic} style={{ height: "30px", width: "auto" }} />
                                                </div>
                                            </OverlayTrigger>
                                        </Col>
                                    ))}
                                </Row>
                                : null}
                            <Row className="px-3-5 mt-1">
                                {comp.tempHP > 0 ?
                                    <ProgressBar style={{ height: "25px" }}
                                        className={`cursor-pointer mb-1 ${comp.tempHP % 2 === 0 ? "bg-tempHP" : "bg-info"}`}
                                        onClick={() => { handleShowHealth(true); setCurrentChar({ id: comp.id, hp: comp.hp, maxHP: comp.maxHP, tempHP: comp.tempHP, func: "updateHealthOfComp", conditions: comp.conditions }); setConditionChangePlayer(false); }}>
                                        {[...Array(comp.tempHP)].map((e, i) =>
                                            <ProgressBar className="skew" variant={i % 2 === 0 ? "info" : "info2"} now={100 / comp.tempHP} key={i} />
                                        )}
                                        <div className="position-absolute bar-lable h5 p-0 text-light fill-available">
                                            <FontAwesomeIcon icon={faShield} /> {comp.tempHP}
                                        </div>
                                    </ProgressBar>
                                    : null}
                                <ProgressBar style={{ height: "25px", backgroundColor: "#9b9b9b" }} className="cursor-pointer p-0" onClick={() => { handleShowHealth(true); setCurrentChar({ id: comp.id, hp: comp.hp, maxHP: comp.maxHP, tempHP: comp.tempHP, func: "updateHealthOfComp", conditions: comp.conditions }); setConditionChangePlayer(false); }}>
                                    <ProgressBar variant={getHealthColor(comp.hp, comp.maxHP)} now={getHealthPerc(comp.hp, comp.maxHP)} key={1} />
                                    <div className="position-absolute bar-lable h5 p-0 mt-0 text-light fill-available">
                                        <FontAwesomeIcon fade={getHealthBeat(comp.hp, comp.maxHP)} icon={comp.hp <= 0 ? faSkull : getHealthPerc(comp.hp, comp.maxHP) <= 10 ? faHeartBroken : faHeart} /> {comp.hp}/{comp.maxHP}
                                    </div>
                                </ProgressBar>
                            </Row>
                        </div>
                    ))}
                </div>
                : null}

            <HealthChange show={showHealth} charID={currentChar.id} healthData={{ hp: currentChar.hp, maxHP: currentChar.maxHP, tempHP: currentChar.tempHP }} handleClose={handleCloseHealth} func={currentChar.func} reloadHealth={reloadHealth} setShowEdit={setShowConditionEdit}></HealthChange>
            <MiscStats show={showMisc} char={char} handleClose={handleCloseMisc} reload={reload} />
            <ShowCondition show={showCondition} handleClose={handleCloseCondition} setShowEdit={setShowConditionEdit} condition={condition} />
            <EditConditions show={showConditionEdit} handleClose={handleCloseConditionEdit} charConditions={conditionChangePlayer ? char.conditions.split(",") : currentChar.conditions.split(",")} charID={conditionChangePlayer ? char.id : currentChar.id} reload={reload} charType={conditionChangePlayer ? "player" : "companion"} />
            <XpChange show={showXp} handleClose={handleCloseXp} setShowLevelUp={setShowLevelUp} char={char} reload={reload} />
            <ChangeSpeed show={showSpeed} handleClose={handleCloseSpeed} char={char} reload={reload} />
            <ChangeHitDice show={showHitDice} handleClose={handleCloseHitDice} char={char} reload={reload} />
            <ChangeDeathSaves show={showDeath} handleClose={handleCloseDeath} char={char} reload={reload} />
            <NewWeapon show={showWeapon} handleClose={handleCloseWeapon} weapon={weapon} charID={char.id} reload={reload} />
            <ChangeCustom show={showCustom} handleClose={handleCloseCustom} char={char} counter={selectedCounter} reload={reload} />
            <LevelUp show={showLevelUp} handleClose={handleCloseLevelUp} char={char} reload={reload} features={features} />
            {currentChar.id !== 0 ?
                <MonsterCard show={showCompDetails} handleClose={handleCloseCompDetails} monsterID={currentChar.monsterID} charID={currentChar.id} charName={currentChar.name} charType={"compPlayer"} />
                : null}
        </div>
    )
}
